import { CaretCircleLeft, Pencil } from '@phosphor-icons/react';
import Heading from '../../components/atoms/Heading';
import ROUTES from '../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/atoms/Text';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import Button from '../../components/atoms/Button';
import {
	deleteAccount,
	getUserProfile,
	patchUserProfile,
} from '../../services/profile.service';
import { setProfile } from '../../redux/slices/profile.slice';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { showAlert } from '../../redux/slices/alert.slice';

import flag_mx from '../../assets/img/svg/flag_mx.svg';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { REGEX } from '../../shared/constants/regex';
import Modal from '../../components/molecules/Modal/Modal';
import {
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../shared/utils/general.util';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';
import { logout, removeToken } from '../../redux/slices/auth.slice';
import { restartAppState } from '../../redux/slices/sat.slice';
import { restartTaxPayerData } from '../../redux/slices/taxProfile.slice';

type UserProfileUpdate = {
	first_name?: string;
	father_surname?: string;
	email?: string;
};

const AccountHeru = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const logClevertapEvent = useCleverTapEvent();
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [updateName, setUpdateName] = useState(false);
	const [updateEmail, setUpdateEmail] = useState(false);

	const [firstNames, setFirstNames] = useState(userProfile?.first_name);
	const [fatherSurname, setFatherSurname] = useState(
		userProfile?.father_surname,
	);
	const [email, setEmail] = useState(userProfile?.email);
	const [showError, setShowError] = useState(false);
	const [isLoadingDeleting, setIsLoadingDeletin] = useState(false);

	const handleInputChangeName = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setFirstNames(event.target.value);
	};

	const handleInputChangeFatherSurname = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setFatherSurname(event.target.value);
	};

	const handleInputChangeEmail = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setEmail(event.target.value);
	};

	const handleCancelName = () => {
		setUpdateName(false);
	};

	const handleCancelEmail = () => {
		setUpdateEmail(false);
	};

	const updateProfile = async (
		updateName?: string,
		updateFatherSurname?: string,
		update_email?: string,
	) => {
		const body: UserProfileUpdate = {};

		if (updateName && updateFatherSurname) {
			body.first_name = updateName;
			body.father_surname = updateFatherSurname;
		}
		if (update_email) {
			body.email = email!;
		}
		setIsLoading(true);
		try {
			await patchUserProfile(userProfile?.id!, body, tokenSession);
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'Aviso',
				description: 'Se actualizo correctamente',
			};
			dispatch(showAlert(alert));
			const userInfo = await getUserProfile(tokenSession);
			dispatch(setProfile(userInfo));
			if (updateName) {
				setUpdateName(false);
			} else if (email) {
				setUpdateEmail(false);
			}
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Aviso',
				description: 'Ocurrio un error al actualizar',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.ACCOUNT_HERU,
		});
	}, []);

	const handleDeleteAccount = async () => {
		setShowDeleteModal(true);
	};

	const onLogout = () => {
		if (isLocalStorageEnabled()) {
			localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
		}
		if (isSessionStorageEnabled()) {
			sessionStorage.removeItem(SESSION_STORAGE_KEY.SHOW_NO_INFO_MODAL);
		}
		dispatch(removeToken());
		dispatch(restartAppState());
		dispatch(restartTaxPayerData());
		dispatch(logout());
	};

	const confirmDeleteAccount = async () => {
		try {
			setIsLoadingDeletin(true);
			logClevertapEvent(ANALYTICS_EVENTS.CONFIRM_ACCOUNT_DELETED);
			await deleteAccount(tokenSession);
			onLogout();
			navigate(ROUTES.LOGIN);
		} catch (error) {
			console.error('Error deleting account:', error);
		} finally {
			setShowDeleteModal(false);
			setIsLoadingDeletin(false);
		}
	};

	const cancelDeleteAccount = () => {
		setShowDeleteModal(false);
	};

	return (
		<div className='p-10'>
			<Modal
				open={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
			>
				<div className='!w-[200px] lg:!w-[calc(100vw-60px)] max-w-[500px]  pt-6 relative'>
					<Text
						size='subtitle'
						sx=''
						color='medium'
						weight='bold'
					>
						¿Estás seguro de que deseas eliminar tu cuenta?
					</Text>
					<Text
						size='body-3'
						sx=''
						color='medium'
						weight='regular'
					>
						Eliminar tu cuenta es una acción permanente. Todos tus datos y
						contenido serán eliminados de forma definitiva de nuestros
						servidores. Esta acción no se puede deshacer.
					</Text>

					<div className='flex justify-end items-center mt-4 gap-4'>
						<Button
							variant='outline'
							label='Cancelar'
							onClick={cancelDeleteAccount}
							sx='!w-fit'
						/>
						<Button
							label='Eliminar cuenta'
							onClick={confirmDeleteAccount}
							sx='!w-fit'
							loading={isLoadingDeleting}
							disabled={isLoadingDeleting}
						/>
					</div>
				</div>
			</Modal>
			<div className='flex items-center gap-3 mb-6'>
				<CaretCircleLeft
					weight='fill'
					color='var(--h-primary)'
					width={32}
					height={32}
					className='cursor-pointer hidden lg:block'
					onClick={() => navigate(ROUTES.USER_PROFILE)}
				/>
				<CaretCircleLeft
					weight='fill'
					color='var(--h-primary)'
					width={24}
					height={24}
					className='cursor-pointer block lg:hidden'
					onClick={() => navigate(ROUTES.USER_PROFILE)}
				/>
				<Heading
					sx='hidden lg:block'
					size='s'
					weight='bold'
				>
					Cuenta Heru
				</Heading>
				<Text
					sx='block lg:hidden'
					size='body-2'
					weight='bold'
				>
					Cuenta Heru
				</Text>
			</div>
			<div className='flex flex-col justify-center items-center mt-12 lg:px-5'>
				<div className='flex flex-col p-4 mb-4 rounded-md shadow-sm w-full lg:w-2/4 bg-white'>
					<div className='flex justify-between items-center w-full'>
						<Text
							size='body-2'
							weight='bold'
							sx='hidden lg:block'
						>
							Nombre
						</Text>
						<Text
							size='body-3'
							weight='bold'
							sx='block lg:hidden'
						>
							Nombre
						</Text>
						<Pencil
							className={`hidden lg:block cursor-pointer ${
								updateName ? 'opacity-5' : ''
							}`}
							onClick={() => setUpdateName(!updateName)}
							size={24}
							weight='light'
						/>
						<Pencil
							className={`block lg:hidden cursor-pointer ${
								updateName ? 'opacity-5' : ''
							}`}
							onClick={() => setUpdateName(!updateName)}
							size={16}
							weight='light'
						/>
					</div>

					<div className='border border-var(--h-neutral-90)-200 w-full mb-4 mt-4'></div>
					{updateName ? (
						<div>
							<Text
								size='caption'
								sx='text-left'
								weight='light'
							>
								Los cambios realizados a tu nombre se verán reflejados en las
								comunicaciones que tengamos contigo.
							</Text>
							<TextField
								required
								size='small'
								sx={{ mt: 4 }}
								label='Nombre'
								value={firstNames}
								onChange={handleInputChangeName}
								variant='outlined'
								fullWidth
								margin='normal'
								error={showError && !firstNames?.trim().length}
							/>
							<TextField
								required
								size='small'
								label='Apellidos'
								value={fatherSurname}
								onChange={handleInputChangeFatherSurname}
								variant='outlined'
								fullWidth
								margin='normal'
								error={showError && !fatherSurname?.trim().length}
							/>

							<div className='flex flex-col-reverse lg:flex-row justify-between mt-4'>
								<Button
									variant='outline'
									sx='mr-8 mt-2 lg:mt-0'
									label='Cancelar'
									onClick={handleCancelName}
								/>

								<Button
									loading={isLoading}
									label='Guardar cambios'
									onClick={() => {
										if (
											!firstNames?.trim().length ||
											!fatherSurname?.trim().length
										) {
											const alert = {
												type: ALERT_TYPE.ERROR,
												title: 'Aviso',
												description: 'Todos los campos son requeridos',
											};
											dispatch(showAlert(alert));
											setShowError(true);
											setTimeout(() => {
												setShowError(false);
											}, 5000);
										} else {
											updateProfile(firstNames, fatherSurname);
										}
									}}
								/>
							</div>
						</div>
					) : (
						<>
							<Text
								size='body-3'
								sx='text-left hidden lg:block'
								weight='regular'
							>
								{userProfile?.first_name || userProfile?.father_surname
									? `${userProfile?.first_name} ${userProfile?.father_surname}`
									: 'Sin nombre'}
							</Text>
							<Text
								size='caption'
								sx='text-left block lg:hidden'
								weight='regular'
							>
								{userProfile?.first_name || userProfile?.father_surname
									? `${userProfile?.first_name} ${userProfile?.father_surname}`
									: 'Sin nombre'}
							</Text>
						</>
					)}
				</div>
				<div className='flex flex-col p-6 mb-4 rounded-md shadow-sm w-full lg:w-2/4 bg-white'>
					<div className='flex justify-between items-center w-full'>
						<Text
							size='body-2'
							weight='bold'
							sx='hidden lg:block'
						>
							Correo electrónico
						</Text>
						<Text
							size='body-3'
							weight='bold'
							sx='block lg:hidden'
						>
							Correo electrónico
						</Text>
						<Pencil
							size={24}
							weight='light'
							className={`hidden lg:block cursor-pointer ${
								updateEmail ? 'opacity-5' : ''
							}`}
							onClick={() => setUpdateEmail(!updateEmail)}
						/>
						<Pencil
							size={16}
							weight='light'
							className={`block lg:hidden cursor-pointer ${
								updateEmail ? 'opacity-5' : ''
							}`}
							onClick={() => setUpdateEmail(!updateEmail)}
						/>
					</div>

					<div className='border border-var(--h-neutral-90)-200 w-full mb-4 mt-4'></div>
					{updateEmail ? (
						<div>
							<Text
								size='caption'
								sx='text-left'
								weight='light'
							>
								Utilizaremos el correo electrónico que elijas para todas
								nuestras comunicaciones contigo.
							</Text>
							<TextField
								size='small'
								className='h-36px'
								label='Correo electrónico'
								value={email}
								onChange={handleInputChangeEmail}
								variant='outlined'
								fullWidth
								margin='normal'
								error={REGEX.emailRegex.test(email!) ? false : true}
								helperText={
									REGEX.emailRegex.test(email!) ? '' : 'Correo inválido'
								}
							/>
							<div className='flex flex-col-reverse lg:flex-row justify-between mt-4'>
								<Button
									variant='outline'
									sx='mr-8 mt-2 lg:mt-0'
									label='Cancelar'
									onClick={handleCancelEmail}
								/>

								<Button
									loading={isLoading}
									label='Guardar cambios'
									onClick={() => updateProfile('', '', email)}
								/>
							</div>
						</div>
					) : (
						<>
							<Text
								size='body-3'
								sx='text-left hidden lg:block'
								weight='regular'
							>
								{userProfile?.email ? userProfile?.email : 'Sin correo'}
							</Text>
							<Text
								size='caption'
								sx='text-left block lg:hidden'
								weight='regular'
							>
								{userProfile?.email ? userProfile?.email : 'Sin correo'}
							</Text>
						</>
					)}
				</div>
				<div className='flex flex-col p-6 mb-4 rounded-md shadow-sm w-full lg:w-2/4 bg-white'>
					<Text
						sx='text-left block lg:hidden'
						size='body-3'
						weight='bold'
					>
						Telefono
					</Text>
					<Text
						sx='text-left hidden lg:block'
						size='body-3'
						weight='bold'
					>
						Telefono
					</Text>
					<div className='border border-var(--h-neutral-90)-200 w-full mb-4 mt-4'></div>
					<Text
						size='body-3'
						sx='hidden lg:block'
						weight='regular'
					>
						{userProfile?.country_code === '+52' && userProfile?.cellphone ? (
							<div className='flex'>
								<img
									alt='flag_mx'
									src={flag_mx}
									className='mr-2'
								/>
								{userProfile?.country_code} {userProfile?.cellphone}
							</div>
						) : (
							`${userProfile?.country_code || ''} ${userProfile?.cellphone || ''}`.trim()
						)}
					</Text>
					<Text
						size='caption'
						sx='block lg:hidden'
						weight='regular'
					>
						{userProfile?.country_code === '+52' && (
							<img
								alt='flag_mx'
								src={flag_mx}
							/>
						)}
						{userProfile?.cellphone
							? `${userProfile?.country_code} ${userProfile?.cellphone}`
							: 'Sin teléfono'}
					</Text>
				</div>
				<Button
					variant='text'
					label='Eliminar cuenta'
					onClick={handleDeleteAccount}
					sx='!w-fit px-10'
					labelColor='blueInformation'
				/>
			</div>
		</div>
	);
};

export default AccountHeru;
