import BenefitsDropdown from '../molecules/BenefitsDropdown';
import PlanFlowButtons from '../molecules/PlanFlowButtons';
import { PropsPlanFlow } from '..';
import {
	PlanPaymentFrecuencySelector,
	PlanRegimeSelector,
} from '../organisms/PlanSelectors';
import {
	PlanFlowContentWrapper,
	TopBanner,
} from '../organisms/LayoutCustomPlan';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import { formatCurrency } from '../../../shared/utils/general.util';
import useAddToCart from '../../../hooks/useAddToCart';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import PrimaryButton from '../../../components/atoms/Button';
import { ArrowLeft } from '@phosphor-icons/react';
import {
	setPlanConfig,
	setSelectedProduct,
	setShowModalCheckout,
} from '../../../redux/slices/plans.slice';
import { RegimeFFT } from '../../../@types/Products.type';

const HeruPlusFlow: React.FC<PropsPlanFlow> = ({
	product,
	planConfig,
	startingPrice,
}) => {
	const logClevertapEvent = useCleverTapEvent();
	const dispatch = useDispatch();
	const { step } = planConfig;
	const confirmedRegime = step > 1;

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { productName, subtitle } = product;

	const { products } = useSelector((state: RootState) => state.productList);

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const heruPlusProduct = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.default_product_version_id === product.defaultId,
		);

	const currentProductVersion = heruPlusProduct?.product_versions?.find(
		(version) => version.periodicity === planConfig?.paymentFrecuency,
	);

	const currentProductVersionPrice = currentProductVersion
		? getPriceWithElasticity(currentProductVersion?.price / 100)
		: product?.price;

	const getProductTotalPrice = () => {
		if (!planConfig?.paymentFrecuency) return startingPrice;

		const amountsDivider: Record<number, number> = {
			30: 1,
			90: 3,
			180: 6,
			365: 12,
		};

		if (currentProductVersionPrice) {
			return Math.round(
				currentProductVersionPrice /
					amountsDivider[planConfig.paymentFrecuency],
			);
		}
		return Math.round(
			startingPrice / amountsDivider[planConfig.paymentFrecuency],
		);
	};

	const propsTopBanner = {
		productName,
		subtitle,
		price: getProductTotalPrice(),
		afterPrice: '/mes',
		beforePrice: !planConfig?.paymentFrecuency ? 'Desde' : '',
		priceDescription: planConfig?.paymentFrecuency
			? `$${formatCurrency(currentProductVersionPrice, false)} Total`
			: '',
	};

	const handleAddProduct = async () => {
		const regimes =
			planConfig?.regimes?.map((regime) => RegimeFFT[regime]) ?? [];
		const payload = [
			{
				product_version_id: currentProductVersion?.id,
				custom_product: {
					group_experiment: elasticityPrice.group_experiment,
					total_paid: Number(currentProductVersionPrice * 100),
				},
				additional_information: {
					regimes,
				},
			},
		];
		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name: heruPlusProduct?.name || 'heru_plus',
			regime: heruPlusProduct?.regime?.join('') || '',
		};
		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);
		await handleAddCustomProduct({
			payload,
			navigateToCart: false,
			resetOrders: true,
		});
		dispatch(setShowModalCheckout(true));
		dispatch(setSelectedProduct(null));
	};

	const handlePreviousStep = () => {
		dispatch(
			setPlanConfig({
				...planConfig,
				paymentFrecuency: undefined,
				step: planConfig.step - 1,
			}),
		);
	};

	const buttonText: Record<number, string> = {
		1: 'Continuar 1 de 2',
		2: product.buttonText,
	};

	const disableButton: Record<number, boolean> = {
		1: !planConfig.regimes?.length,
		2: !planConfig.regimes?.length || !planConfig.paymentFrecuency,
	};

	const handleNextStep = () => {
		if (step === 2) {
			handleAddProduct();
			return;
		}
		dispatch(
			setPlanConfig({
				...planConfig,
				step: planConfig.step + 1,
			}),
		);
	};

	return (
		<>
			<TopBanner {...propsTopBanner} />
			<div className='flex flex-col justify-between h-full'>
				<PlanFlowContentWrapper>
					{step > 1 && (
						<PrimaryButton
							label='Volver'
							variant='text'
							labelColor='primary'
							onClick={handlePreviousStep}
							labelSize='caption'
							beforeIcon={<ArrowLeft weight='bold' />}
							sx='flex items-center gap-2 mb-4 px-2'
						/>
					)}
					<PlanRegimeSelector
						planConfig={planConfig}
						confirmed={confirmedRegime}
						label='Selecciona 2 o más de tus fuentes de ingreso (regímenes)'
					/>
					{confirmedRegime && (
						<>
							<hr className='my-4' />
							<PlanPaymentFrecuencySelector planConfig={planConfig} />
						</>
					)}
					<BenefitsDropdown benefits={heruPlusProduct?.benefits} />
				</PlanFlowContentWrapper>
				<PlanFlowButtons
					disableButton={disableButton[step]}
					buttonLabel={buttonText[step]}
					isLoading={isLoadingAddToCart.products}
					onClickButton={handleNextStep}
				/>
			</div>
		</>
	);
};

export default HeruPlusFlow;
