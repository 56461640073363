import { useEffect, useRef, useState } from 'react';
import {
	IChatIASamantha,
	IMessage,
	IOnboardingResponseSamantha,
} from '../../../@types/IA.type';
import Heading from '../../../components/atoms/Heading';
import Text from '../../../components/atoms/Text';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import TypingDots from '../../intro/molecules/TypingDots';
import {
	getOnboardingAnalysisIA,
	getOnboardingIA,
	postChatIASamantha,
} from '../../../services/heru-ai.service';
import { PaperPlaneTilt } from '@phosphor-icons/react';
import { CircularProgress } from '@mui/material';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import {
	setOnboarding,
	setSamanthaAnalysis,
} from '../../../redux/slices/ai.slice';
import { setShowModalAnalysis } from '../../../redux/slices/plans.slice';
import heruIcon from '../../../assets/img/svg/chat-heru-icon.svg';

const Chat = () => {
	const dispatch = useDispatch();
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { onboarding: initialOnboarding } = useSelector(
		(state: RootState) => state.ai,
	);
	const { status: taxpayer } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const { subscriptions } = useSelector((state: RootState) => state.profile);
	const { acquisitions } = useSelector((state: RootState) => state.productList);

	const [messages, setMessages] = useState<IMessage[]>([]);
	const [conversationProgress, setConversationProgress] =
		useState<IChatIASamantha | null>(null);
	const lastMessageRef = useRef<HTMLDivElement>(null);
	const [inputValue, setInputValue] = useState('');
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [isLoading, setIsLoading] = useState<{
		sendMessage: boolean;
	}>({
		sendMessage: false,
	});

	const getOnboardingAI = async () => {
		try {
			const onboarding: IOnboardingResponseSamantha = await getOnboardingIA(
				userProfile?.id!,
			);

			dispatch(setOnboarding(onboarding));
			return onboarding;
		} catch (error) {}
	};

	const getAnalysisIA = async (onboardingId: number) => {
		try {
			const response = await getOnboardingAnalysisIA(
				userProfile?.id!,
				onboardingId,
			);
			dispatch(setSamanthaAnalysis(response));
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error al generar el análisis',
					description: 'Intente más tarde',
				}),
			);
		}
	};

	const callChatSamanthaAPI = async () => {
		try {
			const lastMessage = messages?.[messages.length - 1].content;
			const response: IChatIASamantha = await postChatIASamantha(
				{
					userId: userProfile?.id!,
					userName: userProfile?.first_name || '-',
					assistantLatestQuestion: lastMessage,
					userMessage: inputValue.trim(),
				},
				tokenSession,
			);

			if (!response?.assistantResponse) {
				throw new Error('No se pudo obtener la respuesta del chat');
			}

			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleSendMessage = async (e: React.FormEvent<any>) => {
		e.preventDefault();
		if (
			messages.length === 0 ||
			userProfile?.id === null ||
			isLoading.sendMessage ||
			inputValue.trim() === ''
		)
			return;

		try {
			setIsLoading((prev) => ({ ...prev, sendMessage: true }));

			const newMessage: IMessage = {
				content: inputValue,
				role: 'user',
			};

			setMessages((prevMessages) => [...prevMessages, newMessage]);
			setInputValue('');

			const response: IChatIASamantha = await callChatSamanthaAPI();
			setConversationProgress(response);

			const aiResponse = {
				content: response?.assistantResponse || '',
				role: 'assistant',
			};
			setMessages((prevMessages) => [...prevMessages, aiResponse as IMessage]);

			if (response?.percentage?.percentage === 100) {
				setIsLoading((prev) => ({ ...prev, sendMessage: false }));
				await getAnalysisIA(response?.onboardingId);
				await getOnboardingAI();
				dispatch(setShowModalAnalysis(true));
			}
		} catch (e) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error al enviar el mensaje',
					description: 'Intente más tarde',
				}),
			);
		} finally {
			setIsLoading((prev) => ({ ...prev, sendMessage: false }));
		}
	};

	const isOnboardingCompleted =
		conversationProgress?.percentage?.percentage === 100;

	const isLinked = taxpayer?.status === 'ACTIVE';

	const messageToShow = () => {
		const name = userProfile?.first_name || '';
		if (isLinked || subscriptions?.length > 0 || acquisitions?.length > 0) {
			return `¡Bienvenido ${name}! Llegaste al lugar indicado para ayudarte a cumplir con el SAT sin estrés. 

Con Heru puedes:
- Declarar tus impuestos
- Generar tus facturas
- Obtener asesoría contable especializada y mucho más.
				
Para asesorarte mejor, por favor cuéntame un poco sobre qué estás buscando con Heru, sobre tu situación fiscal y tus actividades económicas.`;
		}

		return `¡Bienvenido de vuelta, ${name}! Llegaste al lugar indicado para ayudarte a cumplir con el SAT sin estrés. 

Con Heru puedes:
- Declarar tus impuestos
- Generar tus facturas
- Obtener asesoría contable especializada y mucho más.
			
Para asesorarte mejor, por favor cuéntame un poco sobre qué estás buscando con Heru, sobre tu situación fiscal y tus actividades económicas.`;
	};

	useEffect(() => {
		if (lastMessageRef.current)
			lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
	}, [messages]);

	useEffect(() => {
		if (initialOnboarding?.userCurrentData)
			setMessages(initialOnboarding?.userCurrentData?.thread);
		else
			(async () => {
				setIsLoading((prev) => ({ ...prev, sendMessage: true }));
				await new Promise((resolve) => setTimeout(resolve, 3000));
				setIsLoading((prev) => ({ ...prev, sendMessage: false }));
				setMessages([
					{
						role: 'assistant',
						content: messageToShow(),
					},
				]);
			})();
	}, []);

	return (
		<div className='p-4 sm:p-6'>
			<div className='flex flex-col bg-background text-foreground'>
				<div
					className={`flex flex-col w-full overflow-y-auto p-0 sm:p-4 justify-start  h-[calc(100vh-270px)] md:h-[calc(100vh-420px)] max-h-[300px] md:max-h-[500px]`}
				>
					{messages.map((message, index) => (
						<div
							ref={lastMessageRef}
							key={`message-${index}`}
							className={`fade-in-message flex w-full items-start mb-4  ${
								message.role === 'user' ? 'justify-end' : 'justify-start '
							} `}
						>
							<div
								className={`flex gap-2  max-w-[90%] md:max-w-[60%] ${
									message.role === 'user' ? 'flex-row-reverse' : 'flex-row'
								}`}
							>
								<div
									className={`flex items-center justify-center size-10 rounded-[24px]  ${
										message.role === 'user' ? ' bg-h-primary-40 p-4' : ''
									}`}
								>
									{message.role !== 'user' ? (
										<img
											src={heruIcon}
											alt='user'
											width={40}
											height={40}
											className='object-contain  min-w-[40px] min-h-[40px]'
										/>
									) : (
										<Heading
											size='xs'
											weight='bold'
											color='white'
										>
											{userProfile?.first_name?.charAt(0) || ''}
										</Heading>
									)}
								</div>
								<div
									className={`flex flex-col gap-2 ${
										message.role === 'user' ? 'items-end' : 'items-start'
									}`}
								>
									<Text
										size='caption'
										weight='light'
									>
										{message.role === 'user'
											? userProfile?.first_name || 'Tú'
											: 'Asistente virtual heru'}
									</Text>
									<div
										className={`p-3 text-sm ${
											message.role === 'user'
												? 'sm:ml-10 text-right bg-primary text-primary-foreground bg-h-neutral-90 rounded-l-[24px] rounded-br-[24px] shadow-custom'
												: 'sm:mr-10 bg-muted bg-h-primary-40 text-white rounded-r-[24px] rounded-bl-[24px]'
										}`}
									>
										<p
											className='font-light whitespace-pre-wrap'
											dangerouslySetInnerHTML={{
												__html: message.content.replace(
													/(¿[^?]*\?)/g,
													'<span class="bg-white/10 font-medium block my-1.5 rounded-lg">$1</span>',
												),
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
					{isLoading.sendMessage && <TypingDots />}
				</div>

				{!isOnboardingCompleted && (
					<div className='border border-h-neutral-80 bg-h-neutral-95 rounded-2xl mt-4 sm:mt-10'>
						<form
							className='flex items-start py-3 px-4'
							onSubmit={handleSendMessage}
						>
							<textarea
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								className='text-neutral-500 bg-transparent text-sm outline-none w-full h-full resize-none min-h-[60px]'
								placeholder='Escribe tu mensaje aquí'
								rows={2}
								onKeyDown={(e) => {
									if (e.key === 'Enter') handleSendMessage(e);
								}}
							/>
							<button
								type='submit'
								disabled={isLoading.sendMessage}
								className='w-fit'
							>
								<PaperPlaneTilt
									size={20}
									weight='duotone'
									color='#0C3961'
								/>
							</button>
						</form>
					</div>
				)}

				{isOnboardingCompleted && (
					<div className='flex items-center justify-center py-2 px-3'>
						<div className='flex gap-4 items-center justify-center w-full bg-h-primary-95 rounded-lg p-2'>
							<CircularProgress size={24} />
							<Text color='primary'>Estamos analizando tu información...</Text>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Chat;
