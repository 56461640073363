import { Skeleton } from '@mui/material';
import Text from '../../atoms/Text';
import { ProductVersionItem } from '../../../@types/Order.type';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import ItemCollapse from './Items/ItemCollapse';
import { CategoryName } from '../../../@types/Products.type';
import {
	getAdditionalInfoItem,
	getPriceItem,
} from '../../../shared/utils/products.utils';
import { formatPrice } from '../../../shared/utils/general.util';
import useDeleteProducts from '../../../hooks/useDeleteProducts';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import ButtonTrash from '../../atoms/ButtonTrash/ButtonTrash';
interface ItemCheckoutProps {
	item: ProductVersionItem;
	isLoading: boolean;
	planPeriodicity: number;
}

const ItemCheckout = ({
	item,
	isLoading,
	planPeriodicity,
}: ItemCheckoutProps) => {
	const { handleDeleteProducts, isLoading: isLoadingDelete } =
		useDeleteProducts();
	const { userOrder } = useSelector((state: RootState) => state.cart);

	const handleDelete = async (item?: ProductVersionItem) => {
		if (isLoadingDelete) return;
		try {
			let allShoppingItemsIds: { shopping_item_id: number }[] = [];
			if (item) {
				allShoppingItemsIds = [{ shopping_item_id: item.id }];
			} else {
				allShoppingItemsIds = userOrder?.[0]?.shoppingItem?.map((item) => ({
					shopping_item_id: item?.id,
				}));
			}
			await handleDeleteProducts(userOrder?.[0]?.id, allShoppingItemsIds);
		} catch (error) {}
	};

	const DiscountPlanInfo = () => {
		return (
			<Text
				color='green'
				weight='light'
			>
				Primer {planPeriodicity === 30 ? 'mes' : 'año'} gratis
			</Text>
		);
	};

	const renderItem = (item: ProductVersionItem) => {
		if (item.productVersion.product.slug === PRODUCTS_SLUG.ASSISTANCE_ASAT) {
			return (
				<div className='flex gap-2 w-full'>
					<div className='mt-1 w-full'>
						<ItemCollapse
							title={item.productVersion.product.name}
							description={getAdditionalInfoItem(item)}
							subtitle={`$ ${formatPrice(item.total)}`}
						/>
					</div>
					<ButtonTrash
						isLoading={isLoadingDelete}
						onClick={() => handleDelete(item)}
					/>
				</div>
			);
		}

		if (
			item.productVersion.product.category === CategoryName.DeclaraciónAnual
		) {
			return null;
		}

		return (
			<>
				<Text
					size='body-3'
					color='medium'
					sx='flex-1'
				>
					{item.productVersion.product.name}
					{getAdditionalInfoItem(item)}
				</Text>

				<div className='flex gap-2'>
					<div className='flex gap-x-1 items-center'>
						<Text
							size='body-3'
							sx='gap-x-1'
						>
							${getPriceItem(item)}
						</Text>
						{item.purchaseType === 'subscription' && (
							<Text
								size='caption'
								weight='light'
							>
								/
								{item.productVersion.product.category ===
								CategoryName.DeclaraciónAnual
									? 'año'
									: 'mes'}
							</Text>
						)}
					</div>
					<ButtonTrash
						isLoading={isLoadingDelete}
						onClick={() => handleDelete(item)}
					/>
				</div>
			</>
		);
	};

	return (
		<>
			<div className='flex flex-row w-full gap-4 justify-between items-start'>
				{isLoading ? (
					<>
						<Skeleton
							variant='text'
							sx={{ fontSize: '1rem' }}
							width={200}
						/>
						<Skeleton
							variant='text'
							sx={{ fontSize: '1rem' }}
							width={50}
						/>
					</>
				) : (
					renderItem(item)
				)}
			</div>
			{item.discount === item.basePrice &&
				item.purchaseType === 'subscription' &&
				item.productVersion.product.category === CategoryName.Plans && (
					<DiscountPlanInfo />
				)}
		</>
	);
};

export default ItemCheckout;
