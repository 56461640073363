import { Eye, Funnel, XCircle } from '@phosphor-icons/react';
import Button from '../../../components/atoms/Button';
import Text from '../../../components/atoms/Text';
import {
	capitalizeText,
	formatCurrency,
	useStorage,
} from '../../../shared/utils/general.util';
import React from 'react';
import {
	FiscalDocumentInvoicesData,
	FiscalDocumentReceiptsData,
	FiscalDocumentRetentionsData,
	FiscalDocumentType,
} from '../../../@types/FiscalDocuments.type';
import { getFormatDate } from '../../../shared/utils/dates.util';
import { DownloadSimple } from '@phosphor-icons/react/dist/ssr';
import { CircularProgress } from '@mui/material';
import { PlatformOS } from '../../../redux/slices/profile.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import ROUTES from '../../../shared/constants/routes';
import STORAGE_KEYS from '../../../shared/constants/storage';

type Props = {
	isHalfPage: boolean;
	handleShowDetail: (document_id: string | number) => void;
	documentType:
		| FiscalDocumentType.INVOICE
		| FiscalDocumentType.RECEIPT
		| FiscalDocumentType.RETENTION;
	invoice: FiscalDocumentInvoicesData[] | null;
	receipt: FiscalDocumentReceiptsData[] | null;
	retention: FiscalDocumentRetentionsData[] | null;
	setShowModal: React.Dispatch<
		React.SetStateAction<{
			filters: boolean;
		}>
	>;
	isLoading: boolean;
	isLoadingDetail: boolean;
	selectedDocumentId: {
		selected: number | null;
		loadingSelected: number | null;
	};
	filtersApplied: number;
	removeAllFilters: () => void;
};

const FiscalDocumentsCardsMobile: React.FC<Props> = ({
	isHalfPage,
	handleShowDetail,
	invoice,
	retention,
	receipt,
	documentType,
	setShowModal,
	isLoading,
	filtersApplied,
	removeAllFilters,
	isLoadingDetail,
	selectedDocumentId,
}) => {
	const logClevertapEvent = useCleverTapEvent();
	const isViewedFromTaxboard = location.pathname === ROUTES.TAXES;

	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;

	const getDocumentList = () => {
		if (documentType === FiscalDocumentType.INVOICE) {
			return (
				invoice?.map((invoice) => ({
					date: getFormatDate(invoice?.date || '', 'DD MMM YYYY', false, true),
					title: capitalizeText(invoice?.description),
					total: formatCurrency(invoice?.total_amount || 0),
					url: null,
					id: invoice?.invoice_id,
				})) || []
			);
		}

		if (documentType === FiscalDocumentType.RETENTION) {
			return (
				retention?.map((retention) => ({
					date: getFormatDate(
						retention?.date || '',
						'DD MMM YYYY',
						false,
						true,
					),
					title: capitalizeText(
						retention?.issuerData?.responseIssuerData?.[0]?.name,
					),
					total: formatCurrency(retention?.total_amount || 0),
					url: platformOS === PlatformOS.IOS ? null : retention?.xml_url || '',
					id: retention?.retention_id,
				})) || []
			);
		}

		if (documentType === FiscalDocumentType.RECEIPT) {
			return (
				receipt?.map((receipt) => ({
					date: getFormatDate(
						receipt?.presented_at || '',
						'DD MMM YYYY',
						false,
						true,
					),
					title:
						receipt?.type === 'NORMAL'
							? 'Declaración Normal'
							: 'Declaración Complementaria',
					total: formatCurrency(receipt?.amount || 0),
					url: receipt?.declaration_url,
					id: receipt?.id,
				})) || []
			);
		}

		return [];
	};

	const handleDownload = (e: React.MouseEvent, url: string) => {
		logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
			event_type: isViewedFromTaxboard
				? EVENT_TYPES.TAXBOARD_DOCUMENT_DOWNLOADED
				: EVENT_TYPES.FISCAL_DOCUMENTS_DETAIL_ACTION,
			source: 'receipt',
		});
		e.stopPropagation();
		window.open(url, '_blank');
	};

	return (
		<>
			<div
				className={`w-full flex justify-end ${
					isHalfPage
						? 'ml-auto -mt-[66px] mb-[68px]'
						: '-mt-[66px] mb-[60px] sm:-mt-[60px]'
				}`}
			>
				<div className='flex gap-1 items-center'>
					{filtersApplied > 0 && (
						<XCircle
							weight='fill'
							width={20}
							height={20}
							onClick={removeAllFilters}
							color='var(--h-primary)'
						/>
					)}
					<div
						className={`flex items-center justify-center gap-1 rounded-full 
					${filtersApplied > 0 ? 'bg-h-primary-90 pl-2 pr-1 py-1' : 'bg-h-neutral-95 px-2 py-1.5'}`}
					>
						<Button
							onClick={() =>
								setShowModal((prev) => ({ ...prev, filters: true }))
							}
							label='Filtros'
							variant='text'
							labelColor={filtersApplied > 0 ? 'dark' : 'medium'}
							labelSize='caption'
							beforeIcon={<Funnel size={18} />}
						></Button>
						{filtersApplied > 0 && (
							<div className='h-5 w-5 rounded-full bg-white flex items-center justify-center'>
								<Text>{filtersApplied}</Text>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className='flex flex-col gap-3 mt-6 cursor-pointer'>
				{isLoading && (
					<div className='flex items-center justify-center mt-10'>
						<CircularProgress size={25} />
					</div>
				)}

				{getDocumentList()?.map((document, index) => (
					<div
						key={index}
						className=' border-b border-h-neutral-90'
						onClick={() => handleShowDetail(document?.id)}
					>
						<Text
							color='light'
							responsive
							weight='light'
						>
							{document?.date}
						</Text>
						<Text
							responsive
							sx='!truncate !block'
						>
							{document?.title}
						</Text>
						<div className='flex justify-between items-center mt-1 mb-3'>
							<div>
								<Text
									color='light'
									size='mini-1'
									responsive
									weight='light'
								>
									Total
								</Text>
								<Text
									responsive
									sx='!truncate !block'
								>
									{document?.total}
								</Text>
							</div>
							<div className='flex items-center gap-5'>
								{document?.url && (
									<DownloadSimple
										onClick={(e) => handleDownload(e, document?.url || '')}
										color='var(--h-medium)'
										width={20}
										height={20}
									/>
								)}
								{isLoadingDetail &&
								selectedDocumentId.loadingSelected &&
								Number(document?.id) === selectedDocumentId.loadingSelected ? (
									<CircularProgress size={20} />
								) : (
									<Eye
										color='var(--h-primary)'
										width={20}
										height={20}
									/>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default FiscalDocumentsCardsMobile;
