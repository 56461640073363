import { useEffect, useState } from 'react';

interface Props {
	active?: boolean;
	bgColorActive?: string;
	bgColorInactive?: string;
	onToggleChange?: (value: boolean) => void;
	isDisable?: boolean;
}

const ToggleSwitch = ({
	active,
	bgColorActive,
	bgColorInactive,
	isDisable,
	onToggleChange,
}: Props) => {
	const [isOn, setIsOn] = useState(active);

	useEffect(() => {
		setIsOn(active);
	}, [active]);

	const toggleSwitch = () => {
		if (isDisable) return;
		setIsOn(!isOn);
		onToggleChange?.(!isOn);
	};
	return (
		<div
			className={`relative inline-flex items-center h-5 rounded-full w-10 cursor-pointer ${isOn ? (bgColorActive ?? 'bg-h-primary-50') : (bgColorInactive ?? 'bg-h-blue-70')} ${isDisable ? 'opacity-15' : ''}`}
			onClick={toggleSwitch}
		>
			<span
				className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${isOn ? 'translate-x-5' : 'translate-x-1'}`}
			/>
		</div>
	);
};

export default ToggleSwitch;
