import BenefitsDropdown from '../molecules/BenefitsDropdown';
import PlanFlowButtons from '../molecules/PlanFlowButtons';
import { PropsPlanFlow } from '..';
import {
	PlanFlowContentWrapper,
	TopBanner,
} from '../organisms/LayoutCustomPlan';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import useAddToCart from '../../../hooks/useAddToCart';
import { DATES } from '../../../shared/utils/dates';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { PlanRegimeSelector } from '../organisms/PlanSelectors';
import { Regime, RegimeFFT } from '../../../@types/Products.type';
import { REGIMES_CODE } from '../../../@types/Regime.type';

const AnnualDeclaration: React.FC<PropsPlanFlow> = ({
	product,
	planConfig,
	startingPrice,
}) => {
	const logClevertapEvent = useCleverTapEvent();
	const { dataLayer } = window as any;
	const { step } = planConfig;
	const confirmedRegime = step > 1;

	const userProfile = useSelector((state: RootState) => state.profile.user);

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { productName, subtitle } = product;
	const { products } = useSelector((state: RootState) => state.productList);

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const annualRegularizationProduct = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
		);

	const annualPrice =
		annualRegularizationProduct?.price &&
		getPriceWithElasticity(annualRegularizationProduct?.price);

	const propsTopBanner = {
		productName,
		subtitle,
		price: startingPrice,
		afterPrice: 'Cada año',
	};

	const handleAddProduct = async () => {
		const regimes =
			planConfig?.regimes?.map((regime) => RegimeFFT[regime]) ?? [];

		const regimeCodeToRegimeName: Record<string, string> = {
			[REGIMES_CODE.ARRENDAMIENTO]: Regime.Arrendamiento,
			[REGIMES_CODE.RIF]: Regime.Rif,
			[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]: Regime.PlataformasTecnológicas,
			[REGIMES_CODE.RESICO]: Regime.Resico,
			[REGIMES_CODE.ACTIVIDAD_EMPRESARIAL]:
				Regime.ActividadesEmpresarialesYProfesionales,
		};
		const regimesName =
			planConfig?.regimes?.map((regime) => regimeCodeToRegimeName[regime]) ??
			[];

		const payload = [
			{
				product_version_id:
					annualRegularizationProduct?.default_product_version_id,
				custom_product: {
					group_experiment: elasticityPrice.group_experiment,
					total_paid: Number(annualPrice),
					payload_user_id: userProfile?.id,
				},
				additional_information: {
					regimes,
					year: DATES.currentYear,
				},
			},
		];

		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name: annualRegularizationProduct?.name || 'subscripcion_anual',
			regime: regimesName?.join('') || '',
			period: payload?.[0]?.additional_information?.year,
		};
		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

		await handleAddCustomProduct({ payload });
		dataLayer.push({ ecommerce: null });
	};

	return (
		<>
			<TopBanner {...propsTopBanner} />
			<div className='flex flex-col justify-between h-full'>
				<PlanFlowContentWrapper>
					<PlanRegimeSelector
						planConfig={planConfig}
						confirmed={confirmedRegime}
						showSySRegime
					/>
					<BenefitsDropdown benefits={annualRegularizationProduct?.benefits} />
				</PlanFlowContentWrapper>
				<PlanFlowButtons
					disableButton={!planConfig.regimes?.length}
					buttonLabel={product.buttonText}
					isLoading={isLoadingAddToCart.products}
					onClickButton={handleAddProduct}
				/>
			</div>
		</>
	);
};

export default AnnualDeclaration;
