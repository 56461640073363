import { useCallback, useEffect, useState } from 'react';
import { CaretLeft, Eye, PlusCircle } from '@phosphor-icons/react';
import Container from '../../components/atoms/Container';
import Text from '../../components/atoms/Text';
import InvoicingBreadcrumb from './molecules/InvoicingBreadcrumb';
import InvoicingStepper from './molecules/InvoicingStepper';
import PrimaryButton from '../../components/atoms/Button';
import InvoicePreview from './organisms/InvoicePreview';

import InvoiceStep1 from './organisms/InvoiceStep1';
import InvoiceStep2 from './organisms/InvoiceStep2';
import InvoiceStep3 from './organisms/invoiceStep3';
import InvoiceStep4 from './organisms/InvoiceStep4';
import InvoiceStep5 from './organisms/InvoiceStep5';
import { useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REGEX } from '../../shared/constants/regex';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserValidationFields,
	saveReceiver,
	SaveReceiverProps,
	sendDataSummary,
	sendGenerateInvoice,
} from '../../services/invoicing.service';
import Modal from '../../components/molecules/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../shared/constants/routes';
import { PlatformOS, ProfileState } from '../../redux/slices/profile.slice';
import dayjs from 'dayjs';
import { showAlert } from '../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import ERROR from '../../shared/constants/errors';
import { timestampToLocalStringISOFormat } from '../../shared/utils/times.util';
import { SummaryInvoice } from '../../@types/Invoicing.type';
import {
	setInvoicingAvaibleSteps,
	setInvoicingCurrentStep,
	setShowModalStamps,
} from '../../redux/slices/invoicing';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { CircularProgress } from '@mui/material';
import { useStorage } from '../../shared/utils/general.util';
import STORAGE_KEYS from '../../shared/constants/storage';

type Product = {
	product_id: string;
	description: string;
	is_new_product: string;
	unit: string;
	quantity: string;
	price: string;
	discount: string;
	tax: string;
	retentions: string;
};

export type InvoicingFormBody = {
	[key: string]: string | Product[] | { email: string }[] | undefined;
	// Step 1
	issuer_regime: string;
	issuer_zip_code: string;
	saved_receiver: string;
	new_receiver_name: string;
	new_receiver_rfc: string;
	new_receiver_zip_code: string;
	new_receiver_regime: string;
	new_receiver_cfdi_use: string;
	// Step 2
	payment_method: string;
	payment_type: string;
	products: Product[];
	// Step 4
	emails: { email: string }[];
};

type NewInvoiceFlowProps = {
	showBackButton: boolean;
};

export const invoicingResolver = yup
	.object({
		// Step 1
		issuer_regime: yup
			.string()
			.required('Por favor selecciona un régimen fiscal'),
		issuer_zip_code: yup
			.string()
			.length(5, 'El código postal debe ser de 5 dígitos')
			.required('Por favor ingresa tu código postal'),

		saved_receiver: yup.string().required('Por favor selecciona un receptor'),
		new_receiver_name: yup
			.string()
			.required('Por favor ingresa el nombre del receptor'),
		new_receiver_rfc: yup
			.string()
			.required('Por favor ingresa el RFC del receptor')
			.matches(REGEX.rfcRegex, 'Por favor ingresa un RFC válido'),
		new_receiver_zip_code: yup
			.string()
			.length(5, 'El código postal debe ser de 5 dígitos')
			.required('Por favor ingresa el código postal del receptor'),
		new_receiver_regime: yup
			.string()
			.required('Por favor selecciona el régimen fiscal del receptor'),
		new_receiver_cfdi_use: yup
			.string()
			.required('Por favor selecciona el uso del CFDI del receptor'),
		// Step 2
		payment_method: yup
			.string()
			.required('Por favor selecciona un método de pago'),
		payment_type: yup.string().required('Por favor selecciona un tipo de pago'),
		products: yup.array().of(
			yup.object({
				product_id: yup.string().required('Por favor selecciona un producto'),
				description: yup
					.string()
					.required('Por favor ingresa una descripción del producto'),
				unit: yup.string().required('Por favor selecciona una unidad'),
				quantity: yup.string().required('Por favor ingresa una cantidad'),
				price: yup.string().required('Por favor ingresa un precio'),
				discount: yup.string().required('Por favor ingresa un descuento'),
				tax: yup.string().required('Por favor selecciona un impuesto'),
				retentions: yup
					.string()
					.required('Por favor selecciona un tipo de retención'),
			}),
		),
		emails: yup.array().of(
			yup.object({
				email: yup
					.string()
					.matches(REGEX.emailRegex, 'Por favor ingresa un email válido')
					.email('Por favor ingresa un email válido')
					.required('Por favor ingresa un email'),
			}),
		),
	})
	.required();

const NewInvoiceFlow: React.FC<NewInvoiceFlowProps> = ({ showBackButton }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { products } = useSelector((state: RootState) => state.productList);
	const { stampStatus, receivers, userProducts } = useSelector<
		RootState,
		ProfileState
	>((state) => state.profile);
	const isFromIOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) === PlatformOS.IOS;

	const [isLoading, setIsLoading] = useState({
		validationStep1: false,
		validationStep2: false,
		validationStep4: false,
	});
	const {
		avaibleSteps,
		currentStep,
		issuerName: issuerNameByInput,
	} = useSelector((state: RootState) => state.invocing);

	const [isNewReceiver, setIsNewReceiver] = useState(false);
	const [saveNewReceiver, setSaveNewReceiver] = useState(false);
	const [showModal, setShowModal] = useState({
		dueCert: false,
		invoicePreviewMobile: false,
	});
	const [validatedInvoiceSummary, setValidatedInvoiceSummary] =
		useState<SummaryInvoice>();

	const {
		control,
		setValue,
		trigger,
		formState: { errors: formErrors },
		watch,
	} = useForm<InvoicingFormBody>({
		mode: 'onChange',
		// @ts-ignore
		resolver: yupResolver(invoicingResolver),
		defaultValues: {
			// Step 1
			issuer_regime: '',
			issuer_zip_code: '',
			saved_receiver: '',
			new_receiver_name: '',
			new_receiver_rfc: '',
			new_receiver_zip_code: '',
			new_receiver_regime: '',
			new_receiver_cfdi_use: '',
			// Step 2
			invoice_type: 'income',
			payment_method: 'PUE',
			payment_type: '03',
			exchange_rate: 'mxn',
			products: [],
			emails: [],
		},
	});

	const allowEditIssuerName = watch('issuer_regime') === '601';
	const issuerName =
		allowEditIssuerName && issuerNameByInput
			? issuerNameByInput
			: stampStatus?.name || '';

	const savedReceiverInfo = receivers.find(
		(rec) => rec.rfc === watch('saved_receiver'),
	);

	const issuerInfo = {
		name: issuerName.toUpperCase(),
		rfc: stampStatus?.code?.toUpperCase() ?? '',
		tax_regime: watch('issuer_regime'),
		postal_code: stampStatus?.postal_code || watch('issuer_zip_code') || '',
	};

	const receiverInfo = isNewReceiver
		? {
				rfc: watch('new_receiver_rfc').toUpperCase(),
				name: watch('new_receiver_name')?.toUpperCase(),
				tax_regime: watch('new_receiver_regime'),
				postal_code: watch('new_receiver_zip_code'),
				usocfdi: watch('new_receiver_cfdi_use'),
			}
		: {
				rfc: watch('saved_receiver').toUpperCase(),
				name: savedReceiverInfo?.name?.toUpperCase() || '',
				tax_regime: savedReceiverInfo?.tax_regime || '',
				postal_code: savedReceiverInfo?.postal_code || '',
				usocfdi: savedReceiverInfo?.usocfdi || '',
			};

	const onSaveReceiver = async (values: SaveReceiverProps) => {
		try {
			await saveReceiver(values, tokenSession);
		} catch (error) {}
	};

	const validateUserFields = useCallback(
		async ({
			rfc,
			name,
			postal_code,
			tax_regime,
			usocfdi,
		}: Record<string, string>) => {
			const cfdiUse = usocfdi ? `&uso_cfdi=${usocfdi}` : '';
			const encodedName = encodeURIComponent(name);
			try {
				const response = await getUserValidationFields(
					{
						rfc,
						name: encodedName,
						postal_code,
						tax_regime,
						cfdiUse,
					},
					tokenSession,
				);
				if (response?.codigo !== '000') {
					const errorMessage =
						response?.significado_codigo || ERROR.failed_to_fetch_data;
					const alert = {
						type: ALERT_TYPE.ERROR,
						title: 'Ocurrió un error',
						description: errorMessage,
					};
					dispatch(showAlert(alert));
				}
				return response;
			} catch (error: any) {
				const errorLib: Record<string, string> = {
					'The postal code should be 5 digits size':
						'El código postal debe ser de 5 dígitos',
				};
				const errorMessage = error?.response?.data?.message || '';
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Ocurrió un error',
					description: errorLib[errorMessage] || ERROR.failed_to_fetch_data,
				};
				dispatch(showAlert(alert));
			}
		},
		[],
	);

	const validateInfoFirstStep = async (step: number) => {
		if (!stampStatus) {
			dispatch(setInvoicingCurrentStep(2));
			if (step >= avaibleSteps) {
				dispatch(setInvoicingAvaibleSteps(step));
			}
			return;
		}

		const validDate = stampStatus?.valid_to;
		const isDueCertificate = dayjs(validDate).isBefore();

		if (isDueCertificate) {
			setShowModal({ ...showModal, dueCert: true });
			return;
		}

		setIsLoading({ ...isLoading, validationStep1: true });

		const resultValidationIssuer = await validateUserFields(issuerInfo);
		if (resultValidationIssuer?.codigo !== '000') {
			setIsLoading({ ...isLoading, validationStep1: false });
			return;
		}

		const resultValidationReceiver = await validateUserFields(receiverInfo);
		if (resultValidationReceiver?.codigo !== '000') {
			setIsLoading({ ...isLoading, validationStep1: false });
			return;
		}

		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_FISRT_STEP_DATA, {
			event_type: EVENT_TYPES.INVOICE_USERS_INFORMATION,
			name_issuer: issuerInfo?.name,
			rfc_issuer: issuerInfo?.rfc,
			postal_code_issuer: issuerInfo?.postal_code,
			regime_issuer: issuerInfo?.tax_regime,
			name_receiver: receiverInfo?.name,
			rfc_receiver: receiverInfo?.rfc,
			postal_code_receiver: receiverInfo?.postal_code,
			regime_receiver: receiverInfo?.tax_regime,
			usocfdi_receiver: receiverInfo?.usocfdi,
		});

		if (saveNewReceiver)
			onSaveReceiver({
				...receiverInfo,
				commercial_name: watch('new_receiver_name'),
			});

		if (step >= avaibleSteps) {
			dispatch(setInvoicingAvaibleSteps(step));
		}
		dispatch(setInvoicingCurrentStep(step));
		setIsLoading({ ...isLoading, validationStep1: false });
	};

	const validateInfoSecondStep = async (step: number) => {
		if (!stampStatus) {
			dispatch(setShowModalStamps(true));
			return;
		}

		const products = watch('products').map((product: Product) => ({
			key_prod_serv: product.product_id,
			key_unity: product.unit,
			unit_code: product.unit,
			unit_price: Number(product.price),
			quantity: Number(product.quantity),
			has_retentions: product.retentions === 'true',
			iva_exempt: product.tax === 'E',
			discount: Number(product.discount),
			product_code: product.product_id,
			description: product.description,
			transfers: [
				{
					base: 0,
					tax_type: '002',
					factor_type: 'Tasa',
					rate_or_quota: product.tax === 'E' ? 0 : Number(product.tax),
				},
			],
		}));

		const invoiceSummary = {
			receiver: receiverInfo,
			concepts: products,
			invoice_type: 'I',
			issuer: {
				name: issuerInfo.name?.toUpperCase(),
				rfc: issuerInfo.rfc?.toUpperCase(),
				tax_regime: issuerInfo.tax_regime,
			},
			payment_form: watch('payment_type'),
			payment_method: watch('payment_method'),
			regimen_fiscal: issuerInfo.tax_regime,
			place_of_expedition: issuerInfo.postal_code,
			date: timestampToLocalStringISOFormat(new Date()),
		};

		try {
			setIsLoading((prev) => ({ ...prev, validationStep2: true }));
			const data = await sendDataSummary(invoiceSummary, tokenSession);

			setValidatedInvoiceSummary(data);
			dispatch(setInvoicingCurrentStep(3));

			logClevertapEvent(ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT, {
				event_type: EVENT_TYPES.INVOICE_PRODUCT_INFORMATION,
				invoice_type: 'ingreso',
				payment_method: watch('payment_method'),
				payment_form: watch('payment_type'),
				currency: 'MXN',
				product_service: watch('products')[0]?.product_id,
				product_description: watch('products')[0]?.description,
				measure_unit: watch('products')[0]?.unit,
				quantity: watch('products')[0]?.quantity,
				cost_per_unit: watch('products')[0]?.price,
				discount: watch('products')[0]?.discount,
				taxes: watch('products')?.[0]?.tax,
				retention: watch('products')?.[0]?.retentions,
			});

			if (step >= avaibleSteps) {
				dispatch(setInvoicingAvaibleSteps(step));
			}
			dispatch(setInvoicingCurrentStep(step));
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Ocurrió un error al validar la factura',
				description: ERROR.failed_to_fetch_data,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, validationStep2: false }));
		}
	};

	const validateFourStep = async (step: number) => {
		if (!validatedInvoiceSummary || !stampStatus) return;

		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_CONFIRMATION_STEP, {
			event_type: EVENT_TYPES.INVOICE_ADD_EMAIL,
		});

		try {
			setIsLoading({ ...isLoading, validationStep4: true });
			const body = {
				email: {
					to: watch('emails')?.map((email: { email: string }) => email.email),
				},
				data: {
					date: validatedInvoiceSummary?.date,
					invoice_type: validatedInvoiceSummary?.invoice_type,
					payment_form: validatedInvoiceSummary?.payment_form,
					payment_method: validatedInvoiceSummary?.payment_method,
					place_of_expedition: validatedInvoiceSummary?.place_of_expedition,
					issuer: validatedInvoiceSummary?.issuer,
					receiver: validatedInvoiceSummary?.receiver,
					concepts: validatedInvoiceSummary?.concepts,
				},
			};

			await sendGenerateInvoice(body, tokenSession);

			if (step >= avaibleSteps) {
				dispatch(setInvoicingAvaibleSteps(step));
			}
			dispatch(setInvoicingCurrentStep(step));

			logClevertapEvent(ANALYTICS_EVENTS.INVOICE_CONFIRMATION_STEP, {
				event_type: EVENT_TYPES.INVOICE_SEND_CONFIRMATION,
			});
		} catch (error: any) {
			dispatch(setInvoicingCurrentStep(3));

			if (error?.response?.data) {
				if (typeof error.response.data === 'string') {
					const response = JSON.parse(error.response.data);
					const alert = {
						type: ALERT_TYPE.ERROR,
						title: 'Ocurrió un error al enviar la factura',
						description: response.Message ?? ERROR.failed_to_fetch_data,
					};
					dispatch(showAlert(alert));
					return;
				}
			}

			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Ocurrió un error al enviar la factura',
				description: `${error?.response?.data?.message || ERROR.failed_to_fetch_data}. Revisa la información ingresada y vuelve a intentarlo.`,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ ...isLoading, validationStep4: false });
		}
	};

	const handleChangeStep = async (step: number) => {
		if (
			step > avaibleSteps + 1 ||
			isLoading.validationStep1 ||
			isLoading.validationStep2
		)
			return;

		if (currentStep > step) {
			dispatch(setInvoicingCurrentStep(step));
			return;
		}

		if (currentStep === 1) {
			const issuerFields = ['issuer_regime'];
			const newReceiverFields = [
				'new_receiver_name',
				'new_receiver_rfc',
				'new_receiver_zip_code',
				'new_receiver_regime',
				'new_receiver_cfdi_use',
			];
			const savedReceiverField = ['saved_receiver'];

			const fieldsToValidate = [
				...issuerFields,
				...(stampStatus?.postal_code ? [] : ['issuer_zip_code']),
				...(isNewReceiver ? newReceiverFields : savedReceiverField),
			];

			await triggerFields(fieldsToValidate);
			if (hasFormErrors(fieldsToValidate)) return;

			await validateInfoFirstStep(step);
			return;
		}

		if (currentStep === 2) {
			const paymentFields = ['payment_method', 'payment_type', 'products'];
			await triggerFields(paymentFields);

			if (hasFormErrors(paymentFields)) return;
			await validateInfoSecondStep(step);
			return;
		}

		if (currentStep === 3) {
			if (step >= avaibleSteps) {
				dispatch(setInvoicingAvaibleSteps(step));
			}

			dispatch(setInvoicingCurrentStep(step));

			logClevertapEvent(ANALYTICS_EVENTS.INVOICE_THIRD_STEP_VALIDATION, {
				event_type: EVENT_TYPES.INVOICE_SEND_CONFIRMATION,
			});
		}

		if (currentStep === 4) {
			await trigger('emails');
			if (hasFormErrors(['emails'])) return;
			await validateFourStep(step);
		}
	};

	const triggerFields = async (fields: string[]) => {
		for (const field of fields) {
			await trigger(field);
		}
	};

	const hasFormErrors = (fields: string[]): boolean =>
		fields.some((field) => formErrors[field]);

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: 'products',
	});

	const {
		fields: emailFields,
		append: appendEmail,
		remove: removeEmail,
	} = useFieldArray({
		control: control,
		name: 'emails',
	});

	const appendProduct = () => {
		append({
			product_id: '',
			description: '',
			is_new_product: userProducts?.length ? 'false' : 'true',
			unit: 'E48',
			quantity: '1',
			price: '',
			discount: '0',
			tax: '',
			retentions: '',
		});
		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT, {
			event_type: EVENT_TYPES.INVOICE_ADD_PRODUCT,
		});
	};

	useEffect(() => {
		if (fields.length === 0) appendProduct();
		if (emailFields.length === 0) appendEmail({ email: '' });
	}, []);

	const renderSteps = () => {
		const steps: Record<number, JSX.Element> = {
			1: (
				<InvoiceStep1
					control={control}
					watch={watch}
					formErrors={formErrors}
					isNewReceiver={isNewReceiver}
					setIsNewReceiver={setIsNewReceiver}
					saveNewReceiver={saveNewReceiver}
					setSaveNewReceiver={setSaveNewReceiver}
				/>
			),
			2: (
				<InvoiceStep2
					control={control}
					formErrors={formErrors}
					fields={fields}
					remove={remove}
					setValue={setValue}
					trigger={trigger}
					watch={watch}
					isNewReceiver={isNewReceiver}
				/>
			),
			3: <InvoiceStep3 validatedInvoiceSummary={validatedInvoiceSummary} />,
			4: (
				<InvoiceStep4
					control={control}
					formErrors={formErrors}
					fields={emailFields}
					remove={removeEmail}
					append={appendEmail}
					setValue={setValue}
					trigger={trigger}
					watch={watch}
				/>
			),
			5: (
				<InvoiceStep5
					emails={watch('emails').map(
						(email: { email: string }) => email.email,
					)}
				/>
			),
		};

		return steps[currentStep] || null;
	};

	const handleGoBack = () => {
		if (currentStep > 1) dispatch(setInvoicingCurrentStep(currentStep - 1));
	};

	const ASATPlusProduct = products?.[3]?.products?.filter(
		(item) => item?.slug === 'asistencia_asat_2.0',
	);

	return (
		<section className='p-3 md:py-4 md:px-12 mb-20 sm:mb-2 flex flex-col gap-2 sm:gap-4'>
			<Modal
				open={isLoading.validationStep4}
				sx='w-[94%] !max-w-sm'
				onClose={() => {}}
				showCloseButton={false}
			>
				<div className='flex items-center justify-center my-5'>
					<CircularProgress size={40} />
				</div>
				<Text
					sx='!text-center !block'
					weight='bold'
					size='body-3'
				>
					Estamos generando tu factura
				</Text>
				<Text sx='!text-center !block my-4'>
					No cierre esta ventana hasta que el proceso haya terminado.
				</Text>
			</Modal>
			<Modal
				open={showModal.dueCert}
				sx='w-[94%] !max-w-md'
				onClose={() => setShowModal({ ...showModal, dueCert: false })}
			>
				<Text
					sx='!text-center !block'
					weight='bold'
					size='body-3'
				>
					Tus certificados estan vencidos
				</Text>
				<Text sx='!text-center !block my-4'>
					Podemos ayudarte a regenerarlos, para poder emitir facturas con
					nuestro servicio de asistencia ante el SAT
				</Text>
				<PrimaryButton
					label='Contratar Asistencia'
					onClick={() =>
						navigate(
							`${ROUTES.CHECKOUT}?product=${ASATPlusProduct?.[0]?.product_versions[0]?.id}`,
							{ replace: true },
						)
					}
				/>
			</Modal>

			{showBackButton && <InvoicingBreadcrumb />}
			<form className='flex gap-3'>
				<Container sx='flex-1 flex flex-col justify-between sm:min-h-[calc(100dvh-150px)]'>
					<div className='flex flex-col gap-4 md:gap-8'>
						{currentStep < 5 && (
							<div className='flex justify-between items-center mb-2'>
								<button
									type='button'
									onClick={handleGoBack}
									className={currentStep < 2 ? 'hidden' : ''}
								>
									<Text
										size='body-3'
										color='light'
										weight='light'
									>
										<CaretLeft />
										Paso anterior
									</Text>
								</button>
								{currentStep !== 3 && (
									<button
										onClick={() =>
											setShowModal({ ...showModal, invoicePreviewMobile: true })
										}
										type='button'
										className='w-fit flex items-center gap-2 text-h-primary transition bg-h-primary-90/50 py-1 px-2 rounded-md lg:hidden ml-auto'
									>
										<Eye /> Vista previa
									</button>
								)}
							</div>
						)}
						{currentStep < 5 && (
							<InvoicingStepper
								currentStep={currentStep}
								handleChangeStep={handleChangeStep}
								avaibleSteps={avaibleSteps}
							/>
						)}
						<div>{renderSteps()}</div>
					</div>
					{currentStep < 5 && (
						<div
							className={`fixed z-[2] border-b border-h-blue-90 flex gap-3 md:relative p-3 md:p-0 bg-white  md:bottom-auto left-0 w-full ${currentStep === 2 ? 'justify-between' : 'justify-end'} ${isFromIOS ? 'bottom-[88px]' : 'bottom-14'}`}
						>
							{currentStep === 2 && (
								<PrimaryButton
									onClick={appendProduct}
									label='Agregar otro producto a la factura'
									variant='secondary'
									beforeIcon={<PlusCircle className='hidden sm:block' />}
									sx='md:!w-fit md:mt-5 !px-1 sm:!px-5 !leading-5'
								/>
							)}

							<PrimaryButton
								loading={
									isLoading.validationStep1 ||
									isLoading.validationStep2 ||
									isLoading.validationStep4
								}
								onClick={() => handleChangeStep(currentStep + 1)}
								label='Continuar'
								sx={`md:!w-32 md:mt-5 sm:px-5 !block ${
									currentStep === 2 ? '!w-fit !min-w-28' : ''
								}`}
							/>
						</div>
					)}
				</Container>

				{currentStep !== 3 && (
					<InvoicePreview
						showModal={showModal.invoicePreviewMobile}
						setShowModal={setShowModal}
						watch={watch}
						receiverInfo={receiverInfo}
						validatedInvoiceSummary={validatedInvoiceSummary}
					/>
				)}
			</form>
		</section>
	);
};

export default NewInvoiceFlow;
