import { useState } from 'react';
import Text from '../../../atoms/Text';
import { CaretDown } from '@phosphor-icons/react';

interface ItemCollapseProps {
	title: string | React.ReactNode;
	subtitle?: string | React.ReactNode;
	description?: string;
	children?: React.ReactNode;
}

const ItemCollapse = ({
	title,
	subtitle,
	description,
	children,
}: ItemCollapseProps) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className='w-full '>
			<div
				className='flex flex-row  items-start cursor-pointer gap-x-1 w-full'
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className='inline-flex flex-1 md:flex-2 gap-1'>
					{typeof title === 'string' ? <Text sx=''>{title}</Text> : title}
					<CaretDown
						size={20}
						className={`transition-transform duration-300 ${
							isOpen ? 'rotate-180' : 'rotate-0'
						}`}
					/>
				</div>
				<div className='inline-flex flex-2 gap-1'>
					{typeof subtitle === 'string' ? <Text>{subtitle}</Text> : subtitle}
				</div>
			</div>
			{isOpen && (
				<div className='flex flex-col gap-2 mt-2'>
					<hr />
					{description && <Text>{description}</Text>}
					<div className='flex flex-col gap-2'>{children}</div>
				</div>
			)}
		</div>
	);
};

export default ItemCollapse;
