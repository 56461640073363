import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import { Bell, CircleNotch, ShoppingCart } from '@phosphor-icons/react';
import ModalCheckout from '../ModalCheckout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import HeruLogoIcon from '../../../assets/img/svg/heru-logo-v3.svg';
import useUserShoppingCarts from '../../../hooks/useUserShoppingCarts';
import { setShowModalCheckout } from '../../../redux/slices/plans.slice';
import { useStorage } from '../../../shared/utils/general.util';
import STORAGE_KEYS from '../../../shared/constants/storage';
import { PlatformOS } from '../../../redux/slices/profile.slice';

interface TopbarProps {
	showLogo?: boolean;
}

const Topbar: React.FC<TopbarProps> = ({ showLogo }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { getUserRefreshOrder } = useUserShoppingCarts();

	const { userOrder, isLoadingOrder } = useSelector(
		(state: RootState) => state.cart,
	);
	const isFromIOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) === PlatformOS.IOS;

	const openCheckoutModal = async () => {
		await getUserRefreshOrder();
		dispatch(setShowModalCheckout(true));
	};

	const numbersOfItems = userOrder?.[0]?.shoppingItem?.length ?? 0;

	return (
		<section
			className={`fixed -top-1 z-50 bg-white w-full flex border-b items-center py-1 ${
				isFromIOS ? 'pt-12' : ''
			}`}
		>
			<ModalCheckout />
			<img
				src={HeruLogoIcon}
				alt='heru_logo'
				className={`w-14 ml-4 ${showLogo ? 'md:hidden' : 'md:block'}`}
				onClick={() => navigate(ROUTES.ROOT)}
			/>
			<div className='flex w-full justify-end py-2.5'>
				{/* {orders.length > 0 && ( */}
				<button
					onClick={openCheckoutModal}
					className='relative disabled:opacity-50'
					disabled={isLoadingOrder}
				>
					{isLoadingOrder ? (
						<div className='bg-h-primary w-[18px] h-[18px] rounded-full absolute -top-1.5 left-4 flex items-center justify-center'>
							<CircleNotch
								size={12}
								weight='bold'
								className='text-white animate-spin'
							/>
						</div>
					) : (
						numbersOfItems > 0 && (
							<div className='bg-h-red-50 w-[18px] h-[18px] rounded-full absolute -top-1.5 left-4'>
								<span className='text-[10px] absolute top-[2px] left-[6px] text-white'>
									{numbersOfItems}
								</span>
							</div>
						)
					)}
					<ShoppingCart
						size={26}
						color={'var(--h-blue-70)'}
						weight='light'
						className='mr-3'
					/>
				</button>
				{/* )} */}
				<button>
					<Bell
						size={26}
						color={'var(--h-blue-70)'}
						weight='light'
						className='mr-4'
					/>
				</button>
			</div>
		</section>
	);
};

export default Topbar;
