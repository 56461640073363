import { getTextColor, getTextWeight } from '../../../shared/utils/styles.util';

interface Props {
	color?:
		| 'dark'
		| 'medium'
		| 'light'
		| 'white'
		| 'ultraBlueLight'
		| 'darkMenu'
		| 'darkBlue';
	children: React.ReactNode;
	size: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
	weight?: 'light' | 'regular' | 'medium' | 'bold';
	sx?: string;
	responsive?: boolean;
}

const Heading = ({
	size,
	children,
	color = 'dark',
	weight = 'medium',
	sx,
	responsive,
}: Props) => {
	const getHeadingTag = () => {
		switch (size) {
			case '2xl':
				return 'h1';
			case 'xl':
				return 'h2';
			case 'l':
				return 'h3';
			case 'm':
				return 'h4';
			case 's':
				return 'h5';
			case 'xs':
				return 'h6';
			default:
				return 'h4';
		}
	};
	const HeadingTag = getHeadingTag();

	const getHeadingSize = () => {
		switch (size) {
			case '2xl':
				return 'text-[56px] leading-[64px]';
			case 'xl':
				return 'text-[56px] leading-[64px]';
			case 'l':
				return 'text-5xl leading-[60px]';
			case 'm':
				return 'text-[24px] md:text-[40px] md:leading-[54px]';
			case 's':
				return 'text-[18px] md:text-[32px] md:leading-[45px]';
			case 'xs':
				return 'text-2xl leading-8';
			default:
				return 'text-[40px] leading-[54px]';
		}
	};

	const getHeadingSizeResponsive = () => {
		switch (size) {
			case '2xl':
				return `text-6xl lg:text-[56px] leading-[64px]`;
			case 'xl':
				return `text-5xl leading-[56px] lg:text-[56px] leading-[64px]`;
			case 'l':
				return `text-[40px] leading-[48px] lg:text-5xl leading-[60px]`;
			case 'm':
				return `text-[32px] leading-10 lg:text-[40px] leading-[54px]`;
			case 's':
				return `text-2xl leading-8 lg:text-[32px] leading-[45px]`;
			case 'xs':
				return `text-xl leading-7 lg:text-2xl leading-8`;
			default:
				return `text-[32px] leading-10 lg:text-[40px] leading-[54px]`;
		}
	};

	return (
		<HeadingTag
			className={`
				tracking-[-1px] 
				${getTextColor(color)} 
				${getTextWeight(weight)} 
				${responsive ? getHeadingSizeResponsive() : getHeadingSize()}
				${sx}`}
		>
			{children}
		</HeadingTag>
	);
};

export default Heading;
