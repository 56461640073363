import React, { useState } from 'react';
import Text from '../../atoms/Text';
import {
	ArrowBendUpRight,
	CaretDown,
	DownloadSimple,
	Eye,
	Share,
	Trash,
} from '@phosphor-icons/react';
import HomeCard from '../../molecules/HomeCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../molecules/Alert';
import { getAnimation } from '../../../shared/utils/styles.util';
import LITERALS from '../../../shared/constants/literals';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { capitalizeFirstLetter } from '../../../shared/utils/general.util';
import { Checkbox, Tooltip } from '@mui/material';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import Modal from '../../molecules/Modal/Modal';

import zubale from '../../../assets/img/png/Zubale Z 2023.png';
import LinkButton from '../../atoms/LinkButton';
import PrimaryButton from '../../atoms/Button';
import { acceptZubaleTC } from '../../../services/zubale.service';

interface Props {
	eliminateCiec?: boolean;
	fiscalProfile?: boolean;
	isDisabled?: boolean;
	sx?: string;
	openModal?: () => void;
}

const docItems = [
	{
		label: 'Tu constancia de situación fiscal',
		slug: 'CSF',
	},
	{
		label: 'Tu opinión de cumplimiento',
		slug: 'OC',
	},
];

const labels = [
	{
		label: 'Nombre',
		slug: 'name',
	},
	{
		label: 'RFC',
		slug: 'code',
	},
	{
		label: 'Código postal',
		slug: 'zipcode',
	},
];

const FiscalProfileCard: React.FC<Props> = ({
	isDisabled,
	sx,
	fiscalProfile,
	eliminateCiec,
	openModal,
}) => {
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const links = useSelector(
		(state: RootState) => state.taxProfile.links?.links,
	);

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [viewModalOrganizations, setViewModalOrganizations] =
		useState<boolean>(false);
	const [shareSubale, setShareSubale] = useState<boolean>(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);

	const [selectedImage, setSelectedImage] = useState<boolean>(false);

	const [showPdfModal, setShowPdfModal] = useState(false);

	const handleOpenPdfMobile = () => {
		setShowPdfModal(true);
	};

	const handleClosePdfModal = () => {
		setShowPdfModal(false);
	};

	const getTaxPayerValue = (slug: string) => {
		if (!taxPayer) return '';
		switch (slug) {
			case 'name':
				return taxPayer.name?.toLocaleLowerCase() ?? LITERALS.NO_AVAILABLE_DATA;

			case 'code':
				return fiscalProfile
					? (links?.rfc?.toUpperCase() ?? LITERALS.NO_AVAILABLE_DATA)
					: (taxPayer.code?.toUpperCase() ?? LITERALS.NO_AVAILABLE_DATA);

			case 'zipcode':
				return taxPayer?.address?.postal_code || LITERALS.NO_AVAILABLE_DATA;
			default:
				return '';
		}
	};

	const messageShare = `¡Hola! A continuación te comparto mi información de facturación actualizada:${'\n'}
Nombre o razón social: ${
		taxPayer?.name
			?.split(' ')
			?.map((text) => capitalizeFirstLetter(text.toLocaleLowerCase()))
			?.join(' ') || ''
	}
RFC: ${taxPayer?.code || ''}
Código postal: ${taxPayer?.address?.postal_code || ''}${'\n'}
Mi(s) régimen(es) son: 
${
	taxPayer?.status?.tax_regimes
		?.map((r, index) => `${index + 1}. Régimen de ` + `${r.name} \n`)
		.join('') || ''
}

Recuerda que puedes usar https://web.heru.app/ para realizar todas tus facturas gratis en cuestión de segundos${'\n'}
Saludos`;

	const logTaxProfileShareEvent = () => {
		if (userProfile) {
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_TAX_PROFILE_SHARED,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
			});
		}
	};

	const shareHandler = () => {
		if (navigator.share) {
			logTaxProfileShareEvent();
			navigator
				.share({
					title: '',
					text: messageShare,
					// url: 'https://web.heru.app/',
				})
				.then(() => {
					const alert = {
						type: ALERT_TYPE.SUCCESS,
						title: '¡Listo!',
						description: 'Tu información fiscal ha sido compartida',
					};
					dispatch(showAlert(alert));
				})
				.catch(() => {
					location.reload();
				});
		} else {
			const alert = {
				type: ALERT_TYPE.WARNING,
				title: 'Aviso',
				description: LITERALS.ERROR_WEB_SHARE_MESSAGE,
			};
			dispatch(showAlert(alert));
		}
	};
	const handleAcceptZubale = async () => {
		const body = {
			doc_type: 'T&Cs Zubale',
			version: '2024-09-05',
		};
		try {
			await acceptZubaleTC(body);
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: '¡Listo!',
				description: 'Tu información fiscal ha sido compartida',
			};
			dispatch(showAlert(alert));
			setViewModalOrganizations(false);
			setShareSubale(false);
			setAcceptedTerms(false);
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Error',
				description: 'Ocurrió un error al compartir tu información fiscal',
			};
			dispatch(showAlert(alert));
		}
	};
	const handleCloseModal = () => {
		setViewModalOrganizations(false);
		setShareSubale(false);
		setAcceptedTerms(false);
		setSelectedImage(false);
	};

	const handleOpenPdf = () => {
		const pdfUrl =
			'https://d16nv9v9z1ihe6.cloudfront.net/Legal+MX+-+Te%CC%81rminos+y+Condiciones+Comisio%CC%81n+Mercantil+-+Heru.pdf';
		window.open(pdfUrl, '_blank');
	};

	return (
		<HomeCard
			title={fiscalProfile ? 'Información Personal' : 'Perfil fiscal'}
			href='#fiscalform'
			showButton={isDisabled}
			sx={sx}
			headerButton={
				eliminateCiec ? (
					<div
						className='relative group/file ml-4'
						onClick={openModal}
					>
						<Trash
							weight='light'
							color={'var(--h-primary-20)'}
							className='text-bold group-hover/file:hidden'
							width={24}
							height={24}
						/>
						<Trash
							weight='fill'
							color={'var(--h-primary-20)'}
							className='text-bold hidden group-hover/file:block'
							width={24}
							height={24}
						/>
						<div className='transition translate-y-4 group-hover/file:translate-y-0  opacity-0 group-hover/file:opacity-100 absolute bottom-5 left-[calc(100%-56px)] flex flex-col items-center'>
							<Text
								weight='regular'
								size='caption'
								color='white'
								sx='bg-h-primary-20 px-2 py-1 rounded-lg'
							>
								Eliminar
							</Text>
							<CaretDown
								weight='fill'
								color='var(--h-primary-20)'
								width={24}
								height={24}
								className='mt-[-10px]'
							/>
						</div>
					</div>
				) : !isDisabled ? (
					<Tooltip
						title='Compartir'
						placement='top'
					>
						<button
							className='flex flex-col justify-center items-center'
							type='button'
							onClick={() => shareHandler()}
						>
							<Share
								size={24}
								className={getAnimation('ease-in-out')}
							/>
						</button>
					</Tooltip>
				) : null
			}
		>
			{isDisabled && (
				<div className='flex flex-col gap-y-2 '>
					<div className='bg-[var(--h-gray-10)] flex flex-row py-2 shadow-sm pl-4 pr-6'>
						<Text
							size='l'
							sx='color-[var(--h-blue-60)]'
							weight='medium'
						>
							RFC:
						</Text>
						<Text
							size='l'
							sx='color-[var(--h-blue-60)] ml-1'
						>
							XAXX010101000
						</Text>
					</div>
					{docItems.map((doc) => (
						<div
							key={doc.slug}
							className='bg-[var(--h-gray-10)] pl-4 flex flex-row items-center py-2 pr-6 shadow-sm justify-between gap-x-2'
						>
							<Text
								size='l'
								sx='color-[var(--h-blue-60)]'
								weight='medium'
							>
								{doc.label}
							</Text>
							<div>
								<Text
									size='s'
									color='medium'
									sx='mb-1.5'
								>
									<DownloadSimple size={16} />
									Descargar
								</Text>
								<div className='border border-b-[var(--h-gray-20)] mb-2' />
								<Text
									size='s'
									color='medium'
								>
									<ArrowBendUpRight size={16} />
									Compartir
								</Text>
							</div>
						</div>
					))}
				</div>
			)}
			{!isDisabled && (
				<div className='flex flex-col gap-y-6 '>
					{labels.map(({ label, slug }) => {
						const taxPayerValue = getTaxPayerValue(slug);
						if (slug === 'zipcode' && taxPayerValue === 'No disponible') {
							return null;
						}
						return (
							<div
								key={slug}
								className='flex flex-col lg:flex-row lg:justify-between'
							>
								<Text
									size='body-3'
									weight='bold'
									sx='hidden lg:block'
								>
									{label}
								</Text>
								<Text
									size='caption'
									weight='bold'
									sx='block lg:hidden'
								>
									{label}
								</Text>
								<Text
									size='body-3'
									weight='light'
									sx='hidden lg:block capitalize'
								>
									{taxPayerValue}
								</Text>
								<Text
									size='body-3'
									weight='light'
									sx='block lg:hidden capitalize'
								>
									{taxPayerValue}
								</Text>
							</div>
						);
					})}

					{links?.ciec?.[0]?.password && (
						<div className='flex flex-col lg:flex-row lg:justify-between'>
							<Text
								size='body-3'
								weight='bold'
								sx='hidden lg:block w-full'
							>
								Contraseña RFC
							</Text>
							<Text
								size='caption'
								weight='bold'
								sx='block lg:hidden w-full'
							>
								Contraseña RFC
							</Text>
							<div className='flex justify-between'>
								<Text
									size='body-3'
									weight='light'
									sx='hidden lg:block capitalize mr-2'
								>
									{showPassword
										? (links?.ciec?.[0]?.password ?? 'Sin datos')
										: '********'}
								</Text>
								<Eye
									size={20}
									weight='light'
									className='hidden lg:block'
									onClick={() => setShowPassword(!showPassword)}
								/>
							</div>
							<div className='flex justify-between'>
								<Text
									size='body-3'
									weight='light'
									sx='block lg:hidden capitalize mr-2'
								>
									{showPassword
										? (links?.ciec?.[0]?.password ?? 'Sin datos')
										: '********'}
								</Text>
								<Eye
									size={20}
									weight='light'
									className='block lg:hidden'
									onClick={() => setShowPassword(!showPassword)}
								/>
							</div>
						</div>
					)}

					{!fiscalProfile && (
						<div>
							<Text
								size='l'
								weight='medium'
								sx='mb-2'
							>
								Regímenes
							</Text>
							{(!taxPayer?.status?.tax_regimes ||
								taxPayer?.status?.tax_regimes?.length === 0) && (
								<Text size='m'>{LITERALS.NO_AVAILABLE_DATA}</Text>
							)}

							<ul className='list-disc ml-6'>
								{taxPayer?.status?.tax_regimes?.map((regime) => (
									<li key={`regime-${regime.code}`}>
										<Text size='m'>{regime.name}</Text>
									</li>
								))}
							</ul>
						</div>
					)}
					{!isDisabled && (
						<div onClick={() => setViewModalOrganizations(true)}>
							<Text
								size='s'
								weight='bold'
								color='primary'
								sx='cursor-pointer'
							>
								Comparte tu información con alguna organización
							</Text>
						</div>
					)}
					<Modal
						open={viewModalOrganizations}
						onClose={handleCloseModal}
						children={
							shareSubale ? (
								<>
									<Text
										size='xl'
										weight='medium'
										sx='mb-2'
									>
										Zubale
									</Text>
									<Text
										size='m'
										weight='light'
										sx='mb-5'
									>
										Al aceptar terminos y condiciones, tu información fiscal
										será compartida.
									</Text>
									<div className='flex  items-center ml-[-12px] mt-[-20px] mb-4 gap-1'>
										<Checkbox
											checked={acceptedTerms}
											onClick={() => setAcceptedTerms(!acceptedTerms)}
											sx={{
												'&.Mui-checked': {
													color: 'var(--h-primary)',
												},
											}}
										/>
										<Text
											size='m'
											weight='light'
											sx='hidden lg:block'
										>
											Acepto los
										</Text>
										<LinkButton
											sx='hidden lg:block'
											label='Términos y Condiciones'
											onClick={handleOpenPdf}
										/>
										<div className='block lg:hidden w-full text-xs font-light'>
											Acepto los{' '}
											<span
												onClick={handleOpenPdfMobile}
												style={{
													color: 'var(--h-primary)',
													textDecoration: 'underline',
													cursor: 'pointer',
												}}
											>
												Términos y Condiciones
											</span>
										</div>
									</div>
									<Modal
										open={showPdfModal}
										onClose={handleClosePdfModal}
										sx={'w-full h-full'}
									>
										<iframe
											src='https://d16nv9v9z1ihe6.cloudfront.net/Legal+MX+-+Te%CC%81rminos+y+Condiciones+Comisio%CC%81n+Mercantil+-+Heru.pdf'
											width='100%'
											height='100%'
											title='Términos y Condiciones'
										></iframe>
									</Modal>
									<PrimaryButton
										disabled={!acceptedTerms}
										label='Compartir'
										onClick={handleAcceptZubale}
									/>
								</>
							) : (
								<div>
									<Text
										size='l'
										weight='medium'
										sx='mb-2'
									>
										Organizaciones
									</Text>
									<Text
										size='m'
										weight='light'
										sx='mb-3'
									>
										Selecciona una organización para compartir tu información
										fiscal :
									</Text>

									<img
										src={zubale}
										alt='subale'
										className={`p-4 cursor-pointer mb-8 mt-4 w-20 h-20 border ${selectedImage ? 'border-blue-400' : 'border-gray-300'}`}
										onClick={() => setSelectedImage(!selectedImage)}
									/>

									<PrimaryButton
										onClick={() => setShareSubale(true)}
										disabled={!selectedImage}
										label='Aceptar'
									/>
								</div>
							)
						}
					/>
				</div>
			)}
		</HomeCard>
	);
};

export default React.memo(FiscalProfileCard);
