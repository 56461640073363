import { REGIMES_CODE } from './Regime.type';

export interface ListProducts {
	name: CategoryName;
	products: Product[];
}

export enum CategoryName {
	AsistenciaSAT = 'Asistencia SAT',
	DeclaraciónAnual = 'Declaración Anual',
	DeclaraciónAtrasada = 'Declaración Atrasada',
	DeclaraciónMensual = 'Declaración Mensual',
	Plans = 'Planes',
}

export interface Product {
	id: number;
	name: string;
	description: string;
	icon_url: string;
	color: string;
	periodicity: number;
	price: number;
	required_fields: null;
	long_description: LongDescription;
	fiscal_concept: FiscalConcept;
	fiscal_code: string;
	is_purchasable: boolean;
	active: boolean;
	default_product_version_id: number;
	hidden: boolean;
	slug: string;
	category: CategoryName;
	benefits: any;
	recurrency: boolean;
	detail: string;
	ideal: string[];
	sort: number;
	annotations: string[];
	options_type: OptionsType;
	sort_app: number;
	automatic_flow: boolean;
	show_planes_precio: boolean;
	regime: Regime[];
	sub_category: SubCategory;
	pyp_v2: PypV2;
	principal_benefits: string[];
	product_versions: ProductVersion[];
}

export enum FiscalConcept {
	AsuntosYPreparaciónDeImpuestos = 'Asuntos y preparación de impuestos',
	Empty = '',
	ServiciosDeAsesoríaFiscal = 'Servicios de Asesoría Fiscal',
}

export interface LongDescription {
	more_details: MoreDetail[];
	short_description: string[] | string;
	product_title?: string;
	cancel_message?: string;
	button_action_label?: string;
	product_description_top?: string;
	product_description_bottom?: string;
}

export interface MoreDetail {
	terms: string;
	icon_url: string;
	icon_width: string;
	description: string;
	icon_height: string;
	icon_details: string;
	certificate_head: string;
	notice_of_privacy: string;
	icon_at_background: boolean | string;
}

export enum OptionsType {
	DefaultProductVersion = 'default_product_version',
	NonDeclaredPeriods = 'non_declared_periods',
	ProductVersions = 'product_versions',
}

export interface ProductVersion {
	id: number;
	product_id: number;
	currency_id: number;
	price: number;
	periodicity: number;
	description: string;
	created_at: string;
	relative_percentage_off: number;
	updated_at: UpdatedAt;
}

export enum UpdatedAt {
	None = 'None',
}

export interface Benefits {
	benefits: Benefit[];
}
export interface Benefit {
	description: string;
	type: 'general' | 'unique';
}

export interface PypV2 {
	order: number;
	price: number;
	show_tag: boolean;
	tag_text: TagText;
	url_video: string;
	show_price: boolean;
	description: string;
	all_benefits: string[];
	product_name: string;
	cta_video_text: CtaVideoText;
	show_cta_video: boolean;
	after_price_text: string;
	principal_button: PrincipalButton;
	before_price_text: string;
	principal_benefits: string[];
}

export enum CtaVideoText {
	CómoFunciona = '¿Cómo funciona?',
}

export enum PrincipalButton {
	Contratar = 'Contratar',
}

export enum TagText {
	MásComprado = 'Más comprado',
}

export enum Regime {
	ActividadesEmpresarialesYProfesionales = 'Actividades Empresariales y Profesionales',
	Arrendamiento = 'Arrendamiento',
	PlataformasTecnológicas = 'Plataformas Tecnológicas',
	Resico = 'RESICO',
	Rif = 'RIF',
}

export const RegimeFFT: Record<string, string> = {
	[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]: 'PLA-TEC',
	[REGIMES_CODE.RESICO]: 'RESICO',
	[REGIMES_CODE.ACTIVIDAD_EMPRESARIAL]: 'ACT-EMP',
	[REGIMES_CODE.SUELDOS_Y_SALARIOS]: 'SUE-SAL',
	[REGIMES_CODE.ARRENDAMIENTO]: 'ARRIENDOS',
	[REGIMES_CODE.RIF]: 'RIF',
	[REGIMES_CODE.PERSONAS_MORALES_CON_FINES_NO_LUCRATIVOS]: 'PER-MOR',
};
export const ProductFFT = {
	Asistencia_ASAT: 'ASAT',
};

export const RegimeFFTToRegime: Record<string, string> = {
	'PLA-TEC': 'Plataformas Tecnológicas',
	RESICO: 'RESICO',
	'ACT-EMP': 'Actividades Empresariales y Profesionales',
	'SUE-SAL': 'Sueldos y Salarios',
	ARRIENDOS: 'Arrendamiento',
	RIF: 'Rif',
	'PER-MOR': 'PER-MOR',
};

export enum SubCategory {
	DeclaracionesAtrasadas = 'Declaraciones atrasadas',
	DeclaracionesMensuales = 'Declaraciones mensuales',
	PaqueteDeDeclaracionesMensuales = 'Paquete de declaraciones mensuales',
}

export enum Products {
	// ASAT
	ASATBasic = 36,
	ASATPlus = 31,
	// Subscriptions
	SubscriptionPt = 16,
	SubscriptionResico = 78,
	SubscriptionEnterprise = 124,
	SubscriptionLease = 82,
	SubscriptionHeruPlus = 92,
	// Annual
	AnnualPt = 45,
	AnnualResico = 44,
	AnnualEnterprise = 45,
	AnnualSalaried = 44,
	AnnualLease = 45,
	// Regularization
	RegularizationPt = 104,
	RegularizationResico = 106,
	RegularizationEnterprise = 115,
	RegularizationLease = 107,
}

export enum ProductsCategory {
	ASAT = 'asat',
	Subscription = 'subscription',
	Annual = 'annual',
	Regularization = 'regularization',
	csf = 'csf',
	invoicing = 'invoicing',
	plans = 'Planes',
}

export enum MonthlyDeclarationSubscription {
	PLAN_PLATFORM = 'plan_platform',
	PLAN_ENTREPRENEUR = 'plan_entrepreneur',
	PLAN_BUSINESS = 'plan_business',
}
