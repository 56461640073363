import { ListProducts } from '../@types/Products.type';
import HERU_API from '../shared/constants/heruApi';
import { get, post } from '../shared/utils/FetchSSL.util';

const getProducts = async (tokenSession?: string): Promise<ListProducts[]> => {
	try {
		const response = await get(
			`${HERU_API.HERU_API_PRODUCTS}/map?channel=web`,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getReceipts = async (userId: number, tokenSession?: string) => {
	try {
		const response = await get(`${HERU_API.SALES_RECEIPTS}/users/${userId}`, {
			token: true,
			tokenSession,
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const generateReceipt = async (
	userId: number,
	shoppingCartId: number,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			`${HERU_API.SALES_RECEIPTS}/users/${userId}/shopping-cart/${shoppingCartId}`,
			{},
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};
export {
	getProducts,
	getReceipts,
	generateReceipt,
};
