import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Profile } from '../../@types/Profile.type';
import { Catalogs } from '../../@types/Catalogs.type';
import { Regime } from '../../@types/Regime.type';
import { Receiver } from '../../@types/Receiver.type';

import { SubscriptionType } from '../../@types/Subscription';
import { DigitalStampStatus } from '../../@types/DigitalStampStatus.type';
import { UserSavedProduct } from '../../@types/Invoicing.type';
import { PRODUCTS_SLUG } from '../../shared/constants/SlugsProducts';
import { ServiceStatusElement } from '../../@types/ServiceStatus.type';

export enum PlatformOS {
	WEB = 'web',
	IOS = 'ios',
	ANDROID = 'android',
}

export interface ProfileState {
	user?: Profile;
	catalogs?: Catalogs;
	regimes: Regime[];
	receivers: Receiver[];
	stampStatus?: DigitalStampStatus;
	userProducts?: UserSavedProduct[];
	subscriptions: SubscriptionType[];
	activeSuscriptions: SubscriptionType[];
	activeAnnualSubscription: SubscriptionType | undefined;
	isInApp?: boolean;
	serviceStatus?: ServiceStatusElement[];
	isFromIOS?: boolean;
	isHotSale?: boolean;
	priceGroupExperiment?: string;
	errors: {
		[key: string | number]: string;
	};
	hasPendingAnalysis?: boolean;
}

const initialState: ProfileState = {
	receivers: [],
	regimes: [],
	subscriptions: [],
	activeSuscriptions: [],
	activeAnnualSubscription: undefined,
	isInApp: false,
	serviceStatus: [],
	isFromIOS: false,
	isHotSale: false,
	errors: {},
	priceGroupExperiment: '',
	hasPendingAnalysis: false,
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setProfile: (state, action: PayloadAction<Profile>) => {
			state.user = action.payload;
		},
		clearProfile: (state) => {
			state.user = undefined;
		},
		setCatalogs: (state, action: PayloadAction<Catalogs>) => {
			state.catalogs = action.payload;
		},
		setRegimes: (state, action: PayloadAction<Regime[]>) => {
			state.regimes = action.payload;
		},
		setReceivers: (state, action: PayloadAction<Receiver[]>) => {
			state.receivers = action.payload;
		},
		setStampStatus: (state, action: PayloadAction<any>) => {
			state.stampStatus = action.payload;
		},
		setUserProducts: (state, action: PayloadAction<any>) => {
			state.userProducts = action.payload;
		},
		setUserSubscriptions: (
			state,
			action: PayloadAction<SubscriptionType[]>,
		) => {
			state.subscriptions = action.payload;
			state.activeSuscriptions = action.payload.filter(
				(subscription) => subscription.active,
			);
			state.activeAnnualSubscription = action.payload.find(
				(subscription) =>
					subscription?.active &&
					subscription?.product_version?.product?.slug ===
						PRODUCTS_SLUG.ANNUAL_DECLARATION,
			);
		},
		setServiceStatusElement: (
			state,
			action: PayloadAction<ServiceStatusElement[]>,
		) => {
			state.serviceStatus = action.payload;
		},
		setIsHotSale: (state, action) => {
			state.isHotSale = action.payload;
		},
		setAllErrors: (state, action) => {
			state.errors = action.payload;
		},
		setPriceGroupExperiment: (state, action) => {
			state.priceGroupExperiment = action.payload;
		},
		setHasPendingAnalysis: (state, action) => {
			state.hasPendingAnalysis = action.payload;
		},
	},
});

export const {
	setProfile,
	clearProfile,
	setCatalogs,
	setRegimes,
	setReceivers,
	setStampStatus,
	setUserProducts,
	setUserSubscriptions,
	setServiceStatusElement,
	setIsHotSale,
	setAllErrors,
	setPriceGroupExperiment,
	setHasPendingAnalysis,
} = profileSlice.actions;

export default profileSlice.reducer;

