import PrimaryButton from '../../components/atoms/Button';
import Container from '../../components/atoms/Container';
import Heading from '../../components/atoms/Heading';
import Text from '../../components/atoms/Text';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
	setCurrentInvoicingPage,
	setInvoicingAvaibleSteps,
	setInvoicingCurrentStep,
	setShowModalStamps,
	setUserIssuedInvoices,
} from '../../redux/slices/invoicing';
import EmmitedInvoiceList from './molecules/EmmitedInvoiceList';
import NewInvoiceFlow from './newInvoiceFlow';
import { useCallback, useEffect, useState } from 'react';

import {
	getHasUserInvoice,
	getInvoicingCatalog,
	getListOfReceivers,
	getListOfRegimes,
	getUserDigitalStampStatus,
	getUserIssuedInvoice,
	getUserSavedProducts,
} from '../../services/invoicing.service';
import {
	PlatformOS,
	ProfileState,
	setCatalogs,
	setReceivers,
	setRegimes,
	setStampStatus,
	setUserProducts,
} from '../../redux/slices/profile.slice';
import { CircularProgress } from '@mui/material';
import InvoiceStampsInfo from './molecules/InvoiceStampsInfo';
import { UserIssuedInvoice } from '../../@types/UserIssuedInvoice.type';
import { addUserRegimes } from '../../redux/slices/taxProfile.slice';
import { fetchUserRegimesIncomes } from '../../services/satLinking.service';
import IssuedInvoicePreview from './organisms/IssuedInvoicePreview';
import Alert from '../../components/molecules/Alert';
import { addProducts } from '../../redux/slices/products.slice';
import { getProducts } from '../../services/plans.service';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import ROUTES from '../../shared/constants/routes';
import Modal from '../../components/molecules/Modal/Modal';
import { useBlocker } from 'react-router-dom';
import { useStorage } from '../../shared/utils/general.util';
import STORAGE_KEYS from '../../shared/constants/storage';

const Invoicing = () => {
	const logClevertapEvent = useCleverTapEvent();

	const [isLoadingServices, setIsLoadingServices] = useState(false);
	const [issuedInvoices, setIssuedInvoices] = useState<UserIssuedInvoice[]>([]);
	const dispatch = useDispatch();
	const isFromIOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) === PlatformOS.IOS;

	const { currentPage } = useSelector((state: RootState) => state.invocing);
	const { user } = useSelector<RootState, ProfileState>(
		(state) => state.profile,
	);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const alert = useSelector((state: RootState) => state.alert);

	const getRegimes = useCallback(async () => {
		try {
			const { regimes } = await getListOfRegimes(tokenSession);
			dispatch(setRegimes(regimes));
		} catch (error) {}
	}, []);

	const getReceivers = useCallback(async () => {
		try {
			const { receivers } = await getListOfReceivers(tokenSession);
			dispatch(setReceivers(receivers));
		} catch (error) {}
	}, []);

	const getStampStatus = useCallback(async () => {
		try {
			const data = await getUserDigitalStampStatus(user!.id, tokenSession);
			dispatch(setStampStatus(data));
		} catch (error) {}
	}, [user?.id]);

	const getCatalogs = useCallback(async () => {
		try {
			const data = await getInvoicingCatalog();
			dispatch(setCatalogs(data));
		} catch (error) {}
	}, []);

	const getSavedProducts = useCallback(async () => {
		try {
			const data = await getUserSavedProducts(tokenSession);
			dispatch(setUserProducts(data));
		} catch (error) {}
	}, []);

	const getUserRegimes = async () => {
		try {
			const response = await fetchUserRegimesIncomes(tokenSession);
			dispatch(addUserRegimes(response));
		} catch (error) {}
	};

	const getUserInvoiceIssued = useCallback(async () => {
		try {
			const response = await getUserIssuedInvoice(user!.id, tokenSession);
			const mappedInvoices = Object.values(response).flatMap(
				(yearArray) => yearArray,
			);
			const sortedInvoices = mappedInvoices.sort(
				(a: any, b: any) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
			) as UserIssuedInvoice[];

			dispatch(setUserIssuedInvoices(sortedInvoices));
			setIssuedInvoices(sortedInvoices);
		} catch (error) {}
	}, [user?.id]);

	const getUserHasInvoice = useCallback(async () => {
		try {
			const response = await getHasUserInvoice(user!.id, tokenSession);
			if (response) {
				await getUserInvoiceIssued();
			}
		} catch (error) {}
	}, [user?.id]);

	const fetchGetProducts = useCallback(async () => {
		try {
			const response = await getProducts(tokenSession);
			dispatch(addProducts(response));
		} catch (error) {}
	}, []);

	const handleNewInvoice = () => {
		dispatch(setCurrentInvoicingPage('newInvoice'));
		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_HOME_INVOICES, {
			event_type: EVENT_TYPES.INVOICE_GENERATE_NEW_INVOICE,
		});
	};

	useEffect(() => {
		setIsLoadingServices(true);
		if (!user?.id) return;
		Promise.all([
			getRegimes(),
			getCatalogs(),
			getReceivers(),
			getStampStatus(),
			getSavedProducts(),
			getUserHasInvoice(),
			getUserRegimes(),
			fetchGetProducts(),
		]).finally(() => {
			setIsLoadingServices(false);
		});
	}, [user?.id]);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.INVOICING,
		});

		dispatch(setCurrentInvoicingPage('dashboard'));
		dispatch(setInvoicingAvaibleSteps(1));
		dispatch(setInvoicingCurrentStep(1));
		dispatch(setShowModalStamps(false));
	}, []);

	let blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			(currentPage === 'newInvoice' || issuedInvoices?.length < 1) &&
			!isLoadingServices &&
			currentLocation.pathname !== nextLocation.pathname,
	);

	if (isLoadingServices) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (currentPage === 'dashboard' && issuedInvoices?.length) {
		return (
			<section className='p-3 md:py-4 md:px-12 mb-20 sm:mb-2 flex flex-col gap-2 sm:gap-4 '>
				{alert.showAlert && (
					<Alert
						type={alert.type}
						description={alert.description}
						title={alert.title}
					/>
				)}
				<div className='flex justify-between items-center'>
					<Heading
						size='s'
						weight='bold'
						responsive
					>
						Facturación
					</Heading>
					<div
						className={`z-2 fixed sm:relative sm:bottom-auto left-0 bg-white sm:bg-transparent p-3 sm:p-0 w-full sm:w-fit 
							${isFromIOS ? 'bottom-[86px]' : 'bottom-14'}`}
					>
						<PrimaryButton
							onClick={handleNewInvoice}
							label='Emitir nueva factura'
							sx='!w-full sm:!w-fit'
						/>
					</div>
				</div>
				<div className='flex gap-3'>
					<Container sx='flex-1 sm:relative sm:min-h-[calc(100dvh-160px)]'>
						<Text
							size='body-1'
							color='medium'
							weight='bold'
						>
							Datos de facturación
						</Text>
						<InvoiceStampsInfo sx='mt-3' />
						<Text
							size='body-1'
							weight='bold'
							sx='mt-5'
							color='medium'
						>
							Últimas facturas emitidas
						</Text>
						<EmmitedInvoiceList invoices={issuedInvoices} />
					</Container>
					<IssuedInvoicePreview getUserInvoiceIssued={getUserInvoiceIssued} />
				</div>
			</section>
		);
	}

	return (
		<>
			<Modal
				open={blocker.state === 'blocked'}
				sx='w-[94%] !max-w-md'
				onClose={() => blocker.reset && blocker.reset()}
			>
				<Text
					sx='!text-center !block'
					weight='bold'
					size='body-3'
				>
					¿Deseas salir del flujo?
				</Text>
				<Text sx='!text-center !block my-4'>
					La información que ingresaste se perderá, y tendrás que empezar de
					nuevo.
				</Text>
				<PrimaryButton
					label='No, continuar'
					onClick={() => blocker.reset && blocker.reset()}
				/>
				<PrimaryButton
					label='Sí, salir del flujo'
					variant='secondary'
					onClick={() => blocker.proceed && blocker.proceed()}
					sx='mt-2'
				/>
			</Modal>
			<NewInvoiceFlow showBackButton={!!issuedInvoices.length} />
		</>
	);
};

export default Invoicing;
