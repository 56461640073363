import { useEffect, useState } from 'react';
import Modal from '../../../components/molecules/Modal/Modal';
import Text from '../../../components/atoms/Text';
import { CaretLeft, CaretRight, SealPercent } from '@phosphor-icons/react';
import { MenuItem, Select } from '@mui/material';
import { REGIMES_CODE, REGIMES_NAME } from '../../../@types/Regime.type';
import { DATES, getLastYears } from '../../../shared/utils/dates';
import { monthsArray } from '../../../shared/constants/dates';
import PrimaryButton from '../../../components/atoms/Button';
import ModalHeader from '../molecules/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
	PRODUCTS_SLUG,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG,
} from '../../../shared/constants/SlugsProducts';
import { formatCurrency } from '../../../shared/utils/general.util';
import useAddToCart from '../../../hooks/useAddToCart';
import { RegimeFFT } from '../../../@types/Products.type';
import { setShowModalCheckout } from '../../../redux/slices/plans.slice';
import { getSalesPeriodsAdquired } from '../../../services/sales.service';
import { fetchMonthlyDeclarationOverview } from '../../../services/taxes.service';
import { OverviewDeclarationsYear } from '../../../@types/Calendar.type';
import checkBlueCustomIcon from '../../../assets/img/svg/custom-check-blue.svg';
import checkGreenCustomIcon from '../../../assets/img/svg/custom-check-green.svg';
import URL_PARAMS from '../../../shared/constants/urlParams';
import { useSearchParams } from 'react-router-dom';

const regimes = [
	{
		name: REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS,
		value: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
		regimeCode: REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
	},
	{
		name: REGIMES_NAME.RESICO,
		value: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
		regimeCode: REGIMES_CODE.RESICO,
	},
];

const ModalRegularizationsMonthly = ({
	isOpen,
	handleClose,
}: {
	isOpen: boolean;
	handleClose: () => void;
}) => {
	const dispatch = useDispatch();
	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { products } = useSelector((state: RootState) => state.productList);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { status: taxpayer } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const [searchParams] = useSearchParams();
	const regimeFromParams = searchParams.get(URL_PARAMS.REGIME);
	const parseRegimeFromParams = {
		platec: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
		resico: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
	};

	const [regimen, setRegimen] = useState<string>(
		parseRegimeFromParams[regimeFromParams as keyof typeof parseRegimeFromParams] ??
			regimes[0].value,
	);
	const [currentYear, setCurrentYear] = useState<number>(DATES.currentYear);
	const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
	const [periodsAdquired, setPeriodsAdquired] = useState<any>([]);
	const [declarationStatus, setDeclarationStatus] = useState<{
		[year: number]: OverviewDeclarationsYear;
	} | null>(null);

	const isLinked = taxpayer?.status === 'ACTIVE';
	console.log(taxpayer);

	const regularizationProducts = products?.find(
		(product) => product.name === 'Declaración Atrasada',
	)?.products;

	const product = regularizationProducts?.find(
		(product) => product.slug === regimen,
	);

	const yearlyProducts = products?.find(
		(product) => product.name === 'Declaración Anual',
	)?.products;

	const productAnnualRegularization = yearlyProducts?.find(
		(product) => product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
	);

	const subscriptionProductAnnual = yearlyProducts?.find(
		(product) => product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const alreadyPurchasedPeriods = periodsAdquired?.map(
		(period: { month: number; year: number }) =>
			`${period.year}-${period.month}`,
	);

	const isCurrentYear = currentYear === DATES.currentYear;

	const isBeforePlatec = (month: string) => {
		return (
			regimen ===
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES &&
			(currentYear < 2020 || (currentYear === 2020 && Number(month) < 6))
		);
	};

	const isDeclarationPresented = (month: number) => {
		return (
			declarationStatus?.[currentYear]?.declarations?.some(
				(declaration) =>
					declaration.month === month &&
					['PRESENTADA', 'PENDIENTE DE PAGO SAT', 'EN PROCESO'].includes(declaration?.status),
			) &&
			[
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
			].includes(regimen as REGULARIZATION_REGARATION_PRODUCTS_SLUG)
		);
	};

	const monthHaveStatus = (month: number) => {
		if (!declarationStatus?.[currentYear]?.declarations) return false;

		const monthStatus = declarationStatus?.[currentYear]?.declarations?.find(
			(declaration) => declaration.month === month,
		);

		return !!monthStatus || !declarationStatus?.[currentYear]?.declarations;
	};

	const availablePeriods = monthsArray.filter(
		(month) =>
			!alreadyPurchasedPeriods.includes(
				`${currentYear}-${Number(month.value)}`,
			) &&
			!isBeforePlatec(month.value) &&
			!isDeclarationPresented(Number(month.value)) &&
			(monthHaveStatus(Number(month.value)) || isCurrentYear),
	);

	const haveDiscount = selectedMonths.length === availablePeriods.length;

	const price = haveDiscount
		? Math.round((product?.price! / 100) * 0.75)
		: Math.round(product?.price! / 100);

	const priceWithoutDiscount = product?.price! / 100;
	const totalPrice = price * selectedMonths.length;

	const fetchSalesPeriodsAdquired = async () => {
		try {
			const response = await getSalesPeriodsAdquired({
				tokenSession,
				regime:
					RegimeFFT[
						regimes.find((regime) => regime.value === regimen)?.regimeCode!
					],
				period_type: 'monthly',
			});
			if (!response?.monthly) throw new Error('No hay periodos adquiridos');

			setPeriodsAdquired(response?.monthly);
		} catch (error) {}
	};

	const futureMonthsSelected = selectedMonths.filter(
		(month) =>
			Number(month) > DATES.currentMonth && currentYear === DATES.currentYear,
	);

	const handleAddPeriod = (month: string) => {
		if (futureMonthsSelected.length > 0) {
			const periodsWithoutFuture = selectedMonths?.filter(
				(month) =>
					Number(month) <= DATES.currentMonth &&
					currentYear === DATES.currentYear,
			);
			setSelectedMonths(periodsWithoutFuture);
		}

		if (selectedMonths.includes(month)) {
			setSelectedMonths((prev) => prev.filter((m) => m !== month));
		} else {
			setSelectedMonths((prev) => [...prev, month]);
		}
	};

	const handleAddAll = () => {
		setSelectedMonths(availablePeriods.map((month) => month.value));
	};

	const handleAddToCart = async () => {
		const regimeCode = regimes.find(
			(regime) => regime.value === regimen,
		)?.regimeCode;

		const fftRegime =
			RegimeFFT[
				regimeCode ??
					regimes.find((regime) => regime.value === regimen)?.regimeCode!
			];

		const payload: {
			product_version_id?: number;
			additional_information: {
				regimes: string[];
				month?: number;
				year: number;
				product_package?: string;
			};
			custom_product: {
				total_paid: number;
			};
		}[] = selectedMonths.map((month) => ({
			product_version_id: product?.default_product_version_id,
			additional_information: {
				regimes: [fftRegime],
				month: Number(month),
				year: currentYear,
				product_package: currentYear?.toString(),
			},
			custom_product: {
				total_paid: price * 100,
			},
		}));

		if (haveDiscount) {
			payload.push({
				product_version_id:
					currentYear === DATES.currentYear
						? subscriptionProductAnnual?.default_product_version_id
						: productAnnualRegularization?.default_product_version_id,
				additional_information: {
					regimes: [],
					year: currentYear,
					product_package: currentYear?.toString(),
				},
				custom_product: {
					total_paid: 0,
				},
			});
		}

		const response = await handleAddCustomProduct({
			payload,
			navigateToCart: false,
			resetOrders: true,
		});
		if (!response) return;
		handleClose();
		dispatch(setShowModalCheckout(true));
	};

	const getDeclarationsStatus = async () => {
		if (!isLinked) return;
		try {
			const years = getLastYears(5, DATES.currentYear);

			for (const year of years) {
				const response = await fetchMonthlyDeclarationOverview(
					userProfile?.id!,
					year,
					regimen === REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS
						? 'resico'
						: undefined,
				);

				setDeclarationStatus((prevState) => ({
					...prevState,
					[year]: response,
				}));
			}
		} catch (error) {}
	};

	const isDisabledButtonPastYear = () => {
		const isDisabledByYear =
			currentYear ===
			DATES.currentYear -
				(regimen ===
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES
					? 4
					: 2);
		const haveDeclarationStatus = declarationStatus?.[currentYear - 1];
		if (
			!haveDeclarationStatus ||
			regimen === REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS
		)
			return isDisabledByYear;

		const yearHaveDeclarations =
			declarationStatus?.[currentYear - 1]?.declarations?.length > 0;

		return !yearHaveDeclarations || isDisabledByYear;
	};

	const handleChangeRegime = (e: any) => {
		setRegimen(e.target.value);
		setSelectedMonths([]);
		setCurrentYear(DATES.currentYear);
	};

	useEffect(() => {
		fetchSalesPeriodsAdquired();
		setSelectedMonths([]);
		getDeclarationsStatus();
	}, [regimen, isLinked]);

	return (
		<Modal
			open={isOpen}
			onClose={handleClose}
			showCloseButton={false}
			sx='!p-0 rounded-lg overflow-hidden w-[95%] max-w-md max-h-[94dvh] overflow-y-auto'
		>
			<ModalHeader
				title='Regularizar meses atrasados'
				description='Selecciona el régimen y los periodos que deseas regularizar.'
				handleClose={handleClose}
			/>

			<div className='p-4 flex flex-col gap-4 sm:gap-8'>
				<div>
					<Text
						color='dark'
						size='caption'
					>
						Selecciona el régimen
					</Text>
					<Select
						id='regimen-select'
						value={regimen}
						onChange={handleChangeRegime}
						size='small'
						className='w-full mt-1 !text-sm'
					>
						{regimes.map((regime, index) => (
							<MenuItem
								key={`regime-${index}`}
								value={regime.value}
							>
								{regime.name}
							</MenuItem>
						))}
					</Select>
				</div>

				<div className='flex flex-col gap-1 -mt-1'>
					<div
						className={`relative text-h-primary-10 flex justify-between items-center`}
					>
						<button
							disabled={isDisabledButtonPastYear()}
							onClick={() => {
								setCurrentYear((prev) => prev - 1);
								setSelectedMonths([]);
							}}
							className='p-2 disabled:opacity-30 border border-h-neutral-80 rounded-lg hover:bg-h-neutral-90/50 transition'
						>
							<CaretLeft
								size={16}
								weight='bold'
							/>
						</button>
						<Text
							size='body-2'
							weight='medium'
							sx='w-12 justify-center'
						>
							{currentYear}
						</Text>
						<button
							onClick={() => {
								setCurrentYear((prev) => prev + 1);
								setSelectedMonths([]);
							}}
							className='p-2 disabled:opacity-50 border border-h-neutral-80 rounded-lg hover:bg-h-neutral-90/50 transition'
							disabled={currentYear === DATES.currentYear}
						>
							<CaretRight
								size={16}
								weight='bold'
							/>
						</button>
					</div>

					<div className='grid grid-cols-4 gap-1.5 mt-2 mx-auto w-full'>
						{monthsArray.map((month, index) => {
							const isSelected = selectedMonths.includes(month.value);

							const isFutureMonth =
								Number(month.value) > DATES.currentMonth && isCurrentYear;

							const isPeriodAdquired = periodsAdquired?.some(
								(period: { month: number; year: number }) =>
									period.month === Number(month.value) &&
									period.year === currentYear,
							);

							const isDisabled =
								isFutureMonth ||
								isPeriodAdquired ||
								isDeclarationPresented(Number(month.value)) ||
								isBeforePlatec(month.value) ||
								(!monthHaveStatus(Number(month.value)) &&
									currentYear !== DATES.currentYear);

							const showPendingStatus =
								isLinked &&
								!isFutureMonth &&
								monthHaveStatus(Number(month.value)) &&
								!isBeforePlatec(month.value);

							return (
								<button
									key={`month-${index}`}
									className={`
										!text-black relative h-10 py-1 px-1 rounded-lg w-full flex justify-center flex-col items-center border transition 
										disabled:cursor-not-allowed disabled:opacity-50 text-sm 
										${isSelected ? 'bg-h-primary-90/80 border-h-primary-80' : 'border-h-neutral-90 hover:bg-h-neutral-90/50'}
										${isPeriodAdquired ? 'bg-h-neutral-95 hover:bg-h-neutral-95 !opacity-100' : ''} 
										${isDeclarationPresented(Number(month.value)) ? 'bg-h-neutral-95 hover:bg-h-neutral-95 !opacity-100' : ''} 
										${currentYear === DATES.currentYear && haveDiscount && isSelected ? '!bg-h-primary-90/80 !border-h-primary-80' : ''}
										`}
									onClick={() => handleAddPeriod(month.value)}
									disabled={isDisabled}
								>
									<div className='flex items-center gap-1'>
										{month.label.slice(0, 3)}
									</div>

									{isDeclarationPresented(Number(month.value)) ||
									isPeriodAdquired ? (
										<div className='absolute top-1 right-1'>
											<img
												src={
													isDeclarationPresented(Number(month.value))
														? checkGreenCustomIcon
														: checkBlueCustomIcon
												}
												className='size-2.5'
											/>
										</div>
									) : (
										showPendingStatus && (
											<div className='size-2 bg-h-error-50 rounded-full absolute top-1 right-1'></div>
										)
									)}
								</button>
							);
						})}
					</div>
				</div>

				<div className='flex flex-col gap- text-sm -mt-4'>
					{isLinked && (
						<div className='flex items-center gap-1'>
							<div className='size-3 bg-h-error-50 rounded-full'></div>
							Declaración pendiente
						</div>
					)}

					<div className='flex items-center gap-1'>
						<img
							src={checkGreenCustomIcon}
							className='size-3'
						/>
						Periodo declarado
					</div>

					<div className='flex items-center gap-1'>
						<img
							src={checkBlueCustomIcon}
							className='size-3'
						/>
						Adquirido
					</div>
				</div>

				<div className='flex flex-col gap-1'>
					<Text
						size='caption'
						weight='bold'
						sx='flex justify-between'
					>
						<span className='!font-light'>Precio por mes</span>{' '}
						<span>
							${formatCurrency(Math.round(price), false)}{' '}
							{haveDiscount && (
								<span className='line-through opacity-50'>
									${formatCurrency(priceWithoutDiscount, false)}
								</span>
							)}
						</span>
					</Text>
					<Text
						size='caption'
						weight='bold'
						sx='flex justify-between'
					>
						<span className='!font-light'>Meses seleccionados</span>{' '}
						<span>x{selectedMonths.length}</span>
					</Text>
					{haveDiscount && (
						<Text
							size='caption'
							weight='bold'
							color='green'
							sx='flex justify-between !text-h-success-50'
						>
							<span className='!font-medium flex items-center gap-1'>
								Descuento por mes aplicado <SealPercent weight='duotone' />
							</span>{' '}
							<span>25%</span>
						</Text>
					)}
					{haveDiscount && (
						<Text
							size='caption'
							weight='bold'
							color='green'
							sx='flex justify-between !text-h-success-50'
						>
							<span className='!font-medium flex items-center gap-1'>
								Declaración Anual GRATIS <SealPercent weight='duotone' />
							</span>{' '}
							<span>
								$0 <span className='line-through opacity-50'>$800</span>
							</span>
						</Text>
					)}
					<hr className='my-1.5' />
					<Text
						size='caption'
						sx='flex justify-between'
						weight='bold'
					>
						<span className='!font-light'>Total</span>{' '}
						<span>${formatCurrency(Math.round(totalPrice), false)}</span>
					</Text>
				</div>

				<div className=''>
					<div className='flex justify-center flex-col gap-1 sm:gap-2'>
						<PrimaryButton
							loading={isLoadingAddToCart.products}
							disabled={selectedMonths.length === 0}
							onClick={handleAddToCart}
							label={
								haveDiscount
									? `Regularizar ${selectedMonths.length} meses con 25% de dto`
									: `Regularizar ${selectedMonths.length} meses`
							}
							sx={`!h-10 sm:!h-12 ${haveDiscount ? '' : ''}`}
						/>
						{!haveDiscount && (
							<PrimaryButton
								label={`Regulariza todo el ${currentYear} con 25% de dto`}
								sx='!font-light leading-4 !h-10 sm:!h-12'
								variant='tertiary'
								onClick={handleAddAll}
							/>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalRegularizationsMonthly;
