import type { OrderRefreshMapper } from '../@types/Order.type';
import HERU_API from '../shared/constants/heruApi';
import { get, post, put } from '../shared/utils/FetchSSL.util';
import { orderRefreshMapper } from '../shared/utils/mappers/orderItems.mapper';

const fetchPendingOrders = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_ORDERS_PENDING, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};
const getPendingOrderDetail = async (order: number, tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_ORDERS_PENDING_DETAIL(order), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};

const getDefaultPaymentMethods = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_DEFAULT_PAYMENT, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getOrderRefresh = async (params: string, tokenSession?: string) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SHOPPING_CART_REFRESH(params),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return orderRefreshMapper(response);
	} catch (error) {
		throw error;
	}
};

const changeItemShoppingCart = async (
	cartId: number,
	itemId: number,
	body: object,
	tokenSession?: string,
): Promise<OrderRefreshMapper[]> => {
	try {
		const response = await put(
			HERU_API.HERU_API_SHOPPING_CART_CHANGE_ITEM(cartId, itemId),
			body,
			{ token: true, isNewGateway: true, tokenSession },
		);

		return orderRefreshMapper([response?.data.resource]);
	} catch (error) {
		throw error;
	}
};

const addItemBulkShoppingCart = async (
	cartId: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			HERU_API.HERU_API_SHOPPING_CART_BULK(cartId),
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
				// headers: { with_refresh: 'true' },
			},
		);
		return orderRefreshMapper([response]);
	} catch (error) {
		throw error;
	}
};

export {
	fetchPendingOrders,
	getPendingOrderDetail,
	getDefaultPaymentMethods,
	getOrderRefresh,
	changeItemShoppingCart,
	addItemBulkShoppingCart,
};
