import Text from '../../../components/atoms/Text';
import Heading from '../../../components/atoms/Heading';
import { Dispatch, SetStateAction } from 'react';
import PrimaryButton from '../../../components/atoms/Button';

interface IIntroMessageProps {
	userName: string;
	setStep: Dispatch<SetStateAction<number>>;
}

const IntroMessage = ({ userName, setStep }: IIntroMessageProps) => {
	return (
		<div className='mx-4 flex flex-col md:items-start justify-center mt-[50%] md:mt-[20%] fade-in-right'>
			<Heading
				size='m'
				sx='my-[32px] md:my-8 !block !text-2xl md:!text-[40px] !leading-[30px] md:!leading-[50px]'
			>
				Empecemos con tu{' '}
				<Heading
					size='m'
					weight='bold'
					sx='!text-h-primary-50 !mx-2 !contents !text-2xl md:!text-[40px]'
				>
					análisis
				</Heading>{' '}
				de {' '}
				<Heading
					size='m'
					weight='bold'
					sx='!text-h-primary-50 !mx-2 !contents !text-2xl md:!text-[40px]'
				>
					situación fiscal.
				</Heading>{' '}
			</Heading>
			<Text
				size='body-2'
				sx='md:!text-xl'
			>
				¡Bienvenido a heru,
				<Text
					size='body-2'
					color='primary'
					weight='bold'
					sx='md:!text-xl'
				>
					{userName}!
				</Text>
			</Text>
			<Text
				size='body-2'
				weight='light'
				sx='mt-3 md:!text-xl'
				responsive
			>
				Nuestro objetivo es ayudarte a simplificar tus impuestos y cumplir con el SAT sin que esto te represente un estrés.
			</Text>
			<Text
				size='body-2'
				weight='light'
				sx='mt-3 md:!text-xl'
				responsive
			>
				A continuación, te pediremos unos datos básicos que nos permitirán conocerte mejor y recomendarte el plan más adecuado para llevar tu contabilidad.
			</Text>
			<PrimaryButton
				label='Comenzar análisis'
				onClick={() => setStep(2)}
				variant='primary'
				sx='mt-6 !w-fit'
			/>
		</div>
	);
};

export default IntroMessage;
