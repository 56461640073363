const LOCAL_STORAGE_KEY = {
	SHOW_SITE_GPT: 'showSiteGpt',
	TOKEN: 'token',
	REFRESH: 'refresh',
	USER_NAME: 'userName',
	USER_ID: 'userId',
	SESSION_EXPIRED: 'session_expired',
	SOURCE: 'utm_source',
	MEDIUM: 'utm_medium',
	CAMPAIGN: 'utm_campaign',
	LAST_EXTRACTION_DATES: 'last_extraction_dates',
	OVERWRITE_UTM: 'overwrite_utm',
	PRICING_ELASTICY_SENT: 'pricing_elasticy_sent',
	ORGANIZATION_GOBACK: 'organization_go_back',
	LAST_ORDER_PURCHASE: 'lastOrderPurchase',
	IS_FROM_IOS: 'isFromIOS',
	PLATFORM_OS: 'platformOS',
};

export default LOCAL_STORAGE_KEY;
