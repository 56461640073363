import { useState } from 'react';
import Text from '../../atoms/Text';
import { ORGANIZATIONS } from '../../../shared/constants/organizations';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import ModalOrganizations from '../../../pages/checkout/components/organisms/ModalOrganizations';

const OrganizationLink = () => {
	const [showOrgsModal, setShowOrgsModal] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const activeOrgs = userProfile?.organizations?.filter((org) =>
		[
			ORGANIZATIONS.RAPPI,
			ORGANIZATIONS.DIDI,
			ORGANIZATIONS.AIRBNB,
			ORGANIZATIONS.UBER,
			ORGANIZATIONS.CORNERSHOP,
			ORGANIZATIONS.MERCADO_ENVIOS,
			ORGANIZATIONS.UBER_EATS,
		]?.some((keyword) => org?.name?.includes(keyword)),
	);
	return (
		<div>
			<>
				<ModalOrganizations
					showModal={showOrgsModal}
					setShowModal={setShowOrgsModal}
				/>
				{/* Desktop */}
				<div className='hidden lg:flex lg:flex-col mb-2'>
					<div className='flex flex-col'>
						{activeOrgs?.map((org) => (
							<div
								className='flex items-center mb-2'
								key={org.id}
							>
								<img
									src={org?.extra_fields?.icon_url_png}
									alt={org.name}
									width={30}
									height={30}
								/>
								<Text
									size='caption'
									weight='regular'
									color='medium'
									sx='ml-2.5'
								>{`Tu cuenta de ${org.name} ya está conectada`}</Text>
							</div>
						))}
					</div>

					<div className='flex flex-row items-center'>
						<Text
							size='caption'
							weight='regular'
							color='medium'
							sx='!block'
						>
							Descubre si cuentas con descuentos por alianza y
							<button onClick={() => setShowOrgsModal(true)}>
								<Text
									size='caption'
									weight='regular'
									color='primary'
									sx='underline ml-1'
								>
									canjéalos aquí
								</Text>
							</button>
						</Text>
					</div>
					<Text
						size='mini-1'
						weight='light'
						color='medium'
						sx='mt-3'
					>
						*Los descuentos no son acumulables.
					</Text>
				</div>

				{/* Mobile */}
				<div className='flex flex-col lg:hidden mt-1'>
					<div className='flex flex-col'>
						{activeOrgs?.map((org) => (
							<div
								className='flex items-center mb-2'
								key={org.id}
							>
								<img
									src={org?.extra_fields?.icon_url_png}
									alt={org.name}
									width={30}
									height={30}
								/>
								<Text
									size='caption'
									weight='regular'
									color='medium'
									sx='ml-2.5'
								>{`Tu cuenta de ${org.name} ya está conectada`}</Text>
							</div>
						))}
					</div>

					<div className='flex flex-row mt-2'>
						<Text
							size='caption'
							weight='regular'
							color='medium'
							sx='!block'
						>
							Descubre si cuentas con descuentos por alianza
							<button onClick={() => setShowOrgsModal(true)}>
								<Text
									size='caption'
									weight='regular'
									color='primary'
									sx='underline ml-1'
								>
									y canjéalos aquí.
								</Text>
							</button>
						</Text>
					</div>
					<Text
						size='mini-1'
						weight='light'
						color='medium'
						sx='mt-4'
					>
						*Los descuentos no son acumulables.
					</Text>
				</div>
			</>
		</div>
	);
};

export default OrganizationLink;
