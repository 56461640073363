import heruIcon from '../../../assets/img/svg/heru-h-blue.svg';
const TypingDots = () => {
	return (
		<div className='flex items-start mb-4 justify-start gap-2'>
			<div
				className={`flex items-center justify-center size-10 rounded-full bg-h-neutral-90`}
			>
				<img
					src={heruIcon}
					alt='user'
					width={40}
					height={40}
				/>
			</div>
			<div className='max-w-[70%] rounded-lg p-3 bg-h-primary-40 rounded-r-full rounded-bl-full'>
				<div className='flex items-center gap-2'>
					<div className='size-2 bg-white rounded-full animate-pulse-scale' />
					<div className='size-2 bg-white rounded-full animate-pulse-scale animation-delay-200' />
					<div className='size-2 bg-white rounded-full animate-pulse-scale animation-delay-400' />
				</div>
			</div>
		</div>
	);
};

export default TypingDots;
