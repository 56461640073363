import { useNavigate } from 'react-router-dom';
import Button from '../../components/atoms/Button';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import ROUTES from '../../shared/constants/routes';

const OrganizationSuccessLink = () => {
	const navigate = useNavigate();

	const goBack = () => {
		const path = localStorage.getItem(LOCAL_STORAGE_KEY.ORGANIZATION_GOBACK);

		if (path) {
			navigate(`${path}/?step=5&checkout=open`);
		} else {
			navigate(ROUTES.ROOT);
		}
	};
	return (
		<div className='flex items-center justify-center min-h-screen bg-gradient-to-r from-green-400 to-blue-500'>
			<div className='text-center bg-white p-8 rounded-lg shadow-xl max-w-md w-full'>
				<svg
					className='mx-auto h-12 w-12 text-green-600'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
					/>
				</svg>
				<h2 className='mt-6 text-3xl font-extrabold text-gray-900'>
					¡Cuenta de Uber vinculada exitosamente!
				</h2>
				<p className='mt-2 text-sm text-gray-600'>
					Obténhasta 40% de descuento dependiendo de tu nivel.
				</p>

				<Button
					label='Regresar'
					onClick={goBack}
					sx='mt-8'
				/>
			</div>
		</div>
	);
};

export default OrganizationSuccessLink;
