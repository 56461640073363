import React, { useEffect, useState } from 'react';
import { ArrowRight } from '@phosphor-icons/react';
import { PendingProduct } from '../../../@types/Ecommerce';
import { useDispatch, useSelector } from 'react-redux';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import { RootState } from '../../../redux/store';
import {
	getOrderRefresh,
	getPendingOrderDetail,
} from '../../../services/orders.service';
import { CircularProgress } from '@mui/material';
import ROUTES from '../../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import HomeCard from '../../../components/molecules/HomeCard';
import Button from '../../../components/atoms/Button';
import ModalReference from './ModalReference';
import PendingCard from './PendingCard';
import { setPaymentInstructions } from '../../../redux/slices/cart.slice';
import { PAYMENT_METHOD } from '../../../@types/Checkout.type';
import { setShowModalCheckout } from '../../../redux/slices/plans.slice';
import {
	OrderRefreshMapper,
	ProductVersionItem,
	TUserOrder,
} from '../../../@types/Order.type';
import { getProductProdByProductVersion } from '../../../shared/utils/ecommerce.util';

interface LastPendingOrderProps {
	isSingle?: boolean;
}

const LastPendingOrder: React.FC<LastPendingOrderProps> = ({
	isSingle = false,
}) => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { products } = useSelector((state: RootState) => state.productList);

	const [lastPendingOrder, setLastPendingOrder] = useState<PendingProduct>();
	const [allPendingOrders, setAllPendingOrders] = useState<
		OrderRefreshMapper[]
	>([]);
	const [isLoadingPendingOrders, setIsLoadingPendingOrders] = useState(false);
	const [lastOrder, setLastOrder] = useState<TUserOrder>();
	const dispatch = useDispatch();

	const getPendingOrder = async () => {
		try {
			setIsLoadingPendingOrders(true);
			const params =
				'?status=pending&is_renewal=false&shopping_cart_treatment=NORMAL';
			const orders = await getOrderRefresh(params, tokenSession);

			if (orders) {
				setAllPendingOrders(orders);
				const lastOrder = orders.sort((a, b) => b.id - a.id)?.[0];

				const items: ProductVersionItem[] = lastOrder?.shoppingItem.map(
					(item) => {
						const infoProduct = getProductProdByProductVersion(
							products,
							item.productVersionId,
						)!;
						return {
							...item,
							productVersion: {
								product: {
									id: infoProduct.id,
									name: infoProduct.name,
									defaultProductVersionId:
										infoProduct.default_product_version_id,
									description: infoProduct.description,
									isPurchasable: infoProduct.is_purchasable,
									benefits: infoProduct.benefits,
									slug: infoProduct.slug,
									category: infoProduct.category,
									regime: infoProduct.regime,
								},
								price:
									infoProduct.product_versions.find(
										(version) => version.id === item.productVersionId,
									)?.price ?? 0,
								periodicity:
									infoProduct.product_versions.find(
										(version) => version.id === item.productVersionId,
									)?.periodicity ?? infoProduct.periodicity,
							},
						};
					},
				);

				const newOrder: TUserOrder = {
					...lastOrder,
					shoppingItem: items,
				};
				setLastOrder(newOrder);
				if (newOrder) {
					const order = await getPendingOrderDetail(newOrder?.id, tokenSession);
					setLastPendingOrder(order);
				}
			}
		} catch (error: any) {
		} finally {
			setIsLoadingPendingOrders(false);
		}
	};

	useEffect(() => {
		if (!userProfile || !products.length) return;
		getPendingOrder();
	}, [userProfile, products]);

	const logReferencePaymentEvent = (
		action: 'copy_payment_number' | 'finalize_purchase',
	) => {
		if (userProfile) {
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_PENDING_PURCHASE_INTERACTION,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
				action,
			});
		}
	};

	const onOpenInstructions = () => {
		logReferencePaymentEvent('finalize_purchase');
		// setShowModalPaymentSteps(true);
		if (!lastPendingOrder) return;
		dispatch(
			setPaymentInstructions({
				paymentMethod:
					lastPendingOrder?.payment_method_name === PAYMENT_METHOD.SPEI
						? PAYMENT_METHOD.SPEI
						: PAYMENT_METHOD.CASH,
				show: true,
				total: lastPendingOrder.total,
				reference: lastPendingOrder.payment_reference_code ?? '',
				referenceLink: lastPendingOrder.payment_method_reference_link ?? '',
				expiresAt: parseInt(lastPendingOrder.expires_at) ?? 0,
			}),
		);
		dispatch(setShowModalCheckout(true));
	};

	const [showModalPaymentSteps, setShowModalPaymentSteps] = useState(false);

	const navigate = useNavigate();

	return (
		<>
			{allPendingOrders.length > 0 && (
				<>
					<ModalReference
						paymentMethod={lastPendingOrder?.payment_method_name ?? ''}
						showModal={showModalPaymentSteps}
						setShowModal={setShowModalPaymentSteps}
						reference={lastPendingOrder?.payment_reference_code ?? ''}
						total={lastPendingOrder?.total ?? 0}
					/>
					<HomeCard
						title={`${
							allPendingOrders.length > 1
								? 'Pagos pendientes'
								: 'Pago pendiente'
						}`}
						showButton={false}
						headerButton={
							<>
								{allPendingOrders.length > 1 && (
									<Button
										onClick={() => {
											navigate(ROUTES.PENDING_REFERENCES);
										}}
										type='button'
										label={'Ver todas mis referencias'}
										variant='text'
										icon={<ArrowRight size={20} />}
										labelColor='primary'
									/>
								)}
							</>
						}
					>
						{isLoadingPendingOrders || !products?.length || !userProfile ? (
							<div className='flex items-center justify-center'>
								<CircularProgress size={25} />
							</div>
						) : (
							<>
								<PendingCard
									accountNumber={lastPendingOrder?.payment_reference_code ?? ''}
									paymentMethodName={
										lastPendingOrder?.payment_method_name ?? ''
									}
									dateLimit={lastPendingOrder?.expires_at ?? ''}
									total={lastPendingOrder?.total ?? 0}
									items={lastOrder?.shoppingItem ?? []}
									onPress={onOpenInstructions}
									isSingle={isSingle}
								/>
							</>
						)}
					</HomeCard>
				</>
			)}
		</>
	);
};

export default React.memo(LastPendingOrder);
