import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import SATLinkingForm from '../../molecules/SATLinkingForm';
import Tabs from '../../molecules/Tabs';
import Text from '../../atoms/Text';
import FiscalProfileCard from '../FiscalProfileCard';
import DeclarationStatus from '../DeclarationStatus';
import InvoicesSumary from '../InvoicesSumary';
import { linkedAccount, startLoader } from '../../../redux/slices/sat.slice';
import { fetchSATLinkedById } from '../../../services/satLinking.service';
import { RootState } from '../../../redux/store';

import bannerSubscriptions from '../../../assets/img/banners/Suscribete_Small_Desktop.png';
import banner3 from '../../../assets/img/banners/Conoce_planes_Small_Desktop.png';
import onboardingBanner from '../../../assets/img/banners/finish_onboarding.png';
import { ServiceStatusElement } from '../../../@types/ServiceStatus.type';
import ServicesStatuHome from '../ServicesStatuHome';
import BlockedMock from '../ServicesStatuHome/BlockedMock';

import LastPendingOrder from '../../../pages/pendingReferences/components/LastPendingOrder';
import { VARIANT_STATUS } from '../../../shared/constants/serviceStatus';

type TFlow = 'taxes' | 'home';

type Props = {
	flow?: TFlow;
	sxLinkingSAT?: string;
	serviceStatus: ServiceStatusElement[];
	goToPlans?: () => void;
	sx?: string;
};

const items = [
	{
		label: 'Vincular con e.firma',
		slug: 'efirma',
	},
	{
		label: 'Vincular con RFC y contraseña',
		slug: 'password',
	},
];

export const settingsCarrousel = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	nextArrow: <></>,
	prevArrow: <></>,
	appendDots: (dots: any) => (
		<div style={{ position: 'absolute', bottom: 3 }}>
			<ul style={{ margin: '0px' }}> {dots} </ul>
		</div>
	),
};

const SATLinking: React.FC<Props> = ({
	flow = 'home',
	sxLinkingSAT,
	serviceStatus,
	goToPlans,
	sx,
}) => {
	const [activeTab, setActiveTab] = useState(items[0].slug);
	const [hideBlinkEffect, setHideBlinkEffect] = useState(false);
	const [showLinkInfo, setShowLinkInfo] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { hasPendingAnalysis } = useSelector(
		(state: RootState) => state.profile,
	);

	const activeServices = useMemo(() => {
		return serviceStatus?.filter((it) => it?.status !== 'closed');
	}, [serviceStatus]);

	useEffect(() => {
		if (location.hash === '#fiscalform' && hideBlinkEffect) {
			setHideBlinkEffect(false);
		}
	}, [location, hideBlinkEffect]);

	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const successSATLinkHandler = async () => {
		fetchSatLinkedAcount();
	};

	const serviceStatusSubscription = serviceStatus.find(
		(service) =>
			service.current_service_has_step.status === VARIANT_STATUS.BLOCKED &&
			service.current_service_has_step.stage.slug ===
				'stage_subscription_blocked_for_payment_declaration',
	);

	return (
		<section className='my-3 md:my-5'>
			<div
				id='fiscalform'
				className='grid grid-cols-12 gap-4 lg:gap-6 place-content-center '
			>
				<div className={`col-span-12 md:col-span-6 xl:col-span-6 ${sx}`}>
					<div className='block'>
						{hasPendingAnalysis ? (
							<img
								onClick={goToPlans}
								src={onboardingBanner}
								alt='Completa tu análisis fiscal'
								className='relative h-fit-content w-full object-cover rounded-lg cursor-pointer'
							/>
						) : (
							<Slider {...settingsCarrousel}>
								<img
									onClick={goToPlans}
									src={banner3}
									alt='Banner de compra suscripcion'
									className='relative h-fit-content w-full object-cover rounded-lg'
								/>

								<img
									onClick={goToPlans}
									src={bannerSubscriptions}
									alt='Banner de compra suscripcion'
									className='relative h-fit-content w-full object-cover rounded-lg'
								/>
							</Slider>
						)}
					</div>

					<div
						onClick={() => setShowLinkInfo(false)}
						className={`mt-6 rounded-lg border-4  border-h-primary shadow-md ${
							hideBlinkEffect ? 'animate-none' : 'animate-blink'
						} ${sxLinkingSAT}`}
					>
						{showLinkInfo ? (
							<Text
								size='s'
								sx='mb-8 lg:text-xl bg-h-primary text-white p-4'
								weight='medium'
							>
								Vincula aquí tu RFC para calcular tus deducibles e iniciar con
								tu declaración anual
							</Text>
						) : (
							<Text
								size='m'
								sx='justify-center mt-6 mb-8 mx-4 lg:text-xl'
								weight='medium'
							>
								Vincula tu RFC y simplifica tus impuestos con Heru
							</Text>
						)}

						<Tabs
							items={items}
							onClickTab={(val) => setActiveTab(val)}
						/>
						<SATLinkingForm
							onCallbackSuccess={successSATLinkHandler}
							onCallbackError={() => {
								setHideBlinkEffect(true);
								navigate(location.pathname, {});
							}}
							showEFirmaForm={activeTab !== 'password'}
							sx='p-6'
							buttonLabel={
								activeTab === 'password'
									? 'Vincular mi RFC'
									: 'Vincular mi e.firma'
							}
						/>
					</div>
				</div>
				{flow === 'home' && (
					<div className='col-span-12 md:col-span-6 xl:col-span-6  '>
						<div className='flex flex-col gap-y-4'>
							<LastPendingOrder />

							{serviceStatusSubscription ? (
								<BlockedMock failedStripeSub={serviceStatusSubscription} />
							) : (
								<>{activeServices.length > 0 && <ServicesStatuHome />}</>
							)}

							<FiscalProfileCard
								isDisabled
								sx='pr-0'
							/>
							<DeclarationStatus />
							<InvoicesSumary
								title='Facturas del próximo mes'
								showButton
							/>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default SATLinking;
