const SESSION_STORAGE_KEY = {
	PRODUCTS_ADDED: 'products_added',
	CUPON_CODE_ADDED: 'cupon_code_added',
	GO_TO: 'go_to',
	YEAR_ADDED: 'year_added',
	SHOW_NO_INFO_MODAL: 'show_no_info_modal',
	ACTIVE_TAB: 'active_tab',
};

export default SESSION_STORAGE_KEY;
