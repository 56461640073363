import { Dispatch, SetStateAction } from 'react';
import PrimaryButton from '../../../components/atoms/Button';
import Text from '../../../components/atoms/Text';
import Modal from '../../../components/molecules/Modal/Modal';

interface IModalSkipAnalysisProps {
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	goToHome: () => void;
	title: string;
	description: string;
}

const ModalSkipAnalysis = ({
	showModal,
	setShowModal,
	goToHome,
	title,
	description,
}: IModalSkipAnalysisProps) => {
	return (
		<Modal
			open={showModal}
			onClose={() => setShowModal(false)}
		>
			<div className='!w-[300px] lg:!w-[calc(100vw-60px)] max-w-[500px] relative'>
				<Text
					size='subtitle'
					sx='mb-1'
					color='medium'
					weight='bold'
				>
					{title}
				</Text>
				<Text
					size='body-3'
					sx=''
					color='medium'
					weight='regular'
				>
					{description}
				</Text>

				<div className='flex justify-end items-center mt-4 gap-2'>
					<PrimaryButton
						label='Salir'
						sx='!w-full'
						variant='danger'
						onClick={goToHome}
					/>
					<PrimaryButton
						variant='outline'
						label='Cancelar'
						onClick={() => setShowModal(false)}
						sx='!w-full'
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalSkipAnalysis;
