import { CheckCircle, Warning } from '@phosphor-icons/react';
import Confetti from 'react-confetti';
import Text from '../../components/atoms/Text';
import Button from '../../components/atoms/Button';
import useScreenSize from '../../hooks/useScreenSize';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../shared/constants/routes';
import { useEffect, useState, useCallback } from 'react';
import { setOrders } from '../../redux/slices/cart.slice';
import { useDispatch, useSelector } from 'react-redux';
import { OrderReceipt } from '../../@types/OrderReceipts.type';
import { getReceipts } from '../../services/plans.service';
import { RootState } from '../../redux/store';
import { showAlert } from '../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import { CircularProgress } from '@mui/material';
import { fetchSATLinkedById } from '../../services/satLinking.service';
import { linkedAccount } from '../../redux/slices/sat.slice';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import { PAYMENT_METHOD } from '../../@types/Checkout.type';
import { formatPrice, useStorage } from '../../shared/utils/general.util';
import { sendUserInteractionEvent } from '../../services/user-interaction.service';
import STORAGE_KEYS from '../../shared/constants/storage';
import { getMonthName } from '../../shared/utils/dates.util';
import {
	PRODUCTS_SLUG,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG,
} from '../../shared/constants/SlugsProducts';

const OnboardingSuccessfulPurchase = () => {
	const { height, width } = useScreenSize();
	const navigation = useNavigate();
	const dispatch = useDispatch();
	const [receiptUrl, setReceiptUrl] = useState<string>('');
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [isPolling, setIsPolling] = useState(true);
	const { status: taxpayer } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const { dataLayer } = window as any;
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? 'web';

	const storage = useStorage();
	const purchase = JSON.parse(
		storage.getItem(LOCAL_STORAGE_KEY.LAST_ORDER_PURCHASE) || '{}',
	);

	const isPaypal =
		new URLSearchParams(location.search).get('paymentMethod') ===
		PAYMENT_METHOD.PAYPAL;

	const fetchSatLinkedAcount = async () => {
		try {
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error) {}
	};

	const getPurchaseReceipts = useCallback(async () => {
		try {
			const receipts = await getReceipts(userProfile?.id!, tokenSession);

			const foundReceipt = receipts?.find(
				(receipt: OrderReceipt) => receipt?.shopping_cart_id === purchase?.id,
			);

			if (foundReceipt?.receipt_url) {
				setReceiptUrl(foundReceipt?.receipt_url);
				setIsPolling(false);
			}
		} catch (error) {
			setIsPolling(false);
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Aviso',
				description: 'Error, intente más tarde',
			};
			dispatch(showAlert(alert));
		}
	}, [userProfile?.id, tokenSession]);

	const trackingEvent = async () => {
		const dataLayerProperties = {
			event: ANALYTICS_EVENTS.PURCHASE,
			phone: userProfile?.cellphone,
			rfc: taxPayer?.code ?? '',
			ecommerce: {
				transaction_id: purchase?.id,
				value: formatPrice(parseInt(purchase?.total!)),
				tax: (parseInt(purchase?.taxes!) ?? 0) / 100,
				currency: 'MXN',
				payment_method: isPaypal ? PAYMENT_METHOD.PAYPAL : PAYMENT_METHOD.CARD,
				coupon: purchase?.coupon?.code ?? '',
				Items: purchase?.shoppingItem?.map((item: any) => ({
					item_name: item?.productVersion?.product?.name,
				})),
			},
		};
		dataLayer.push({ ecommerce: null });
		dataLayer.push(dataLayerProperties);

		try {
			const regularizations = purchase?.shoppingItem?.filter((item: any) =>
				[
					REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
					REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
					PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
					PRODUCTS_SLUG.ANNUAL_DECLARATION,
				].includes(item?.productVersion?.product?.slug),
			) ?? [];

			const eventProperties = {
				amount: parseInt(purchase?.total!) / 100,
				chargedId: purchase?.id!,
				paymentMethod: isPaypal ? PAYMENT_METHOD.PAYPAL : PAYMENT_METHOD.CARD,
				succed: true,
				coupon: purchase?.coupon?.code ?? '',
				Items: purchase?.shoppingItem?.map((item: any) => ({
					product_name: item?.productVersion?.product?.name,
					product_version_id:
						item?.productVersion?.product?.defaultProductVersionId,
					month: item?.additionalInformation?.month,
					month_name: getMonthName(item?.additionalInformation?.month, true),
					year: item?.additionalInformation?.year,
				})),
				periodsCovered: regularizations
					?.map(
						(item: any) =>
							`${getMonthName(item?.additionalInformation?.month, true)} ${item?.additionalInformation?.year}`,
					)
					?.join(', '),
				source: platformOS,
			};
			await sendUserInteractionEvent(
				userProfile?.id!,
				ANALYTICS_EVENTS.CHARGED,
				platformOS,
				eventProperties,
			);
		} catch (error) {}
	};

	useEffect(() => {
		if (!userProfile || !isPolling) return;

		const pollingInterval = setInterval(() => {
			getPurchaseReceipts();
		}, 2000);

		return () => clearInterval(pollingInterval);
	}, [userProfile, isPolling, getPurchaseReceipts]);

	useEffect(() => {
		if (!userProfile) return;
		trackingEvent();
	}, [userProfile]);

	useEffect(() => {
		dispatch(setOrders([]));
		fetchSatLinkedAcount();
	}, []);

	const goToHome = () => navigation(ROUTES.ROOT);
	const isLinked = taxpayer?.status === 'ACTIVE';
	const newReceiptURL = receiptUrl ? `${receiptUrl}?t=${Date.now()}` : null;

	return (
		<div className='flex flex-col justify-center items-center p-4'>
			<Confetti
				width={width}
				height={height}
				recycle={false}
			/>

			<div className='w-full h-full md:w-2/3 max-w-[600px] flex flex-col justify-center items-center mx-auto mt-4 md:mt-6 gap-1'>
				<CheckCircle
					size={80}
					weight='fill'
					color='var(--h-success-50)'
					className='mx-auto'
				/>
				<Text
					size='body-2'
					weight='bold'
					sx='text-center mx-auto mt-3'
				>
					¡Tu pago ha sido procesado exitosamente!
				</Text>

				<Text
					size='caption'
					sx='!block text-center mb-3'
				>
					A continuación encontrarás tu recibo de pago con más información sobre
					tu servicio activado.
				</Text>

				<Button
					label={isLinked ? 'Continuar' : 'Vincular RFC ahora'}
					variant='primary'
					sx='w-full mt-4'
					onClick={goToHome}
				/>

				{!isLinked && (
					<Text
						size='caption'
						sx='flex items-center text-center mb-3  bg-h-neutral-90 p-2 rounded-lg'
					>
						<Warning
							className='min-w-4'
							size={16}
						/>
						Recuerda que el paso más importante que debes tomar es vincular tu
						RFC. Haz click en vincular RFC ahora para empezar
					</Text>
				)}

				{newReceiptURL ? (
					<iframe
						src={newReceiptURL}
						className='w-full h-[calc(100vh-400px)] border-0 rounded-lg'
						title='Recibo de compra'
					/>
				) : (
					<div>
						<div className='flex items-center justify-center mt-10'>
							<CircularProgress size={25} />
						</div>
						<Text
							size='caption'
							color='primary'
							sx='!block text-center mt-1'
						>
							Estamos procesando tu recibo de compra, este proceso tarda algunos segundos.
						</Text>
					</div>
				)}
			</div>
		</div>
	);
};

export default OnboardingSuccessfulPurchase;
