import { TrashSimple, CircleNotch } from '@phosphor-icons/react';

const ButtonTrash = ({
	onClick,
	isLoading,
	disabled,
}: {
	onClick: () => void;
	isLoading?: boolean;
	disabled?: boolean;
}) => {
	return (
		<button
			disabled={disabled || isLoading}
			className='size-9 bg-h-neutral-94 flex items-center justify-center rounded-lg'
			onClick={onClick}
		>
			{isLoading ? <CircleNotch className='animate-spin' /> : <TrashSimple />}
		</button>
	);
};

export default ButtonTrash;
