import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import {
	ArrowRight,
	CalendarCheck,
	CheckFat,
	ClockCountdown,
	Lock,
	SignOut,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, useMediaQuery } from '@mui/material';

//? Components
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import { ALERT_TYPE } from '../../molecules/Alert';
import AlertBanner from '../../molecules/AlertBanner';
import HomeCard from '../../molecules/HomeCard';
import ModalESignature from '../../../pages/serviceStatus/components/organisms/ModalESignature';
import ModalDigitalSeals from '../../../pages/serviceStatus/components/organisms/ModalDigitalSeals';

//? Utils
import ROUTES from '../../../shared/constants/routes';
import {
	ACTION_CURRENT_SLUG,
	GLOBAL_STATUS,
	PURCHASED_DATE,
	VARIANT_STATUS,
	renderAnnualValidateStatus,
} from '../../../shared/constants/serviceStatus';
import LITERALS from '../../../shared/constants/literals';
import { HUBSPOT_LINKS } from '../../../shared/constants/hubspot';
import { CONFIG_WHATSAPP_SUPPORT } from '../../../shared/constants/whatsappNumbers';

//? Services
import {
	getAnnualDeclarationDocument,
	getDeclarationDocument,
} from '../../../services/serviceStatus.service';

//? Redux
import { RootState } from '../../../redux/store';
import { showAlert } from '../../../redux/slices/alert.slice';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { PlatformOS } from '../../../redux/slices/profile.slice';
import STORAGE_KEYS from '../../../shared/constants/storage';
import { useStorage } from '../../../shared/utils/general.util';

interface ActionButtonProps {
	label: string;
	variant:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'outline'
		| 'text'
		| 'danger'
		| 'danger-secondary';
	onClick?: () => void;
	isLoading?: boolean;
}

const alertSuccess = {
	type: ALERT_TYPE.SUCCESS,
	title: 'Aviso',
	description: `Documento descargado correctamente.`,
};

const alertError = {
	type: ALERT_TYPE.ERROR,
	title: 'Aviso',
	description: `Error al descargar el documento, intenta de nuevo.`,
};

const hrefLink = '#fiscalform';

const ServicesStatuHome = () => {
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS);
	const { serviceStatus } = useSelector((state: RootState) => state.profile);

	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const isMobile = useMediaQuery('(max-width: 768px)');

	const [showESignature, setShowESignature] = useState(false);
	const [showDigitalSeals, setShowDigitalSeals] = useState(false);
	const [isLoadingDownloadDocument, setIsLoadingDownloadDocument] =
		useState(false);
	const [hasDoc, setHasDoc] = useState(false);
	const [isFetchingDocument, setIsFetchingDocument] = useState(false);

	const activeServices = useMemo(() => {
		return (
			serviceStatus?.filter((it) => it?.status !== GLOBAL_STATUS.CLOSED) || []
		);
	}, [serviceStatus]);

	const lastActiveService = useMemo(() => {
		return (
			serviceStatus?.filter((it) => it?.status !== GLOBAL_STATUS.CLOSED)?.[0] ||
			null
		);
	}, [serviceStatus]);

	const ActionButton: React.FC<ActionButtonProps> = ({
		label,
		variant,
		onClick,
		isLoading,
	}) => {
		return (
			<div className='w-full'>
				<Button
					loading={isLoading}
					onClick={onClick}
					label={label}
					variant={variant}
				/>
			</div>
		);
	};

	const getColor = (isCurrentStep: boolean, isCompleted: boolean) => {
		const iconType =
			lastActiveService?.current_service_has_step?.stage?.icon_type;

		switch (true) {
			case isCurrentStep && iconType === VARIANT_STATUS.BLOCKED:
				return 'bg-h-error-50';
			case isCurrentStep && iconType === VARIANT_STATUS.ABANDONED:
				return 'bg-h-neutral-50';
			case isCurrentStep && iconType === VARIANT_STATUS.COMPLETED:
				return 'bg-h-success-50';
			case isCurrentStep:
				return 'bg-h-information-50';
			case !isCurrentStep && isCompleted:
				return 'bg-h-success-50';
			default:
				return 'bg-h-neutral-80';
		}
	};

	const getColorText = (isCurrentStep: boolean, isCompleted: boolean) => {
		const iconType =
			lastActiveService?.current_service_has_step?.stage?.icon_type;

		switch (true) {
			case isCurrentStep && iconType === VARIANT_STATUS.BLOCKED:
				return 'red';
			case isCurrentStep && iconType === VARIANT_STATUS.ABANDONED:
				return 'gray';
			case isCurrentStep && iconType === VARIANT_STATUS.COMPLETED:
				return 'greenSuccess';
			case isCurrentStep:
				return 'blueInformation';
			case !isCurrentStep && isCompleted:
				return 'greenSuccess';
			default:
				return 'gray';
		}
	};

	const getIcon = (isCurrentStep: boolean) => {
		const iconType =
			lastActiveService?.current_service_has_step?.stage?.icon_type;

		switch (true) {
			case isCurrentStep && iconType === VARIANT_STATUS.BLOCKED:
				return (
					<Lock
						size={14}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);
			case isCurrentStep && iconType === VARIANT_STATUS.ABANDONED:
				return (
					<SignOut
						size={18}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);

			case isCurrentStep && iconType === VARIANT_STATUS.IN_PROGRESS:
				return (
					<ClockCountdown
						size={14}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);
			case isCurrentStep && iconType === VARIANT_STATUS.COMPLETED:
				return (
					<CheckFat
						size={14}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);
			default:
				return <></>;
		}
	};

	const hasDocument = async () => {
		if (!lastActiveService?.product?.additional_information) return;

		try {
			setIsFetchingDocument(true);
			if (lastActiveService?.product?.additional_information) {
				const { year, month } =
					lastActiveService?.product?.additional_information;
				if (year && month) {
					const data = await getDeclarationDocument(year, month, tokenSession);
					if (data.length > 0) {
						setHasDoc(true);
					} else {
						setHasDoc(false);
					}
				} else if (year && !month) {
					const resp = await getAnnualDeclarationDocument(year, tokenSession);
					if (resp.length > 0) {
						setHasDoc(true);
					} else {
						setHasDoc(false);
					}
				}
				setIsFetchingDocument(false);
			}
		} catch (error) {
		} finally {
			setIsFetchingDocument(false);
		}
	};

	const handleDocument = useCallback(async () => {
		try {
			setIsLoadingDownloadDocument(true);
			if (
				lastActiveService &&
				lastActiveService?.product &&
				lastActiveService?.product?.additional_information
			) {
				const { year, month } =
					lastActiveService?.product?.additional_information;

				if (year && month) {
					const data = await getDeclarationDocument(year, month, tokenSession);
					if (data) {
						const monthlyReceiptUrl = data[0]?.url;
						platformOS === PlatformOS.IOS
							? (window.location.href = data[0]?.url)
							: window.open(monthlyReceiptUrl, '_blank', 'noopener,noreferrer');
						dispatch(showAlert(alertSuccess));
					}
				} else if (year && !month) {
					const resp = await getAnnualDeclarationDocument(year, tokenSession);
					if (resp) {
						const annualReceiptUrl = resp[0]?.url;
						platformOS === PlatformOS.IOS
							? (window.location.href = resp[0]?.url)
							: window.open(annualReceiptUrl, '_blank', 'noopener,noreferrer');
						dispatch(showAlert(alertSuccess));
					}
				}
			}
		} catch (error) {
			dispatch(showAlert(alertError));
		} finally {
			setIsLoadingDownloadDocument(false);
		}
	}, [lastActiveService]);

	const renderActionBySlug = (slug: string) => {
		let content = null;

		switch (slug) {
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_TAX_VERIFICATION_DECLARATION:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_TAX_VERIFICATION_ANNUAL_DECLARATION:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_PROCESSING_SERVICE_ASAT:
				content = (
					<div className='w-full'>
						<a href={hrefLink}>
							<Button
								label='Vincular mi RFC'
								variant={'secondary'}
							/>
						</a>
					</div>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_SUBSCRIPTION_BLOCKED_FOR_PAYMENT_DECLARATION:
				content = (
					<ActionButton
						onClick={goToDetail}
						label='Desbloquear periodo'
						variant={'primary'}
					/>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_IDENTIFICATION_FORM:
				content = (
					<ActionButton
						onClick={() => (window.location.href = HUBSPOT_LINKS.ASAT_FORM)}
						label='Completar formulario'
						variant={'secondary'}
					/>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_COMPLETED_DECLARATION:
			case ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION:
				content = (
					<>
						{hasDoc && (
							<>
								{isFetchingDocument ? (
									<div className='flex items-center justify-center mt-5'>
										<CircularProgress size={25} />
									</div>
								) : (
									<div className='w-full flex justify-end mt-5'>
										<div className='w-full lg:w-56'>
											<Button
												loading={isLoadingDownloadDocument}
												onClick={() => handleDocument()}
												label='Descargar acuse'
												variant={'primary'}
											/>
										</div>
									</div>
								)}
							</>
						)}
					</>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_E_SIGNATURE_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_E_SIGNATURE_PROCESSING_SERVICE_ASAT:
				content = (
					<ActionButton
						onClick={() => setShowESignature(true)}
						label='Subir mi e.Firma'
						variant={'secondary'}
					/>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_STAMPS_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_STAMPS_PROCESSING_SERVICE_ASAT:
				content = (
					<ActionButton
						onClick={() => setShowDigitalSeals(true)}
						label='Subir mis sellos digitales'
						variant={'secondary'}
					/>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_ABANDONED_STEP_IDENTIFICATION_FORM:
			case ACTION_CURRENT_SLUG.STAGE_ABANDONED_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_ABANDONED_PROCESSING_SERVICE_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_CALL_WITH_USER:
				content = (
					<ActionButton
						onClick={() => window.open(HUBSPOT_LINKS.BOOK_CALL, '_blank')}
						label='Agendar llamada'
						variant={'secondary'}
					/>
				);
				break;

			default:
				break;
		}
		return (
			<div
				onClick={() => {
					logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
						event_type: EVENT_TYPES.HOME_SERVICE_STATUS_CTA,
					});
				}}
			>
				{content}
			</div>
		);
	};

	const navigate = useNavigate();

	const goToAllServices = () => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_VIEW_ALL_SERVICE_STATUS,
		});
		navigate(ROUTES.SERVICE_STATUS);
	};

	const goToDetail = () => {
		navigate(`${ROUTES.SERVICE_STATUS}/${lastActiveService?.id}`, {
			state: { origin: 'home' },
		});
	};

	const getStepName = (isCurrentStep: any, name: string) => {
		if (isCurrentStep) {
			if (
				lastActiveService?.current_service_has_step?.stage?.icon_type ===
				VARIANT_STATUS.BLOCKED
			) {
				return 'Proceso bloqueado';
			} else if (
				lastActiveService?.current_service_has_step?.stage?.icon_type ===
				VARIANT_STATUS.ABANDONED
			) {
				return 'Proceso abandonado';
			} else if (
				lastActiveService?.current_service_has_step?.stage?.icon_type ===
				VARIANT_STATUS.COMPLETED
			) {
				if (
					lastActiveService?.service_type_has_product?.product_slug.includes(
						'asat',
					)
				) {
					return 'Completado';
				} else {
					return 'Presentada';
				}
			} else {
				return 'En proceso';
			}
		} else {
			if (
				lastActiveService?.service_type_has_product?.product_slug.includes(
					'asat',
				)
			) {
				return 'Completado';
			} else {
				return name;
			}
		}
	};

	const renderOnHoldText = () => {
		if (
			lastActiveService?.product?.regime?.includes('Plataformas Tecnológicas')
		) {
			return 'Estamos a la espera de tus certificados de ingresos para proceder con tu declaración.';
		} else {
			return 'Estamos recopilando tus datos y pronto procesaremos tu declaración.';
		}
	};

	const whatsappLink = useMemo(
		() =>
			`${CONFIG_WHATSAPP_SUPPORT}?text=${LITERALS.WHATSSAP_REGULARIZATIONS}`,
		[],
	);

	const renderDescription = () => {
		if (lastActiveService?.current_service_has_alternate_step_id) {
			return (
				<Text
					size='caption'
					color='gray'
					weight='regular'
					sx='mb-2'
				>
					Para continuar con tu servicio necesitamos ponernos en contacto
					contigo
				</Text>
			);
		} else {
			if (
				lastActiveService?.current_service_has_step?.stage?.slug ===
				ACTION_CURRENT_SLUG.STAGE_IN_PROGRESS_VALIDATE_STATUS_MONTHLY_DECLARATION_IN_ANNUAL_DECLARATION
			) {
				return (
					<>
						{lastActiveService?.current_service_has_step?.fields_status
							?.regulations_pending_for_present === VARIANT_STATUS.COMPLETED &&
						lastActiveService?.current_service_has_step?.fields_status
							?.declarations_pending_for_pay_in_sat ===
							VARIANT_STATUS.PENDING ? (
							<a
								className='cursor-pointer'
								href={whatsappLink}
								target='_blank'
								rel='noreferrer'
							>
								<Text
									size='caption'
									color='gray'
									weight='regular'
									sx='!block'
								>
									{renderAnnualValidateStatus(
										lastActiveService?.current_service_has_step?.fields_status,
									)}
								</Text>
							</a>
						) : (
							<Text
								size='caption'
								color='gray'
								weight='regular'
							>
								{renderAnnualValidateStatus(
									lastActiveService?.current_service_has_step?.fields_status,
								)}
							</Text>
						)}
					</>
				);
			} else if (
				lastActiveService?.current_service_has_step?.stage?.slug ===
				ACTION_CURRENT_SLUG.STAGE_IN_PROGRESS_ON_HOLD_DECLARATION
			) {
				return (
					<Text
						size='caption'
						color='gray'
						weight='regular'
						sx='mb-2'
					>
						{renderOnHoldText()}
					</Text>
				);
			} else if (
				lastActiveService?.current_service_has_step?.stage?.slug ===
					ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION ||
				lastActiveService?.current_service_has_step?.stage?.slug ===
					ACTION_CURRENT_SLUG.STAGE_COMPLETED_DECLARATION
			) {
				return (
					<Text
						size='caption'
						color='gray'
						weight='regular'
						sx='mb-2'
					>
						{hasDoc
							? lastActiveService?.current_service_has_step?.stage?.description
							: 'Tu declaración ya está presentada. Ahora extraeremos la información del SAT para obtener tu acuse. Cuando esté listo podrás descargarlo.'}
					</Text>
				);
			} else {
				return (
					<Text
						size='caption'
						color='gray'
						weight='regular'
						sx='mb-2'
					>
						{lastActiveService?.current_service_has_step?.stage?.description}
					</Text>
				);
			}
		}
	};

	const renderAnnualInfo = () => {
		if (
			lastActiveService?.current_service_has_step.stage?.icon_type !==
			VARIANT_STATUS.COMPLETED
		) {
			if (
				lastActiveService?.service_type_has_product?.product_slug ===
					PRODUCTS_SLUG.ANNUAL_DECLARATION ||
				lastActiveService?.service_type_has_product?.product_slug ===
					PRODUCTS_SLUG.ANNUAL_DECLARATION_BASIC_WITH_REGULARIZATION
			) {
				return (
					<AlertBanner
						icon={
							<CalendarCheck
								size={24}
								weight='fill'
								color='var(--h-warning-30)'
							/>
						}
						variant='warning'
						title={
							dayjs(lastActiveService?.created_at).isAfter(PURCHASED_DATE)
								? 'Tu declaración será entregada en Mayo'
								: 'Tu declaración será presentada en un plazo de 15 días'
						}
						description={
							dayjs(lastActiveService?.created_at).isAfter(PURCHASED_DATE)
								? '¡No te preocupes! si tienes saldo a favor, no se verá afectado.'
								: 'Recuerda que es una declaración extemporánea'
						}
						sx='mb-6 !border-none items-center shadow-md'
					/>
				);
			}
		}
	};

	useEffect(() => {
		if (
			lastActiveService?.current_service_has_step?.stage?.slug ===
				ACTION_CURRENT_SLUG.STAGE_COMPLETED_DECLARATION ||
			ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION
		) {
			hasDocument();
		}
	}, [lastActiveService]);

	return (
		<>
			<ModalESignature
				showModal={showESignature}
				setShowModal={() => setShowESignature(false)}
				onCallbackSuccess={() => {
					setShowESignature(false);
				}}
			/>
			<ModalDigitalSeals
				showModal={showDigitalSeals}
				setShowModal={() => setShowDigitalSeals(false)}
				onCallbackSuccess={() => {
					setShowDigitalSeals(false);
				}}
			/>
			<HomeCard
				title={`${
					activeServices?.length === 1
						? 'Estado de tu servicio'
						: 'Estado de tus servicios'
				}`}
				href={hrefLink}
				showButton={false}
				headerButton={
					<>
						{activeServices?.length > 1 && (
							<Button
								onClick={() => {
									goToAllServices();
								}}
								type='button'
								label={isMobile ? 'Ver todos' : 'Ver todos mis servicios'}
								variant='text'
								icon={<ArrowRight size={20} />}
								labelColor='primary'
							/>
						)}
					</>
				}
			>
				{false ? (
					<div className='flex justify-center m-2'>
						<CircularProgress size={30} />
					</div>
				) : (
					<>
						<div className='bg-h-neutral-94 flex flex-col p-4 rounded-lg shadow-md'>
							<div className='flex items-center justify-between'>
								<Text
									size='mini-1'
									color='gray'
									weight='light'
									sx='mb-2'
								>
									Producto
								</Text>
								{lastActiveService?.product?.regime && (
									<Text
										size='mini-1'
										color='gray'
										weight='light'
										sx='mb-2'
									>
										Regimen
									</Text>
								)}
							</div>

							<div className='flex items-center justify-between'>
								<Text
									size='body-2'
									color='dark'
									weight='medium'
									sx='hidden lg:flex'
								>
									{lastActiveService?.product?.name}
								</Text>
								<Text
									size='caption'
									color='dark'
									weight='medium'
									sx='flex lg:hidden'
								>
									{lastActiveService?.product?.name}
								</Text>
								{lastActiveService?.product?.regime && (
									<div className='flex flex-col'>
										<div className='bg-h-neutral-94 rounded-lg shadow-md p-2'>
											<Text
												size={isMobile ? 'mini-2' : 'mini-1'}
												color='gray'
												weight='regular'
												sx='text-right'
											>
												{lastActiveService?.product?.regime.length > 1
													? `Contratado para ${lastActiveService?.product?.regime.length} regímenes`
													: lastActiveService?.product?.regime[0]}
											</Text>
										</div>
									</div>
								)}
							</div>

							<div
								onClick={goToDetail}
								className='flex items-center w-full mt-8 mb-6 justify-between px-0 md:px-2 lg:px-4 cursor-pointer'
							>
								{lastActiveService?.service_overview?.service_has_step_overview.map(
									(step, index) => (
										<Fragment key={`step-${index}`}>
											<div className='flex flex-col items-center'>
												<div
													className={`${getColor(
														!!step?.current_service_has_step_id,
														step?.completed,
													)} h-8 w-8 rounded-full flex items-center justify-center`}
												>
													{step?.current_service_has_step_id ? (
														<>
															<div className='bg-white w-6 h-6 rounded-full flex items-center justify-center'>
																<div
																	className={`${getColor(
																		!!step?.current_service_has_step_id,
																		step?.completed,
																	)} rounded-full w-5 h-5 flex items-center justify-center`}
																>
																	{getIcon(!!step?.current_service_has_step_id)}
																</div>
															</div>
														</>
													) : (
														<>
															{index ===
															lastActiveService?.service_overview
																?.service_has_step_overview.length -
																1 ? (
																<CheckFat
																	size={14}
																	weight='fill'
																	color='var(--h-text-white)'
																/>
															) : (
																getIcon(!!step?.current_service_has_step_id)
															)}
														</>
													)}
												</div>

												<Text
													size='mini-1'
													color={getColorText(
														!!step?.current_service_has_step_id,
														step?.completed,
													)}
													weight='light'
													sx='mt-2 text-center'
												>
													{getStepName(
														step?.current_service_has_step_id,
														step?.name,
													)}
												</Text>
											</div>
											{index <
											lastActiveService?.service_overview
												?.service_has_step_overview.length -
												1 ? (
												<div
													className={`${getColor(
														!!step.current_service_has_step_id,
														step.completed,
													)} h-[2.5px] w-6 sm:w-28 md:w-40 lg:w-48 rounded-lg mb-5`}
												></div>
											) : null}
										</Fragment>
									),
								)}
							</div>

							<div className='mb-4'>{renderDescription()}</div>

							{renderAnnualInfo()}
							<div>
								{lastActiveService?.current_service_has_alternate_step_id ? (
									<>
										<div className='w-full'>
											<Button
												onClick={() =>
													window.open(HUBSPOT_LINKS.BOOK_CALL, '_blank')
												}
												label='Agendar llamada'
												variant={'primary'}
											/>
										</div>
									</>
								) : (
									<>
										{renderActionBySlug(
											lastActiveService?.current_service_has_step?.stage
												?.slug || '',
										)}
									</>
								)}
							</div>
						</div>
					</>
				)}
			</HomeCard>
		</>
	);
};

export default React.memo(ServicesStatuHome);
