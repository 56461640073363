import { useEffect, useState } from 'react';
import PlanOverview from './organisms/PlanOverview';
import ProductCardSimple from './organisms/ProductCardSimple';
import ModalRegularizationsMonthly from './organisms/ModalRegularizationsMonthly';
import ModalRegularizationsYearly from './organisms/ModalRegularizationsYearly';
import imgBannerRegularizationsMonthly from '../../assets/img/png/plan-card-reg-monthly.png';
import imgBannerRegularizationsYearly from '../../assets/img/png/plan-card-reg-yearly.png';
import { useSearchParams } from 'react-router-dom';
import URL_PARAMS from '../../shared/constants/urlParams';

const regularizationProducts = [
	{
		slug: 'regularizations-monthly',
		name: (
			<>
				Declaraciones <br /> mensuales atrasadas
			</>
		),
		ctaLabel: 'Regularizar periodos atrasados',
		description:
			'Regulariza tus declaraciones pendientes por presentación de Plataformas Tecnológicas y RESICO.',
		benefits: [
			'Presentamos tus declaraciones atrasadas en un plazo de 48 horas.',
			'Optimizamos tu declaración buscando que pagues lo menos posible.',
			'Recibirás un análisis detallado de cada regularización presentada.',
			'Tendrás la opción de revisar tu declaración con un contador.'
		],
		imgBanner: imgBannerRegularizationsMonthly,
	},
	{
		slug: 'regularizations-yearly',
		name: (
			<>
				Declaraciones <br /> anuales atrasadas
			</>
		),
		ctaLabel: 'Ponte al día con tus anuales',
		description:
			'Regulariza tus declaraciones anuales pendientes de presentación de hasta 5 años atrás.',
		benefits: [
			'Presentamos tus declaraciones anuales atrasadas en un plazo de 5 días.',
			'Optimizamos el análisis de tus deducciones personales con inteligencia artificial.',
			'Recibirás un análisis detallado de cada declaración anual presentada.',
			'Tendrás la opción de revisar tu declaración con un contador.'
		],
		imgBanner: imgBannerRegularizationsYearly,
	},
];

const RegularizationsPlanSection = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const flow_periodicity = searchParams.get(URL_PARAMS.FLOW_PERIODICITY);

	const [isOpen, setIsOpen] = useState({
		monthly: false,
		yearly: false,
	});

	const handleOpen = (type: 'monthly' | 'yearly') => {
		setIsOpen({ ...isOpen, [type]: true });
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set(URL_PARAMS.FLOW_PERIODICITY, type);
		setSearchParams(newSearchParams);
	};

	const handleClose = (type: 'monthly' | 'yearly') => {
		setIsOpen({ ...isOpen, [type]: false });
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete(URL_PARAMS.FLOW_PERIODICITY);
		setSearchParams(newSearchParams);
	};

	useEffect(() => {
		if (!flow_periodicity) return;
		if (flow_periodicity === 'monthly') {
			handleOpen('monthly');
		} else if (flow_periodicity === 'yearly') {
			handleOpen('yearly');
		}
	}, [flow_periodicity]);

	return (
		<div className='w-full mx-auto max-w-[840px]'>
			<ModalRegularizationsMonthly
				isOpen={isOpen.monthly}
				handleClose={() => handleClose('monthly')}
			/>

			<ModalRegularizationsYearly
				isOpen={isOpen.yearly}
				handleClose={() => handleClose('yearly')}
			/>

			<PlanOverview
				title='Regulariza tus declaraciones pendientes'
				description='Te ayudamos a ponerte al día con tus declaraciones pendientes, evitándote molestos problemas con el SAT como multas y sanciones.'
			/>
			<div className='md:grid md:grid-cols-2 flex flex-col items-start gap-2 md:gap-8 mt-2 px-3'>
				{regularizationProducts.map((product, index) => (
					<ProductCardSimple
						imgBanner={product.imgBanner}
						ctaLabel={product.ctaLabel}
						name={product.name}
						description={product.description}
						benefits={product.benefits}
						ctaAction={() =>
							handleOpen(
								product.slug === 'regularizations-monthly'
									? 'monthly'
									: 'yearly',
							)
						}
						key={`product-${index}`}
						stylesDescription='mt-2'
					/>
				))}
			</div>
		</div>
	);
};

export default RegularizationsPlanSection;
