import clevertap from 'clevertap-web-sdk';
import STORAGE_KEYS from '../shared/constants/storage';
import { useStorage } from '../shared/utils/general.util';
import { PlatformOS } from '../redux/slices/profile.slice';

const useCleverTapEvent = () => {
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS);
	const logCleverTapEvent = (
		eventName: string,
		properties: Record<string, string | number | boolean | any[]> = {},
	) => {
		const sourceChannel = platformOS ?? PlatformOS.WEB;
		clevertap.event.push(eventName, {
			...properties,
			timestamp: new Date(),
			source_channel: sourceChannel,
		});
	};

	return logCleverTapEvent;
};

export default useCleverTapEvent;
