import React from 'react';
import { default as ModalMUI } from '@mui/material/Modal';
import { XCircle } from '@phosphor-icons/react';

type Props = {
	sx?: string;
	open: boolean;
	type?: 'dialog' | 'fullscreen';
	children: React.ReactNode;
	onClose?: () => void;
	showCloseButton?: boolean;
};

const Modal = ({
	open,
	children,
	onClose,
	sx = '',
	type = 'fullscreen',
	showCloseButton = true,
	...props
}: Props) => {
	return (
		<>
			{type === 'dialog' ? (
				<dialog
					{...props}
					open={open}
					className={`fixed z-50 ${sx}`}
					onClose={onClose}
				>
					<div>{children}</div>
				</dialog>
			) : (
				<ModalMUI
					open={open}
					onClose={onClose}
				>
					<div
						style={{ clipPath: 'inset(0 round 16px)' }}
						className={`bg-white absolute top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] max-w-4xl p-4 lg:p-5 rounded-xl ${sx}`}
					>
						{children}

						{showCloseButton && (
							<XCircle
								className='absolute top-2 right-2 cursor-pointer text-h-neutral-70'
								size={24}
								weight='fill'
								onClick={onClose}
							/>
						)}
					</div>
				</ModalMUI>
			)}
		</>
	);
};

export default Modal;
