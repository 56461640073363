import {
	Cart,
	CartPorduct,
	CartProductVersion,
	CartVersionProduct,
	Coupon,
	OrderPending,
} from '../../../@types/Ecommerce';
import {
	OrderRefresh,
	OrderRefreshMapper,
	ShoppingItemMapper,
} from '../../../@types/Order.type';

function cartMapper(req: any[]): Cart[] {
	return req.map((data: any) => ({
		basePrice: data['base_price'] ?? -1,
		coupon: orderCouponMapper(data['coupon'] ?? {}),
		createdAt: data['created_at'] ?? '',
		discount: data['discount'] ?? 0,
		id: data['id'] ?? -1,
		items: orderProductsMapper(data['items'] ?? []),
		status: data['status'] ?? 'close',
		subtotal: data['subtotal'] ?? -1,
		taxes: data['taxes'] ?? -1,
		total: data['total'] ?? -1,
		updatedAt: data['updated_at'] ?? '',
		userId: data['user_id'] ?? -1,
	}));
}

function orderCouponMapper(data: any): Coupon {
	return {
		activeSubscription: data['active_subscription'] ?? false,
		code: data['code'] ?? '',
		duration: data['duration'] ?? '',
		id: data['id'] ?? -1,
		name: data['name'] ?? '',
		percentOff: data['percent_off'] ?? 0,
	};
}

export function orderProductsMapper(data: any[]): CartPorduct[] {
	return data.map((element) => ({
		additionalInformation: element['additional_information'] ?? {},
		basePrice: element['base_price'] ?? -1,
		coupon: orderCouponMapper(element['coupon'] ?? {}),
		createdAt: element['created_at'] ?? '',
		discount: element['discount'] ?? -1,
		id: element['id'] ?? -1,
		productVersion: orderProductVersionMapper(element['product_version'] ?? {}),
		purchaseType: element['purchase_type'] ?? '',
		subtotal: element['subtotal'] ?? -1,
		taxes: element['taxes'] ?? -1,
		total: element['total'] ?? -1,
		updatedAt: element['updated_at'] ?? '',
		customProduct: element['custom_product'] ?? {},
	}));
}

function orderProductMapper(data: any): CartPorduct {
	return {
		additionalInformation: data['additional_information'] ?? {},
		basePrice: data['base_price'] ?? -1,
		coupon: orderCouponMapper(data['coupon'] ?? {}),
		createdAt: data['created_at'] ?? '',
		discount: data['discount'] ?? -1,
		id: data['id'] ?? -1,
		productVersion: orderProductVersionMapper(data['product_version'] ?? {}),
		purchaseType: data['purchase_type'] ?? '',
		subtotal: data['subtotal'] ?? -1,
		taxes: data['taxes'] ?? -1,
		total: data['total'] ?? -1,
		updatedAt: data['updated_at'] ?? '',
	};
}

function orderProductVersionMapper(data: any): CartProductVersion {
	return {
		createdAt: data['created_at'] ?? '',
		currencyId: data['currency_id'] ?? -1,
		description: data['description'] ?? '',
		id: data['id'] ?? -1,
		periodicity: data['periodicity'] ?? -1,
		price: data['price'] ?? -1,
		product: orderCardVersionProductMapper(data['product'] ?? {}),
		productId: data['product_id'] ?? -1,
	};
}

function orderCardVersionProductMapper(data: any): CartVersionProduct {
	return {
		additionalFields: data['additional_fields'] ?? [],
		color: data['color'] ?? null,
		defaultProductVersionId: data['default_product_version_id'] ?? -1,
		description: data['description'] ?? '',
		fiscalCode: data['fiscal_code'] ?? '',
		fiscalConcept: data['fiscal_concept'] ?? '',
		iconUrl: data['icon_url'] ?? '',
		id: data['id'] ?? -1,
		isPurchasable: data['is_purchasable'] ?? false,
		longDescription: data['long_description'] ?? null,
		name: data['name'] ?? '',
		requiredFields: data['required_fields'] ?? null,
		tags: data['tags'] ?? [],
		benefits: data['benefits'] ?? [],
		slug: data['slug'] ?? '',
		category: data['category'] ?? '',
		pyp_v2: data['pyp_v2'] ?? null,
	};
}

export function orderPendingMapper(req: any[]): OrderPending[] {
	return req.map((data: any) => ({
		id: data['id'] ?? -1,
		userId: data['user_id'] ?? -1,
		status: data['status'] ?? 'pending',
		basePrice: data['base_price'] ?? -1,
		createdAt: data['created_at'] ?? '',
		discount: data['discount'] ?? 0,
		subtotal: data['subtotal'] ?? -1,
		taxes: data['taxes'] ?? -1,
		total: data['total'] ?? -1,
		updatedAt: data['updated_at'] ?? '',
		items: orderProductsMapper(data['items'] ?? []),
		sourceChannel: data['source_channel'] ?? '',
		expiresAt: data['expires_at'] ?? '',
	}));
}

function orderRefreshMapper(req: OrderRefresh[]): OrderRefreshMapper[] {
	const mapped = req.map((data: any) => ({
		basePrice: data['base_price'] ?? -1,
		coupon: data['coupon'] ? orderCouponMapper(data['coupon']) : undefined,
		createdAt: data['created_at'] ?? '',
		id: data['id'] ?? -1,
		discount: data['discount'] ?? 0,
		userId: data['user_id'] ?? -1,
		status: data['status'] ?? 'close',
		total: data['total'] ?? -1,
		paymentId: data['payment_id'] ?? null,
		updatedAt: data['updated_at'] ?? '',
		subtotal: data['subtotal'] ?? -1,
		taxes: data['taxes'] ?? -1,
		transactionId: data['transaction_id'] ?? -1,
		isRenewal: data['is_renewal'] ?? false,
		sourceChannel: data['source_channel'] ?? '',
		shoppingCartTreatment: null,
		utmSource: data['utm_source'] ?? '',
		utmCampaign: data['utm_campaign'] ?? '',
		utmMedium: data['utm_medium'] ?? '',
		shoppingItem: orderShoppingItemMapper(data['shopping_item'] ?? []),
	}));

	return mapped;
}

function orderShoppingItemMapper(req: any[]): ShoppingItemMapper[] {
	return req.map((data: any) => ({
		id: data['id'] ?? -1,
		shoppingCartId: data['shopping_cart_id'] ?? -1,
		productVersionId: data['product_version_id'] ?? -1,
		createdAt: data['created_at'] ?? '',
		updatedAt: data['updated_at'] ?? '',
		purchaseType: data['purchase_type'] ?? '',
		basePrice: data['base_price'] ?? -1,
		discount: data['discount'] ?? 0,
		subtotal: data['subtotal'] ?? -1,
		taxes: data['taxes'] ?? -1,
		coupon: orderCouponMapper(data['coupon'] ?? {}),
		total: data['total'] ?? -1,
		additionalInformation: data['additional_information'] ?? {},
		customProduct: data['custom_product'] ?? {},
	}));
}

export default cartMapper;

export { orderProductMapper, orderRefreshMapper };
