import {
	Briefcase,
	Check,
	IdentificationCard,
	User,
	XCircle,
} from '@phosphor-icons/react';
import Heading from '../../../components/atoms/Heading';
import Text from '../../../components/atoms/Text';
import { useMediaQuery } from '@mui/material';
import PrimaryButton from '../../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';

const OnboardingAnalysis = ({
	userName,
	rfc,
	economicActivities,
	analysis,
	onClose,
	nextStep,
	page = 'onboarding',
}: {
	userName: string;
	rfc: string;
	economicActivities?: string;
	analysis: any;
	onClose: () => void;
	nextStep: () => void;
	page?: 'onboarding' | 'plans';
}) => {
	const navigate = useNavigate();
	const validatedRFC = rfc.length === 13;
	let validatedActivity =
		economicActivities &&
		economicActivities !== '"USER_DID_NOT_WANT_TO_ANSWER"' &&
		economicActivities !== '"USER_DID_NOT_KNOW"' &&
		economicActivities !== '"USER_DID_NOT_REMEMBER"' &&
		economicActivities !== null &&
		economicActivities !== undefined
			? economicActivities
			: '';

	const isMobile = useMediaQuery('(max-width: 768px)');

	try {
		const parsedActivities = JSON.parse(validatedActivity);
		if (Array.isArray(parsedActivities)) {
			validatedActivity = parsedActivities.join(', ');
		} else {
			validatedActivity = parsedActivities;
		}
	} catch (error) {}

	const havePlan = analysis?.slug && analysis?.slug !== 'cant_recommend_plan';

	const handleNextStep = () => {
		if (havePlan || page === 'plans') nextStep();
		else navigate('/');
	};

	return (
		<div className='flex flex-col fade-in-bottom p-3'>
			<div className='flex flex-col'>
				<div className='flex flex-col gap-y-1 p-4 sm:p-10 bg-white shadow-md rounded-2xl w-full relative'>
					{isMobile ? (
						<Text
							size='body-2'
							weight='bold'
							sx='!w-full mb-3'
						>
							Tu análisis fiscal heru
						</Text>
					) : (
						<Heading
							size='xs'
							sx='!w-full mb-3'
							weight='bold'
						>
							Tu análisis fiscal heru
						</Heading>
					)}
					<button
						onClick={onClose}
						className='text-h-neutral-70 absolute top-4 right-4'
					>
						<XCircle
							size={30}
							weight='fill'
						/>
					</button>
					<div className='flex flex-col md:flex-row justify-between'>
						<Text
							size='caption'
							weight='bold'
						>
							<User
								size={20}
								color='var(--h-primary-50)'
							/>{' '}
							{userName}
						</Text>
						{validatedRFC && (
							<Text
								size='caption'
								weight='bold'
							>
								<IdentificationCard
									size={20}
									color='var(--h-primary-50)'
								/>{' '}
								RFC: {rfc}
							</Text>
						)}
					</div>
					{validatedActivity && (
						<div className='flex flex-row justify-between'>
							<Text
								size='caption'
								weight='bold'
							>
								<Briefcase
									size={20}
									color='var(--h-primary-50)'
								/>{' '}
								{validatedActivity}
							</Text>
						</div>
					)}
					<hr className='my-4 sm:my-6' />

					<div
						className={`
							${page === 'plans' ? 'max-h-[calc(100vh-340px)] sm:max-h-[calc(100vh-460px)] overflow-y-auto' : 'max-h-[calc(100vh-300px)] sm:max-h-[calc(100vh-360px)] overflow-y-auto'}`}
					>
						<Text
							size='caption'
							weight='medium'
							color='medium'
						>
							{analysis?.introduction}
						</Text>
						<div className='flex flex-col gap-y-4 overflow-y-auto'>
							<Text
								size='caption'
								weight='light'
								sx='!mt-4'
								color='medium'
							>
								{analysis?.regimesSummary?.regimesDescription}
							</Text>
							<div className='ml-4 flex flex-col gap-2'>
								{analysis?.regimesSummary?.bulletPoints?.map(
									(point: any, index: number) => {
										return (
											<div key={index}>
												<Text
													size='caption'
													weight='light'
													color='medium'
												>
													<span className='font-medium'>{index + 1}.</span>{' '}
													{point?.content}
												</Text>
											</div>
										);
									},
								)}
							</div>
						</div>
						<div className='flex flex-col gap-y-4  overflow-y-auto mt-8'>
							<Text
								size='caption'
								weight='medium'
								color='medium'
							>
								{analysis?.regimeBenefits?.summary}
							</Text>
							<div className='ml-4 flex flex-col gap-2'>
								{analysis?.regimeBenefits?.bulletPoints.map(
									(point: any, index: number) => {
										return (
											<div key={index}>
												<Text
													size='caption'
													weight='medium'
													color='medium'
												>
													{index + 1}. {point?.title}
												</Text>
												<Text
													size='caption'
													weight='light'
													color='medium'
												>
													{point?.description}
												</Text>
											</div>
										);
									},
								)}
							</div>
						</div>

						<div className='flex flex-col gap-y-4 overflow-y-auto mt-8'>
							<Text
								size='caption'
								weight='medium'
								sx='!mt-4'
								color='medium'
							>
								{analysis?.heruBenefits?.summary}
							</Text>
							<div className='ml-2 flex flex-col gap-2'>
								{analysis?.heruBenefits?.bulletPoints.map(
									(point: any, index: number) => {
										return (
											<div
												key={index}
												className='flex items-center gap-3'
											>
												<Check
													className='text-h-primary-50 min-w-[20px]'
													weight='bold'
												/>
												<Text
													size='caption'
													weight='light'
													color='medium'
												>
													{point?.description}
												</Text>
											</div>
										);
									},
								)}
							</div>
						</div>
						<Text
							size='caption'
							weight='light'
							color='medium'
							sx='mt-8 opacity-80'
						>
							{userName} recuerda que este es un análisis previo y no puede ser
							considerado como un documento oficial.*
						</Text>
					</div>
					<PrimaryButton
						label={havePlan ? 'Conocer mi plan personalizado' : 'Continuar'}
						onClick={handleNextStep}
						sx='!w-full mt-6'
					/>
				</div>
			</div>
		</div>
	);
};

export default OnboardingAnalysis;
