import HERU_API from '../shared/constants/heruApi';
import { get } from '../shared/utils/FetchSSL.util';

const getSalesPeriodsAdquired = async ({
	tokenSession,
	regime,
	period_type,
}: {
	tokenSession?: string;
	regime?: string;
	period_type: 'monthly' | 'annual';
}) => {
	try {
		const response = await get(
			regime
				? `${HERU_API.HERU_API_SALES_PERIODS_ADQUIRED}?regime=${regime}&declaration_period=${period_type}`
				: `${HERU_API.HERU_API_SALES_PERIODS_ADQUIRED}?declaration_period=${period_type}`,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export { getSalesPeriodsAdquired };
