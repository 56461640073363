import React, { useEffect } from 'react';
import Text from '../../../components/atoms/Text';
import { XCircle } from '@phosphor-icons/react';
import Heading from '../../../components/atoms/Heading';
import {
	capitalizeText,
	formatCurrency,
	useStorage,
} from '../../../shared/utils/general.util';
import Button from '../../../components/atoms/Button';
import Tag from '../../../components/molecules/Tag';
import { useMediaQuery } from '@mui/material';
import ResumeProperty from '../../taxesAndInvoices/molecules/ResumeProperty';
import SideToModalContainer from '../../taxesAndInvoices/molecules/SideToModalContainer';
import { FiscalDocumentType } from '../../../@types/FiscalDocuments.type';
import { getFormatDate, getMonthName } from '../../../shared/utils/dates.util';
import { getInvoicePaymentMethod } from '../../../shared/constants/fiscalDocuments';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { SelectedFiscalDocument } from './FiscalDocumentsSection';
import { PlatformOS } from '../../../redux/slices/profile.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import ROUTES from '../../../shared/constants/routes';
import STORAGE_KEYS from '../../../shared/constants/storage';

interface Props {
	selectedDocument: SelectedFiscalDocument;
	setSelectedDocument: React.Dispatch<
		React.SetStateAction<SelectedFiscalDocument>
	>;
	documentType:
		| FiscalDocumentType.INVOICE
		| FiscalDocumentType.RECEIPT
		| FiscalDocumentType.RETENTION;
}

const FiscalDocumentDetail: React.FC<Props> = ({
	setSelectedDocument,
	selectedDocument,
	documentType,
}) => {
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;
	const logClevertapEvent = useCleverTapEvent();
	const isViewedFromTaxboard = location.pathname === ROUTES.TAXES;

	const handleClose = () => {
		setSelectedDocument({
			...selectedDocument,
			[documentType]: null,
		});
	};

	const detailTitle: Record<string, string> = {
		invoice: 'Detalle de la factura',
		receipt: 'Detalle de la declaración',
		retention: 'Detalle de la certificación',
	};

	const isMobile = useMediaQuery('(max-width: 640px)');

	const invoiceInfo = selectedDocument?.invoice;
	const retentionInfo = selectedDocument?.retention;
	const receiptInfo = selectedDocument?.receipt;

	const getShortDate = (date: string) => {
		const formatedDate =
			getFormatDate(date || '', 'DD MMM YYYY', false, true) || '';

		const month = formatedDate?.split(' ')?.[1];
		const shortMonth = month?.substring(0, 3);
		return (
			`${formatedDate?.split?.(' ')?.[0]} ${shortMonth} ${formatedDate?.split(' ')?.[2]}` ||
			''
		);
	};

	const handleDownload = (url: string) => {
		window.open(url, '_blank', 'noopener');
		logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
			event_type: isViewedFromTaxboard
				? EVENT_TYPES.TAXBOARD_DOCUMENT_DOWNLOADED
				: EVENT_TYPES.FISCAL_DOCUMENTS_DETAIL_ACTION,
			source: documentType,
		});
	};

	useEffect(() => {
		if (selectedDocument[documentType]) {
			logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
				event_type: isViewedFromTaxboard
					? EVENT_TYPES.TAXBOARD_DOCUMENT_DETAIL_OPENED
					: EVENT_TYPES.FISCAL_DOCUMENTS_DETAIL,
				source: documentType,
			});
		}
	}, [selectedDocument]);

	const propertiesByType: Record<
		string,
		{ label: string; value: string | undefined }[] | null
	> = {
		invoice: [
			{
				label: 'Subtotal',
				value: formatCurrency(invoiceInfo?.invoice?.subtotal || 0),
			},
			{
				label: 'Impuestos',
				value: formatCurrency(invoiceInfo?.invoice?.tax || 0),
			},
			{
				label: 'hr',
				value: 'hr',
			},
			{
				label: 'Método de pago',
				value: invoiceInfo?.invoice?.payment_type,
			},
			{
				label: 'Forma de pago',
				value:
					invoiceInfo?.invoice?.payment_method &&
					getInvoicePaymentMethod(invoiceInfo?.invoice?.payment_method),
			},
		],

		retention: [
			{
				label: 'Monto total',
				value: formatCurrency(
					retentionInfo?.retention?.total_operation_amount || 0,
				),
			},
			{
				label: 'IVA Retenido',
				value: formatCurrency(
					retentionInfo?.items?.reduce(
						(acc, item) =>
							item?.tax_type === '02' ? acc + item.retained_amount : acc,
						0,
					) || 0,
				),
			},
			{
				label: 'ISR Retenido',
				value: formatCurrency(
					retentionInfo?.items?.reduce(
						(acc, item) =>
							item?.tax_type === '01' ? acc + item.retained_amount : acc,
						0,
					) || 0,
				),
			},
		],
		receipt: [
			{
				label: 'Tipo de declaración',
				value:
					selectedDocument?.receipt?.declaration_type === 'NORMAL'
						? 'Normal'
						: 'Complementaria',
			},
			{
				label: 'Presentada por',
				value:
					selectedDocument?.receipt?.presented_by === 'Heru'
						? 'Heru'
						: 'Externo',
			},
			{
				label: 'Pagado',
				value: receiptInfo?.vat_detail_amount_to_pay
					? selectedDocument?.receipt?.payed
						? 'Sí'
						: 'No'
					: undefined,
			},
		],
	};

	const invoiceIsReceived = invoiceInfo?.invoice?.receiver_id === taxPayer?.id;

	const getPropertiesByType = () => {
		return propertiesByType[documentType]?.map((property, index) =>
			property.label === 'hr' ? (
				<hr
					key={`property-index-${index}`}
					className='my-3'
				/>
			) : property?.value ? (
				<div key={`property-index-${index}`}>
					<ResumeProperty
						label={property.label}
						value={property.value}
					/>
				</div>
			) : null,
		);
	};

	const detailContent: Record<string, React.ReactNode> = {
		invoice: (
			<>
				<div className='mt-2 flex justify-between items-center'>
					<div>
						<Text
							size={'mini-1'}
							weight='light'
							color='light'
							truncate
						>
							Tipo de factura
						</Text>
						<div className='flex gap-2 items-center'>
							<Text
								size={isMobile ? 'caption' : 'body-3'}
								truncate
							>
								{invoiceIsReceived ? 'Factura recibida' : 'Factura emitida'}
							</Text>
							<Tag
								label={
									invoiceInfo?.invoice?.status === 'VIGENTE'
										? 'Vigente'
										: 'Cancelada'
								}
								type='filled'
								caseVariant={
									invoiceInfo?.invoice?.status === 'VIGENTE'
										? 'success'
										: 'error'
								}
								sx='!p-1 !py-[2px]'
							/>
						</div>
					</div>
					<div className='flex flex-col items-end'>
						<Text
							size={'mini-1'}
							weight='light'
							color='light'
							truncate
						>
							Fecha de generación
						</Text>
						<Text
							size={isMobile ? 'caption' : 'body-3'}
							truncate
						>
							{invoiceInfo?.invoice?.certified_at
								? getShortDate(invoiceInfo?.invoice?.certified_at)
								: '-'}
						</Text>
					</div>
				</div>
				<Text
					size={'mini-1'}
					weight='light'
					color='light'
					sx='mt-2'
				>
					{invoiceIsReceived ? 'Receptor' : 'Emisor'}
				</Text>
				<Text
					size={isMobile ? 'body-3' : 'body-1'}
					weight='medium'
					truncate
				>
					{capitalizeText(
						(invoiceIsReceived
							? invoiceInfo?.issuerData?.name
							: invoiceInfo?.receiverData?.name) || '',
					)}
				</Text>
				<Text
					size={'mini-1'}
					weight='light'
					color='light'
					sx='mt-2'
				>
					Concepto
				</Text>
				<Text
					size={isMobile ? 'caption' : 'body-3'}
					truncate
				>
					{capitalizeText(
						invoiceInfo?.items?.[0]?.description || 'Sin descripción',
					)}
				</Text>
				<Text
					size={'mini-1'}
					weight='light'
					color='light'
					truncate
					sx='mt-2'
				>
					RFC
				</Text>
				<Text
					size={isMobile ? 'caption' : 'body-3'}
					truncate
				>
					{invoiceInfo?.issuerData?.code}
				</Text>
				<div className='flex justify-between items-center'>
					<Text
						weight='light'
						truncate
					>
						Total
					</Text>
					<Heading
						size={isMobile ? 'xs' : 'm'}
						weight='bold'
					>
						{formatCurrency(invoiceInfo?.invoice?.total || 0)}
					</Heading>
				</div>
				<hr className='mt-3' />
				<div className='flex flex-col gap-2 mt-3'>{getPropertiesByType()}</div>
				<Text
					size='mini-1'
					weight='light'
					color='light'
					truncate
					sx='mt-3'
				>
					Detalle
				</Text>
				<Text
					size='caption'
					sx='justify-between w-full px-3 py-2 bg-h-neutral-94 rounded-lg border border-h-neutral-90'
				>
					<span>Cantidad de conceptos</span>
					<span>{selectedDocument?.invoice?.items?.length}</span>
				</Text>
				<hr className='my-3' />
				{invoiceInfo?.invoice?.xml_url && platformOS !== PlatformOS.IOS && (
					<Button
						label='Descargar factura (XML)'
						onClick={() => handleDownload(invoiceInfo?.invoice?.xml_url)}
					/>
				)}
			</>
		),
		receipt: (
			<>
				<div className='mt-2 flex justify-between items-center'>
					<div>
						<Text
							size={'mini-1'}
							weight='light'
							color='light'
							truncate
						>
							Fecha de presentación
						</Text>
						<div className='flex gap-2 items-center'>
							<Text
								size={isMobile ? 'caption' : 'body-3'}
								truncate
							>
								{getFormatDate(
									receiptInfo?.presented_at || '',
									'DD MMM YYYY',
									false,
									true,
								)}
							</Text>
						</div>
					</div>
				</div>
				{selectedDocument?.receipt?.month &&
					selectedDocument?.receipt?.year && (
						<>
							<Text
								size={'mini-1'}
								weight='light'
								color='light'
								sx='mt-2'
							>
								Periodo correspondiente
							</Text>
							<Text
								size={isMobile ? 'body-3' : 'body-1'}
								weight='medium'
								truncate
							>
								{getMonthName(selectedDocument?.receipt?.month!, true)}{' '}
								{selectedDocument?.receipt?.year}
							</Text>
						</>
					)}
				<Text
					size={'mini-1'}
					weight='light'
					color='light'
					truncate
					sx='mt-3'
				>
					Resultado Fiscal
				</Text>
				<Heading
					size={isMobile ? 'xs' : 'm'}
					weight='bold'
				>
					{formatCurrency(receiptInfo?.vat_detail_amount_to_pay || 0)}
				</Heading>
				<hr className='mt-3' />
				<div className='flex flex-col gap-2 mt-3'>{getPropertiesByType()}</div>
				<hr className='my-3' />
				<div className='flex flex-col gap-3'>
					{receiptInfo?.declaration_url && (
						<Button
							variant='secondary'
							label='Descargar resultado de la declaración'
							onClick={() => handleDownload(receiptInfo?.declaration_url)}
						/>
					)}
					{receiptInfo?.declaration_detail_document && (
						<Button
							label='Descargar detalle de la declaración'
							onClick={() =>
								handleDownload(receiptInfo?.declaration_detail_document)
							}
						/>
					)}
				</div>
			</>
		),
		retention: (
			<>
				<div className='flex flex-col justify-between min-h-full'>
					<div>
						<div className='mt-2 flex justify-between items-center'>
							<div>
								<Text
									size={'mini-1'}
									weight='light'
									color='light'
									truncate
								>
									Emisor
								</Text>
								<div className='flex gap-2 items-center'>
									<Text
										size={isMobile ? 'caption' : 'body-3'}
										sx={isMobile ? 'max-w-[170px]' : ''}
									>
										{capitalizeText(retentionInfo?.issuerData?.name || '')}
									</Text>
								</div>
							</div>
							<div className='flex flex-col items-end'>
								<Text
									size={'mini-1'}
									weight='light'
									color='light'
									truncate
								>
									Fecha de generación
								</Text>
								<Text
									size={isMobile ? 'caption' : 'body-3'}
									truncate
								>
									{getFormatDate(
										retentionInfo?.retention?.certified_at || '',
										'DD MMM YYYY',
										false,
										true,
									)}
								</Text>
							</div>
						</div>
						<Text
							size={'mini-1'}
							weight='light'
							color='light'
							sx='mt-2'
						>
							Periodo
						</Text>
						<Text
							size={isMobile ? 'body-3' : 'body-1'}
							weight='medium'
							truncate
						>
							{getMonthName(
								Number(retentionInfo?.retention?.period_from?.split('-')?.[1]),
								true,
							)}

							{` ${retentionInfo?.retention?.period_from?.split('-')?.[0]}`}
						</Text>
						<Text
							size={'mini-1'}
							weight='light'
							color='light'
							truncate
							sx='mt-3'
						>
							Monto Total
						</Text>
						<Heading
							size={isMobile ? 'xs' : 'm'}
							weight='bold'
						>
							{formatCurrency(
								retentionInfo?.retention?.total_operation_amount || 0,
							)}
						</Heading>
						<hr className='mt-3' />
						<div className='flex flex-col gap-2 mt-3'>
							{getPropertiesByType()}
						</div>
					</div>
					<div>
						<hr className='my-3' />
						{retentionInfo?.retention?.xml_url &&
							platformOS !== PlatformOS.IOS && (
								<Button
									label='Descargar el certificado (XML)'
									onClick={() =>
										handleDownload(retentionInfo?.retention?.xml_url)
									}
								/>
							)}
					</div>
				</div>
			</>
		),
	};

	return (
		<SideToModalContainer
			isOpen={!!selectedDocument[documentType]}
			handleClose={handleClose}
		>
			<div className='flex justify-between items-center'>
				<Text
					size={isMobile ? 'label' : 'body-1'}
					weight='bold'
					truncate
				>
					{detailTitle[documentType]}
				</Text>
				<XCircle
					onClick={handleClose}
					color='var(--h-primary)'
					weight='fill'
					width={24}
					height={24}
					className='cursor-pointer'
				/>
			</div>
			<hr className='my-3' />
			<div className='max-h-[86dvh]'>{detailContent[documentType]}</div>
		</SideToModalContainer>
	);
};

export default FiscalDocumentDetail;
