import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ModalServiceOptions from '../shopping/components/molecules/ModalServiceOptions';
import {
	ArrowsClockwise,
	CheckCircle,
	CopySimple,
	Pencil,
	WarningDiamond,
} from '@phosphor-icons/react';
import Text from '../../components/atoms/Text';
import {
	SubscriptionStatus,
	SubscriptionType,
} from '../../@types/Subscription';
import {
	formatPrice,
	getFailuresText,
	getRecurrency,
} from '../../shared/utils/general.util';
import CustomIcon from '../../components/atoms/CustomIcon';
import { getFormatDate } from '../../shared/utils/dates.util';
import Divider from '../../components/atoms/Divider';
import Tag from '../../components/molecules/Tag';
import ModalSelectCard from '../shopping/components/organisms/ModalSelectCard';
import {
	createNewCustomer,
	getClientCustomer,
} from '../../services/payments.service';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { postRenewSubscription } from '../../services/suscription.service';
import { getOrderFailed } from '../../services/failed.service';
import { getPendingOrderDetail } from '../../services/orders.service';
import { PendingDetail } from '../../@types/Pending';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { showAlert } from '../../redux/slices/alert.slice';
import { getBrandIcon } from '../../shared/utils/payments.util';
import ModalReference from '../pendingReferences/components/ModalReference';
import Button from '../../components/atoms/Button';
interface SubCardProps {
	item: SubscriptionType;
	fetchUserSubscriptions: () => void;
}

const SubCard: React.FC<SubCardProps> = ({ item, fetchUserSubscriptions }) => {
	const [showModal, setShowModal] = useState({
		churn: false,
		history: false,
	});

	const dispatch = useDispatch();

	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const [showModalSelectCard, setShowModalSelectCard] = useState(false);
	const [customerStripeId, setCustomerStripeId] = useState('');
	const [failedOrder, setFailedOrder] = useState([]);
	const [pendingSub, setPendingSub] = useState<PendingDetail>();
	const [showModalReference, setShowModalReference] = useState(false);
	const [isLoadingReactivate, setIsLoadingReactivate] = useState(false);

	const getCustomer = useCallback(async () => {
		try {
			const response = await getClientCustomer(tokenSession);
			if (response) {
				setCustomerStripeId(response[0]?.id ?? '');
			}
		} catch (error: any) {
			if (error?.response?.status === 404) {
				await createNewCustomer(tokenSession);
				return;
			}
		}
	}, []);

	const fetchOrderFailed = useCallback(async () => {
		try {
			const resp = await getOrderFailed(tokenSession);
			setFailedOrder(resp);
		} catch (error) {}
	}, []);

	const getPendingSubscriptionDetail = async () => {
		try {
			const order = await getPendingOrderDetail(
				item?.last_transaction?.shopping_cart_id!,
				tokenSession,
			);

			setPendingSub(order);
		} catch (error) {}
	};

	const onOpenModalPayment = () => {
		setShowModalSelectCard(true);
	};

	const onPressReference = () => {
		setShowModalReference(true);
	};

	const renderPaymentMethod = (paymentType: string) => {
		if (paymentType) {
			if (paymentType === 'card') {
				return (
					<div
						onClick={onOpenModalPayment}
						className='flex items-center justify-between'
					>
						<img
							src={getBrandIcon(item?.payment_details?.brand)}
							alt='brand'
							className='w-[32px] h-[32px] ml-2'
						/>
						<div className='flex flex-col ml-4'>
							<Text
								size='s'
								color='dark'
							>
								•••• •••• •••• {item?.payment_details?.last_four}
							</Text>
							<Text
								size='xs'
								color='dark'
								sx='justify-end'
							>
								{item?.payment_details?.expires}
							</Text>
						</div>

						<button
							onClick={onOpenModalPayment}
							className='hidden md:block'
						>
							<Pencil
								size={16}
								weight='light'
								className='ml-5'
							/>
						</button>
					</div>
				);
			} else if (paymentType === 'cash') {
				return (
					<div className='flex items-center justify-between'>
						<CustomIcon
							sx='ml-0'
							color={'var(--h-primary)'}
							name={'oxxo'}
							width={52}
						/>
						<Text
							size='s'
							color='dark'
							sx='ml-2'
						>
							Efectivo
						</Text>
					</div>
				);
			} else if (paymentType === 'spei') {
				return (
					<div className='flex items-center justify-between'>
						<CustomIcon
							sx='ml-0'
							color={'var(--h-primary)'}
							name={'spei'}
							width={52}
						/>
						<Text
							size='s'
							color='dark'
							sx='ml-2'
						>
							Transferencia
						</Text>
					</div>
				);
			}
		} else {
			if (item?.last_transaction) {
				if (
					item?.last_transaction?.platform_transaction_id === 'without_payment'
				) {
					return (
						<div className='flex items-center justify-between'>
							<CustomIcon
								sx='ml-0'
								color={'var(--h-primary)'}
								name={'oxxo'}
								width={52}
							/>
							<Text
								size='s'
								color='dark'
								sx='ml-2'
							>
								Traditional cash
							</Text>
						</div>
					);
				}
			}
		}
	};

	const onReactiveRenovation = async () => {
		try {
			setIsLoadingReactivate(true);
			await postRenewSubscription(item?.id, tokenSession);
			fetchUserSubscriptions();
		} catch (error) {
		} finally {
			setIsLoadingReactivate(false);
		}
	};

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'Éxito',
				description: 'Texto copiado al portapapeles',
			};
			dispatch(showAlert(alert));
		});
	};

	const renderActions = () => {
		return (
			<>
				{item?.payment_status === SubscriptionStatus.failed && (
					<button
						onClick={onOpenModalPayment}
						className='flex items-center bg-h-error-90 my-4 rounded-md p-2 '
					>
						<div>
							<WarningDiamond
								size={24}
								weight='fill'
								color='var(--h-error-50)'
							/>
						</div>
						<Text
							size='s'
							color='white'
							sx='ml-2 mr-1 !text-h-error-50 !block !text-left'
						>
							{getFailuresText(item?.failure_reason)}
							<span className='font-bold underline'>
								editar tu método de pago aquí para poder realizar el cobro.{' '}
							</span>
						</Text>
					</button>
				)}

				{!item?.renew_at_period_end &&
					item?.payment_status !== SubscriptionStatus.failed && (
						<>
							<div className='flex flex-col md:flex-row items-center bg-h-success-95 rounded-md px-4 py-3 justify-between'>
								<div className='flex flex-col'>
									<Text
										size='s'
										color='darkMenu'
										sx='ml-2 mr-1'
									>
										{`Perderás los beneficios de tu suscripción el ${getFormatDate(
											item?.current_period_end,
										)}.`}
									</Text>
								</div>
								<div>
									{isLoadingReactivate ? (
										<div className='flex'>
											<CircularProgress
												size={18}
												color='success'
											/>
										</div>
									) : (
										<button
											onClick={() => {
												onReactiveRenovation();
											}}
											className='flex items-center bg-h-green-50 py-2 px-8 rounded-md justify-center mt-3 md:mt-0'
										>
											<ArrowsClockwise
												size={25}
												weight='fill'
												color='var(--h-gray-10)'
											/>
											<Text
												size='s'
												color='white'
												sx='ml-2.5'
											>
												Habilitar renovación automática
											</Text>
										</button>
									)}
								</div>
							</div>
						</>
					)}

				{item?.payment_status === 'pending' && (
					<div className='flex flex-col md:flex-row  md:items-center bg-blue-50 rounded-md px-4 py-3 justify-between mt-1 md:mt-3'>
						<div className='flex flex-col'>
							<Text
								size='s'
								color='darkMenu'
								sx='ml-2 mr-1'
							>
								{`Copia la referencia adjunta y transfiere a través de ${
									item?.payment_type === 'cash' ? 'OXXO' : 'SPEI'
								} de antes del ${getFormatDate(pendingSub?.expires_at!)}`}
							</Text>

							<div className='flex flex-col md:flex-row md:items-center md:justify-normal md:mt-2'>
								<button
									className='flex flex-row gap-x-2 items-center mt-3 md:mt-0 mb-3 md:mb-0'
									onClick={() =>
										copyToClipboard(pendingSub?.payment_reference_code!)
									}
								>
									<CopySimple
										size={18}
										color='var(--h-primary)'
										weight='fill'
									/>
									<div className='w-3/4'>
										<Text
											color='primary'
											size='s'
										>
											{item?.payment_type === 'cash' ? 'Referencia' : 'CLABE'}
										</Text>
										<Text
											color='primary'
											size='s'
											weight='bold'
										>
											{pendingSub?.payment_reference_code}
										</Text>
									</div>
								</button>
								<div className='ml-6'>
									<Text
										size='s'
										color='dark'
									>
										Valor a pagar
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										${formatPrice(pendingSub?.total!)}
									</Text>
								</div>
							</div>
						</div>
						<div>
							<button
								className='bg-h-primary flex items-center rounded-md py-3 md:py-2 px-4 md:px-2 w-full justify-center mt-3 md:mt-0'
								onClick={onPressReference}
							>
								<Text
									size='s'
									color='white'
								>
									Ver instrucciones de pago
								</Text>
							</button>
						</div>
					</div>
				)}
			</>
		);
	};

	useEffect(() => {
		getCustomer();
		fetchOrderFailed();
	}, []);

	useEffect(() => {
		if (item?.payment_status === 'pending') {
			getPendingSubscriptionDetail();
		}
	}, []);

	const benefits =
		// @ts-ignore
		item?.product_version?.product?.benefits?.benefits?.map(
			(benefit: { description: string }) => benefit.description,
		) || item?.product_version?.product?.benefits;

	return (
		<>
			<ModalReference
				paymentMethod={pendingSub?.payment_method_name ?? ''}
				showModal={showModalReference}
				setShowModal={setShowModalReference}
				reference={pendingSub?.payment_reference_code ?? ''}
				total={pendingSub?.total ?? 0}
			/>
			<ModalSelectCard
				order={failedOrder[0]}
				showModal={showModalSelectCard}
				setShowModal={setShowModalSelectCard}
				activeSubscription={item}
				customerStripeId={customerStripeId}
				fetchUserSuscriptions={fetchUserSubscriptions}
			/>
			{item?.active ? (
				<div
					key={`active-sub-${item.id}`}
					className={`'flex flex-col border-2 ${item?.payment_status === SubscriptionStatus.failed ? 'border-h-error-50' : 'border-h-gray-20'} px-5 py-4 rounded-md relative bg-white w-full mb-4`}
				>
					<ModalServiceOptions
						showModal={showModal}
						setShowModal={setShowModal}
						subscription={item}
						fetchUserSuscriptions={fetchUserSubscriptions}
					/>

					<div className='block md:hidden'>{renderActions()}</div>

					{item?.payment_status !== SubscriptionStatus.failed && (
						<div className='absolute top-4 left-4'>
							<Tag
								label='Activa'
								caseVariant='success'
								type='filled'
							/>
						</div>
					)}

					<div className='flex flex-col w-full'>
						<div className='flex flex-col items-center mt-8 md:mt-0'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								{item?.product_version?.product?.name}
							</Text>
						</div>
						<div className='flex flex-col md:flex-row items-center justify-center mt-1'>
							{item?.product_version?.product?.regime &&
								item?.product_version?.product?.regime?.map((regime, index) => (
									<Text
										size='s'
										color='dark'
										key={index}
										sx='mr-1'
									>
										{item?.product_version?.product?.regime &&
										index === item?.product_version.product?.regime.length - 1
											? regime
											: `${regime},`}
									</Text>
								))}
						</div>
						<div className='flex flex-col md:flex-row items-center justify-between mt-4'>
							<div className='flex flex-col mt-1 w-full md:order-2'>
								{benefits
									?.map((benefit: string, index: number) => (
										<div
											key={`benefit-${index}`}
											className='flex gap-1.5 mb-4 ml-8'
										>
											<CheckCircle
												size={16}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='medium'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))
									.slice(0, 3)}
							</div>

							<div className='mt-3 mb-2 w-full md:hidden'>
								<Divider />
							</div>

							<div className='flex flex-col w-full md:order-1 md:border-r md:pr-5 md:mt-2'>
								{/* TODO! se descomentara cuando se tenga e cambio */}
								{/* <div className='flex items-center justify-between mb-5 md:mb-3'>
									<Text
										size='s'
										color='dark'
									>
										{item?.renew_at_period_end
											? 'Valor a pagar'
											: 'Último valor pagado'}
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										$
										{item?.renew_at_period_end
											? formatPrice(item?.product_version?.price)
											: formatPrice(item?.last_transaction?.amount)}
									</Text>
								</div> */}

								<div className='flex items-center justify-between mb-5 md:mb-3'>
									<Text
										size='s'
										color='dark'
									>
										{item?.renew_at_period_end
											? 'Fecha de tu proxima renovación'
											: 'Fecha de tu última renovación'}
									</Text>
									<Text
										size='s'
										weight='bold'
										color='dark'
										sx='text-right'
									>
										{item?.renew_at_period_end
											? getFormatDate(item?.current_period_end)
											: getFormatDate(item?.last_transaction?.created_at)}
										{}
									</Text>
								</div>

								{item?.renew_at_period_end && (
									<div className='flex items-center justify-between mb-5 md:mb-3'>
										<Text
											size='s'
											color='dark'
										>
											Método de pago
										</Text>
										{renderPaymentMethod(item?.payment_type!)}
									</div>
								)}
								<div className='flex items-center justify-between mb-5 md:mb-3'>
									<Text
										size='s'
										color='dark'
									>
										Periodicidad
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										{getRecurrency(item?.product_version?.periodicity)}
									</Text>
								</div>
							</div>
						</div>
						<div className='hidden md:block'>{renderActions()}</div>
						<div className='block md:hidden'>
							<Button
								variant='text'
								label='Editar metodo de pago'
								onClick={onOpenModalPayment}
								beforeIcon={
									<Pencil
										size={16}
										weight='light'
										color='var(--h-primary-50)'
									/>
								}
								labelColor='blueLight'
								sx='!justify-center !items-center w-full  font-medium'
							/>
						</div>
					</div>
				</div>
			) : (
				<div
					key={`active-sub-${item.id}`}
					className='flex flex-col border-2 border-h-gray-20 p-6 rounded-md relative bg-white w-full mb-4'
				>
					<ModalServiceOptions
						showModal={showModal}
						setShowModal={setShowModal}
						subscription={item}
						fetchUserSuscriptions={fetchUserSubscriptions}
					/>

					<div className='absolute top-2 md:top-4 left-3 md:left-4'>
						<Tag
							label='Cancelada'
							caseVariant='error'
							type='filled'
						/>
					</div>

					<div className='flex flex-col w-full'>
						<div className='flex flex-col items-center mt-4 md:mt-0'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								{item?.product_version?.product?.name}
							</Text>
						</div>
						<div className='flex items-center justify-center mt-1'>
							{item?.product_version?.product?.regime &&
								item?.product_version?.product?.regime?.map((regime, index) => (
									<Text
										size='s'
										color='dark'
										key={index}
										sx='mr-1'
									>
										{item?.product_version?.product?.regime &&
										index === item?.product_version?.product?.regime?.length - 1
											? regime
											: `${regime},`}
									</Text>
								))}
						</div>
						<div className='flex flex-col md:flex-row items-center justify-between mt-4'>
							<div className='flex flex-col mt-1 w-full items-center md:items-start md:order-2'>
								{benefits
									?.map((benefit: string, index: number) => (
										<div
											key={`benefit-${index}`}
											className='flex gap-1.5 mb-4 ml-8'
										>
											<CheckCircle
												size={16}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='medium'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))
									.slice(0, 3)}
							</div>

							<div className='mt-3 mb-2 w-full md:hidden'>
								<Divider />
							</div>

							<div className='flex flex-col w-full md:order-1 md:border-r md:pr-5'>
								<div className='flex items-center justify-between mb-5 md:mb-3'>
									<Text
										size='s'
										color='dark'
									>
										Valor de la suscripción
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										${formatPrice(item?.product_version?.price)}
									</Text>
								</div>

								<div className='flex items-center justify-between mb-5 md:mb-3'>
									<Text
										size='s'
										color='dark'
									>
										Fecha
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										{getFormatDate(item?.current_period_start)} -{' '}
										{getFormatDate(item?.current_period_end)}
									</Text>
								</div>

								<div className='flex items-center justify-between mb-5 md:mb-3'>
									<Text
										size='s'
										color='dark'
									>
										Periodicidad
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										{getRecurrency(item?.product_version?.periodicity)}
									</Text>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(SubCard);
