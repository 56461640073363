import HERU_API from '../shared/constants/heruApi';
import { get, patch, post } from '../shared/utils/FetchSSL.util';

const fetchAllOrganizations = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_ORGANIZATIONS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const postAssignOrganization = async (
	org: number,
	userId: number,
	body: any,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			HERU_API.HERU_API_ORGANIZATION_LINK_ACCOUNT(org, userId),
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getUberLogin = async () => {
	try {
		const response = await get(HERU_API.HERU_API_UBER_LOGIN, {
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getUberLoginConnect = async (code: string, state: string) => {
	const redirectUri = 'https://web.heru.app/cart';
	try {
		const response = await get(
			HERU_API.HERU_API_UBER_LOGIN_CONNECT(code, state, redirectUri),
			{ isNewGateway: true },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const patchUber = async (org_id: any, tokenSession?: string) => {
	const request = {};
	try {
		const response = await patch(
			HERU_API.HERU_API_ORGANIZATION_TIER(org_id),
			request,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postOrganizationCredentials = async (
	body: any,
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.HERU_API_AUTH_ORGANIZATION, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	fetchAllOrganizations,
	postAssignOrganization,
	getUberLogin,
	getUberLoginConnect,
	patchUber,
	postOrganizationCredentials,
};
