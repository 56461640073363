import { createSlice } from '@reduxjs/toolkit';
import {
	PlanRegularizationPeriod,
	PlansProduct,
} from '../../pages/plansAndPrices';

type PlanConfig = {
	paymentFrecuency?: number;
	regimes?: string[];
	annualYears?: number[];
	regimeConfig?: {
		lease: string;
	};
	step: number;
	regularizationPeriods?: PlanRegularizationPeriod[];
};

interface PlansPricesState {
	selectedInvoiceCategory: string;
	planConfig: PlanConfig;
	selectedProduct: PlansProduct | null;
	isYearly: boolean;
	showModalCheckout: boolean;
	showModalAnalysis: boolean;
	haveLegacySubscription: boolean;
	showModalCancelPendingOrder: boolean;
}

const initialState: PlansPricesState = {
	selectedInvoiceCategory: 'monthly_declarations',
	planConfig: {
		step: 1,
	},
	selectedProduct: null,
	isYearly: true,
	showModalCheckout: false,
	showModalAnalysis: false,
	showModalCancelPendingOrder: false,
	haveLegacySubscription: false,
};

export const plansPricesSlice = createSlice({
	name: 'plans',
	initialState,
	reducers: {
		setSelectedInvoiceCategory: (state, action) => {
			state.selectedInvoiceCategory = action.payload;
		},
		setPlanConfig: (state, action) => {
			state.planConfig = action.payload;
		},
		setSelectedProduct: (state, action) => {
			state.selectedProduct = action.payload;
		},
		resetPlans: (state) => {
			state.selectedInvoiceCategory = initialState.selectedInvoiceCategory;
			state.planConfig = initialState.planConfig;
			state.selectedProduct = initialState.selectedProduct;
		},
		setIsYearly: (state, action) => {
			state.isYearly = action.payload;
		},
		setShowModalCheckout: (state, action) => {
			state.showModalCheckout = action.payload;
		},
		setShowModalAnalysis: (state, action) => {
			state.showModalAnalysis = action.payload;
		},
		setHaveLegacySubscription: (state, action) => {
			state.haveLegacySubscription = action.payload;
		},
		setShowModalCancelPendingOrder: (state, action) => {
			state.showModalCancelPendingOrder = action.payload;
		},
	},
});

export const {
	setSelectedInvoiceCategory,
	setPlanConfig,
	setSelectedProduct,
	resetPlans,
	setIsYearly,
	setShowModalCheckout,
	setShowModalAnalysis,
	setHaveLegacySubscription,
	setShowModalCancelPendingOrder,
} = plansPricesSlice.actions;

export default plansPricesSlice.reducer;
