import { XCircle } from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';

const ModalHeader = ({
	title,
	description,
	handleClose,
}: {
	title: string;
	description: string;
	handleClose: () => void;
}) => {
	return (
		<div className='p-4 relative'>
			<Text
				color='dark'
				weight='bold'
			>
				{title}
			</Text>
			<Text
				color='dark'
				weight='light'
				size='caption'
				sx='opacity-70'
			>
				{description}
			</Text>

			<XCircle
				className='absolute top-2 right-2 cursor-pointer text-h-neutral-70'
				size={24}
				weight='fill'
				onClick={handleClose}
			/>
		</div>
	);
};

export default ModalHeader;
