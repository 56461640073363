import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/molecules/Modal/Modal';
import OnboardingAnalysis from '../../intro/organisms/OnboardingAnalysis';
import { RootState } from '../../../redux/store';
import { setShowModalAnalysis } from '../../../redux/slices/plans.slice';
import { getOnboardingIA } from '../../../services/heru-ai.service';
import { IOnboardingResponseSamantha } from '../../../@types/IA.type';
import { useEffect, useState } from 'react';

const ModalAnalysis = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState({
		lastOnboardingProgress: false,
	});
	const [lastOnboardingProgress, setLastOnboardingProgress] =
		useState<IOnboardingResponseSamantha | null>(null);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { samanthaAnalysis } = useSelector((state: RootState) => state.ai);
	const { showModalAnalysis } = useSelector((state: RootState) => state.plans);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const getOnboardingIAProgress = async (userId: number) => {
		try {
			setIsLoading({ ...isLoading, lastOnboardingProgress: true });
			const response: IOnboardingResponseSamantha = await getOnboardingIA(
				userId,
				tokenSession,
			);
			setLastOnboardingProgress(response);
			return response;
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, lastOnboardingProgress: false });
		}
	};

	useEffect(() => {
		if (userProfile?.id) getOnboardingIAProgress(userProfile?.id);
	}, [userProfile?.id]);

	return (
		<Modal
			open={showModalAnalysis}
			onClose={() => dispatch(setShowModalAnalysis(false))}
			showCloseButton={false}
			sx='!p-0 rounded-lg overflow-hidden w-[95%] max-w-[700px] max-h-[94dvh] overflow-y-auto'
		>
			<div>
				<OnboardingAnalysis
					userName={
						userProfile?.first_name
							? `${userProfile?.first_name} ${userProfile?.father_surname}`
							: 'Cliente'
					}
					rfc={lastOnboardingProgress?.userCurrentData?.rfc || ''}
					economicActivities={
						lastOnboardingProgress?.userCurrentData
								?.economic_activities_detail || ''
					}
					analysis={samanthaAnalysis}
					onClose={() => dispatch(setShowModalAnalysis(false))}
					nextStep={() => dispatch(setShowModalAnalysis(false))}
					page='plans'
				/>
			</div>
		</Modal>
	);
};

export default ModalAnalysis;
