import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import Modal from '../../molecules/Modal/Modal';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserProfile,
	patchUserProfile,
} from '../../../services/profile.service';
import { setProfile } from '../../../redux/slices/profile.slice';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../molecules/Alert';
import { useEffect, useState } from 'react';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { useStorage } from '../../../shared/utils/general.util';

interface Props {
	isOpen: boolean;
}

interface FormInputs {
	first_name: string;
	email: string;
}

const MissingProfileInfoModal = ({ isOpen }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormInputs>({
		defaultValues: {
			first_name: userProfile?.first_name || '',
			email: userProfile?.email || '',
		},
	});
	const tokenSession = useSelector(
		(state: RootState) => state.auth.tokenSession,
	);

	const onSubmit = async (data: FormInputs) => {
		const body: Record<string, string> = {};
		if (!userProfile?.first_name) {
			body.first_name = data.first_name;
		}
		if (!userProfile?.email) {
			body.email = data.email;
		}
		setIsLoading(true);
		try {
			await patchUserProfile(userProfile?.id!, body, tokenSession);
			const userInfo = await getUserProfile(tokenSession);
			
			useStorage().setItem(LOCAL_STORAGE_KEY.USER_NAME, userInfo.first_name);
			dispatch(
				showAlert({
					type: ALERT_TYPE.SUCCESS,
					title: 'Éxito',
					description: 'Perfil completado correctamente',
				}),
			);
			dispatch(setProfile(userInfo));
		} catch (error: any) {
			if (
				error?.response?.data?.message?.includes(
					'El correo electrónico ya está registrado',
				)
			) {
				dispatch(
					showAlert({
						type: ALERT_TYPE.ERROR,
						title: 'Error',
						description: 'El correo electrónico ya está registrado',
					}),
				);
				return;
			}
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error',
					description:
						'Error al completar el perfil, intente nuevamente más tarde',
				}),
			);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (userProfile) {
			reset({
				first_name: userProfile.first_name || '',
				email: userProfile.email || '',
			});
		}
	}, [userProfile]);

	return (
		<Modal
			showCloseButton={false}
			open={isOpen}
			onClose={() => {}}
			sx='overflow-y-auto w-[90%] lg:w-fit-content !p-0'
		>
			<div className='flex flex-col lg:flex-row'>
				<img
					src='https://heru-static-assets.s3.us-east-2.amazonaws.com/Mask-group.webp'
					alt='hombre sonriendo'
					className='w-[300px] object-cover hidden lg:block'
				/>
				<img
					src='https://heru-static-assets.s3.us-east-2.amazonaws.com/mobile.webp'
					alt='hombre sonriendo'
					className='block lg:hidden'
				/>

				<div className='px-4 flex flex-col gap-4 lg:gap-y-8 lg:mx-10 mt-5 p-4'>
					<Text
						sx='!block text-left lg:text-xl'
						size='body-3'
						weight='bold'
					>
						¡Completa tu perfil!
					</Text>

					<Text sx='!block'>
						Necesitamos tu {!userProfile?.first_name && <strong>nombre</strong>}
						{`${!userProfile?.first_name && !userProfile?.email ? ' y ' : ''}`}
						{!userProfile?.email && <strong>correo electrónico</strong>} para
						mejorar tu experiencia. Completa esta información ahora.
					</Text>

					<form
						onSubmit={handleSubmit(onSubmit)}
						className='w-full'
					>
						{!userProfile?.first_name && (
							<TextField
								{...register('first_name', {
									required: 'El nombre es requerido',
								})}
								label='Nombre'
								fullWidth
								margin='normal'
								error={!!errors.first_name}
								helperText={errors.first_name?.message}
							/>
						)}

						{!userProfile?.email && (
							<TextField
								{...register('email', {
									required: 'El correo es requerido',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Correo electrónico inválido',
									},
								})}
								label='Correo electrónico'
								fullWidth
								margin='normal'
								error={!!errors.email}
								helperText={errors.email?.message}
							/>
						)}

						<Button
							type='submit'
							label='Completar mi perfil'
							sx='mb-5 mt-2 lg:mt-4 w-full'
							loading={isLoading}
						/>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default MissingProfileInfoModal;
