import HERU_API from '../shared/constants/heruApi';
import { getCustom, patch } from '../shared/utils/FetchSSL.util';

const getLastShoppingCartWithDetail = async (tokenSession?: string) => {
	try {
		const response = await getCustom(HERU_API.HERU_API_SHOPPING_CART_LAST, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const changeShoppingCartStatus = async ({
	shoppingCartId,
	status,
	tokenSession,
}: {
	shoppingCartId: number;
	status: 'open' | 'closed';
	tokenSession?: string;
}) => {
	try {
		const response = await patch(
			`${HERU_API.SHOPPING_CARTS}/${shoppingCartId}`,
			{ status },
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);

		return response;
	} catch (error) {
		throw error;
	}
};

export { getLastShoppingCartWithDetail, changeShoppingCartStatus };
