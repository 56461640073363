import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../atoms/Button';
type Props = {
	items: Array<{ label: string; slug: string }>;
	onClickTab: (tabSelected: string) => void;
	sx?: string;
	activeTabDefault?: string;
	sizes?: 'small' | 'large';
	disabled?: boolean;
	isDark?: boolean;
};

const Tabs: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	items,
	sx = '',
	onClickTab,
	activeTabDefault,
	sizes = 'large',
	disabled = false,
	isDark = false,
}) => {
	const [activeTab, setActiveTab] = useState(items[0].slug);

	const onSelectedTab = (tabSelected: string) => {
		if (disabled) return;
		setActiveTab(tabSelected);
		onClickTab(tabSelected);
	};

	const getTabClass = useMemo(
		() => (tabSelected: string) =>
			activeTab === tabSelected
				? `!rounded-[30px] ${isDark ? '!text-h-dark-menu !bg-h-primary-80' : ''}`
				: `bg-transparent  ${isDark ? '!text-h-text-light' : '!text-h-gray-100'} !shadow-none '`,
		[activeTab, isDark],
	);

	const getTabSizeClass = useMemo(
		() => () => (sizes === 'small' ? '!h-fit  py-1 px-4 ' : ''),
		[sizes],
	);

	useEffect(() => {
		setActiveTab(activeTabDefault || items[0].slug);
	}, [activeTabDefault]);

	return (
		<div
			className={`bg-white flex flex-row rounded-2xl shadow-[0_1px_6px_-1px_rgba(0,0,0,0.2)] mx-4 gap-x-3 py-2 px-4 overflow-x-auto ${sx}`}
		>
			{items.map((item) => (
				<Button
					key={`tab-${item.slug}`}
					label={item.label}
					onClick={() => onSelectedTab(item.slug)}
					sx={`${getTabClass(item.slug)} ${getTabSizeClass()} flex-1`}
					variant='secondary'
				/>
			))}
		</div>
	);
};

export default React.memo(Tabs);
