import { AddressElement } from '@stripe/react-stripe-js';

const AddressForm = () => {
	return (
		<form className='my-3'>
			<AddressElement
				options={{
					mode: 'billing',
					defaultValues: { address: { country: 'MX' } },
				}}
			/>
		</form>
	);
};

export default AddressForm;
