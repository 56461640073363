import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth.slice';
import { profileSlice } from './slices/profile.slice';
import { satStatusLinkSlice } from './slices/sat.slice';
import { isDesktopSlice } from './slices/dimensions.slice';
import { alertSlice } from './slices/alert.slice';
import { taxProfileSlice } from './slices/taxProfile.slice';
import { cartSlice } from './slices/cart.slice';
import { productsSlice } from './slices/products.slice';
import { taxCalendarSlice } from './slices/taxCalendar.slice';
import { expandedSlice } from './slices/expanded.slice';
import { rappiSlice } from './slices/rappi.slice';
import { invocingSlice } from './slices/invoicing';
import { plansPricesSlice } from './slices/plans.slice';
import { configSlice } from './slices/config.slice';
import { aiSlice } from './slices/ai.slice';

export const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		profile: profileSlice.reducer,
		satLinkStatus: satStatusLinkSlice.reducer,
		isDesktop: isDesktopSlice.reducer,
		alert: alertSlice.reducer,
		taxProfile: taxProfileSlice.reducer,
		cart: cartSlice.reducer,
		productList: productsSlice.reducer,
		taxCalendar: taxCalendarSlice.reducer,
		expanded: expandedSlice.reducer,
		rappi: rappiSlice.reducer,
		invocing: invocingSlice.reducer,
		plans: plansPricesSlice.reducer,
		config: configSlice.reducer,
		ai: aiSlice.reducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
