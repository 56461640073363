import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../components/atoms/Text';
import { RootState } from '../../../redux/store';
import { setIsYearly } from '../../../redux/slices/plans.slice';

const TogglePeriodicity = ({
	savingsPercentage,
	className,
}: {
	savingsPercentage?: number;
	className?: string;
}) => {
	const dispatch = useDispatch();
	const { isYearly } = useSelector((state: RootState) => state.plans);

	return (
		<div className={`flex items-center gap-2 ${className}`}>
			<Text
				size='body-3'
				weight='light'
				color='light'
			>
				Mensual
			</Text>
			<label className='inline-flex items-center cursor-pointer'>
				<input
					type='checkbox'
					value=''
					className='sr-only peer'
					checked={isYearly}
					onChange={() => dispatch(setIsYearly(!isYearly))}
				/>
				<div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-h-primary"></div>
			</label>
			<Text
				size='body-3'
				weight='medium'
				color='dark'
			>
				Anual -{savingsPercentage || 25}% OFF
			</Text>
		</div>
	);
};

export default TogglePeriodicity;
