import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	IOnboardingResponseSamantha,
	OnboardingAnalysis,
} from '../../@types/IA.type';

export interface AIState {
	onboarding: IOnboardingResponseSamantha | null;
	samanthaAnalysis: OnboardingAnalysis | null;
}

const initialState: AIState = {
	onboarding: null,
	samanthaAnalysis: null,
};

export const aiSlice = createSlice({
	name: 'AI',
	initialState,
	reducers: {
		setSamanthaAnalysis: (state, action: PayloadAction<any>) => {
			state.samanthaAnalysis = action.payload;
		},
		setOnboarding: (state, action: PayloadAction<any>) => {
			state.onboarding = action.payload;
		},
	},
});

export const { setSamanthaAnalysis, setOnboarding } = aiSlice.actions;
