import { useEffect, useState } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { Product } from '../../@types/Products.type';
import ProductCard from './organisms/ProductCard';
import ModalAnalysis from './organisms/ModalAnalysis';

type Props = {
	setShowRecommendedPlan: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecommendedPlanSection: React.FC<Props> = ({
	setShowRecommendedPlan,
}) => {
	const { products } = useSelector((state: RootState) => state.productList);
	const { samanthaAnalysis } = useSelector((state: RootState) => state.ai);
	const [recommendedPlan, setRecommendedPlan] = useState<Product | null>(null);

	useEffect(() => {
		const subscriptionProductsFiltered = products?.find(
			//@ts-ignore
			(item) => item?.name === 'Planes',
		)?.products;

		const recommendedPlanFind = subscriptionProductsFiltered?.find(
			(product) => product.slug === samanthaAnalysis?.slug,
		);

		if (!recommendedPlanFind) return;

		setRecommendedPlan(recommendedPlanFind);
	}, [products]);

	return (
		<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 relative bg-h-neutral-94'>
			<div className='max-w-[500px] mx-auto'>
				<ModalAnalysis />

				<ProductCard
					showPeriodicitySelector
					product={recommendedPlan}
					recommended={true}
					onSkip={() => setShowRecommendedPlan(false)}
				/>
			</div>
		</section>
	);
};

export default RecommendedPlanSection;
