import { useState } from 'react';
import { deleteAllItemCart, postOrders } from '../services/order.service';
import {
	DELETE_IDS,
	ProductVersionItem,
	TUserOrder,
} from '../@types/Order.type';
import { RootState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setOrders, setUserOrder } from '../redux/slices/cart.slice';
import { getOrderRefresh } from '../services/orders.service';
import { PAYMENT_METHOD } from '../@types/Checkout.type';
import { getProductProdByProductVersion } from '../shared/utils/ecommerce.util';

const useDeleteProducts = () => {
	const dispatch = useDispatch();
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { products } = useSelector((state: RootState) => state.productList);
	const [isLoading, setIsLoading] = useState(false);

	const createOrder = async () => {
		try {
			let sourceChannel = 'web';

			const body = {
				status: 'open',
				source_channel: sourceChannel,
			};
			const response = await postOrders(body, tokenSession);
			if (response) {
				dispatch(setOrders([response]));
			}
		} catch (error) {}
	};

	const getOrder = async () => {
		try {
			const params = `?status=open&status=failed&is_renewal=false&payment_method=${PAYMENT_METHOD.CARD}`;

			const orders = await getOrderRefresh(params, tokenSession);

			if (orders.length === 0) {
				createOrder();
			} else {
				const lastOrder = orders.sort((a, b) => b.id - a.id)?.[0];

				const items: ProductVersionItem[] = lastOrder?.shoppingItem.map(
					(item) => {
						const infoProduct = getProductProdByProductVersion(
							products,
							item.productVersionId,
						)!;
						return {
							...item,
							productVersion: {
								product: {
									id: infoProduct.id,
									name: infoProduct.name,
									defaultProductVersionId:
										infoProduct.default_product_version_id,
									description: infoProduct.description,
									isPurchasable: infoProduct.is_purchasable,
									benefits: infoProduct.benefits,
									slug: infoProduct.slug,
									category: infoProduct.category,
									regime: infoProduct.regime,
								},
								price:
									infoProduct.product_versions.find(
										(version) => version.id === item.productVersionId,
									)?.price ?? 0,
								periodicity:
									infoProduct.product_versions.find(
										(version) => version.id === item.productVersionId,
									)?.periodicity ?? infoProduct.periodicity,
							},
						};
					},
				);

				const newOrder: TUserOrder = {
					...lastOrder,
					shoppingItem: items,
				};

				dispatch(setUserOrder([newOrder]));
			}
		} catch (error) {}
	};

	const handleDeleteProducts = async (
		orderId: number,
		shoppingItemIds: DELETE_IDS[],
	) => {
		try {
			setIsLoading(true);
			await deleteAllItemCart(shoppingItemIds, orderId, tokenSession);
			await getOrder();
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	return { isLoading, handleDeleteProducts };
};

export default useDeleteProducts;
