import techBackground from '../../../assets/img/svg/bg-tech-pattern.svg'

const PromotionalBanner = () => {
    return (
        <a
            href='https://noticias.heru.app/en/articles/4001729'
            target='_blank'
            style={{
                backgroundImage: `url(${techBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
            className='pt-0.5 w-full px-3 border-h-primary-60 shadow shadow-primary border-2 block min-h-10 rounded-lg mb-4 gap-1 font-medium text-center text-[16px] md:text-[20px]'>Promo de fin de año <span className='text-h-primary font-bold'>¡Hasta 25% de dto en tus declaraciones atrasadas!</span></a>
    );
};

export default PromotionalBanner;
