import { useEffect, useState } from 'react';

//? Components
import Calendar from './organism/Calendar';
import Heading from '../../components/atoms/Heading';
import PeriodDetail from './organism/PeriodDetail';
import FiscalBanner from './molecules/FiscalBanner';

//? Redux
import FiscalResumeLogic from './organism/FiscalResumeLogic';
import { CaretCircleLeft } from '@phosphor-icons/react';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedPeriod,
	setShowModal,
} from '../../redux/slices/taxCalendar.slice';
import { TPeriodicity } from '../../@types/Calendar.type';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import FiscalDocumentsSection from '../fiscalDocuments/organisms/FiscalDocumentsSection';
import Text from '../../components/atoms/Text';
import AnnualTemporalyDetail from './organism/AnnualTemporalyDetail';
import { REGIMES_CODE } from '../../@types/Regime.type';
import Container from '../../components/atoms/Container';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { UserRegime } from '../../@types/TaxProfile';
import { getSalesPeriodsAdquired } from '../../services/sales.service';

const TaxesAndInvoicesLinked = () => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [periodsAdquired, setPeriodsAdquired] = useState<any>([]);

	const { selectedPeriod, period } = useSelector(
		(state: RootState) => state.taxCalendar,
	);
	const { userRegimes, userHasRetentions } = useSelector(
		(state: RootState) => state.taxProfile,
	);

	const showSelectedPeriodDetail =
		selectedPeriod.month.detail || selectedPeriod.year.selectedYear;

	const showDetailCompletePage =
		selectedPeriod.month.detailByRegimeComplete ||
		selectedPeriod.year.selectedDeclarationComplete;

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_SELECT_PERIODICITY,
			source: period,
		});

		logClevertapEvent(ANALYTICS_EVENTS.TAXBOARD_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_VIEW_DASHBOARD,
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});

		dispatch(
			setShowModal({
				modalName: 'annualPendingMonths',
				show: false,
			}),
		);
		dispatch(
			setSelectedPeriod({
				month: {
					general: null,
					detail: null,
					detailByRegime: null,
					detailByRegimeComplete: null,
				},
				year: {
					currentYear: null,
					selectedYear: null,
					selectedDeclaration: null,
					selectedDeclarationComplete: null,
				},
			}),
		);
	}, []);

	const handleGoBack = () => {
		dispatch(
			setSelectedPeriod({
				year: {
					...selectedPeriod.year,
					detailByRegimeComplete: null,
				},
				month: {
					...selectedPeriod.month,
					detailByRegimeComplete: null,
				},
			}),
		);
	};

	const userIsPlatec = () => {
		const userHasPlatecRegime =
			userRegimes &&
			userRegimes?.some(
				(regime) => regime?.code === REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
			);

		if (!userHasPlatecRegime) {
			return userHasRetentions?.hasRetentions;
		}

		return true;
	};

	const fetchSalesPeriodsAdquired = async () => {
		try {
			const response = await getSalesPeriodsAdquired({
				tokenSession,
				regime: 'PLA-TEC',
				period_type: 'monthly',
			});
			if (!response?.monthly) throw new Error('No hay periodos adquiridos');

			setPeriodsAdquired(response?.monthly);
		} catch (error) {}
	};

	useEffect(() => {
		fetchSalesPeriodsAdquired();
	}, []);

	return (
		<section className='p-3 sm:p-12 flex flex-col gap-4 bg-h-neutral-94 lg:min-h-[calc(100vh-46px)]'>
			<Heading
				size='s'
				weight='bold'
				responsive
			>
				Tablero de impuestos
			</Heading>
			<FiscalBanner />
			{showDetailCompletePage ? (
				<div>
					<div className='mb-3'>
						<button
							onClick={handleGoBack}
							className='flex items-center gap-1'
						>
							<CaretCircleLeft
								size={20}
								weight='fill'
								color='var(--h-primary)'
							/>
							<Text
								size='caption'
								color='medium'
								weight='medium'
							>
								Volver al detalle del periodo
							</Text>
						</button>
					</div>
					<FiscalDocumentsSection
						startUpload={false}
						setStartUpload={() => {}}
					/>
				</div>
			) : userIsPlatec() ? (
				<div>
					<div className='sm:mt-5'>
						<div className='flex flex-col lg:flex-row gap-4'>
							{/* ====== UNCOMMENT WHEN ANNUAL DATA IS AVAILABLE ====== */}
							{/* {showSelectedPeriodDetail ? <PeriodDetail /> : <Calendar />} */}
							{/* <FiscalResumeLogic /> */}

							{showSelectedPeriodDetail ? (
								<PeriodDetail periodsAdquired={periodsAdquired} />
							) : (
								<Calendar periodsAdquired={periodsAdquired} />
							)}
							{period === TPeriodicity.MONTHLY && (
								<FiscalResumeLogic periodsAdquired={periodsAdquired} />
							)}
						</div>
					</div>
				</div>
			) : (
				<Container sx='sm:mt-6'>
					<AnnualTemporalyDetail />
				</Container>
			)}
		</section>
	);
};

export default TaxesAndInvoicesLinked;
