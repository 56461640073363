const URL_PARAMS = {
	PLATFORM_OS: 'platform_os',
	CHECKOUT: 'checkout',
	COUPON: 'coupon',
	FLOW: 'flow',
	FLOW_PERIODICITY: 'flow_periodicity',
	REGIME: 'regime',
};

export default URL_PARAMS;
