import {
	ArrowRight,
	CaretDown,
	CaretUp,
	Check,
	Medal,
} from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import Heading from '../../../components/atoms/Heading';
import { formatCurrency } from '../../../shared/utils/general.util';
import PrimaryButton from '../../../components/atoms/Button';
import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Benefit, Product } from '../../../@types/Products.type';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import useAddToCart from '../../../hooks/useAddToCart';
import TogglePeriodicity from './TogglePeriodicity';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import {
	setShowModalCancelPendingOrder,
	setShowModalCheckout,
} from '../../../redux/slices/plans.slice';
import { sendUserInteractionEvent } from '../../../services/user-interaction.service';
import Modal from '../../../components/molecules/Modal/Modal';
import circleImagePlatform from '../../../assets/img/png/plan-card-orgs.png';
import circleImageEntrepreneur from '../../../assets/img/png/plan-card-entrepreneur.png';
import circleImageBusiness from '../../../assets/img/png/plan-card-business.png';
import { changeSubscription } from '../../../services/suscription.service';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../../components/molecules/Alert';

const tooltipContent: Record<string, string[]> = {
	plan_platform: [
		'Conductores de Uber, Didi, Amazon Flex',
		'Conductores de Mercado Extra',
		'Repartidores de Rappi, Didi Food, Uber Eats, Zubale',
	],
	plan_entrepreneur: [
		'Freelancers',
		'Personas trabajando para empresas extranjeras y que reciben sus ingresos a través de plataformas como Deel',
		'Personas que arriendan apartamentos o casas',
		'Personas que rentan sus propiedades por Airbnb',
		'Comerciantes que venden productos a través de plataformas como Mercado Libre o Shopify',
		'Influencers que generan ingresos gracias a sus marcas en Instagram o YouTube',
	],
	plan_business: [
		'Pequeñas y medianas empresas sin empleados',
		'Personas con ingresos superiores a los $300,000 mensuales',
	],
};

const circleImages: Record<string, string> = {
	plan_platform: circleImagePlatform,
	plan_entrepreneur: circleImageEntrepreneur,
	plan_business: circleImageBusiness,
};

type ProductCardProps = {
	product: Product | null;
	recommended?: boolean;
	className?: string;
	showPeriodicitySelector?: boolean;
	showPriceDescription?: boolean;
	showDescription?: boolean;
	ctaLabel?: string;
	customPrice?: number;
	subtitle?: string;
	limitBenefits?: number;
	onSkip?: () => void | null;
};

const ProductCard: React.FC<ProductCardProps> = ({
	product,
	className,
	recommended = false,
	showPeriodicitySelector = false,
	showPriceDescription = true,
	showDescription = true,
	ctaLabel,
	customPrice,
	subtitle,
	limitBenefits = 4,
	onSkip,
}) => {
	if (!product) return null;

	const dispatch = useDispatch();
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { isYearly, haveLegacySubscription } = useSelector(
		(state: RootState) => state.plans,
	);
	const { activeSuscriptions } = useSelector(
		(state: RootState) => state.profile,
	);
	const { samanthaAnalysis } = useSelector((state: RootState) => state.ai);
	const { refreshOrders } = useSelector((state: RootState) => state.cart);

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const [isLoading, setIsLoading] = useState({
		changeSubscription: false,
	});
	const [showAllBenefits, setShowAllBenefits] = useState(false);
	const [showModalUserWantsUpgrade, setShowModalUserWantsUpgrade] =
		useState(false);
	const [showActivitiesDescription, setShowActivitiesDescription] =
		useState(false);
	const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

	const activeSubscriptionsWithoutAnnual = activeSuscriptions.filter(
		(sub) =>
			sub.product_version?.product?.slug !== 'suscripcion_declaracion_anual',
	);

	const userHaveSubscriptions =
		activeSubscriptionsWithoutAnnual.length > 0 || haveLegacySubscription;

	const benefits = product.benefits.benefits
		? product.benefits.benefits.map((benefit: Benefit) => benefit.description)
		: product.benefits;

	const visibleBenefits = showAllBenefits
		? benefits
		: benefits.slice(0, limitBenefits);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const getProductVersion = (product: Product, periodicity?: number) => {
		const periodicityToUse = periodicity || (isYearly ? 365 : 30);

		return (
			product?.product_versions?.find(
				(productVersion) => productVersion?.periodicity === periodicityToUse,
			) || product?.product_versions?.[0]
		);
	};

	const getSavingsByYear = (product: Product) => {
		const priceYearly = getProductVersion(product, 365).price / 100;
		const priceMonthly = getProductVersion(product, 30).price / 100;

		const priceYearlyPerMonth = priceYearly / 12;
		const savings = Math.abs(priceYearly - priceMonthly * 12);

		const savingsPercentage =
			((priceMonthly - priceYearlyPerMonth) / priceMonthly) * 100;

		return {
			savings: formatCurrency(Math.floor(savings), false),
			savingsPercentage: Math.floor(savingsPercentage) || 25,
		};
	};

	const priceMonthly = getProductVersion(product, 30).price / 100;

	const price = isYearly
		? getProductVersion(product).price / 100 / 12
		: getProductVersion(product).price / 100;

	const handleClickProduct = async (product: Product) => {
		if (!userProfile) return;

		if (userHaveSubscriptions) {
			setSelectedProduct(product);
			setShowModalUserWantsUpgrade(true);
			return;
		}
		const haveSubscriptionPendingOrder =
			refreshOrders.length &&
			refreshOrders
				.find((order) => order.status === 'pending')
				?.shoppingItem?.find((item: any) =>
					[139, 140, 141, 142, 143, 144].includes(item.productVersionId),
				);

		if (haveSubscriptionPendingOrder) {
			dispatch(setShowModalCancelPendingOrder(true));
			return;
		}

		let productVersion;

		try {
			await sendUserInteractionEvent(
				userProfile.id,
				ANALYTICS_EVENTS.PLAN_CTA_CLICKED,
				'web',
				{
					request_type: 'new_user_intent',
					plan_clicked: product?.slug,
					periodicity: isYearly ? 'yearly' : 'monthly',
					source: 'plans_and_prices_v3',
				},
			);
		} catch (error) {}

		if (product?.slug === PRODUCTS_SLUG.ASSISTANCE_ASAT) {
			productVersion = product?.product_versions?.[0];
		} else {
			productVersion = product?.product_versions?.find(
				(productVersion) =>
					productVersion?.periodicity === (isYearly ? 365 : 30),
			);
		}

		const payload = [
			{
				product_version_id: productVersion?.id,
				additional_information: {
					regimes: [],
				},
			},
		];

		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name: product?.name,
		};

		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

		const response = await handleAddCustomProduct({
			payload,
			navigateToCart: false,
			resetOrders: true,
		});
		if (!response) return;

		dispatch(setShowModalCheckout(true));
	};

	const handleChangeSubscription = async () => {
		if (!userProfile || isLoading.changeSubscription) return;
		try {
			setIsLoading({ changeSubscription: true });
			const payload = {
				userId: userProfile?.id!,
				subscriptionId: activeSubscriptionsWithoutAnnual?.[0]?.id,
				currentProductVersionId:
					activeSubscriptionsWithoutAnnual?.[0]?.product_version?.id!,
				newProductVersionId: selectedProduct?.default_product_version_id!,
			};
			const response = await changeSubscription(payload);
			if (!response?.currentProduct) {
				throw new Error('Error al cambiar la suscripción');
			}
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'Solicitud para cambiar la suscripción enviada',
				description: 'Uno de nuestros asesores te apoyará en el cambio.',
			};
			dispatch(showAlert(alert));
			setShowModalUserWantsUpgrade(false);
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Error al hacer la solicitud para cambiar la suscripción',
				description: 'Intenta nuevamente en unos minutos',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ changeSubscription: false });
		}
	};

	const isAlreadyActive = activeSuscriptions?.find(
		(sub) => sub.product_version?.product?.slug === product?.slug,
	)?.active;

	const ctaLabelToUse =
		ctaLabel || haveLegacySubscription
			? 'Actualizar a este plan'
			: `Activar ${product?.name?.toLowerCase()}`;

	const showRecommendedTag = recommended && !userHaveSubscriptions;

	return (
		<div className='w-full'>
			{/* ========= SKIP ========== */}
			{onSkip && (
				<button
					onClick={() => onSkip()}
					className='mb-6 bg-h-neutral-90 rounded-lg text-sm px-4 py-2 shadow border border-h-neutral-80 flex items-center gap-4 text-left font-light justify-center w-full'
				>
					Explorar otros planes
					<ArrowRight
						size={24}
						className='min-w-[24px]'
					/>
				</button>
			)}

			{showPeriodicitySelector && (
				<TogglePeriodicity
					className='my-3 mx-auto justify-center'
					savingsPercentage={25}
				/>
			)}

			<Modal
				open={showModalUserWantsUpgrade}
				onClose={() => {
					setShowModalUserWantsUpgrade(false);
					setSelectedProduct(null);
				}}
				showCloseButton={false}
				sx='rounded-lg overflow-hidden w-[95%] max-w-[400px] !p-4'
			>
				<div className='flex flex-col gap-6'>
					<div>
						<Text
							weight='bold'
							sx='!block !text-center'
						>
							{haveLegacySubscription
								? `Gracias por tu interés en actualizar tu plan actual al ${selectedProduct?.name}`
								: 'Ya cuentas con un plan activo.'}
						</Text>
						{haveLegacySubscription ? (
							<Text sx='!block !text-center mt-2'>
								Al hacer clic en “Confirmar” asignaremos tu solicitud a uno de
								nuestros agentes de soporte para que te ayude con el cambio.
							</Text>
						) : (
							<Text sx='!block !text-center mt-2'>
								Si deseas modificar tu suscripción de{' '}
								<b>
									{
										activeSubscriptionsWithoutAnnual?.[0]?.product_version
											?.product?.name
									}
								</b>{' '}
								a <b>{product?.name}</b>, haz clic en confirmar y uno de
								nuestros asesores te apoyará en el cambio.
							</Text>
						)}
					</div>
					<PrimaryButton
						loading={isLoading.changeSubscription}
						label={haveLegacySubscription ? 'Confirmar' : 'Actualizar'}
						onClick={handleChangeSubscription}
					/>
				</div>
			</Modal>

			{samanthaAnalysis?.slug &&
				samanthaAnalysis?.slug !== 'cant_recommend_plan' && (
					<div
						className={`bg-h-primary flex items-center justify-center gap-2 rounded-t-2xl px-2 py-2 pb-10 -mb-8 ${showRecommendedTag ? 'opacity-100' : 'opacity-0'}`}
					>
						<Medal
							className='text-white'
							weight='fill'
						/>
						<Text
							size='caption'
							color='white'
							weight='medium'
						>
							Este es tu plan recomendado
						</Text>
					</div>
				)}
			<div
				className={`bg-white shadow overflow-hidden relative p-4 rounded-2xl flex flex-col gap-6 justify-start items-start ${className}`}
			>
				{circleImages[product.slug] && (
					<img
						src={circleImages[product.slug]}
						alt={product.slug}
						className='size-32 absolute -top-7 -right-4'
					/>
				)}

				<Heading
					size='xs'
					weight='bold'
					color='dark'
					sx='text-left mt-1 max-w-[100px] '
				>
					{product.name}
				</Heading>

				{subtitle && (
					<Text
						size='caption'
						color='medium'
						weight='light'
						sx='text-center -mt-4'
					>
						{subtitle}
					</Text>
				)}

				<div>
					<Text
						size='caption'
						color='dark'
						sx='mb-2'
					>
						Mensual
					</Text>

					{customPrice ? (
						<Heading
							size='s'
							weight='bold'
							color='dark'
							responsive={isMobile}
							sx='text-center'
						>
							${formatCurrency(Math.floor(customPrice), false)}
						</Heading>
					) : (
						<div className='-mt-3'>
							<div className='flex items-center gap-2 w-fit'>
								<Heading
									size='s'
									weight='bold'
									color='dark'
									responsive={isMobile}
								>
									${formatCurrency(Math.floor(price), false)}
								</Heading>

								{isYearly && (
									<div className='flex items-center gap-2 mt-1 sm:mt-3'>
										<Text
											size='body-3'
											color='medium'
											sx='line-through'
										>
											${formatCurrency(Math.floor(priceMonthly), false)}
										</Text>
										<Text
											size='caption'
											color='primary'
											sx=''
										>
											(- 25%)
										</Text>
									</div>
								)}
							</div>
							{showPriceDescription && (
								<Text
									size='caption'
									color='dark'
									sx={`mt-2 h-[24px] ${isYearly ? '!text-h-success-50' : ''}`}
									weight='light'
								>
									{isYearly
										? `Estás ahorrando $${getSavingsByYear(product).savings} al año.`
										: `Te recomendamos elegir el plan anual y ahorrar $${getSavingsByYear(product).savings}`}
								</Text>
							)}
						</div>
					)}
				</div>
				{showDescription && (
					<Text
						color='dark'
						weight='light'
						sx='min-h-[70px]'
					>
						{product.description}
					</Text>
				)}

				<PrimaryButton
					label={isAlreadyActive ? 'Plan activo' : ctaLabelToUse}
					onClick={() => handleClickProduct(product)}
					loading={isLoadingAddToCart.products}
					disabled={isLoadingAddToCart.products || isAlreadyActive}
					sx={` ${
						isAlreadyActive
							? '!bg-h-success-90 !text-h-success-30'
							: 'disabled:!text-white !bg-h-primary disabled:!opacity-50'
					}`}
					beforeIcon={
						isAlreadyActive ? (
							<Check
								weight='bold'
								className='size-4'
							/>
						) : null
					}
				/>
				{tooltipContent[product.slug] && (
					<div className='flex flex-col gap-4 w-full'>
						<button
							onClick={() =>
								setShowActivitiesDescription(!showActivitiesDescription)
							}
							className='w-full flex justify-center'
						>
							<Text
								color='primary'
								weight='regular'
								size='caption'
								sx=''
							>
								Clientes que comunmente activan este plan
								{showActivitiesDescription ? (
									<CaretUp
										className='text-h-primary'
										weight='bold'
									/>
								) : (
									<CaretDown
										className='text-h-primary'
										weight='bold'
									/>
								)}
							</Text>
						</button>

						{showActivitiesDescription && (
							<ul className='flex flex-col gap-2'>
								{tooltipContent[product.slug].map((item, index) => (
									<li
										key={index}
										className='flex items-start gap-2'
									>
										<div className='min-h-[4px] min-w-[4px] bg-h-primary rounded-full mt-[6px]' />
										<Text
											size='mini-1'
											weight='light'
										>
											{item}
										</Text>
									</li>
								))}
							</ul>
						)}
					</div>
				)}
				<hr className='w-full' />
				<Text
					size='caption'
					weight='bold'
					color='medium'
				>
					Tu plan incluye
				</Text>
				<div className='flex flex-col gap-2'>
					{visibleBenefits.map((benefit: string, index: number) => (
						<div
							key={index}
							className='flex items-center gap-2 border-b border-h-neutral-200 pb-2'
						>
							<Check
								className='text-h-primary min-w-[14px]'
								size={14}
								weight='bold'
							/>
							<Text
								size='caption'
								color='dark'
								weight='light'
							>
								{benefit}
							</Text>
						</div>
					))}
				</div>
				{product.benefits.benefits.length > limitBenefits && (
					<button
						className='flex items-center gap-2 text-h-primary w-fit ml-auto cursor-pointer'
						onClick={() => setShowAllBenefits(!showAllBenefits)}
					>
						<Text
							size='caption'
							color='primary'
						>
							{showAllBenefits ? 'Ver menos' : 'Ver más'}
						</Text>
						{showAllBenefits ? (
							<CaretUp weight='bold' />
						) : (
							<CaretDown weight='bold' />
						)}
					</button>
				)}
			</div>
		</div>
	);
};

export default ProductCard;
