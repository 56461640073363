import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Order, TUserOrder } from '../../@types/Order.type';
import { PAYMENT_METHOD } from '../../@types/Checkout.type';

type UTM = {
	utm_source: string;
	utm_campaign: string;
	utm_medium: string;
};

type PaymentInstructions = {
	show: boolean;
	paymentMethod: PAYMENT_METHOD;
	total: number;
	reference: string;
	referenceLink: string;
	expiresAt: number;
};
export interface CartState {
	numbersOfItems: number;
	orders: Order[];
	products?: string;
	utmInfo?: UTM;
	additionalInformation?: Record<string, string>;
	couponCode?: string;
	overwriteUtm?: boolean;
	paymentInstructions: PaymentInstructions;
	isLoadingOrder?: boolean;
	userOrder: TUserOrder[];
	refreshOrders: any[];
}

const initialState: CartState = {
	numbersOfItems: 0,
	products: '',
	utmInfo: {
		utm_source: '',
		utm_campaign: '',
		utm_medium: '',
	},
	couponCode: '',
	overwriteUtm: false,
	orders: [],
	refreshOrders: [],
	isLoadingOrder: false,
	paymentInstructions: {
		show: false,
		paymentMethod: PAYMENT_METHOD.SPEI,
		total: 0,
		reference: '',
		referenceLink: '',
		expiresAt: 0,
	},
	userOrder: [],
};

export const cartSlice = createSlice({
	name: 'Cart',
	initialState,
	reducers: {
		updateAmountOfItems: (state, action: PayloadAction<number>) => {
			state.numbersOfItems = action.payload;
		},
		updateUTMs: (state, action: PayloadAction<UTM>) => {
			state.utmInfo = action.payload;
		},
		addProductToCart: (state, action: PayloadAction<string>) => {
			state.products = action.payload;
		},
		addAdditionalInformation: (
			state,
			action: PayloadAction<Record<string, string>>,
		) => {
			state.additionalInformation = action.payload;
		},
		setCouponCode: (state, action: PayloadAction<string>) => {
			state.couponCode = action.payload;
		},
		clearCart: (state) => {
			state.products = '';
			state.additionalInformation = {};
			state.couponCode = '';
		},
		setOverwriteUtm: (state, action: PayloadAction<boolean>) => {
			state.overwriteUtm = action.payload;
		},
		setOrders: (state, action: PayloadAction<Order[]>) => {
			state.orders = action.payload;
		},
		setRefreshOrders: (state, action: PayloadAction<any[]>) => {
			state.refreshOrders = action.payload;
		},
		setUserOrder: (state, action: PayloadAction<TUserOrder[]>) => {
			state.userOrder = action.payload;
		},
		setPaymentInstructions: (
			state,
			action: PayloadAction<PaymentInstructions>,
		) => {
			state.paymentInstructions = action.payload;
		},
		clearPaymentInstructions: (state) => {
			state.paymentInstructions = initialState.paymentInstructions;
		},
		setIsLoadingOrder: (state, action: PayloadAction<boolean>) => {
			state.isLoadingOrder = action.payload;
		},
	},
});

export const {
	updateAmountOfItems,
	updateUTMs,
	addProductToCart,
	addAdditionalInformation,
	setCouponCode,
	clearCart,
	setOverwriteUtm,
	setOrders,
	setPaymentInstructions,
	clearPaymentInstructions,
	setIsLoadingOrder,
	setUserOrder,
	setRefreshOrders,
} = cartSlice.actions;
