import { Warning } from '@phosphor-icons/react';
import AlertBanner from '../../../components/molecules/AlertBanner';

const SatAlert = () => {
	return (
		<div className='flex justify-center  items-center mb-4 md:mb-6'>
			<AlertBanner
				variant='error'
				title='Inconveniente temporal en la descarga de retenciones'
				description={
					<div>
						El SAT está presentando intermitencias que afectan la descarga de
						retenciones. Te invitamos a cargar tus facturas o certificados{' '}
						<a
							href='/fiscal-documents/'
							className='font-bold '
						>
							aquí
						</a>{' '}
						para que podamos generar tu declaración del mes. También puedes
						consultar nuestro{' '}
						<a
							target='_blank'
							href='https://heru.frontkb.com/en/articles/3578625'
							className='font-bold'
						>
							blog
						</a>{' '}
						para aprender cómo subir los documentos fácilmente.
					</div>
				}
				icon={
					<Warning
						size={36}
						weight='fill'
						color='var(--h-hot-sale) '
						className='flex-2'
					/>
				}
				sx='w-full lg:w-[600px]'
				sizeTitle='body-2'
				sizeDescription='body-2'
			/>
		</div>
	);
};

export default SatAlert;
