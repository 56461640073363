import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Table, { TColumns, TRowData } from '../../components/molecules/Table';
import Heading from '../../components/atoms/Heading';
import { getFormatDate } from '../../shared/utils/dates.util';
import { formatCurrency } from '../../shared/utils/general.util';
import { DownloadSimple } from '@phosphor-icons/react';
import { getMonthName } from '../../shared/utils/dates.util';
import { useMediaQuery } from '@mui/material';
import Text from '../../components/atoms/Text';
import Container from '../../components/atoms/Container';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import { PlatformOS } from '../../redux/slices/profile.slice';
import STORAGE_KEYS from '../../shared/constants/storage';
import { useStorage } from '../../shared/utils/general.util';

const certificatesColumns: TColumns[] = [
	{
		title: 'Periodo',
		dataIndex: 'period_date',
	},
	{
		title: 'Fecha de emisión',
		dataIndex: 'emission_date',
	},
	{
		title: 'Emisor',
		dataIndex: 'issuer',
	},
	{
		title: 'Monto total',
		dataIndex: 'total',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

const RappiCertificates = () => {
	const logClevertapEvent = useCleverTapEvent();

	const rappiCertificates = useSelector(
		(state: RootState) => state.rappi.certificates,
	);

	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;

	const getCertificatePeriod = (date: string) => {
		const month = date.split('-')[1];
		const year = date.split('-')[0];

		if (month === '01') {
			return `Diciembre ${Number(year) - 1}`;
		}

		return `${getMonthName(Number(month) - 1, true)} ${year}`;
	};

	const handleDownload = (url: string, type: 'pdf' | 'xml') => {
		logClevertapEvent(ANALYTICS_EVENTS.RAPPI_CERTIFICATES, {
			event_type:
				type === 'pdf'
					? EVENT_TYPES.RAPPI_CERTIFICATES_DOWNLOAD_PDF
					: EVENT_TYPES.RAPPI_CERTIFICATES_DOWNLOAD_XML,
		});
		window.open(url, '_blank', 'noreferrer');
	};

	const rappiCertificatesOrdered = [...rappiCertificates]?.sort(
		(a, b) =>
			new Date(b.emitted_at).getTime() - new Date(a.emitted_at).getTime(),
	);

	const dataRows: TRowData[] = rappiCertificatesOrdered?.map((certificate) => ({
		rowContent: {
			period_date: {
				content: getCertificatePeriod(certificate?.emitted_at || ''),
				textStyle: 'py-1',
			},
			emission_date: {
				content: `${getFormatDate(
					certificate?.emitted_at || '',
					'DD MMM YYYY',
					false,
					true,
				)}`,
			},
			issuer: {
				content: 'Rappi',
			},
			total: {
				content: formatCurrency(Number(certificate?.total_amount) || 0),
			},
			actions: {
				content: (
					<div className=''>
						<div className='flex gap-1'>
							<div
								onClick={() => handleDownload(certificate?.xml_url, 'xml')}
								className='flex items-center gap-2 text-h-primary text-sm justify-center w-fit mx-auto cursor-pointer hover:opacity-100'
							>
								Descargar XML
								<DownloadSimple
									weight='bold'
									width={16}
									height={16}
								/>
							</div>
							<div
								onClick={() => handleDownload(certificate?.pdf_url, 'pdf')}
								className='flex items-center gap-2 text-h-primary text-sm justify-center w-fit mx-auto cursor-pointer hover:opacity-100'
							>
								Descargar PDF
								<DownloadSimple
									weight='bold'
									width={16}
									height={16}
								/>
							</div>
						</div>
					</div>
				),
			},
		},
	}));

	const isMobile = useMediaQuery('(max-width: 1024px)');

	return (
		<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 bg-h-neutral-94 min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-46px)]'>
			<Heading
				size='s'
				weight='bold'
				responsive
			>
				Certificados de Rappi
			</Heading>
			<Container sx='flex-1'>
				{isMobile ? (
					<div className='flex flex-col gap-3'>
						{rappiCertificates.map((certificate, index) => (
							<div
								key={`certificate-index-${index}`}
								className=' border-b border-h-neutral-90'
							>
								<Text
									color='light'
									responsive
									weight='light'
									size='caption'
								>
									Certificado de Rappi
								</Text>
								<Text sx='!truncate !block'>
									{getCertificatePeriod(certificate?.emitted_at || '')}
								</Text>
								<div className='flex justify-between items-center mt-1 mb-3'>
									<div>
										<Text
											color='light'
											size='mini-1'
											responsive
											weight='light'
										>
											Total
										</Text>
										<Text
											responsive
											sx='!truncate !block'
										>
											{certificate?.total_amount}
										</Text>
									</div>
									<div className='flex items-center gap-6'>
										{certificate?.xml_url && platformOS !== PlatformOS.IOS && (
											<div
												onClick={() =>
													handleDownload(certificate?.xml_url, 'xml')
												}
											>
												<Text
													color='primary'
													size='mini-1'
												>
													XML
													<DownloadSimple
														color='var(--h-primary)'
														width={20}
														height={20}
													/>
												</Text>
											</div>
										)}
										{certificate?.pdf_url && (
											<div
												onClick={() =>
													handleDownload(certificate?.pdf_url, 'pdf')
												}
											>
												<Text
													color='primary'
													size='mini-1'
												>
													PDF
													<DownloadSimple
														color='var(--h-primary)'
														width={20}
														height={20}
													/>
												</Text>
											</div>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<Table
						columns={certificatesColumns}
						rowsData={dataRows}
					/>
				)}
			</Container>
		</section>
	);
};

export default RappiCertificates;
