import React from 'react';
import Text from '../../../../../components/atoms/Text';

interface PaymentMethodItemProps {
	label: string;
	description: string;
	showDescription?: boolean;
	imgSrc?: string;
	imgAlt?: string;
	children?: React.ReactNode;
	icon?: React.ReactNode;
}

const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({
	label,
	description,
	imgSrc,
	imgAlt,
	showDescription,
	children,
	icon,
}) => {
	return (
		<div className='flex flex-col justify-start items-start gap-x-4 gap-y-1 my-2 p-2'>
			<div className='inline-flex  gap-x-3'>
				{imgSrc && (
					<img
						src={imgSrc}
						alt={imgAlt}
						width={60}
						className='h-auto'
					/>
				)}
				{icon && icon}
				<Text
					size='caption'
					weight='light'
					color='darkMenu'
				>
					{label}
				</Text>
			</div>
			{showDescription && (
				<Text
					size='caption'
					color='light'
					weight='light'
				>
					{description}
				</Text>
			)}
			{children}
		</div>
	);
};

export default React.memo(PaymentMethodItem);
