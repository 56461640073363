import { useCallback, useEffect, useState } from 'react';
import { getProducts } from '../../../services/plans.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { addProducts } from '../../../redux/slices/products.slice';
import { useNavigate } from 'react-router-dom';
import { CategoryName } from '../../../@types/Products.type';
import ModalSkipAnalysis from './ModalSkipAnalysis';
import ROUTES from '../../../shared/constants/routes';
import { setIsOnboarding } from '../../../redux/slices/config.slice';
import ProductCard from '../../plansAndPricesV2/organisms/ProductCard';

interface Props {
	productSlug: string;
	regimes: string[];
}

const OnboardingProduct = ({ productSlug }: Props) => {
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { products } = useSelector((state: RootState) => state.productList);

	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			dispatch(addProducts(data));
		} catch (error) {
		} finally {
		}
	}, []);

	useEffect(() => {
		getAllProducts();
	}, []);

	const goToHome = () => {
		dispatch(setIsOnboarding(false));
		navigate(ROUTES.PLANS_AND_PRICES);
	};

	const subscriptionProducts = products?.filter(
		(product) => product.name === CategoryName.Plans,
	)?.[0]?.products;

	const recommendedProduct = subscriptionProducts?.find(
		(product) => product.slug === productSlug,
	);

	return (
		<div className='max-w-[440px] mt-10 mx-auto'>
			<ModalSkipAnalysis
				showModal={showModal}
				setShowModal={setShowModal}
				goToHome={goToHome}
				title='No te pierdas los beneficios exclusivos que te esperan.'
				description='Sabemos lo importante que es mantener tus obligaciones fiscales al día. Completa tu suscripción para que podamos acompañarte en cada paso del proceso.'
			/>
			<ProductCard
				product={recommendedProduct!}
				recommended={true}
				className='lg:min-h-[760px]'
				showPeriodicitySelector
				onSkip={() => setShowModal(true)}
			/>
		</div>
	);
};

export default OnboardingProduct;
