import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfigState {
	isOnboarding: boolean;
}

const initialState: ConfigState = {
	isOnboarding: false, //Todo: change to false
};

export const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setIsOnboarding: (state, action: PayloadAction<boolean>) => {
			state.isOnboarding = action.payload;
		},
	},
});

export const { setIsOnboarding } = configSlice.actions;
