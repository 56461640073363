import React, { useState } from 'react';

import { CaretDown, CaretUp, DownloadSimple, CircleNotch } from '@phosphor-icons/react';

//? Types
import { Acquisitions } from '../../../../@types/Acquisitions.type';
import { ServiceStatusElement } from '../../../../@types/ServiceStatus.type';

//? Components
import Text from '../../../../components/atoms/Text';
import CustomIcon from '../../../../components/atoms/CustomIcon';

//? Utils
import { formatCurrency } from '../../../../shared/utils/general.util';
import { getFormatDate } from '../../../../shared/utils/dates.util';
import { getBrandIcon } from '../../../../shared/utils/payments.util';
interface PurchaseRowProps {
	item: Acquisitions;
	index: number;
	statusObject: ServiceStatusElement;
	downloadUrl?: string;
	onGenerateReceipt: () => void;
	isGeneratingReceipt?: boolean;
}

const PurchaseRow: React.FC<PurchaseRowProps> = ({
	item,
	index,
	statusObject,
	onGenerateReceipt,
	isGeneratingReceipt,
}) => {
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = () => {
		setOpen((prevState) => !prevState);
	};

	const renderLastFour = (item: Acquisitions) => {
		const initial = '•••• •••• •••• ';
		if (item?.payment_details?.last_four) {
			return initial + item?.payment_details?.last_four;
		} else {
			return initial + item?.last_transaction?.token_digits;
		}
	};

	const renderExpires = (item: Acquisitions) => {
		if (item?.payment_details?.expires) {
			return item?.payment_details?.expires;
		} else {
			return item?.last_transaction?.expires_at;
		}
	};

	const renderPaymentMethod = (paymentMethod: string, item: Acquisitions) => {
		if (paymentMethod === 'CARD') {
			return (
				<div className='flex flex-row items-center'>
					<img
						src={getBrandIcon(
							item?.payment_details?.brand ??
								item?.last_transaction?.token_brand ??
								'',
						)}
						alt='brand'
						className='w-[32px] h-[32px] ml-2'
					/>

					<div className='flex flex-col ml-4'>
						<Text
							size='s'
							sx='lg:hidden'
						>
							{renderLastFour(item)}
						</Text>

						<Text
							size='s'
							sx='lg:hidden'
						>
							{renderExpires(item)}
						</Text>

						<Text
							size='m'
							sx='lg:flex hidden'
						>
							{renderLastFour(item)}
						</Text>

						<Text
							size='m'
							sx='lg:flex hidden'
						>
							{renderExpires(item)}
						</Text>
					</div>
				</div>
			);
		} else if (paymentMethod === 'CASH') {
			return (
				<div className='flex flex-row items-center'>
					<CustomIcon
						sx='ml-0'
						color={'var(--h-primary)'}
						name={'oxxo'}
						width={32}
					/>
					<Text
						size='m'
						color='dark'
						sx='ml-2'
					>
						Efectivo
					</Text>
				</div>
			);
		} else if (paymentMethod === 'SPEI') {
			return (
				<div className='flex flex-row items-center'>
					<CustomIcon
						sx='ml-0'
						color={'var(--h-primary)'}
						name={'spei'}
						width={32}
					/>
					<Text
						size='m'
						color='dark'
						sx='ml-2'
					>
						Transferencia
					</Text>
				</div>
			);
		} else if (paymentMethod === 'Paypal') {
			return (
				<div className='flex flex-row items-center'>
					<CustomIcon
						sx='ml-0'
						color={'var(--h-primary)'}
						name={'paypal'}
						width={20}
					/>
					<Text
						size='m'
						color='dark'
						sx='ml-2'
					>
						Paypal
					</Text>
				</div>
			);
		} else {
			return (
				<div className='flex flex-row items-center'>
					<CustomIcon
						sx='ml-0'
						color={'var(--h-primary)'}
						name={'oxxo'}
						width={32}
					/>
					<Text
						size='m'
						color='dark'
						sx='ml-2'
					>
						Traditional cash
					</Text>
				</div>
			);
		}
	};

	const getStatusText = (status: string) => {
		if (status === 'active') {
			return 'Activa';
		} else if (status === 'cancelled') {
			return 'Cancelada';
		} else {
			return 'Finalizada';
		}
	};

	return (
		<>
			<div
				key={index}
				className={`hidden lg:flex ${
					index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
				} w-full`}
			>
				<div className='w-[25%] px-2 py-4'>
					<Text size='m' color='dark' weight='bold'>
						{item?.product?.name ?? ''}
					</Text>
					{item?.product?.regime?.map((regime, index) => (
						<Text key={`regime-${index}`} size='m' color='dark' sx='mt-2'>
							{regime}
						</Text>
					))}
				</div>

				<div className='w-[15%] px-2 py-4 flex justify-center items-center'>
					{!statusObject ? (
						<Text
							size='xs'
							color={'dark'}
						>
							-
						</Text>
					) : (
						<div
							className={`inline-block px-2 rounded-sm ${
								statusObject?.status === 'active'
									? 'bg-h-green-30'
									: statusObject?.status === 'Cancelada'
										? 'bg-h-red-30'
										: 'bg-gray-300'
							}`}
						>
							<Text
								size='xs'
								color={
									statusObject?.status === 'active'
										? 'green'
										: statusObject?.status === 'Cancelada'
											? 'red'
											: 'gray'
								}
							>
								{getStatusText(statusObject?.status)}
							</Text>
						</div>
					)}
				</div>

				<div className='w-[15%] px-2 py-4 flex justify-center items-center'>
					{!statusObject ? (
						<Text
							size='m'
							color='dark'
						>
							-
						</Text>
					) : (
						<Text
							size='m'
							color='dark'
						>
							{getFormatDate(statusObject?.created_at)}
						</Text>
					)}
				</div>

				<div className='w-[25%] px-2 py-4 flex justify-center items-center'>
					<div className='flex flex-row items-center'>
						{item?.last_transaction ? (
							renderPaymentMethod(item?.payment_method, item)
						) : (
							<Text
								size='m'
								color='dark'
								sx='mr-10'
							>
								-
							</Text>
						)}
					</div>
				</div>

				<div className='w-[15%] px-2 py-4 flex justify-center items-center'>
					{item?.shopping_item?.total ? (
						<Text
							size='m'
							color='dark'
							sx='mr-10'
							weight='bold'
						>
							{formatCurrency(item?.shopping_item?.total / 100)}
						</Text>
					) : (
						<Text
							size='m'
							color='dark'
							sx='mr-10'
							weight='bold'
						>
							-
						</Text>
					)}
				</div>

				<div className='w-[5%] flex justify-center items-center'>
					<button
						onClick={onGenerateReceipt}
						disabled={isGeneratingReceipt}
						className='text-h-primary'
					>
						{isGeneratingReceipt ? (
							<CircleNotch size={20} className="animate-spin" />
						) : (
							<DownloadSimple size={24} />
						)}
					</button>
				</div>
			</div>

			{/* mobile section */}
			<>
				<div
					className={`flex items-center justify-between ${
						index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
					} py-4 px-1 lg:hidden`}
				>
					<div className='flex flex-col'>
						<Text
							size='m'
							weight='bold'
							sx='mb-1'
						>
							{item?.product?.name ?? ''}
						</Text>
						{item?.product?.regime?.map((regime, index) => (
							<Text
								key={`regime-${index}`}
								size='s'
							>
								{regime}
							</Text>
						))}
					</div>

					<button
						className='mr-2'
						onClick={handleOpen}
					>
						{open ? (
							<CaretUp
								size={18}
								weight='light'
								color={'var(--h-primary)'}
							/>
						) : (
							<CaretDown
								size={18}
								weight='light'
								color={'var(--h-primary)'}
							/>
						)}
					</button>
				</div>

				{open && (
					<div
						className={`flex flex-col lg:hidden px-1 ${
							index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
						}`}
					>
						<div className='flex items-center justify-between mb-3'>
							<Text size='s'>Estado</Text>
							<div
								className={`inline-block px-2 rounded-sm ${
									statusObject?.status === 'active'
										? 'bg-h-green-30'
										: statusObject?.status === 'Cancelada'
											? 'bg-h-red-30'
											: 'bg-gray-300'
								}`}
							>
								<Text
									size='xs'
									color={
										statusObject?.status === 'active'
											? 'green'
											: statusObject?.status === 'Cancelada'
												? 'red'
												: 'gray'
									}
								>
									{getStatusText(statusObject?.status)}
								</Text>
							</div>
						</div>
						<div className='flex items-center justify-between mb-3'>
							<Text size='s'>Fecha de pago</Text>
							<Text
								size='s'
								weight='bold'
							>
								{getFormatDate(statusObject?.created_at)}
							</Text>
						</div>
						<div className='flex items-center justify-between mb-3'>
							<Text size='s'>Valor pagado</Text>
							{item?.shopping_item?.total ? (
								<Text
									size='s'
									weight='bold'
								>
									{formatCurrency(item?.shopping_item?.total / 100)}
								</Text>
							) : (
								<Text
									size='s'
									weight='bold'
								>
									-
								</Text>
							)}
						</div>
						<div className='flex items-center justify-between mb-2'>
							{item?.last_transaction &&
								renderPaymentMethod(item?.payment_method, item)}
						</div>
						<button
							onClick={onGenerateReceipt}
							disabled={isGeneratingReceipt}
							className='text-h-primary cursor-pointer text-sm pb-2 flex justify-center items-center gap-2'
						>
							{isGeneratingReceipt ? (
								<CircleNotch size={16} className="animate-spin" />
							) : (
								<>
									Descargar <DownloadSimple size={16} />
								</>
							)}
						</button>
					</div>
				)}
			</>
		</>
	);
};

export default React.memo(PurchaseRow);
