import { useCallback, useEffect } from 'react';
import Navigation from './routes/Navigation';
import clevertap from 'clevertap-web-sdk';
import * as Sentry from '@sentry/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import growthbookFile from './shared/constants/growthbook';
import { getErrors } from './services/app.service';
import { useDispatch, useSelector } from 'react-redux';
import { setAllErrors } from './redux/slices/profile.slice';
import { RootState } from './redux/store';

// import { clarity } from 'react-microsoft-clarity';
// import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://b29b37d2a037e5d7bba1efecd9648407@o1074769.ingest.sentry.io/4506630182207488',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/web.heru.app/],
		}),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 0.1, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
	const dispatch = useDispatch();
	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};
	const userProfile = useSelector((state: RootState) => state.profile.user);

	useEffect(() => {
		if (!userProfile || !userProfile?.id) return;

		loadGrowthbook();
		growthbookFile?.setAttributes({
			user_id: Number(userProfile?.id),
		});
	}, [userProfile]);

	const getAllErrors = useCallback(async () => {
		const resp = await getErrors();
		dispatch(setAllErrors(resp.resource.errors));
	}, []);

	useEffect(() => {
		clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
		clevertap.privacy.push({ useIP: false });
		clevertap.init('844-WW6-4W6Z');
	}, []);

	useEffect(() => {
		growthbookFile.loadFeatures();
		// clarity.init('kka5f156de');
	}, []);

	useEffect(() => {
		getAllErrors();
	}, []);

	return (
		<GrowthBookProvider growthbook={growthbookFile}>
			<Navigation />
		</GrowthBookProvider>
	);
}

export default App;
