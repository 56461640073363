import { useEffect, useMemo, useReducer, useState } from 'react';
import {
	PRODUCTS_SLUG,
	regularizationsPT,
} from '../../../../shared/constants/SlugsProducts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import ItemCollapse from './ItemCollapse';
import {
	AdditionalInformation,
	ProductVersionItem,
} from '../../../../@types/Order.type';
import { formatPrice } from '../../../../shared/utils/general.util';
import Text from '../../../atoms/Text';
import { getPriceFromProductVersionId } from '../../../../shared/utils/products.utils';
import { CategoryName } from '../../../../@types/Products.type';
import { DATES } from '../../../../shared/utils/dates';
import {
	initialRegularizationItems,
	regularizationItemsReducer,
} from '../../../../reducers/regularizationItems/regularizationItemsReducer';
import useDeleteProducts from '../../../../hooks/useDeleteProducts';
import ButtonTrash from '../../../atoms/ButtonTrash/ButtonTrash';
import { CircleNotch, X } from '@phosphor-icons/react';

const ItemAnnualDeclaration = () => {
	const { userOrder } = useSelector((state: RootState) => state.cart);
	const { products } = useSelector((state: RootState) => state.productList);
	const { handleDeleteProducts, isLoading: isLoadingDelete } =
		useDeleteProducts();

	const [annualProds, dispatchAnnual] = useReducer(
		regularizationItemsReducer,
		initialRegularizationItems,
	);
	const [selectedItem, setSelectedItem] = useState<ProductVersionItem | null>(
		null,
	);

	const getRegularizationRegimesProducts = () => {
		regularizationsPT.forEach((regu) => {
			const regProds =
				userOrder[0]?.shoppingItem?.filter(
					(product) => product?.productVersion?.product.slug === regu.slug,
				) ?? [];

			if (regProds.length > 0) {
				dispatchAnnual({ type: regu.type, payload: regProds });
			} else {
				dispatchAnnual({ type: regu.type, payload: [] });
			}
		});
	};

	useEffect(() => {
		if (userOrder[0]?.shoppingItem) {
			getRegularizationRegimesProducts();
		}
	}, [userOrder[0]?.shoppingItem]);

	const periodLabelReg = useMemo(
		() => (additionalInfo: AdditionalInformation) => {
			if (additionalInfo.year) {
				return additionalInfo.year;
			}

			return null;
		},
		[annualProds],
	);

	const price = (reguRegimen: ProductVersionItem[]) =>
		(!!reguRegimen[0]?.discount
			? reguRegimen[0]?.basePrice - reguRegimen[0]?.discount
			: reguRegimen[0]?.basePrice) * reguRegimen.length;

	const saving = (reguRegimen: ProductVersionItem[]) =>
		getPriceFromProductVersionId(
			products,
			reguRegimen[0]?.productVersionId ?? 0,
		) *
			reguRegimen.length -
		price(reguRegimen) / 100;

	const allAnnualProducts =
		userOrder[0]?.shoppingItem.filter(
			(item) =>
				item.productVersion.product.slug ===
				PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
		) ?? [];

	const annualProducts =
		userOrder[0]?.shoppingItem.filter(
			(item) =>
				item.productVersion.product.category ===
					CategoryName.DeclaraciónAnual &&
				item.productVersion.product.slug !==
					PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
		) ?? [];

	const handleDeleteSingleAnnual = async (prod: ProductVersionItem) => {
		if (isLoadingDelete) return;
		try {
			setSelectedItem(prod);

			await handleDeleteProducts(userOrder?.[0]?.id, [
				{ shopping_item_id: prod?.id! },
			]);
		} catch (error) {
		} finally {
			setSelectedItem(null);
		}
	};

	const handleDeleteAllAnnualRegularizations = async () => {
		if (isLoadingDelete) return;
		try {
			const allShoppingItemsIds = allAnnualProducts.map((item) => ({
				shopping_item_id: item?.id,
			}));
			await handleDeleteProducts(userOrder?.[0]?.id, allShoppingItemsIds);
		} catch (error) {
		} finally {
			setSelectedItem(null);
		}
	};

	return (
		<div className='flex flex-col gap-2'>
			{allAnnualProducts.length > 0 && (
				<div className='flex gap-2'>
					<div className='mt-1 w-full'>
						<ItemCollapse
							title={allAnnualProducts[0]?.productVersion.product.name}
							subtitle={`$ ${formatPrice(price(allAnnualProducts))}`}
						>
							<div>
								<Text
									size='caption'
									color='light'
								>
									{allAnnualProducts
										.sort((a, b) => {
											return (
												(a.additionalInformation?.month ?? 0) -
												(b.additionalInformation?.month ?? 0)
											);
										})
										.map((item) => {
											const period = periodLabelReg(
												item?.additionalInformation ?? {},
											);
											return (
												<div
													className='flex items-center gap-1'
													key={`period-${item.id}`}
												>
													<Text
														size='caption'
														color='light'
													>
														{period}
													</Text>
													{isLoadingDelete && selectedItem?.id === item.id ? (
														<CircleNotch
															className='animate-spin'
															size={16}
														/>
													) : (
														<X
															onClick={() => {
																handleDeleteSingleAnnual(item);
															}}
															className='cursor-pointer'
															size={16}
														/>
													)}
												</div>
											);
										})}
								</Text>
								{!!saving(allAnnualProducts) && (
									<div className='flex flex-row justify-end'>
										<Text
											color='green'
											sx='text-right'
										>
											Estas ahorrando ${saving(allAnnualProducts)}
										</Text>
									</div>
								)}
							</div>
						</ItemCollapse>
					</div>
					<ButtonTrash
						onClick={() => handleDeleteAllAnnualRegularizations()}
						isLoading={isLoadingDelete && !selectedItem}
					/>
				</div>
			)}
			{annualProducts.map((prod) => (
				<div className='flex gap-2 items-center'>
					<ItemCollapse
						title={`${prod.productVersion.product.name}`}
						subtitle={`$ ${formatPrice(prod.total)}`}
					>
						{prod.purchaseType === 'subscription' ? (
							<div className=''>
								<Text weight='light'>
									Cubre tu declaración anual a presentar en Abril{' '}
									{DATES.currentYear + 1}
								</Text>
								{!!saving([prod]) && (
									<div className='flex flex-row justify-end'>
										<Text
											color='green'
											sx='text-right'
										>
											Estas ahorrando ${saving([prod])}
										</Text>
									</div>
								)}
							</div>
						) : (
							<>
								{!!saving([prod]) && (
									<div className='flex flex-row justify-end'>
										<Text
											color='green'
											sx='text-right'
										>
											Estas ahorrando ${saving([prod])}
										</Text>
									</div>
								)}
							</>
						)}
					</ItemCollapse>
					<ButtonTrash
						onClick={() => handleDeleteSingleAnnual(prod)}
						isLoading={isLoadingDelete && selectedItem === prod}
					/>
				</div>
			))}
		</div>
	);
};

export default ItemAnnualDeclaration;
