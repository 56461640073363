import { IObligations } from '../../@types/Fiscal.type';

export const periodSections = [
	{
		label: 'Mensuales',
		slug: 'monthly' as keyof IObligations,
	},
	{
		label: 'Anuales',
		slug: 'annual' as keyof IObligations,
	},
];

export enum REMOTE_CONFIG_PRODUCTS {
	DECLARATION_PRODUCTS = 'declaration_products',
	SUBSCRIPTION_DECLARATION_PRODUCTS = 'subscription_declaration_products',
	REGULARIZATION_PRODUCTS = 'regularization_products',
	ASAT_PRODUCTS = 'asat_products',
}

export const benefitsProducts = [
	'Declaración de ISR e IVA',
	'Asistencia fiscal por expertos',
	'Portal de facturación',
	'Portal de seguimiento de ingresos y gastos',
	'Identificación de gastos deducibles por medio de IA',
	'Actualización de tu información fiscal automáticamente',
];

export const risksProducts = [
	'Pagos mas altos al SAT por multas y recargo',
	'Entrar a las listas negras del SAT',
	'Bloqueo de facturación',
	'Entrar a buró de crédito por créditos fiscales',
];

export const testBeneficts = {
	benefits: [
		{
			description:
				'Te ayudamos con el alta en el SAT, el régimen y la modalidad de pago en caso de que aún no lo hayas hecho',
			type: 'general',
		},
		{
			description:
				'Preparamos y presentamos tu declaración de IVA e ISR mensual de tus ingresos generados por medio de plataformas tecnológicas',
			type: 'general',
		},
		{
			description:
				'Detección automática de deducciones optimizada con inteligencia artificial',
			type: 'general',
		},
		{
			description:
				'Soporte profesional de nuestros especialistas contables a través de correo electrónico',
			type: 'unique',
		},
		{
			description:
				'Generación de hasta 5 facturas gratis desde nuestra plataforma de facturación',
			type: 'unique',
		},
		{
			description: 'Descarga de facturas y documentos fiscales desde el 2020',
			type: 'general',
		},
		{
			description:
				'Descarga semanal de nuevas facturas recibidas o emitidas en la plataforma del SAT',
			type: 'unique',
		},
		{
			description:
				'Descarga mensual de tu opinión de Cumplimiento y Constancia de Situación Fiscal',
			type: 'general',
		},
		{
			description:
				'Visibilidad completa de tus declaraciones presentadas, pendientes de presentación y resultado de cada declaración',
			type: 'general',
		},
		{
			description:
				'Generación de hasta de 1 línea de captura gratuita por declaración en caso de vencimiento',
			type: 'general',
		},
		{
			description:
				'Acceso ilimitado a tu perfil fiscal y análisis contable desde nuestra plataforma web y app',
			type: 'general',
		},
		{
			description:
				'Notificaciones y tips por correo, mensaje de texto y WhatsApp para optimizar tu situación fiscal y pagar menos impuestos',
			type: 'general',
		},
	],
};

export const testEntrepreneur = {
	benefits: [
		{
			descripcion:
				'Cubre tus declaraciones por actividad de Plataformas Tecnológicas en caso de que generes ingresos por este régimen',
			type: 'general',
		},
		{
			descripcion:
				'Conciliación automática de tus estados de cuenta bancarios contra las facturas del SAT',
			type: 'general',
		},
		{
			descripcion:
				'Soporte fiscal especializado de acuerdo a tu actividad económica con inteligencia artificial',
			type: 'general',
		},
		{
			descripcion:
				'Soporte profesional de nuestros especialistas contables a través de WhatsApp y correo electrónico',
			type: 'general',
		},
		{
			descripcion:
				'Declaración anual por tus ingresos como RESICO, Arrendamiento, RIF, Plataformas Tecnológicas y Sueldos y Salarios correspondiente al año fiscal contratado',
			type: 'general',
		},
		{
			descripcion:
				'Generación de hasta 100 facturas gratis desde nuestra plataforma de facturación',
			type: 'unique',
		},
		{
			descripcion:
				'Descarga cada 3 días de nuevas facturas recibidas o emitidas en la plataforma del SAT',
			type: 'unique',
		},
	],
};

export const testBus = {
	benefits: [
		{
			descripcion: 'Tendrás contador asignado individualmente a tu cuenta',
			type: 'unique',
		},
		{
			descripcion:
				'1 hora gratuita de videollamada mensual con tu contador asignado',
			type: 'unique',
		},
		{
			descripcion:
				'Descarga diaria de nuevas facturas recibidas o emitidas en la plataforma del SAT',
			type: 'unique',
		},
		{
			descripcion: 'Hasta 500 facturas mensuales gratuitas',
			type: 'unique',
		},
	],
};
