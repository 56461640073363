import React, { useCallback, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	useStripe,
	useElements,
	Elements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { CreditCard } from '@phosphor-icons/react';
import { RootState } from '../../../../redux/store';
import {
	PlatformOS,
} from '../../../../redux/slices/profile.slice';
import {
	getCreateIntent,
	postShoppingCartProcess,
	sendStripeToken,
} from '../../../../services/payments.service';
import { AlertPayload, showAlert } from '../../../../redux/slices/alert.slice';
import ROUTES from '../../../../shared/constants/routes';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../../shared/constants/events';
import LOCAL_STORAGE_KEY from '../../../../shared/constants/localStorageKey';
import Button from '../../../../components/atoms/Button';
import { BlockedSub } from '../../../../@types/BlockedSubs';
import useCleverTapEvent from '../../../../hooks/useClevertapEvent';
import { isSessionStorageEnabled, useStorage } from '../../../../shared/utils/general.util';
import {
	PAYMENT_METHOD,
	PLATFORM_CATALOG,
} from '../../../../@types/Checkout.type';
import { useSearchParams } from 'react-router-dom';
import STORAGE_KEYS from '../../../../shared/constants/storage';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY as string);
interface UnlockStripeProps {
	order: BlockedSub;
	onPaymentMethodSuccess?: () => void;
	showPaymentElement: boolean;
	selectedCardId?: string;
	confirmPayment?: boolean;
	setConfrimPayment: (value: boolean) => void;
	customerStripeId: string;
	setTokenIdPurchased: (value: string) => void;
}
const UnlockStripe: React.FC<UnlockStripeProps> = ({
	order,
	onPaymentMethodSuccess,
	selectedCardId,
	confirmPayment,
	showPaymentElement,
	setConfrimPayment,
	customerStripeId,
	setTokenIdPurchased,
}) => {
	return (
		<>
			<Elements
				stripe={stripePromise}
				options={{
					mode: 'setup',
					currency: 'mxn',
				}}
			>
				<CheckoutForm
					order={order}
					onPaymentMethodSuccess={onPaymentMethodSuccess}
					selectedCardId={selectedCardId}
					confirmPayment={confirmPayment}
					showPaymentElement={showPaymentElement}
					setConfrimPayment={setConfrimPayment}
					customerStripeId={customerStripeId}
					setTokenIdPurchased={setTokenIdPurchased}
				/>
			</Elements>
		</>
	);
};

const CheckoutForm: React.FC<UnlockStripeProps> = ({
	order,
	onPaymentMethodSuccess,
	selectedCardId,
	confirmPayment,
	showPaymentElement,
	setConfrimPayment,
	customerStripeId,
	setTokenIdPurchased,
}) => {
	const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);
	
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;
	const { utmInfo } = useSelector((state: RootState) => state.cart);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const [searchParams] = useSearchParams();

	const get_ClientIntent = useCallback(async (userId: number) => {
		try {
			const response = await getCreateIntent(userId, tokenSession);
			return response;
		} catch (error) {}
	}, []);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const handlerSaveCard = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();

			if (!stripe) return;

			setIsLoadingPaymentMethod(true);

			const { error: submitError } = await elements!.submit();

			if (submitError) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						submitError.message ??
						'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
				});
				setIsLoadingPaymentMethod(false);
				return;
			}

			const res = await get_ClientIntent(order?.user_id);
			const clientSecret = res?.Body?.responseCall?.data?.client_secret;

			if (!clientSecret) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						'Ocurrió un error al intentar guardar la tarjeta, intenta de nuevo',
				});
			}

			const { error, setupIntent } = await stripe!.confirmSetup({
				elements: elements!,
				redirect: 'if_required',
				clientSecret: clientSecret!,
				confirmParams: {
					return_url: location.href + ROUTES.CHECKOUT,
				},
			});
			setIsLoadingPaymentMethod(false);

			if (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: error.message ?? '',
				});
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: false,
				});
			} else {
				await sendStripeToken(
					customerStripeId ?? '',
					(setupIntent.payment_method as string) ?? '',
					tokenSession,
				);
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: true,
				});
				showAlertTop({
					title: 'Tarjeta guardada',
					type: 'success',
					description: 'Tarjeta guardada exitosamente',
				});
				onPaymentMethodSuccess?.();
			}
		} catch (error) {
			setIsLoadingPaymentMethod(false);
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
			});
		}
	};

	const handleConfirmPayment = async () => {
		try {
			if (!selectedCardId) return;
			const items = order?.items
				.map((item) => `description=${item?.product_version?.description}`)
				.join('&');
			const prices = order?.items
				.map((item) => `price=${item.total}`)
				.join('&');
			const total = order?.total || 0;
			const urlParams = new URLSearchParams();
			urlParams.append('total', total.toString());
			urlParams.append('items', items!);
			urlParams.append('prices', prices!);
			urlParams.append('paymentMethod', 'CARD');
			urlParams.append('channel_source', 'web');
			urlParams.append('orderId', order.id.toString());
			urlParams.append('tax', order.taxes.toString());
			urlParams.append('coupon', order?.coupon?.code ?? '');
			if (!stripe) return;

			const isSessionAvailable = isSessionStorageEnabled();
			const utmCampaign = isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
				: utmInfo?.utm_campaign;
			const utmSource = isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
				: utmInfo?.utm_source;
			const utmMedium = isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
				: utmInfo?.utm_medium;
			const additionalInformation = {
				utms: {
					utm_campaign: utmCampaign ?? 'organic',
					utm_source: utmSource ?? 'organic',
					utm_medium: utmMedium ?? 'organic',
				},
			};

			const channelApp = searchParams.get('platform_os');
			const channelSource = searchParams.get('channel_source');

			const sourceChannel =
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
					? channelApp ?? platformOS
					: 'web';
			if (
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
			) {
				urlParams.append('channel_source', 'app');
				urlParams.append('platform_os', platformOS);
			} else {
				urlParams.append('channel_source', 'web');
			}

			const res = await postShoppingCartProcess({
				tokenSession,
				cartId: order.id,
				tokenId: selectedCardId,
				renewSubscription: false,
				additionalInformation,
				sourceChannel,
				paymentMethod: PAYMENT_METHOD.CARD,
				platformId: PLATFORM_CATALOG.STRIPE,
			});
			setTokenIdPurchased(res?.transaction.token_id!);

			const { error } = await stripe.confirmPayment({
				clientSecret: res?.transaction?.additional_information.cliente_secret,
				confirmParams: {
					return_url: location.origin,
				},
				redirect: 'if_required',
			});

			if (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: error.message ?? '',
				});
				setConfrimPayment(false);
			} else {
				onPaymentMethodSuccess?.();
			}
		} catch (error) {
			setConfrimPayment(false);
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al procesar el pago, intenta de nuevo',
			});
		}
	};

	useEffect(() => {
		if (confirmPayment) {
			handleConfirmPayment();
		}
	}, [confirmPayment]);

	return (
		<>
			{showPaymentElement ? (
				<form onSubmit={handlerSaveCard}>
					<PaymentElement />
					<Button
						beforeIcon={<CreditCard size={24} />}
						loading={isLoadingPaymentMethod}
						disabled={!stripe}
						variant='primary'
						label='Agregar'
						sx='h-[47px] ml-2 mt-4'
						type='submit'
					/>
				</form>
			) : (
				<div />
			)}
		</>
	);
};

export default UnlockStripe;
