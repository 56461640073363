import { useState } from 'react';
import { ArrowRight, Check, CheckCircle, XCircle } from '@phosphor-icons/react';
import { useLocation } from 'react-router-dom';
import PlanOverview from './organisms/PlanOverview';
import onboardingCardBg from '../../assets/img/png/onboarding-card-bg.png';
import Text from '../../components/atoms/Text';
import PrimaryButton from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import Modal from '../../components/molecules/Modal/Modal';
import Chat from './organisms/Chat';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import { useStorage } from '../../shared/utils/general.util';
import STORAGE_KEYS from '../../shared/constants/storage';

const benefits = [
	'Completa tu análisis fiscal en solo 1 minuto',
	'Recibe tips y consejos personalizados para pagar menos impuestos',
	'Obtén una recomendación del plan Heru que mejor se ajusta a tus necesidades',
	'Opimiza tus finanzas con el respaldo de expertos y tecnología avanzada',
	'Lleva tus obligaciones fiscales de manera sencilla y sin preocupaciones',
];

const OnboardingPlanSection = ({ handleSkip }: { handleSkip: () => void }) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const showParams = searchParams.get('show');
	const isMandatorySamantha = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_SAMANTHA,
	);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const onboardingFailedDate = useStorage().getItem(
		STORAGE_KEYS.ONBOARDING_FAILED_DATE,
	);

	const { status: taxpayer } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const [showModal, setShowModal] = useState(
		showParams && showParams === 'analisis' ? true : false,
	);
	const isLinked = taxpayer?.status === 'ACTIVE';

	const isMemberBeforeSamantha =
		userProfile?.joined_at &&
		new Date(userProfile.joined_at) < new Date('2024-11-01T00:00:00Z');

	const canSkip = () => {
		if (onboardingFailedDate) {
			return true;
		}
		if (isMemberBeforeSamantha) {
			return !isMandatorySamantha;
		}
		return false;
	};

	return (
		<div>
			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
				showCloseButton={false}
				sx='!p-0 rounded-lg overflow-hidden w-[95%] max-w-[700px] max-h-[94dvh] overflow-y-auto'
			>
				<div>
					<div className='flex items-start justify-between p-4 sm:p-6'>
						<div className='flex flex-col'>
							<Text
								size='xl'
								weight='medium'
							>
								¡Comencemos con tu análisis fiscal!
							</Text>
							{isLinked && (
								<Text
									size='mini-1'
									weight='medium'
									color='primary'
									sx='flex items-center mt-1 !gap-1'
								>
									<CheckCircle
										size={14}
										weight='bold'
										className='text-h-primary min-w-[14px] min-h-[14px]'
									/>
									Este análisis toma en cuenta tus regímenes fiscales actuales.
								</Text>
							)}
						</div>
						<button onClick={() => setShowModal(false)}>
							<XCircle
								size={24}
								weight='fill'
								className='text-h-neutral-70'
							/>
						</button>
					</div>
					<Chat />
				</div>
			</Modal>

			<div className='w-full mx-auto max-w-[1100px]'>
				<PlanOverview
					title='Comencemos a trabajar en el análisis de tu situación fiscal'
					description='Queremos recomendarte el plan que más se acople a tus necesidades, para lograr esto el primer paso es finalizar tu análisis fiscal.'
				/>

				<div className='max-w-[450px] mx-auto p-4'>
					{canSkip() && (
						<button
							onClick={handleSkip}
							className='mb-6 bg-h-neutral-90 rounded-lg text-sm px-4 py-2 shadow border border-h-neutral-80 flex items-center gap-4 text-left font-light'
						>
							Explorar los planes disponibles antes de realizar mi análisis
							fiscal
							<ArrowRight
								size={24}
								className='min-w-[24px]'
							/>
						</button>
					)}

					<div className='bg-white shadow rounded-lg flex flex-col'>
						<img
							src={onboardingCardBg}
							alt='icon'
						/>
						<div className='p-4 flex flex-col gap-4'>
							<Heading
								sx='text-center mt-2'
								size='xs'
								weight='medium'
							>
								Análisis fiscal gratuito
							</Heading>

							<ul className='flex flex-col gap-2'>
								{benefits.map((benefit) => (
									<li className='flex items-center gap-2'>
										<Check
											weight='bold'
											className='text-h-primary min-w-[14px] min-h-[14px]'
										/>
										<Text
											weight='light'
											size='caption'
										>
											{benefit}
										</Text>
									</li>
								))}
							</ul>

							<PrimaryButton
								label='Realizar mi análisis'
								sx='!bg-h-primary !h-10 sm:!h-12'
								onClick={() => setShowModal(true)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingPlanSection;
