import React, { useMemo, useState } from 'react';
import Text from '../../atoms/Text';
import { ChatCircleDots, DownloadSimple, Share } from '@phosphor-icons/react';
import { getAnimation } from '../../../shared/utils/styles.util';
import { ALERT_TYPE } from '../Alert';
import LITERALS from '../../../shared/constants/literals';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../redux/slices/alert.slice';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import NegativeOpinionModal from '../../organisms/NegativeOpinionModal';
import { PYP_CATEGORY_LABELS } from '../../../pages/plan/data/ecommerceData';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { RootState } from '../../../redux/store';
import { capitalizeFirstLetter } from '../../../shared/utils/general.util';
import { CONFIG_WHATSAPP_SUPPORT } from '../../../shared/constants/whatsappNumbers';
import { Tooltip } from '@mui/material';
import Tag from '../Tag';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';

type Status = 'positive' | 'negative' | 'pending';
interface Props {
	fileName: string;
	status: Status;
	hrefFile: string;
	statusText: string;
	sx?: string;
}

const FiscalDocShare: React.FC<Props> = ({
	fileName,
	status,
	hrefFile,
	sx,
	statusText,
}) => {
	const [showModal, setShowModal] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const logClevertapEvent = useCleverTapEvent();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const bgStatusColorTag = useMemo(() => {
		if (status === 'pending') return 'bg-h-gray-20';
		if (status === 'positive') {
			return 'bg-h-green-30';
		}
		return 'bg-h-red-30';
	}, [status]);

	const textStatusColorTag = useMemo(() => {
		if (status === 'pending') return 'loading';
		if (status === 'positive') {
			return 'success';
		}
		return 'error';
	}, [status]);

	const logTaxDocEvent = (_eventType: 'share' | 'download') => {
		if (userProfile) {
			const eventType =
				_eventType === 'share'
					? EVENT_TYPES.HOME_TAX_DOCUMENTS_SHARED
					: EVENT_TYPES.HOME_TAX_DOCUMENTS_DOWNLOADED;
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: eventType,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
			});
		}
	};

	const textDoc = `¡Hola! A continuación comparto el PDF de mi${fileName
		.toLocaleLowerCase()
		.replaceAll(
			'tu',
			'',
		)}.${'\n'}${'\n'}Haz click en el link de abajo para descargarlo de forma segura${'\n'}`;
	const shareFile = async () => {
		if (!hrefFile) return;
		if (navigator.share) {
			logTaxDocEvent('share');
			navigator
				.share({ url: hrefFile, title: textDoc })
				.then(() => {})
				.catch(() => {
					location.reload();
				});
		} else {
			const alert = {
				type: ALERT_TYPE.WARNING,
				title: 'Aviso',
				description: LITERALS.ERROR_WEB_SHARE_MESSAGE,
			};
			dispatch(showAlert(alert));
		}
	};

	const onStatusDocClick = () => {
		if (userProfile) {
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_TAX_STATUS_INTERACTION,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
			});
		}
		if (status === 'negative') {
			setShowModal(true);
		}
	};

	const whatsappLink = useMemo(
		() =>
			`${CONFIG_WHATSAPP_SUPPORT}?text=${
				LITERALS.WHATSAPP_MESSAGE_PROBLEM
			} con mi documento: ${fileName.replace('Tu', '')} por favor`,
		[fileName],
	);

	return (
		<div
			className={`flex flex-col sm:flex-row justify-between gap-3 items-center rounded-lg border border-neutral-200 p-4 ${sx}`}
		>
			<NegativeOpinionModal
				title={
					fileName.includes('situación fiscal')
						? 'Situación fiscal negativa'
						: 'Opinion negativa'
				}
				show={showModal}
				setShow={setShowModal}
				onClickCta={() =>
					navigate(ROUTES.PLANS_AND_PRICES, {
						state: { category: PYP_CATEGORY_LABELS[3] }, //? ASAT category
					})
				}
			/>
			<div className='flex flex-col  justify-center items-center sm:items-start gap-y-2'>
				<Text
					size='caption'
					weight='medium'
					sx='block lg:hidden'
				>
					{fileName}
				</Text>
				<Text
					size='body-3'
					weight='medium'
					sx='hidden lg:block'
				>
					{fileName}
				</Text>
				<button
					className={`py-1 px-2 w-fit ${bgStatusColorTag} rounded-lg h-fit`}
					onClick={onStatusDocClick}
				>
					<Tag
						type='text'
						label={capitalizeFirstLetter(statusText.toLocaleLowerCase())}
						caseVariant={textStatusColorTag}
					/>
				</button>
			</div>
			<div className='flex flex-row gap-4'>
				{hrefFile ? (
					<Tooltip
						title='Descargar'
						placement='top'
					>
						<a
							onClick={() => logTaxDocEvent('download')}
							download
							href={hrefFile}
							className='flex flex-col justify-center items-center'
						>
							<DownloadSimple
								size={24}
								className='hover:animate-bounce'
							/>
						</a>
					</Tooltip>
				) : (
					<Tooltip
						title='Contactar a soporte'
						placement='top'
					>
						<a
							className='flex flex-col justify-center items-center'
							href={whatsappLink}
							target='_blank'
						>
							<ChatCircleDots
								size={24}
								color='var(--h-blue-60)'
							/>
						</a>
					</Tooltip>
				)}

				{hrefFile && (
					<Tooltip
						title='Compartir'
						placement='top'
					>
						<button
							className='flex flex-col justify-center items-center'
							type='button'
							onClick={shareFile}
							disabled={!hrefFile}
						>
							<Share
								size={24}
								className={!hrefFile ? '' : getAnimation('ease-in-out')}
								color='var(--h-blue-60)'
							/>
						</button>
					</Tooltip>
				)}
			</div>
		</div>
	);
};

export default React.memo(FiscalDocShare);
