import React, { useCallback, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	useStripe,
	useElements,
	Elements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { CreditCard } from '@phosphor-icons/react';

import {
	getCreateIntent,
	postShoppingCartProcess,
	sendStripeToken,
} from '../../../services/payments.service';
import { Order } from '../../../@types/Order.type';
import Button from '../../../components/atoms/Button';
import { AlertPayload, showAlert } from '../../../redux/slices/alert.slice';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { RootState } from '../../../redux/store';
import { PlatformOS } from '../../../redux/slices/profile.slice';
import ROUTES from '../../../shared/constants/routes';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { useSearchParams } from 'react-router-dom';
import {
	formatPrice,
	isSessionStorageEnabled,
	useStorage,
} from '../../../shared/utils/general.util';
import {
	PAYMENT_METHOD,
	PLATFORM_CATALOG,
} from '../../../@types/Checkout.type';
import STORAGE_KEYS from '../../../shared/constants/storage';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY as string);
interface HeruStripeProps {
	order: Order;
	scrollToBottom: () => void;
	onPaymentMethodSuccess?: () => void;
	showPaymentElement: boolean;
	paymentMethodId?: string;
	confirmPayment?: boolean;
	setConfrimPayment: (value: boolean) => void;
	customerStripeId: string;
}
const HeruStripe: React.FC<HeruStripeProps> = ({
	order,
	scrollToBottom,
	onPaymentMethodSuccess,
	paymentMethodId,
	confirmPayment,
	showPaymentElement,
	setConfrimPayment,
	customerStripeId,
}) => {
	return (
		<>
			<Elements
				stripe={stripePromise}
				options={{
					mode: 'setup',
					currency: 'mxn',
				}}
			>
				<CheckoutForm
					order={order}
					scrollToBottom={scrollToBottom}
					onPaymentMethodSuccess={onPaymentMethodSuccess}
					paymentMethodId={paymentMethodId}
					confirmPayment={confirmPayment}
					showPaymentElement={showPaymentElement}
					setConfrimPayment={setConfrimPayment}
					customerStripeId={customerStripeId}
				/>
			</Elements>
		</>
	);
};

const CheckoutForm: React.FC<HeruStripeProps> = ({
	order,
	scrollToBottom,
	onPaymentMethodSuccess,
	paymentMethodId,
	confirmPayment,
	showPaymentElement,
	setConfrimPayment,
	customerStripeId,
}) => {
	const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);
	const [isLoadingProcess, setIsLoadingProcess] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { utmInfo } = useSelector((state: RootState) => state.cart);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;
	const get_ClientIntent = useCallback(async (userId: number) => {
		try {
			const response = await getCreateIntent(userId, tokenSession);
			return response;
		} catch (error: any) {}
	}, []);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const handlerSaveCard = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			scrollToBottom();
			event.preventDefault();

			if (!stripe) return;

			setIsLoadingPaymentMethod(true);

			const { error: submitError } = await elements!.submit();

			if (submitError) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						submitError.message ??
						'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
				});
				setIsLoadingPaymentMethod(false);

				return;
			}

			const res = await get_ClientIntent(order?.user_id);
			const clientSecret = res?.Body?.responseCall?.data?.client_secret;

			if (!clientSecret) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						'Ocurrió un error al intentar guardar la tarjeta, intenta de nuevo',
				});
			}

			const { error, setupIntent } = await stripe!.confirmSetup({
				elements: elements!,
				redirect: 'if_required',
				clientSecret: clientSecret!,
				confirmParams: {
					return_url: location.href + ROUTES.CHECKOUT,
				},
			});
			setIsLoadingPaymentMethod(false);

			if (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: error.message ?? '',
				});
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: false,
				});
			} else {
				await sendStripeToken(
					customerStripeId ?? '',
					(setupIntent.payment_method as string) ?? '',
					tokenSession,
				);
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: true,
				});
				showAlertTop({
					title: 'Tarjeta guardada',
					type: 'success',
					description: 'Tarjeta guardada exitosamente',
				});
				onPaymentMethodSuccess?.();
			}
		} catch (error) {
			setIsLoadingPaymentMethod(false);
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
			});
		}
	};

	const handleConfirmPayment = async () => {
		try {
			if (!paymentMethodId || isLoadingPaymentMethod) return;

			scrollToBottom();
			setIsLoadingProcess(true);
			const channelApp = searchParams.get('platform_os');
			const total = order?.total || 0;
			const urlParams = new URLSearchParams();
			urlParams.append('paymentMethod', 'CARD');
			urlParams.append('shoppingCartId', order.id.toString());
			if (!stripe) return;

			const isSessionAvailable = isSessionStorageEnabled();
			const utmCampaign = isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
				: utmInfo?.utm_campaign;
			const utmSource = isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
				: utmInfo?.utm_source;
			const utmMedium = isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
				: utmInfo?.utm_medium;

			const channelSource = searchParams.get('channel_source');

			const additionalInformation = {
				utms: {
					utm_campaign: utmCampaign ?? 'organic',
					utm_source: utmSource ?? 'organic',
					utm_medium: utmMedium ?? 'organic',
				},
			};
			const sourceChannel =
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
					? (channelApp ?? platformOS)
					: 'web';
			if (
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
			) {
				urlParams.append('channel_source', 'app');
				urlParams.append('platform_os', platformOS);
			} else {
				urlParams.append('channel_source', 'web');
			}

			const res = await postShoppingCartProcess({
				tokenSession,
				cartId: order.id,
				tokenId: paymentMethodId,
				renewSubscription: false,
				additionalInformation,
				sourceChannel,
				paymentMethod: PAYMENT_METHOD.CARD,
				platformId: PLATFORM_CATALOG.STRIPE,
			});
			const { error } = await stripe.confirmPayment({
				clientSecret: res?.transaction?.additional_information.cliente_secret,
				confirmParams: {
					return_url:
						location.origin + `/successful-purchase/?${urlParams.toString()}`,
				},
			});
			if (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: error.message ?? '',
				});
				setConfrimPayment(false);
				const eventProperties = {
					event_type: EVENT_TYPES.PLANS_CHARGED,
					Amount: formatPrice(parseInt(total.toString())),
					'Charged ID': order.id.toString(),
					'Payment mode': 'CARD',
					coupon: order?.coupon?.code ?? '',
				};
				logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
			}
		} catch (error) {
			setConfrimPayment(false);
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al procesar el pago, intenta de nuevo',
			});
		} finally {
			setIsLoadingProcess(false);
			const eventProperties = {
				event_type: EVENT_TYPES.PLANS_CHARGED,
				Amount: formatPrice(parseInt(order.total.toString())),
				'Charged ID': order.id.toString(),
				'Payment mode': 'CARD',
				coupon: order?.coupon?.code ?? '',
			};
			logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
		}
	};

	useEffect(() => {
		if (confirmPayment && !isLoadingProcess) {
			handleConfirmPayment();
		}
	}, [confirmPayment, isLoadingProcess]);

	return (
		<>
			{showPaymentElement ? (
				<form onSubmit={handlerSaveCard}>
					<PaymentElement />
					<Button
						beforeIcon={<CreditCard size={24} />}
						loading={isLoadingPaymentMethod}
						disabled={!stripe}
						variant='primary'
						label='Agregar'
						sx='h-[47px] ml-2 mt-4'
						type='submit'
					/>
				</form>
			) : (
				<div />
			)}
		</>
	);
};

export default HeruStripe;
