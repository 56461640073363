import {
	ChartLineUp,
	ChartPieSlice,
	Files,
	HouseLine,
	ReadCvLogo,
	Receipt,
	SignOut,
	SquaresFour,
} from '@phosphor-icons/react';
import ROUTES, { ROUTES_SLUGS } from '../../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Text from '../../atoms/Text';
import { getFirstLetter, useStorage } from '../../../shared/utils/general.util';
import { RootState } from '../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { logout, removeToken } from '../../../redux/slices/auth.slice';
import { restartAppState } from '../../../redux/slices/sat.slice';
import { restartTaxPayerData } from '../../../redux/slices/taxProfile.slice';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import SESSION_STORAGE_KEY from '../../../shared/constants/sessionStorageKey';
import { PlatformOS } from '../../../redux/slices/profile.slice';
import STORAGE_KEYS from '../../../shared/constants/storage';

const MobileMenu = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const isFromIOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) === PlatformOS.IOS;
	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
	const subMenuRef = useRef<HTMLDivElement>(null);

	const options: {
		label: string | null;
		slug: string;
		icon: React.ReactNode;
		path: string;
	}[] = [
		{
			label: 'Planes',
			slug: ROUTES_SLUGS.PLANS,
			icon: (
				<Files
					size={24}
					weight='bold'
				/>
			),
			path: ROUTES.PLANS_AND_PRICES,
		},
		{
			label: 'Facturación',
			slug: ROUTES_SLUGS.INVOICING,
			icon: (
				<Receipt
					size={24}
					weight='bold'
				/>
			),
			path: ROUTES.INVOICING,
		},
		{
			label: 'Inicio',
			slug: ROUTES_SLUGS.HOME,
			icon: (
				<HouseLine
					size={24}
					weight='bold'
				/>
			),
			path: ROUTES.ROOT,
		},
		{
			label: 'Impuestos',
			slug: ROUTES_SLUGS.TAXES,
			icon: (
				<ChartLineUp
					size={24}
					weight='bold'
				/>
			),
			path: ROUTES.TAXES,
		},
		{
			label: null,
			slug: ROUTES_SLUGS.MORE,
			icon: (
				<SquaresFour
					size={24}
					weight='bold'
				/>
			),
			path: 'submenu',
		},
	];

	const subOptions: {
		label: string | null;
		slug: string;
		icon: React.ReactNode;
		path: string;
	}[] = [
		{
			label: 'Salir',
			slug: ROUTES_SLUGS.LOGOUT,
			icon: <SignOut size={24} />,
			path: ROUTES_SLUGS.LOGOUT,
		},
		{
			label: 'Estado de servicio',
			slug: ROUTES_SLUGS.SERVICE_STATUS,
			icon: <ChartPieSlice size={24} />,
			path: ROUTES.SERVICE_STATUS,
		},
		{
			label: 'Documentos fiscales',
			slug: ROUTES_SLUGS.FISCAL_DOCUMENTS,
			icon: <ReadCvLogo size={24} />,
			path: ROUTES.FISCAL_DOCUMENTS,
		},
		{
			label: `${userProfile?.first_name}`,
			slug: ROUTES_SLUGS.USER_PROFILE,
			icon: (
				<div className='w-[21px] h-[21px] rounded-full bg-white  border border-h-primary-10 flex items-center justify-center text-white'>
					<Text
						size='xs'
						color='dark'
						weight='medium'
					>
						{getFirstLetter(userProfile?.first_name || '')}
					</Text>
				</div>
			),
			path: ROUTES.USER_PROFILE,
		},
	];

	const handleLogout = () => {
		useStorage().removeItem(LOCAL_STORAGE_KEY.TOKEN);
		useStorage().removeItem(SESSION_STORAGE_KEY.SHOW_NO_INFO_MODAL);
		dispatch(removeToken());
		dispatch(restartAppState());
		dispatch(restartTaxPayerData());
		dispatch(logout());
		navigate(ROUTES.LOGIN);
	};

	const handleNavigate = (path: string) => {
		if (path === 'submenu') {
			setIsSubMenuOpen((prev) => !prev);
		} else if (path === ROUTES_SLUGS.LOGOUT) {
			handleLogout();
		} else {
			setIsSubMenuOpen(false);
			navigate(path);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				subMenuRef.current &&
				!subMenuRef.current.contains(event.target as Node)
			) {
				setIsSubMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div
			ref={subMenuRef}
			className={`z-[100] fixed bottom-0 w-full bg-white flex border-t border-h-blue-20 md:hidden
				${isFromIOS ? 'pb-6' : ''}`}
		>
			{isSubMenuOpen && (
				<div
					className={`z-[100] fixed right-2 bg-white rounded shadow-lg w-[220px] border border-h-neutral-90 ${
						isFromIOS ? 'bottom-[100px]' : 'bottom-[74px]'
					}`}
				>
					{subOptions.map((option, index) => {
						const isActive = location.pathname === option.path;
						const icon = React.cloneElement(option.icon as React.ReactElement, {
							weight: isActive ? 'fill' : 'light',
						});

						return (
							<div
								onClick={() => handleNavigate(option.path)}
								className={`flex items-center justify-end gap-3 text-sm py-3.5 px-4 text-right font-medium border-b border-h-neutral-90
									${isActive ? 'bg-h-primary text-white' : ''}`}
								key={`${option.slug}-${index}`}
							>
								{option.label} {icon}
							</div>
						);
					})}
				</div>
			)}

			{options.map((option) => {
				const isSubMenuActive =
					isSubMenuOpen && option.slug === ROUTES_SLUGS.MORE;
				const isActive = location.pathname === option.path;

				const isActiveSubmenu =
					option.path === 'submenu' &&
					[
						ROUTES.SERVICE_STATUS,
						ROUTES.FISCAL_DOCUMENTS,
						ROUTES.USER_PROFILE,
					].includes(location.pathname);

				const icon = React.cloneElement(option.icon as React.ReactElement, {
					weight: isActive || isSubMenuActive || isActiveSubmenu ? 'fill' : 'regular',
				});

				return (
					<div
						onClick={() => handleNavigate(option.path)}
						className={`w-full flex flex-col items-center justify-center gap-1 px-2 py-3 text-[10px] font-medium text-h-dark 
							${isActive ? 'bg-h-primary text-white' : ''}
							${isSubMenuActive ? 'bg-h-primary-90' : ''}
							${isActiveSubmenu ? '!bg-h-primary !text-white' : ''}`}
						key={option.slug}
					>
						{icon}
						{option.label}
					</div>
				);
			})}
		</div>
	);
};

export default MobileMenu;
