import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
	useStripe,
	useElements,
	Elements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDown } from '@phosphor-icons/react';

import {
	createNewCustomer,
	getClientCustomer,
	getCreateIntent,
	getPaymentCards,
	postShoppingCartProcess,
	sendStripeToken,
} from '../../../services/payments.service';

// ?Components
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

// ?Redux
import { AlertPayload, showAlert } from '../../../redux/slices/alert.slice';
import { RootState } from '../../../redux/store';

// ?Constants
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import ROUTES from '../../../shared/constants/routes';

// ?Hooks
import useCleverTapEvent from '../../../hooks/useClevertapEvent';

// ?Types
import { Order } from '../../../@types/Order.type';
import {
	Card,
	PAYMENT_METHOD,
	PLATFORM_CATALOG,
} from '../../../@types/Checkout.type';

// ?Redux
import { PlatformOS } from '../../../redux/slices/profile.slice';

// ?Utils
import {
	formatPrice,
	isSessionStorageEnabled,
	useStorage,
} from '../../../shared/utils/general.util';
import { Radio, SelectChangeEvent } from '@mui/material';
import { CardInfo } from '../../../pages/checkout/components/organisms/SelectPaymentMethod';
import AddressForm from './AddressForm';
import { setShowModalCheckout } from '../../../redux/slices/plans.slice';
import { getOrderRefresh } from '../../../services/orders.service';
import STORAGE_KEYS from '../../../shared/constants/storage';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY as string);
interface CheckoutStripeWidgetProps {
	order: Order;
	setIsLoading: React.Dispatch<
		React.SetStateAction<{
			coupon: boolean;
			checkout: boolean;
			order: boolean;
		}>
	>;
	category: PAYMENT_METHOD;
	setCategory: (category: PAYMENT_METHOD) => void;
	idUser?: number;
	onPaymentMethodSuccess?: () => void;
	onCancel?: () => void;
}

const CheckoutStripeWidget = forwardRef(
	(
		{
			order,
			onPaymentMethodSuccess,
			onCancel,
			idUser,
			category,
			setCategory,
			setIsLoading,
		}: CheckoutStripeWidgetProps,
		ref,
	) => {
		return (
			<>
				<Elements
					stripe={stripePromise}
					options={{
						mode: 'setup',
						currency: 'mxn',
						locale: 'es',
					}}
				>
					<CheckoutForm
						category={category}
						setCategory={setCategory}
						order={order}
						onPaymentMethodSuccess={onPaymentMethodSuccess}
						onCancel={onCancel}
						idUser={idUser}
						ref={ref}
						setIsLoading={setIsLoading}
					/>
				</Elements>
			</>
		);
	},
);

const CheckoutForm = forwardRef(
	(
		{
			order,
			onPaymentMethodSuccess,
			onCancel,
			setIsLoading,
			category,
			setCategory,
		}: CheckoutStripeWidgetProps,
		ref,
	) => {
		const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);
		const [customerStripeId, setCustomerStripeId] = useState('');
		const [paymentMethodId, setPaymentMethodId] = useState('');
		const [allPaymentsCards, setAllPaymentsCards] = useState<Card[]>([]);
		// const [cardName, setCardName] = useState('');
		// const [errorField, setErrorField] = useState('');
		const userProfile = useSelector((state: RootState) => state.profile.user);

		const stripe = useStripe();
		const elements = useElements();
		const dispatch = useDispatch();
		const logClevertapEvent = useCleverTapEvent();
		const navigate = useNavigate();

		const { tokenSession } = useSelector((state: RootState) => state.auth);
		const { utmInfo } = useSelector((state: RootState) => state.cart);
		const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;
		const [searchParams] = useSearchParams();
		const formRef = useRef<HTMLFormElement>(null);

		useImperativeHandle(ref, () => ({
			childFunction() {
				// Call
				if (formRef.current) {
					if (!!paymentMethodId) {
						setIsLoading((prevValue) => ({ ...prevValue, checkout: true }));
						handleConfirmPayment();
					} else {
						formRef.current.requestSubmit();
					}
				} else {
					if (!!paymentMethodId) {
						setIsLoading((prevValue) => ({ ...prevValue, checkout: true }));
						handleConfirmPayment();
					}
				}
			},
		}));

		const get_ClientIntent = useCallback(async (userId: number) => {
			try {
				const response = await getCreateIntent(userId, tokenSession);
				return response;
			} catch (error: any) {}
		}, []);

		const showAlertTop = ({ title, type, description }: AlertPayload) => {
			const alert = {
				type,
				title,
				description,
			};
			dispatch(showAlert(alert));
		};

		const handlerSaveCard = async (event: React.FormEvent<HTMLFormElement>) => {
			try {
				event.preventDefault();
				setIsLoadingPaymentMethod(true);
				setIsLoading((prevValue) => ({ ...prevValue, checkout: true }));

				if (!stripe) return;

				const { error: submitError } = await elements!.submit();

				if (submitError) {
					showAlertTop({
						title: 'Error',
						type: 'error',
						description:
							submitError?.message ??
							'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
					});
					// if (cardName.length === 0) {
					// 	setErrorField('El nombre del titular es requerido');
					// }
					setIsLoadingPaymentMethod(false);
					setIsLoading((prevValue) => ({ ...prevValue, checkout: false }));

					return;
				}

				const user_id = userProfile?.id;
				const res = await get_ClientIntent(user_id!);
				const clientSecret = res?.Body?.responseCall?.data?.client_secret;

				if (!clientSecret) {
					showAlertTop({
						title: 'Error',
						type: 'error',
						description:
							'Ocurrió un error al intentar guardar la tarjeta, intenta de nuevo',
					});
					setIsLoadingPaymentMethod(false);
					setIsLoading((prevValue) => ({ ...prevValue, checkout: false }));
					return;
				}

				const addressElement = elements?.getElement('address');

				//@ts-ignore
				const { complete, value } = await addressElement?.getValue();

				if (complete) {
					// Allow user to proceed to the next step
					// Optionally, use value to store the address details
				}

				const { error, setupIntent } = await stripe!.confirmSetup({
					elements: elements!,
					redirect: 'if_required',
					clientSecret: clientSecret!,
					confirmParams: {
						return_url: location.href + ROUTES.CHECKOUT,
						// payment_method_data: {
						// 	billing_details: {
						// 		name: cardName.trimStart().trimEnd(),
						// 	},
						// },
					},
				});

				if (error) {
					showAlertTop({
						title: 'Error',
						type: 'error',
						description: error?.message ?? '',
					});
					logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
						event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
						success: false,
					});
					setIsLoadingPaymentMethod(false);
					setIsLoading((prevValue) => ({ ...prevValue, checkout: false }));
				} else {
					const resp = await sendStripeToken(
						customerStripeId ?? '',
						(setupIntent?.payment_method as string) ?? '',
						tokenSession,
					);
					logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
						event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
						success: true,
					});
					showAlertTop({
						title: 'Tarjeta guardada',
						type: 'success',
						description: 'Tarjeta guardada exitosamente',
					});
					getCardList();
					if (resp.id) {
						handleConfirmPayment(resp.id);
					}
					setIsLoadingPaymentMethod(false);
				}
			} catch (error) {
				setIsLoadingPaymentMethod(false);
				setIsLoading((prevValue) => ({ ...prevValue, checkout: false }));
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
				});
			}
		};

		const handleConfirmPayment = async (_paymentId?: string) => {
			try {
				const paymentMethodIdUser = _paymentId ?? paymentMethodId;

				if (!paymentMethodIdUser || isLoadingPaymentMethod) return;

				if (!stripe) return;

				const isSessionAvailable = isSessionStorageEnabled();
				const utmCampaign = isSessionAvailable
					? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
					: utmInfo?.utm_campaign;
				const utmSource = isSessionAvailable
					? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
					: utmInfo?.utm_source;
				const utmMedium = isSessionAvailable
					? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
					: utmInfo?.utm_medium;

				const channelSource = searchParams.get('channel_source');
				const channelApp = searchParams.get('platform_os');

				const additionalInformation = {
					utms: {
						utm_campaign: utmCampaign ?? 'organic',
						utm_source: utmSource ?? 'organic',
						utm_medium: utmMedium ?? 'organic',
					},
				};

				const sourceChannel =
					channelSource === 'app' ||
					platformOS == PlatformOS.IOS ||
					PlatformOS.ANDROID
						? (channelApp ?? platformOS)
						: 'web';

				const res = await postShoppingCartProcess({
					tokenSession,
					cartId: order.id,
					tokenId: paymentMethodIdUser,
					renewSubscription: false,
					additionalInformation,
					sourceChannel,
					paymentMethod: PAYMENT_METHOD.CARD,
					platformId: PLATFORM_CATALOG.STRIPE,
				});

				if (res.statusCode === 412) {
					throw new Error('Error al procesar el pago');
				}

				if (res.status === 'processed') {
					setIsLoading((prevValue) => ({ ...prevValue, checkout: false }));
					onPaymentMethodSuccess?.();
					navigate(ROUTES.ONBOARDING_SUCCESSFUL_PURCHASE);
					dispatch(setShowModalCheckout(false));
					return;
				}

				const { error } = await stripe.confirmPayment({
					clientSecret: res?.transaction?.additional_information.cliente_secret,
					confirmParams: {
						return_url: location.origin + `/onboarding/successful-purchase`,
					},
				});
				if (error) {
					showAlertTop({
						title: 'Error',
						type: 'error',
						description: error.message ?? '',
					});
					const eventProperties = {
						event_type: EVENT_TYPES.PLANS_CHARGED,
						Amount: formatPrice(parseInt(order.total.toString())),
						'Charged ID': order.id.toString(),
						'Payment mode': 'CARD',
						coupon: order?.coupon?.code ?? '',
					};
					logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
				}
				const eventProperties = {
					event_type: EVENT_TYPES.PLANS_CHARGED,
					Amount: order.total.toString(),
					'Charged ID': order?.id?.toString() ?? '',
					'Payment mode':
						order.total === 0 ? PAYMENT_METHOD.TRADITIONAL_CASH : category,
					coupon: order?.coupon?.code ?? '',
				};
				logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
				onPaymentMethodSuccess?.();
			} catch (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: 'Ocurrió un error al procesar el pago, intenta de nuevo',
				});
			} finally {
				setIsLoadingPaymentMethod(false);
				setIsLoading((prevValue) => ({ ...prevValue, checkout: false }));

				const eventProperties = {
					event_type: EVENT_TYPES.PLANS_CHARGED,
					Amount: formatPrice(parseInt(order.total.toString())),
					'Charged ID': order.id.toString(),
					'Payment mode': 'CARD',
					coupon: order?.coupon?.code ?? '',
				};
				logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
			}
		};

		const getCustomer = useCallback(async () => {
			try {
				const response = await getClientCustomer(tokenSession);
				if (response) {
					setCustomerStripeId(response[0].id ?? '');
				}
			} catch (error: any) {
				if (error?.response?.status === 404) {
					const response = await createNewCustomer(tokenSession);
					if (response) {
						setCustomerStripeId(response.id ?? '');
					}
					return;
				}
			}
		}, []);

		useEffect(() => {
			getCustomer();
			getCardList();
		}, []);

		useEffect(() => {
			if (category !== PAYMENT_METHOD.CARD) {
				setPaymentMethodId('');
				// setErrorField('');
			}
		}, [category]);

		const getCardList = useCallback(async () => {
			try {
				// setIsLoadingCards(true);
				const resp: Card[] = await getPaymentCards(tokenSession);
				setAllPaymentsCards(resp);
				if (resp?.length > 0) {
					const lastCard = resp[0];
					setPaymentMethodId(lastCard?.id);
				}
			} catch (error) {
			} finally {
				// setIsLoadingCards(false);
			}
		}, []);

		const getUserOrder = async (paymentMethod: PAYMENT_METHOD) => {
			try {
				const params = `?status=open&status=failed&is_renewal=false&payment_method=${paymentMethod}`;
				const orders = await getOrderRefresh(params, tokenSession);

				return orders;
			} catch (error) {
				return error;
			}
		};

		const handleChangeCard = (event: SelectChangeEvent) => {
			setCategory(PAYMENT_METHOD.CARD);
			setPaymentMethodId(event.target.value as string);
			getUserOrder(PAYMENT_METHOD.CARD);
		};

		const handleAddNewCard = () => {
			setCategory(PAYMENT_METHOD.CARD);
			setPaymentMethodId('');
			getUserOrder(PAYMENT_METHOD.CARD);
		};

		// const onCardNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// 	setCardName(event.target.value);
		// 	setErrorField('');
		// };

		return (
			<>
				{allPaymentsCards.length > 0 && (
					<div>
						{' '}
						<Text
							size='body-3'
							sx='mb-1'
							color='darkMenu'
						>
							Tarjetas guardadas
						</Text>
						{allPaymentsCards.map((card) => (
							<div key={`cards-${card.id}`}>
								<div className='flex flex-row mb-2 items-center gap-y-4'>
									<Radio
										checked={paymentMethodId === card.id}
										onChange={handleChangeCard}
										value={card.id}
										name='radio-buttons-payments'
										inputProps={{ 'aria-label': 'A' }}
									/>

									<CardInfo
										card={card}
										hideExpireDate
									/>
								</div>
							</div>
						))}
						<Button
							variant='tertiary'
							label='Agregar nueva tarjeta'
							sx='text-center !h-[46px]'
							onClick={handleAddNewCard}
						/>
						<hr className='my-4' />
					</div>
				)}

				{paymentMethodId === '' && category === PAYMENT_METHOD.CARD && (
					<>
						{/* <div className='flex flex-col gap-y-1 mb-3  '>
							<label className='text-h-gray-70'>
								Nombre completo del titular de la tarjeta{' '}
							</label>
							<input
								type='text'
								name='cardName'
								value={cardName}
								onChange={onCardNameChange}
								className={`border border-gray-300 p-3 focus:shadow rounded-[5px] text-h-medium leading-[1.15] focus:outline-none focus:ring-2 focus:ring-blue-300 focus:shadow-blue-300 focus:border-h-primary-50 dark:bg-white ${
									errorField.length > 0 ? '!border-[#df1b41] border-2' : ''
								}`}
								placeholder='Nombres y apellidos'
							/>
							{errorField && (
								<Text
									size='caption'
									color='red'
								>
									{errorField}
								</Text>
							)}
						</div> */}
						<form
							ref={formRef}
							onSubmit={handlerSaveCard}
						>
							{elements && <PaymentElement />}
							<div
								className={`flex flex-col-reverse md:flex-row ${
									onCancel ? 'justify-between' : 'justify-end'
								} gap-x-2 gap-y-4 mt-4 `}
							>
								{onCancel && (
									<Button
										variant='tertiary'
										label='Cancelar'
										sx='text-center !h-[46px]'
										onClick={onCancel}
									/>
								)}
							</div>
						</form>
						<AddressForm />
					</>
				)}
				{paymentMethodId === '' &&
					category !== PAYMENT_METHOD.CARD &&
					allPaymentsCards.length === 0 && (
						<button
							className='flex justify-between items-center w-full border-2 border-h-neutral-90 rounded-lg px-4 py-3 mb-4'
							onClick={() => setCategory(PAYMENT_METHOD.CARD)}
						>
							<Text>Tarjeta de Crédito/Débito </Text>
							<CaretDown size={20} />
						</button>
					)}
			</>
		);
	},
);

export default CheckoutStripeWidget;
