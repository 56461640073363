import { useEffect, useRef, useState } from 'react';
import Button from '../../components/atoms/Button';
import Text from '../../components/atoms/Text';
import Heading from '../../components/atoms/Heading';
import { PaperPlaneTilt, XCircle } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
	getOnboardingAnalysisIA,
	getOnboardingIA,
	postChatIASamantha,
} from '../../services/heru-ai.service';
import {
	IChatIASamantha,
	IMessage,
	IOnboardingResponseSamantha,
} from '../../@types/IA.type';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../shared/constants/routes';
import { setIsOnboarding } from '../../redux/slices/config.slice';
import ModalSkipAnalysis from './organisms/ModalSkipAnalysis';
import IntroMessage from './organisms/IntroMessage';
import TypingDots from './molecules/TypingDots';
import { CircularProgress } from '@mui/material';
import OnboardingAnalysis from './organisms/OnboardingAnalysis';
import newHeruLogo from '../../assets/img/svg/heru-logo-v3.svg';
import OnboardingProduct from './organisms/OnboardingProduct';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import growthbookFile from '../../shared/constants/growthbook';
import { MonthlyDeclarationSubscription } from '../../@types/Products.type';
import { setShowModalCheckout } from '../../redux/slices/plans.slice';
import { sendUserInteractionEvent } from '../../services/user-interaction.service';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import heruIcon from '../../assets/img/svg/chat-heru-icon.svg';
import ModalCheckout from '../../components/organisms/ModalCheckout';
import ModalAIFailed from './organisms/ModalAIFailed';
import clevertap from 'clevertap-web-sdk';

const Intro = () => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const hideCloseOnboarding = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.HIDE_CLOSE_ONBOARDING,
	);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const stepParams = searchParams.get('step');

	const checkoutStatusParams = searchParams.get('checkout');

	const [timer, setTimer] = useState(30);
	const [step, setStep] = useState(stepParams ? parseInt(stepParams) : 1);
	const [inputValue, setInputValue] = useState('');
	const [isTyping, setIsTyping] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalAIFailed, setShowModalAIFailed] = useState(false);
	const [messages, setMessages] = useState<IMessage[]>([]);
	const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
	const [lastOnboardingProgress, setLastOnboardingProgress] =
		useState<IOnboardingResponseSamantha | null>(null);
	const [userId, setUserId] = useState<number | null>(null);
	const [analysis, setAnalysis] = useState<any | null>(null);
	const [isLoading, setIsLoading] = useState({
		message: false,
		lastOnboardingProgress: false,
		analysis: false,
	});

	const lastMessageRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const getOnboardingIAProgress = async (userId: number) => {
		if (lastOnboardingProgress?.userCurrentData) return;
		try {
			setIsLoading({ ...isLoading, lastOnboardingProgress: true });
			const response: IOnboardingResponseSamantha = await getOnboardingIA(
				userId,
				tokenSession,
			);
			setLastOnboardingProgress(response);
			return response;
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, lastOnboardingProgress: false });
		}
	};

	const getAnalysisIA = async (userId: number, onboardingId: number) => {
		try {
			setIsLoading({ ...isLoading, analysis: true });
			await getOnboardingIAProgress(userId);
			const response = await getOnboardingAnalysisIA(userId, onboardingId);
			if (response && response.introduction) setStep(4);
			setAnalysis(response);
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, analysis: false });
		}
	};

	const callSamanthaAPI = async (userMessage: string, userId: number) => {
		try {
			const lastMessage = messages?.[messages.length - 1].content;
			const response: IChatIASamantha = await postChatIASamantha(
				{
					userId,
					userName: userProfile?.first_name || '-',
					assistantLatestQuestion: lastMessage,
					userMessage,
				},
				tokenSession,
			);

			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleSendMessage = async (e: React.FormEvent<any>) => {
		e.preventDefault();
		if (
			messages.length === 0 ||
			userId === null ||
			isLoading.message ||
			isTyping
		)
			return;

		if (inputValue.trim() !== '') {
			try {
				setIsLoading((prev) => ({ ...prev, message: true }));
				const newMessage: IMessage = {
					content: inputValue,
					role: 'user',
				};
				setMessages((prevMessages) => [...prevMessages, newMessage]);
				setIsTyping(true);
				setInputValue('');

				const inputCleaned = inputValue.trim();

				const response: IChatIASamantha = await callSamanthaAPI(
					inputCleaned,
					userId,
				);
				const aiResponse = {
					content: response?.assistantResponse || '',
					role: 'assistant',
				};

				setMessages((prevMessages) => [
					...prevMessages,
					aiResponse as IMessage,
				]);
				if (response.percentage.percentage === 100 && response.onboardingId) {
					getAnalysisIA(userId, response.onboardingId);
					setIsOnboardingCompleted(true);
				}
			} catch (error) {
				setShowModalAIFailed(true);
			} finally {
				setIsTyping(false);
				setIsLoading((prev) => ({ ...prev, message: false }));
			}
		}
	};

	const firstMessage = `¡Bienvenido ${userProfile?.first_name}! Llegaste al lugar indicado para ayudarte a cumplir con el SAT sin estrés. 

Con Heru puedes:
- Declarar tus impuestos
- Generar tus facturas
- Obtener asesoría contable especializada y mucho más.
    
Para asesorarte mejor, por favor cuéntame un poco sobre qué estás buscando con Heru, sobre tu situación fiscal y tus actividades económicas.`;

	const goToHome = () => {
		dispatch(setIsOnboarding(false));
		navigate(ROUTES.ROOT);
	};

	const addFirstMessage = async () => {
		setIsTyping(true);
		await new Promise((resolve) => setTimeout(resolve, 3000));
		setIsTyping(false);
		setMessages([{ role: 'assistant', content: firstMessage }]);
	};

	useEffect(() => {
		if (checkoutStatusParams === 'open') {
			dispatch(setShowModalCheckout(true));
		}
	}, [checkoutStatusParams]);

	useEffect(() => {
		if (!userProfile || !userProfile?.id) return;

		const userId = true
			? userProfile?.id
			: 99990000 + Math.floor(Math.random() * 10000);
		setUserId(Number(userId));

		loadGrowthbook();
		growthbookFile?.setAttributes({
			user_id: Number(userProfile?.id),
		});

		try {
			clevertap.onUserLogin.push({
				Site: {
					Name: `${userProfile?.first_name} ${userProfile?.father_surname}`,
					Identity: userProfile?.id,
					Email: userProfile?.email,
					Phone: `${userProfile?.country_code}${userProfile?.cellphone}`,
				},
			});
		} catch (error) {}
	}, [userProfile]);

	useEffect(() => {
		if (!userId) return;
		(async () => {
			const lastOnboardingProgress = await getOnboardingIAProgress(userId);

			if (lastOnboardingProgress?.percentage.percentage === 100) {
				getAnalysisIA(userId, lastOnboardingProgress.userCurrentData.id);
				setIsOnboardingCompleted(true);
			}
		})();
	}, [userId]);

	useEffect(() => {
		if (lastMessageRef.current)
			lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
	}, [messages]);

	useEffect(() => {
		if (lastOnboardingProgress?.userCurrentData) {
			setMessages(lastOnboardingProgress?.userCurrentData?.thread);
		} else if (lastOnboardingProgress) {
			addFirstMessage();
		}
	}, [lastOnboardingProgress, userId]);

	const nextStep = () => navigate(ROUTES.PLANS_AND_PRICES);

	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};

	const hideOnboarding = async () => {
		if (!userProfile?.id) return;

		try {
			await sendUserInteractionEvent(
				userProfile.id,
				ANALYTICS_EVENTS.SAMANTHA_EVENT,
				'web',
				{
					action: 'hide_onboarding',
				},
			);
		} catch (error) {}

		setShowModal(true);
	};

	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (isOnboardingCompleted && timer > 0) {
			interval = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);
		} else if (timer === 0) {
			setStep(3);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [isOnboardingCompleted, timer]);

	return (
		<div className='bg-h-neutral-94'>
			<ModalCheckout />
			<div className='sticky top-0 z-50 w-full shadow-md px-4 sm:px-8 py-3'>
				<img
					src={newHeruLogo}
					alt='logo'
					className='w-[50px] sm:w-[80px]'
				/>
			</div>

			<ModalSkipAnalysis
				showModal={showModal}
				setShowModal={setShowModal}
				goToHome={goToHome}
				title='Te perderás de tu análisis fiscal previo.'
				description='¿Estás seguro de que deseas terminar el chat?'
			/>

			<ModalAIFailed
				isOpen={showModalAIFailed}
				onClose={() => setShowModalAIFailed(false)}
				onConfirm={() => navigate(ROUTES.PLANS_AND_PRICES)}
				title='Nuestra plataforma está recibiendo un alto número de peticiones que no nos permiten continuar con tu análisis fiscal.'
				description='Sin embargo, te vamos a dirigir a nuestra sección de planes para que conozcas más de nosotros y puedas elegir el plan más de acuedo para ti.'
			/>

			<div
				className={`${
					step !== 4 && step !== 5
						? 'p-3 md:p-5 pb-0 md:pb-6 lg:px-12 lg:py-12'
						: ''
				} w-full h-full relative max-w-[860px] mx-auto`}
			>
				{/* Intro a ver el chat con el bot */}{' '}
				{step == 1 && (
					<IntroMessage
						userName={userProfile?.first_name || ''}
						setStep={setStep}
					/>
				)}
				{/*  Chat con el bot para conocer al usuario fiscalmente */}
				{step === 2 && (
					<div className='fade-in-bottom'>
						<div className='flex justify-between gap-2 items-center mb-4 sm:mb-8'>
							<Heading
								size='xs'
								sx='!block !text-[18px] !leading-[22px]  md:!text-[36px] !leading-[42px]'
							>
								Comencemos con tu
								<span className='!text-h-primary-50 font-bold'>
									{' '}
									análisis fiscal.
								</span>
							</Heading>
							{!hideCloseOnboarding && (
								<button
									onClick={hideOnboarding}
									className=' text-h-neutral-70 sm:-mt-20'
								>
									<XCircle
										size={30}
										weight='fill'
									/>
								</button>
							)}
						</div>

						<div className='flex flex-col bg-background text-foreground '>
							<div
								className={`flex flex-col w-full overflow-y-auto p-0 sm:p-4 justify-start  h-[calc(100vh-330px)] md:h-[calc(100vh-420px)] `}
							>
								{messages.map((message, index) => (
									<div
										ref={lastMessageRef}
										key={`message-${index}`}
										className={`fade-in-message flex w-full items-start mb-4  ${
											message.role === 'user' ? 'justify-end' : 'justify-start '
										} `}
									>
										<div
											className={`flex gap-2  max-w-[90%] md:max-w-[60%] ${
												message.role === 'user'
													? 'flex-row-reverse'
													: 'flex-row'
											}`}
										>
											<div
												className={`flex items-center justify-center size-10 rounded-[24px]  ${
													message.role === 'user' ? ' bg-h-primary-40 p-4' : ''
												}`}
											>
												{message.role !== 'user' ? (
													<img
														src={heruIcon}
														alt='user'
														width={40}
														height={40}
														className='object-contain  min-w-[40px] min-h-[40px]'
													/>
												) : (
													<Heading
														size='xs'
														weight='bold'
														color='white'
													>
														{userProfile?.first_name?.charAt(0) || ''}
													</Heading>
												)}
											</div>
											<div
												className={`flex flex-col gap-2 ${
													message.role === 'user' ? 'items-end' : 'items-start'
												}`}
											>
												<Text
													size='body-3'
													weight='light'
												>
													{message.role === 'user'
														? userProfile?.first_name || 'Tú'
														: 'Asistente virtual heru'}
												</Text>
												<div
													className={` p-3 ${
														message.role === 'user'
															? 'sm:ml-10 text-right bg-primary text-primary-foreground bg-h-neutral-90 rounded-l-[24px] rounded-br-[24px] shadow-custom'
															: 'sm:mr-10 bg-muted bg-h-primary-40 text-white rounded-r-[24px] rounded-bl-[24px]'
													}`}
												>
													<p
														className='font-light whitespace-pre-wrap'
														dangerouslySetInnerHTML={{
															__html: message.content.replace(
																/(¿[^?]*\?)/g,
																'<span class="bg-white/10 font-medium block my-1.5 rounded-lg">$1</span>',
															),
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								))}
								{isTyping && <TypingDots />}
							</div>
							{!isOnboardingCompleted && (
								<div>
									<div className='border border-h-neutral-90 bg-white rounded-2xl mt-4 sm:mt-10'>
										<form
											className='flex items-start py-2 px-3'
											onSubmit={handleSendMessage}
										>
											<textarea
												value={inputValue}
												onChange={(e) => setInputValue(e.target.value)}
												className='text-neutral-500 bg-transparent outline-none w-full h-full resize-none min-h-[90px]'
												placeholder='Escribe tu mensaje aquí'
												rows={3}
												onKeyDown={(e) => {
													if (e.key === 'Enter') handleSendMessage(e);
												}}
											/>
											<Button
												type='submit'
												disabled={isLoading.message || isTyping}
												sx='!w-fit'
												label=''
												variant='text'
												icon={
													<PaperPlaneTilt
														size={32}
														weight='duotone'
														color='#0C3961'
													/>
												}
											/>
										</form>
									</div>
									<div className='text-center text-h-neutral-50 text-sm mt-1'>
										Por favor escribe toda tu respuesta antes de enviarla
									</div>
								</div>
							)}
							{isOnboardingCompleted && (
								<div className='flex items-center justify-center py-2 px-3'>
									<div className='flex gap-4 items-center justify-center w-full bg-h-primary-95 rounded-lg p-2'>
										<div className='relative'>
											<CircularProgress size={28} />
											<span className='absolute top-0 left-0 text-h-primary-50 text-sm size-[28px] flex items-center justify-center'>
												{timer}
											</span>
										</div>
										<Text color='primary'>
											Estamos analizando tu información...
										</Text>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
				{/* Pre Analisis Fiscal */}
				{[3, 4].includes(step) && (
					<OnboardingAnalysis
						userName={`${userProfile?.first_name || ''} ${
							userProfile?.father_surname || ''
						}`}
						rfc={lastOnboardingProgress?.userCurrentData?.rfc || ''}
						economicActivities={
							lastOnboardingProgress?.userCurrentData
								?.economic_activities_detail || ''
						}
						analysis={analysis}
						onClose={() => {
							navigate(ROUTES.PLANS_AND_PRICES);
						}}
						nextStep={nextStep}
					/>
				)}
				{step === 5 && (
					<OnboardingProduct
						productSlug={
							analysis?.slug ?? MonthlyDeclarationSubscription.PLAN_PLATFORM
						}
						regimes={analysis?.regimesCodes ?? []}
					/>
				)}
			</div>
		</div>
	);
};

export default Intro;
