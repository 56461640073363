import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//? Components
import MobileMenu from '../../components/organisms/MobileMenu';
import CollapsibleSidebar from '../../components/organisms/CollapsibleSidebar';

//? Redux
import { RootState } from '../../redux/store';
import { setIsDesktop } from '../../redux/slices/dimensions.slice';
import Alert, { ALERT_TYPE } from '../../components/molecules/Alert';
import { hideAlert, showAlert } from '../../redux/slices/alert.slice';
import {
	addAdditionalInformation,
	addProductToCart,
	setCouponCode,
} from '../../redux/slices/cart.slice';
import ROUTES from '../../shared/constants/routes';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';
import { SEARCH_PARAMS } from '../../shared/constants/searchParams';
import Topbar from '../../components/organisms/Topbar';
import {
	isSessionStorageEnabled,
	useStorage,
} from '../../shared/utils/general.util';
import { setGoto } from '../../redux/slices/auth.slice';
import { fetchCheckUserRappi } from '../../services/invoicing.service';
import { addRappiCertificates } from '../../redux/slices/rappi.slice';
import MissingProfileInfoModal from '../../components/organisms/MissingProfileInfoModal';
import useUserShoppingCarts from '../../hooks/useUserShoppingCarts';
import { getProducts } from '../../services/plans.service';
import { addProducts } from '../../redux/slices/products.slice';
import STORAGE_KEYS from '../../shared/constants/storage';
import { PlatformOS } from '../../redux/slices/profile.slice';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
	const dispatch = useDispatch();
	const isDesktop = useSelector((state: RootState) => state.isDesktop.value);
	const alert = useSelector((state: RootState) => state.alert);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const isFromIOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) === PlatformOS.IOS;
	const { isOnboarding } = useSelector((state: RootState) => state.config);
	const { getUserRefreshOrder } = useUserShoppingCarts();

	const { products } = useSelector((state: RootState) => state.productList);

	const [expanded, setExpanded] = useState(false);

	const location = useLocation();
	const [searchParams] = useSearchParams();

	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			dispatch(addProducts(data));
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Aviso',
				description: 'Error, intente más tarde',
			};
			dispatch(showAlert(alert));
		}
	}, [tokenSession]);

	const getIsRappiUser = useCallback(async () => {
		try {
			if (!userProfile?.id) return;
			const resource = await fetchCheckUserRappi(
				userProfile?.id!,
				tokenSession,
			);
			dispatch(addRappiCertificates(resource));
		} catch (error) {}
	}, [userProfile]);

	useEffect(() => {
		const handleResize = () => {
			const newIsDesktop = window.innerWidth > 768;
			dispatch(setIsDesktop(newIsDesktop));
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch]);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	useEffect(() => {
		const isSessionStoragAvailable = isSessionStorageEnabled();
		if (isSessionStoragAvailable) {
			sessionStorage.setItem(SESSION_STORAGE_KEY.GO_TO, location.pathname);
		} else {
			dispatch(setGoto(location.pathname));
		}

		const productsAdded = searchParams.getAll('add_to_cart');
		if (productsAdded.length > 0) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEY.PRODUCTS_ADDED,
					JSON.stringify(productsAdded),
				);
			} else {
				dispatch(addProductToCart(JSON.stringify(productsAdded)));
			}
		}

		const cuponAdded = searchParams.get(SEARCH_PARAMS.CUPON_CODE);
		if (cuponAdded) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEY.CUPON_CODE_ADDED,
					cuponAdded,
				);
			} else {
				dispatch(setCouponCode(cuponAdded));
			}
		}

		const yearAdded = searchParams.get(SEARCH_PARAMS.YEAR);
		if (yearAdded) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(SESSION_STORAGE_KEY.YEAR_ADDED, yearAdded);
			} else {
				dispatch(addAdditionalInformation({ year: yearAdded }));
			}
		}

		const activeTab = searchParams.get('activeTab');
		if (activeTab) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(SESSION_STORAGE_KEY.ACTIVE_TAB, activeTab);
			}
		}
	}, []);

	useEffect(() => {
		getIsRappiUser();
		if (
			location.pathname === ROUTES.CHECKOUT ||
			location.pathname === ROUTES.PLANS_AND_PRICES
		) {
			return;
		}
	}, [userProfile?.id]);

	useEffect(() => {
		getUserRefreshOrder();
	}, [products]);

	useEffect(() => {
		getAllProducts();
	}, []);

	const isIntro = location.pathname.includes('intro');
	const userHaveIncompleteProfile = Boolean(
		userProfile && (!userProfile?.first_name || !userProfile?.email),
	);

	return (
		<div className='flex relative h-full'>
			<MissingProfileInfoModal isOpen={userHaveIncompleteProfile} />
			{isDesktop && !isIntro && (
				<div
					className={`md:flex md:flex-col md:fixed md:inset-y-0 z-[80] h-full ${
						!isDesktop ? 'hidden md:w-0' : expanded ? 'w-64' : 'md:w-0'
					}`}
				>
					<CollapsibleSidebar
						expanded={expanded}
						setExpanded={setExpanded}
					/>
				</div>
			)}

			<div
				className={`flex flex-col w-full ${
					isIntro ? '' : isFromIOS ? 'pt-[100px]' : 'pt-12'
				}`}
			>
				{!isIntro && <Topbar showLogo={isOnboarding} />}

				<main
					className={`flex-1 ${
						isDesktop && !isIntro ? (expanded ? 'ml-64' : 'ml-[60px]') : 'ml-0'
					} ${isIntro ? '' : 'pb-14 md:pb-0'}`}
				>
					{!isIntro && <MobileMenu />}

					{alert.showAlert && (
						<Alert
							type={alert.type}
							description={alert.description}
							title={alert.title}
						/>
					)}
					{children}
				</main>
			</div>
		</div>
	);
};

export default React.memo(MainLayout);
