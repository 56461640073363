import Text from '../../../components/atoms/Text';
import Heading from '../../../components/atoms/Heading';
import { Check } from '@phosphor-icons/react';
import PrimaryButton from '../../../components/atoms/Button';
import { useMediaQuery } from '@mui/material';

const ProductCardSimple = ({
	name,
	price,
	description,
	benefitsTitle = 'Beneficios incluidos',
	benefits,
	ctaLabel,
	ctaAction,
	className,
	imgBanner,
	stylesDescription,
}: {
	name: React.ReactNode;
	price?: number;
	description: string;
	benefits: string[];
	benefitsTitle?: string;
	ctaAction: () => void;
	ctaLabel: string;
	className?: string;
	imgBanner: string;
	stylesDescription?: string;
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');
	return (
		<div
			className={`bg-white shadow p-4 rounded-2xl flex flex-col gap-6 justify-start relative ${className} overflow-hidden`}
		>
			{imgBanner && (
				<img
					src={imgBanner}
					alt={description}
					className='size-32 absolute -top-7 -right-4'
				/>
			)}
			<Heading
				size='xs'
				weight='bold'
				color='dark'
				sx='text-left mt-1 max-w-[200px] sm:max-w-none'
			>
				{name}
			</Heading>

			{price && (
				<Heading
					size='s'
					weight='bold'
					color='dark'
					responsive={isMobile}
					sx='text-left -my-2'
				>
					${price}
				</Heading>
			)}

			<Text
				color='dark'
				weight='light'
				sx={`min-h-[100px] -mt-4 ${stylesDescription}`}
			>
				{description}
			</Text>

			<PrimaryButton
				label={ctaLabel}
				onClick={ctaAction}
				sx='disabled:!text-white !bg-h-primary'
			/>

			<hr />

			<Text
				size='caption'
				weight='bold'
				color='medium'
			>
				{benefitsTitle}
			</Text>

			<div className='flex flex-col gap-2'>
				{benefits.map((benefit, index) => (
					<div
						key={index}
						className='flex items-center gap-2 border-b border-h-neutral-200 pb-2'
					>
						<Check
							className='text-h-primary min-w-[14px]'
							size={14}
							weight='bold'
						/>
						<Text
							size='caption'
							color='dark'
							weight='light'
						>
							{benefit}
						</Text>
					</div>
				))}
			</div>
		</div>
	);
};

export default ProductCardSimple;
