import { initializeApp } from 'firebase/app';
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue,
} from 'firebase/remote-config';
import { useCallback, useEffect, useState } from 'react';

const useRemoteConfig = (remoteKeys: string[]) => {
	const [remoteProducts, setRemoteProducts] =
		useState<Record<string, string>[]>();
	const [isLoadingRemoteProducts, setIsLoadingRemoteProducts] = useState(true);
	const [error, setError] = useState(null);

	const fetchValuesByKeys = (
		keysToFetch: string[],
		setCategory: any,
		remoteConfig: any,
	) => {
		const remoteProductsArray = [];

		for (const key of keysToFetch) {
			try {
				const value = getValue(remoteConfig, key);
				remoteProductsArray.push(JSON.parse(value.asString()));
			} catch (error) {
			}
		}

		setCategory(remoteProductsArray);
	};

	const fetchRemoteConfig = useCallback(async () => {
		const firebaseConfig = {
			apiKey: import.meta.env.VITE_API_KEY_FIREBASE,
			authDomain: import.meta.env.VITE_AUTH_DOMAIN_FIREBASE,
			projectId: import.meta.env.VITE_PROJECT_ID_FIREBASE,
			storageBucket: import.meta.env.VITE_STORAGE_BUCKET_FIREBASE,
			messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID_FIREBASE,
			appId: import.meta.env.VITE_APP_ID_FIREBASE,
		};
		const app = initializeApp(firebaseConfig);
		const firebaseRemoteConfig = getRemoteConfig(app);
		// UPDATE Remote config IN 3 SECONDS (DEV ONLY!):
		firebaseRemoteConfig.settings = {
			// minimumFetchIntervalMillis: 60000, // 1 min
			minimumFetchIntervalMillis: 43200000, // 12 hours
			fetchTimeoutMillis: 60000,
		};
		setIsLoadingRemoteProducts(true);

		try {
			await fetchAndActivate(firebaseRemoteConfig);
			fetchValuesByKeys(remoteKeys, setRemoteProducts, firebaseRemoteConfig);
		} catch (error) {
			//@ts-ignore
			setError(error);
		} finally {
			setIsLoadingRemoteProducts(false);
		}
	}, []);

	useEffect(() => {
		fetchRemoteConfig();
	}, []);

	return { remoteProducts, isLoadingRemoteProducts, error };
};

export default useRemoteConfig;
