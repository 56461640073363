import React from 'react';
import Modal from '../../../components/molecules/Modal/Modal';
import { PAYMENT_METHOD } from '../../../@types/Checkout.type';
import { formatPrice } from '../../../shared/utils/general.util';
import CustomIcon from '../../../components/atoms/CustomIcon';
import Text from '../../../components/atoms/Text';
import { CopySimple } from '@phosphor-icons/react';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import { showAlert } from '../../../redux/slices/alert.slice';
import { useDispatch } from 'react-redux';
import HeruLogoIcon from '../../../assets/img/svg/heru-logo-v3.svg';

interface Props {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	paymentMethod: string;
	total: number;
	reference: string;
}

const ModalReference: React.FC<Props> = ({
	paymentMethod,
	total,
	reference,
	showModal,
	setShowModal,
}) => {
	const dispatch = useDispatch();

	const listSpei = [
		<li key={1}>
			<strong>1.</strong> Accede a tu banca en línea.
		</li>,
		<li key={2}>
			<strong>2.</strong> Da de alta la CLABE en esta ficha.{' '}
			<strong>El banco deberá de ser STP</strong>.
		</li>,
		<li key={3}>
			<strong>3.</strong> Como beneficiario y concepto ingresa el nombre{' '}
			<strong>“Heru Servicios”</strong>.
		</li>,
		<li key={4}>
			<strong>4.</strong> Realiza la transferencia correspondiente por la
			cantidad exacta en esta ficha,{' '}
			<strong>de lo contrario se rechazará el cargo</strong>.
		</li>,
		<li key={5}>
			<strong>5.</strong>Al confirmar tu pago, el portal de tu banco generará un
			comprobante digital.{' '}
			<strong>
				En el podrás verificar que se haya realizado correctamente.
			</strong>{' '}
			Conserva este comprobante de pago.
		</li>,
	];
	const listCash = [
		<li key={1}>
			<strong>1.</strong> Acude a la tienda OXXO más cercana.{' '}
			<a
				href='https://www.google.com.mx/maps/search/oxxo/'
				target='_blank'
				className='!text-h-primary'
			>
				Encuéntrala aquí
			</a>
			.
		</li>,
		<li key={2}>
			<strong>2.</strong> Indica en caja que quieres realizar un pago de{' '}
			<strong>OXXOPay</strong>.
		</li>,
		<li key={3}>
			<strong>3.</strong> Dicta al cajero el número de referencia en esta ficha
			para que tecleé directamete en la pantalla de venta.
		</li>,
		<li key={4}>
			<strong>4.</strong> Realiza el pago correspondiente con dinero en
			efectivo.
		</li>,
		<li key={5}>
			<strong>5.</strong> Al confirmar tu pago, el cajero te entregará un
			comprobante impreso.{' '}
			<strong>
				En el podrás verificar que se haya realizado correctamente.
			</strong>{' '}
			Conserva este comprobante de pago.
		</li>,
	];

	const copyToClipboard = () => {
		navigator.clipboard.writeText(reference).then(() => {
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'Éxito',
				description: 'Texto copiado al portapapeles',
			};
			dispatch(showAlert(alert));
		});
	};

	return (
		<Modal
			sx='w-full md:w-[600px]'
			open={showModal}
			onClose={() => setShowModal(false)}
		>
			<div className='rounded-md flex flex-col shadow-md p-4'>
				<div className='mt-2 mb-4 flex items-center justify-center'>
					<img
						src={HeruLogoIcon}
						alt='heru_logo'
						className='w-14'
					/>
				</div>

				<div className='flex flex-col'>
					<div className='flex items-center justify-between'>
						<div>
							{paymentMethod === PAYMENT_METHOD.SPEI ? (
								<CustomIcon
									sx='ml-0'
									color={'var(--h-primary)'}
									name={'spei'}
									width={72}
								/>
							) : (
								<CustomIcon
									sx='ml-0'
									color={'var(--h-primary)'}
									name={'oxxo'}
									width={72}
								/>
							)}
						</div>

						<div className='flex flex-col items-end mb-4'>
							<Text size='caption'>MONTO A PAGAR</Text>
							<Text weight='bold'>$ {formatPrice(total)} MXN</Text>
						</div>
					</div>
					<Text
						color='gray'
						size='body-3'
						sx='mb-4'
					>
						{paymentMethod === PAYMENT_METHOD.SPEI
							? 'Utiliza exactamente esta cantidad al realizar el pago.'
							: 'OXXO cobrará una comisión adicional al momento de realizar el pago.'}
					</Text>
					<Text
						color='primary'
						weight='medium'
						sx='mb-2'
					>
						{paymentMethod === PAYMENT_METHOD.SPEI ? 'CLABE' : 'REFERENCIA'}
					</Text>

					<div
						onClick={copyToClipboard}
						className='flex items-center justify-center bg-h-primary rounded-md py-2 cursor-pointer'
					>
						<CopySimple
							size={18}
							color='var(--h-text-white)'
							weight='fill'
							className='mr-2'
						/>
						<Text color='white'>{reference}</Text>
					</div>

					<div className='flex flex-col mt-4'>
						<Text
							weight='medium'
							sx='mb-4'
							color='primary'
						>
							INSTRUCCIONES
						</Text>
						<ol className='ml-4 mb-2'>
							{paymentMethod === PAYMENT_METHOD.SPEI
								? listSpei.map((item) => item)
								: listCash.map((item) => item)}
						</ol>
						<div className='flex flex-col items-center justify-center bg-h-success-95 shadow-md px-4 py-2'>
							<Text
								color='green'
								sx='text-center'
								weight='medium'
							>
								Al completar estos pasos recibirás un correo de Heru confirmando
								tu pago.
							</Text>
						</div>
						<Text
							weight='light'
							size='mini-1'
							sx='!block my-4'
						>
							*La confirmación de pago puede tardar hasta 24 horas. En caso de
							no recibir el correo de confirmación, puedes contactarnos a{' '}
							<a
								href='mailto:hola@heru.mx'
								className='text-h-primary'
							>
								ayuda@heru.mx
							</a>
						</Text>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalReference;
