import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Modal from '../../../components/molecules/Modal/Modal';
import { setShowModalCancelPendingOrder } from '../../../redux/slices/plans.slice';
import { changeShoppingCartStatus } from '../../../services/shoppingCart.service';
import Text from '../../../components/atoms/Text';
import PrimaryButton from '../../../components/atoms/Button';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import { useState } from 'react';
import { getOrderRefresh } from '../../../services/orders.service';
import { setRefreshOrders } from '../../../redux/slices/cart.slice';

const ModalCancelPendingOrder = () => {
	const dispatch = useDispatch();
	const { showModalCancelPendingOrder } = useSelector(
		(state: RootState) => state.plans,
	);
	const { refreshOrders } = useSelector((state: RootState) => state.cart);
	const pendingOrders = refreshOrders.find(
		(order) => order.status === 'pending',
	);

	const [isLoading, setIsLoading] = useState({
		changeOrderStatus: false,
	});

	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const getUserOrder = async () => {
		try {
			const orders = await getOrderRefresh('', tokenSession);
			dispatch(setRefreshOrders(orders));
			return orders;
		} catch (error) {
			return error;
		}
	};

	const changeOrderStatus = async () => {
		try {
			setIsLoading({ ...isLoading, changeOrderStatus: true });
			await changeShoppingCartStatus({
				shoppingCartId: pendingOrders?.id,
				status: 'closed',
			});
			dispatch(
				showAlert({
					type: ALERT_TYPE.SUCCESS,
					title: 'Orden cancelada exitosamente',
					description:
						'Te enviaremos un correo con el detalle de la cancelación',
				}),
			);
			await getUserOrder();
			dispatch(setShowModalCancelPendingOrder(false));
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error',
					description: 'Ocurrió un error al cancelar la orden',
				}),
			);
		} finally {
			setIsLoading({ ...isLoading, changeOrderStatus: false });
		}
	};

	return (
		<Modal
			open={showModalCancelPendingOrder}
			onClose={() => dispatch(setShowModalCancelPendingOrder(false))}
			showCloseButton={false}
			sx='rounded-lg overflow-hidden w-[95%] max-w-md max-h-[94dvh] overflow-y-auto flex flex-col gap-4'
		>
			<Text sx='!block text-center'>
				Tienes una orden pendiente por un producto similar,{' '}
				<b>¿Deseas cancelarla?</b>
			</Text>
			<PrimaryButton
				onClick={changeOrderStatus}
				label='Cancelar orden'
				variant='tertiary'
				loading={isLoading.changeOrderStatus}
			/>
			<PrimaryButton
				onClick={() => dispatch(setShowModalCancelPendingOrder(false))}
				label='No, volver'
				sx='!h-10'
			/>
		</Modal>
	);
};

export default ModalCancelPendingOrder;
