import { useEffect, useState } from 'react';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../../@types/Products.type';
import { CircularProgress, Input } from '@mui/material';
import PlanOverview from './organisms/PlanOverview';
import Modal from '../../components/molecules/Modal/Modal';
import ModalHeader from './molecules/ModalHeader';
import Text from '../../components/atoms/Text';
import PrimaryButton from '../../components/atoms/Button';
import ProductCardSimple from './organisms/ProductCardSimple';
import useAddToCart from '../../hooks/useAddToCart';
import {
	setShowModalCancelPendingOrder,
	setShowModalCheckout,
} from '../../redux/slices/plans.slice';
import imgBannerAsat from '../../assets/img/png/plan-card-entrepreneur.png';

const ASAT_BENEFITS = [
	'Actualización de datos personales ante el SAT.',
	'Inscripción en el RFC.',
	'Ayuda con alta de usuario y contraseña de acceso al SAT.',
	'Alta o cambio de régimen de acuerdo a actividad física.',
	'Activación de buzón tributario.',
	'Obtención de cita en el SAT.',
	'Obtención o renovación de FIEL (Firma Electrónica Avanzada).',
	'Obtención de CSD (Certificado de Sello Digital) para facturación.',
	'Análisis y propuesta de resolución de cartas de invitación de cumplimiento por parte del SAT.',
];

const AsatPlanSection = () => {
	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();
	const dispatch = useDispatch();

	const [plan, setPlan] = useState<Product | null>(null);
	const { products } = useSelector((state: RootState) => state.productList);
	const [showModal, setShowModal] = useState(false);
	const [requirement, setRequirement] = useState('');
	const { refreshOrders } = useSelector((state: RootState) => state.cart);

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const haveASATPendingOrder =
		refreshOrders.length &&
		refreshOrders
			.find((order) => order.status === 'pending')
			?.shoppingItem?.find((item: any) => [31].includes(item.productVersionId));

	const handleAddToCart = async () => {
		const payload = [
			{
				product_version_id: plan?.default_product_version_id,
				additional_information: {
					asat_information: {
						message: requirement,
					},
				},
			},
		];

		const response = await handleAddCustomProduct({
			payload: payload,
			navigateToCart: false,
			resetOrders: true,
		});

		if (!response) return;
		handleCloseModal();
		dispatch(setShowModalCheckout(true));
	};

	useEffect(() => {
		const subscriptionProductsFiltered = products?.find(
			//@ts-ignore
			(item) => item?.name === 'Otros Planes',
		)?.products;

		const recommendedPlanFind = subscriptionProductsFiltered?.find(
			(product) => product.slug === 'asistencia_asat_2.0',
		);

		if (!recommendedPlanFind) return;

		setPlan(recommendedPlanFind);
	}, [products]);

	if (!plan) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	return (
		<div className='max-w-[600px] mx-auto relative'>
			<Modal
				open={showModal}
				onClose={handleCloseModal}
				showCloseButton={false}
				sx='!p-0 rounded-lg overflow-hidden w-[95%] max-w-md max-h-[94dvh] overflow-y-auto'
			>
				<ModalHeader
					title='Activación de servicio'
					description='Completa tu solicitud de trámites fiscales'
					handleClose={handleCloseModal}
				/>

				<div className='p-4'>
					<div>
						<Text
							size='caption'
							weight='bold'
							sx='flex justify-between'
						>
							<span className='!font-medium'>
								Trámites fiscales ante el SAT{' '}
							</span>{' '}
							<span>${plan.price / 100}</span>
						</Text>
					</div>

					<hr className='my-4' />

					<div>
						<Text
							size='caption'
							weight='light'
							sx='mb-4'
						>
							Describe con tanto detalle como sea posible qué tipo de trámite o
							ayuda fiscal requieres. De esta forma nuestro especialista
							contable tendrá mucho más claro lo que estás buscando y podrá
							asistirte mejor.
						</Text>

						<Input
							autoFocus
							placeholder='Describe tu requerimiento'
							multiline
							rows={4}
							className='w-full border border-h-neutral-200 rounded-lg !p-2 mb-3'
							value={requirement}
							onChange={(e) => setRequirement(e.target.value)}
						/>
					</div>

					<PrimaryButton
						label='Enviar solicitud'
						onClick={handleAddToCart}
						loading={isLoadingAddToCart.products}
						disabled={isLoadingAddToCart.products || !requirement.trim()}
					/>
				</div>
			</Modal>

			<PlanOverview
				title='Trámites fiscales SAT'
				description='Deja que nuestros expertos se encarguen de tus trámites fiscales mientras tú te enfocas en hacer crecer tu negocio.'
			/>

			<div className='px-2'>
				<ProductCardSimple
					imgBanner={imgBannerAsat}
					name={
						<>
							Trámites <br /> fiscales ante <br /> el SAT
						</>
					}
					description='¿Necesitas ayuda con tus trámites fiscales? Déjalo en manos de nuestros especialistas y libera tu agenda.'
					benefitsTitle='Trámites fiscales incluidos en tu plan'
					benefits={ASAT_BENEFITS}
					ctaAction={() => {
						if (haveASATPendingOrder) {
							dispatch(setShowModalCancelPendingOrder(true));
							return;
						}
						setShowModal(true);
					}}
					ctaLabel='Activar servicio'
					price={plan.price / 100}
					className='max-w-[400px] mx-auto '
				/>
			</div>
		</div>
	);
};

export default AsatPlanSection;
