import { useEffect, useMemo, useReducer, useState } from 'react';
import { regularizationsPT } from '../../../../shared/constants/SlugsProducts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import ItemCollapse from './ItemCollapse';
import { nameMonth } from '../../../../shared/utils/formatMonth';
import {
	AdditionalInformation,
	ProductVersionItem,
} from '../../../../@types/Order.type';
import { formatPrice } from '../../../../shared/utils/general.util';
import Text from '../../../atoms/Text';
import { getPriceFromProductVersionId } from '../../../../shared/utils/products.utils';
import { RegimeFFTToRegime } from '../../../../@types/Products.type';
import {
	initialRegularizationItems,
	regularizationItemsReducer,
} from '../../../../reducers/regularizationItems/regularizationItemsReducer';
import ButtonTrash from '../../../atoms/ButtonTrash/ButtonTrash';
import useDeleteProducts from '../../../../hooks/useDeleteProducts';
import { CircleNotch, X } from '@phosphor-icons/react';

const ItemRegularization = () => {
	const { userOrder } = useSelector((state: RootState) => state.cart);
	const { products } = useSelector((state: RootState) => state.productList);
	const { handleDeleteProducts, isLoading: isLoadingDelete } =
		useDeleteProducts();
	const [selectedItem, setSelectedItem] = useState<any | null>(null);

	const [regularizationProds, dispatchRegu] = useReducer(
		regularizationItemsReducer,
		initialRegularizationItems,
	);
	const allRegularizationProducts = useMemo(
		() => [
			regularizationProds.regularizationsPTDeliver,
			regularizationProds.regularizationsPTDriver,
			regularizationProds.regularizationsPTHost,
			regularizationProds.regularizationsPTSeller,
			regularizationProds.regularizationsPTFleetPartner,
			regularizationProds.regularizationsPTFull,
			regularizationProds.regularizationsRESICOPlus,
			regularizationProds.regularizationsRESICOExpress,
			regularizationProds.regularizationsArriendosMonth,
			regularizationProds.regularizationsArriendosTrimester,
			regularizationProds.regularizationsRIF,
			regularizationProds.regularizationsActEmp,
		],
		[regularizationProds],
	);

	const getRegularizationRegimesProducts = () => {
		regularizationsPT.forEach((regu) => {
			const regProds =
				userOrder[0]?.shoppingItem?.filter(
					(product) => product?.productVersion?.product.slug === regu.slug,
				) ?? [];

			if (regProds.length > 0) {
				dispatchRegu({ type: regu.type, payload: regProds });
			} else {
				dispatchRegu({ type: regu.type, payload: [] });
			}
		});
	};

	useEffect(() => {
		if (userOrder[0]?.shoppingItem) {
			getRegularizationRegimesProducts();
		}
	}, [userOrder[0]?.shoppingItem]);

	const periodLabelReg = useMemo(
		() => (additionalInfo: AdditionalInformation) => {
			if (additionalInfo.month) {
				return `${nameMonth(additionalInfo?.month)} ${additionalInfo.year}`;
			}

			if (additionalInfo.period) {
				return additionalInfo.period;
			}

			return null;
		},
		[regularizationProds],
	);

	const getRegularizationRegimenName = (
		additionalInformation: AdditionalInformation,
	) => {
		return additionalInformation?.regimes
			?.map((regime) => RegimeFFTToRegime[regime])
			.join(', ');
	};

	const price = (reguRegimen: ProductVersionItem[]) =>
		(!!reguRegimen[0]?.discount
			? reguRegimen[0]?.basePrice
			: reguRegimen[0]?.basePrice) * reguRegimen.length;

	const saving = (reguRegimen: ProductVersionItem[]) =>
		getPriceFromProductVersionId(
			products,
			reguRegimen[0]?.productVersionId ?? 0,
		) *
			reguRegimen.length -
		price(reguRegimen) / 100;

	const handleDeleteRegularization = async (item?: any) => {
		if (isLoadingDelete) return;
		setSelectedItem(item);
		try {
			if (item) {
				await handleDeleteProducts(userOrder?.[0]?.id, [
					{ shopping_item_id: item?.id },
				]);
			} else {
				const allShoppingItemsIds = userOrder?.[0]?.shoppingItem?.map((item) => ({
					shopping_item_id: item?.id,
				}));
				await handleDeleteProducts(userOrder?.[0]?.id, allShoppingItemsIds);
			}
		} finally {
			setSelectedItem(null);
		}
	};

	return (
		<div>
			{!!allRegularizationProducts.length &&
				allRegularizationProducts.map((reguRegimen, index) => {
					if (reguRegimen.length === 0) {
						return <div key={`view-${index}`} />;
					}
					return (
						<div
							className='flex gap-2'
							key={`view-${index}`}
						>
							<ItemCollapse
								key={`cart-item-${reguRegimen[0].id}`}
								title={
									<div className='flex flex-col gap-0'>
										<Text>Declaración mensual atrasada</Text>
										<Text weight='light'>
											{`${getRegularizationRegimenName(
												reguRegimen[0].additionalInformation,
											)} x (${reguRegimen.length})`}
										</Text>
									</div>
								}
								subtitle={`$ ${formatPrice(price(reguRegimen))}`}
							>
								<div>
									<div className='flex flex-wrap gap-2.5'>
										{reguRegimen
											.sort((a, b) => {
												return (
													(a.additionalInformation?.month ?? 0) -
													(b.additionalInformation?.month ?? 0)
												);
											})
											.map((item) => {
												const period = periodLabelReg(
													item?.additionalInformation ?? {},
												);
												return (
													<div
														className='flex items-center gap-1'
														key={`period-${item.id}`}
													>
														<Text
															size='caption'
															color='light'
														>
															{period}
														</Text>
														{isLoadingDelete && selectedItem?.id === item.id ? (
															<CircleNotch
																className='animate-spin'
																size={16}
															/>
														) : (
															<X
																onClick={() => {
																	handleDeleteRegularization(item);
																}}
																className='cursor-pointer'
																size={16}
															/>
														)}
													</div>
												);
											})}
									</div>

									{!!saving(reguRegimen) && (
										<div className='flex flex-row justify-end'>
											<Text
												color='green'
												sx='text-right'
											>
												Estas ahorrando ${saving(reguRegimen)}
											</Text>
										</div>
									)}
								</div>
							</ItemCollapse>
							<ButtonTrash
								onClick={() => handleDeleteRegularization()}
								isLoading={isLoadingDelete && !selectedItem}
							/>
						</div>
					);
				})}
		</div>
	);
};

export default ItemRegularization;
