import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { fetchAcquisitions } from '../services/shopping.service';
import { addAcquisitions } from '../redux/slices/products.slice';
import { getOnboardingIA } from '../services/heru-ai.service';
import { IOnboardingResponseSamantha } from '../@types/IA.type';
import {
	setHasPendingAnalysis,
	setServiceStatusElement,
	setUserSubscriptions,
} from '../redux/slices/profile.slice';
import { getUserSuscriptions } from '../services/suscription.service';
import { linkedAccount, startLoader } from '../redux/slices/sat.slice';
import { fetchSATLinkedById } from '../services/satLinking.service';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../shared/constants/events';
import { SATLinkStatus } from '../@types/SATLinking';
import useCleverTapEvent from './useClevertapEvent';
import { getAllServiceStatus } from '../services/serviceStatus.service';

const useService = () => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const [isLoading, setIsLoading] = useState({
		adquisitions: false,
		onboardingIAProgress: false,
		subscriptions: false,
		serviceStatus: false,
	});

	const getAcquisitions = async () => {
		try {
			setIsLoading({ ...isLoading, adquisitions: true });
			const response = await fetchAcquisitions(tokenSession);
			dispatch(addAcquisitions(response));
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, adquisitions: false });
		}
	};

	const getSubscriptions = async () => {
		try {
			setIsLoading({ ...isLoading, subscriptions: true });
			const subs = await getUserSuscriptions(tokenSession);
			dispatch(setUserSubscriptions(subs));
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, subscriptions: false });
		}
	};

	const getOnboardingIAProgress = async (userId: number) => {
		try {
			setIsLoading({ ...isLoading, onboardingIAProgress: true });
			const response: IOnboardingResponseSamantha = await getOnboardingIA(
				userId,
				tokenSession,
			);

			if (response?.percentage?.percentage) {
				if (response.percentage.percentage !== 100) {
					dispatch(setHasPendingAnalysis(true));
				}
			}
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, onboardingIAProgress: false });
		}
	};

    const getSatLinkedAccount = async (userId: number, userPhone: string) => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_MAIN_SCREEN_VIEWED,
				id: userId,
				phone: userPhone,
				link: resource.status === SATLinkStatus.ACTIVE,
			});
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

    const getServiceStatus = async () => {
		try {
			setIsLoading({ ...isLoading, serviceStatus: true });
			const resource = await getAllServiceStatus(tokenSession);
			if (Array.isArray(resource) && resource.length > 0) {
				dispatch(setServiceStatusElement(resource));
			}
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, serviceStatus: false });
		}
	};

	return {
		getAcquisitions,
		getOnboardingIAProgress,
		getSubscriptions,
		getSatLinkedAccount,
		getServiceStatus,
		isLoading,
	};
};

export default useService;
