const ROUTES = {
	//? AUTH
	SEND_OTP_CODE: '/send-otp',
	VERIFY_OTP_CODE: '/verify-otp',
	GENERATE_OTP: '/generate-otp',

	//? USER
	USER_PROFILE: '/profile/',

	//? PAGES
	ROOT: '/',
	ONBOARDING: '/onboarding/',
	LOGIN: '/login/',
	INVOICING: '/invoice/',
	NEW_INVOICE: '/new-invoice',
	INVOICE_DETAIL: `/invoice/:invoice_id/`,
	SHOPPING: '/shopping/',
	ACTIVE_SUBS_LIST: '/active-subscriptions/',
	SUCCESSFUL_PURCHASE: '/successful-purchase/',
	TAX_PROFILE: '/tax-profile/',
	ACCOUNT_HERU: '/account-heru/',
	PAYMENT_METHODS: '/payment-methods/',
	REFERRALS: '/referidos/',
	FISCAL_DOCUMENTS: '/fiscal-documents/',
	RAPPI_CERTIFICATES: '/rappi-certificates/',
	ONBOARDING_SUCCESSFUL_PURCHASE: '/onboarding/successful-purchase',
	REGISTER: '/register',

	//? PLANS
	PLANS: '/plans-legacy/',
	PLANS_AND_PRICES: '/plans/',
	CHECKOUT: '/checkout/',
	PAYMENT: '/payment/',
	PRODUCTS: '/products/',
	CART: '/cart/',
	MY_SUBSCRIPTIONS: '/my-subscriptions',
	ORGANIZATION_SUCCESS_LINK: '/organization-success-link',

	//? TAXES
	TAXES: '/taxes/',

	//? SERVICE STATUS
	SERVICE_STATUS: '/service-status',
	SERVICE_STATUS_DETAIL: '/service-status/:id',
	ASAT_SUCCESS: '/form-asat-success',

	//? PENDING ORDERS
	PENDING_REFERENCES: '/pending-references',

	//? ONBOARDING
	INTRO: '/intro',
};

const ROUTES_SLUGS: Record<string, string> = {
	HOME: 'home',
	PLANS: 'plans-legacy',
	PLANS_AND_PRICES: 'plans',
	INVOICING: 'invoice',
	FISCAL_DOCUMENTS: 'fiscal-documents',
	TAXES: 'taxes',
	SERVICE_STATUS: 'service-status',
	RAPPI_CERTIFICATES: 'rappi-certificates',
	PROFILE: 'profile',
	REFERRALS: 'referidos',
	LOGOUT: 'logout',
};

export default ROUTES;
export { ROUTES_SLUGS };
