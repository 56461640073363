import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { CopySimple } from '@phosphor-icons/react';
import JsBarcode from 'jsbarcode';
import { PAYMENT_METHOD } from '../../../@types/Checkout.type';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';

import { formatPrice } from '../../../shared/utils/general.util';
import { ALERT_TYPE } from '../../molecules/Alert';
import { showAlert } from '../../../redux/slices/alert.slice';
import CustomIcon from '../../atoms/CustomIcon';
import Tag from '../../molecules/Tag';
import { RootState } from '../../../redux/store';
import { getFormatDate } from '../../../shared/utils/dates.util';
import Heading from '../../atoms/Heading';

interface PaymentInstructionsProps {
	paymentMethod: PAYMENT_METHOD;
	total: number;
	reference: string;
}

const listSpei = [
	<li key={1}>
		<strong>1.</strong> Accede a tu banca en línea.
	</li>,
	<li key={2}>
		<strong>2.</strong> Da de alta la CLABE en esta ficha.{' '}
		<strong>El banco deberá de ser Banamex</strong>.
	</li>,
	<li key={3}>
		<strong>3.</strong> Como beneficiario y concepto ingresa el nombre{' '}
		<strong>“Heru Servicios”</strong>.
	</li>,
	<li key={4}>
		<strong>4.</strong> Realiza la transferencia correspondiente por la cantidad
		exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>
		.
	</li>,
	<li key={5}>
		<strong>5.</strong>Al confirmar tu pago, el portal de tu banco generará un
		comprobante digital.{' '}
		<strong>En el podrás verificar que se haya realizado correctamente.</strong>{' '}
		Conserva este comprobante de pago.
	</li>,
];

const listCash = [
	<li key={1}>
		<strong>1.</strong> Acude a la tienda OXXO más cercana.{' '}
		<a
			href='https://www.google.com.mx/maps/search/oxxo/'
			target='_blank'
			className='!text-h-primary'
		>
			Encuéntrala aquí
		</a>
		.
	</li>,
	<li key={2}>
		<strong>2.</strong> Indica en caja que quieres realizar un pago de{' '}
		<strong>OXXOPay</strong>.
	</li>,
	<li key={3}>
		<strong>3.</strong> Escanea el código de barra que aparece en la pantalla.
	</li>,
	<li key={4}>
		<strong>4.</strong> Realiza el pago correspondiente con cualquier método de
		pago.
	</li>,
	<li key={5}>
		<strong>5.</strong> Al confirmar tu pago, el cajero te entregará un
		comprobante impreso.{' '}
		<strong>En el podrás verificar que se haya realizado correctamente.</strong>{' '}
		Conserva este comprobante de pago.
	</li>,
];

const PaymentInstructions = ({
	paymentMethod,
	reference,
	total,
}: PaymentInstructionsProps) => {
	const { paymentInstructions } = useSelector((state: RootState) => state.cart);
	const dispatch = useDispatch();
	const barcodeRef = useRef(null);

	useEffect(() => {
		if (barcodeRef.current) {
			JsBarcode(barcodeRef.current, reference, {
				format: 'CODE128', // Formato de código de barras
				lineColor: '#000',
				width: 2,
				height: 70,
				displayValue: true,
			});
		}
	}, [reference]);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(reference).then(() => {
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'Éxito',
				description: 'Texto copiado al portapapeles',
			};
			dispatch(showAlert(alert));
		});
	};

	const today = dayjs();

	const expireDate =
		typeof paymentInstructions.expiresAt === 'string'
			? getFormatDate(paymentInstructions.expiresAt, undefined, true)
			: `${dayjs
					.unix(paymentInstructions.expiresAt)
					?.locale('es')
					?.format('DD')} de ${dayjs
					.unix(paymentInstructions.expiresAt)
					?.locale('es')
					?.format('MMMM')} de ${dayjs
					.unix(paymentInstructions.expiresAt)
					?.locale('es')
					?.format('YYYY')} a las ${dayjs
					.unix(paymentInstructions.expiresAt)
					?.locale('es')
					?.format('hh:mm A')}`;

	const dayLefts =
		typeof paymentInstructions.expiresAt === 'string'
			? dayjs(paymentInstructions.expiresAt).diff(today, 'day')
			: dayjs.unix(paymentInstructions.expiresAt).diff(today, 'day');

	return (
		<div className='rounded-md flex flex-col p-4 md:p-0'>
			<div className='flex flex-col items-center'>
				<div className='flex flex-col items-center'>
					<div>
						{paymentMethod === PAYMENT_METHOD.SPEI ? (
							<CustomIcon
								sx='ml-0'
								color={'var(--h-primary)'}
								name={'spei'}
								width={72}
							/>
						) : (
							<CustomIcon
								sx='ml-0'
								color={'var(--h-primary)'}
								name={'oxxo'}
								width={100}
								height={50}
							/>
						)}
					</div>

					<Heading
						size='s'
						sx='mt-2 hidden md:block'
					>
						MX $ {formatPrice(Number(total))} MXN
					</Heading>
					<Heading
						size='m'
						sx='mt-2 block md:hidden'
					>
						MX $ {formatPrice(Number(total))} MXN
					</Heading>
				</div>
				<Text
					color='gray'
					size='caption'
					weight='light'
					sx='text-center'
				>
					{paymentMethod === PAYMENT_METHOD.SPEI
						? 'Utiliza exactamente esta cantidad al realizar el pago.'
						: 'OXXO cobrará una comisión adicional al momento de realizar el pago.'}
				</Text>

				{!!paymentInstructions.expiresAt && (
					<div className='flex flex-col md:flex-row items-center gap-2 mt-2 mb-4'>
						<Text sx='text-center'>Vence el {expireDate}</Text>
						<Tag
							type='filled'
							caseVariant='error'
							label={`Vence en ${dayLefts} días`}
							sx='!px-1 text-center'
						/>
					</div>
				)}

				{paymentMethod === PAYMENT_METHOD.CASH && (
					<div className='w-full flex justify-center'>
						<svg ref={barcodeRef}></svg>
					</div>
				)}

				<div className='mt-3'>
					{paymentMethod === PAYMENT_METHOD.SPEI && (
						<Text
							color='primary'
							weight='medium'
							sx='mb-1 items-center justify-center'
						>
							CLABE
						</Text>
					)}
					{paymentMethod === PAYMENT_METHOD.SPEI ? (
						<div
							onClick={copyToClipboard}
							className='flex items-center justify-center text-black rounded-md p-2 cursor-pointer bg-h-primary-40'
						>
							<CopySimple
								size={18}
								color='var(--h-text-white)'
								weight='fill'
								className='mr-2'
							/>
							<Text color='white'>{reference}</Text>
						</div>
					) : (
						<Button
							variant='text'
							beforeIcon={
								<CopySimple
									size={20}
									color='var(--h-primary-40)'
								/>
							}
							label='Copiar código'
							labelColor='primary'
							onClick={copyToClipboard}
						/>
					)}
				</div>

				<div className='flex flex-col mt-4'>
					<Text sx='mb-4'>INSTRUCCIONES</Text>
					<ol className='ml-4 mb-2 font-light flex flex-col gap-y-2'>
						{paymentMethod === PAYMENT_METHOD.SPEI
							? listSpei.map((item) => item)
							: listCash.map((item) => item)}
					</ol>

					<Text
						weight='light'
						size='body-3'
						sx='!block my-4 bg-h-neutral-94 p-4 rounded-lg'
					>
						*La confirmación de pago puede tardar hasta 24 horas. En caso de no
						recibir el correo de confirmación, puedes contactarnos a{' '}
						<a
							href='mailto:ayuda@heru-app.com'
							className='font-medium'
						>
							ayuda@heru-app.com
						</a>
					</Text>
				</div>
			</div>
		</div>
	);
};

export default PaymentInstructions;
