import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import AlertBanner from '../../../components/molecules/AlertBanner';
import FiscalResume from '../molecules/FiscalResume';
import ModalAnnualPendingMonths from './ModalAnnualPendingMonths';
import Button from '../../../components/atoms/Button';
import {
	setSelectedPeriod,
	setShowModal,
} from '../../../redux/slices/taxCalendar.slice';
import {
	TPeriodicity,
	TaxboardDeclarationStatus,
} from '../../../@types/Calendar.type';
import { getMonthName } from '../../../shared/utils/dates.util';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { UserRegime } from '../../../@types/TaxProfile';

interface Props {
	periodsAdquired: any[];
}

const FiscalResumeLogic: React.FC<Props> = ({ periodsAdquired }) => {
	const logClevertapEvent = useCleverTapEvent();
	const dispatch = useDispatch();

	const {
		year,
		period,
		showModal,
		selectedPeriod,
		month,
		statusByPriotityMonth,
		fiscalResume,
	} = useSelector((state: RootState) => state.taxCalendar);
	const { activeAnnualSubscription, activeSuscriptions } = useSelector(
		(state: RootState) => state.profile,
	);
	const { isRecentlyLinked } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const userHaveMonthlySubscription = activeSuscriptions?.some(
		(subscription) =>
			subscription?.product_version?.product?.slug ===
				PRODUCTS_SLUG.DECLARATION && subscription?.active,
	);

	const showInvoices = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_FISCAL_DOCUMENTS,
	);

	const statusBannerMonthly = () => {
		const variant: Record<
			string,
			'error' | 'success' | 'processing' | 'warning'
		> = {
			[TaxboardDeclarationStatus.PENDING]: 'error',
			[TaxboardDeclarationStatus.IN_PROCESS]: 'processing',
			[TaxboardDeclarationStatus.PRESENTED]: 'success',
			[TaxboardDeclarationStatus.PENDING_PAY]: 'warning',
		};

		const title: Record<string, string> = {
			[TaxboardDeclarationStatus.PENDING]: 'Tienes declaraciones pendientes',
			[TaxboardDeclarationStatus.IN_PROCESS]:
				'La información estará disponible en 10 días',
			[TaxboardDeclarationStatus.PRESENTED]: `Estás al día en tus declaraciones de ${getMonthName(month)} de ${year}`,
			[TaxboardDeclarationStatus.PENDING_PAY]: `Tienes declaraciones pendientes de pago`,
			['ACQUIRED']: `Periodo adquirido`, //TODO: Change this copy Stive
		};

		const description: Record<string, string> = {
			[TaxboardDeclarationStatus.PENDING]:
				'Presenta todas tus declaraciones pendientes y evita multas o sanciones.',
			[TaxboardDeclarationStatus.IN_PROCESS]:
				'Recuerda presentar puntualmente tus declaraciones con Heru.',
			[TaxboardDeclarationStatus.PRESENTED]:
				activeSuscriptions.length > 0
					? 'Recuerda presentar puntualmente tus declaraciones para mantener tu estatus al día.'
					: 'Suscríbete a los servicios de Heru y presenta puntualmente tus declaraciones.',
			[TaxboardDeclarationStatus.PENDING_PAY]:
				activeSuscriptions.length > 0
					? 'Apresúrate a hacer tus pagos con tus líneas de captura.'
					: 'Apresúrate a hacer tus pagos con tus líneas de captura. Recuerda suscribirte a los servicios de Heru para mantenerte al día.',
			['ACQUIRED']: `Periodo adquirido`, //TODO: Change this copy Stive
		};

		if (
			periodsAdquired?.length > 0 &&
			!!periodsAdquired?.find(
				(period: any) => period.month === month && period.year === year,
			) &&
			statusByPriotityMonth === TaxboardDeclarationStatus.PENDING
		) {
			return (
				<AlertBanner
					variant='info'
					title={title['ACQUIRED']}
					description={description['ACQUIRED']}
				/>
			);
		}
		return (
			<AlertBanner
				variant={variant[statusByPriotityMonth]}
				title={title[statusByPriotityMonth]}
				description={description[statusByPriotityMonth]}
			/>
		);
	};

	const statusBannerYearly = () => {
		const yearStatus = selectedPeriod?.year?.selectedYear?.status;

		const variant: Record<
			string,
			'error' | 'success' | 'processing' | 'warning'
		> = {
			[TaxboardDeclarationStatus.PENDING]: 'error',
			[TaxboardDeclarationStatus.IN_PROCESS]: 'processing',
			[TaxboardDeclarationStatus.PRESENTED]: 'success',
			[TaxboardDeclarationStatus.PENDING_PAY]: 'warning',
		};

		const title: Record<string, string> = {
			[TaxboardDeclarationStatus.PENDING]: `Tu declaración anual de ${year} está pendiente`,
			[TaxboardDeclarationStatus.IN_PROCESS]:
				'La información estará disponible en 10 días',
			[TaxboardDeclarationStatus.PRESENTED]: `Estás al día en tus declaraciones de ${year}`,
			[TaxboardDeclarationStatus.PENDING_PAY]:
				'Hay pagos pendientes al SAT en Agosto 2023',
		};

		const description: Record<string, string> = {
			[TaxboardDeclarationStatus.PENDING]:
				'Presenta tus declaraciones pendientes, suscríbete a los servicios de Heru y evita atrasarte en tus declaraciones futuras.',
			[TaxboardDeclarationStatus.IN_PROCESS]: userHaveMonthlySubscription
				? 'Recuerda presentar puntualmente tus declaraciones con Heru.'
				: 'Suscríbete a los servicios de Heru y presenta puntualmente tus declaraciones.',
			[TaxboardDeclarationStatus.PRESENTED]:
				'Suscríbete a los servicios de Heru y presenta puntualmente tus declaraciones.',
			[TaxboardDeclarationStatus.PENDING_PAY]:
				'Apresúrate a hacer tus pagos con tus líneas de captura.',
		};

		return (
			<AlertBanner
				variant={variant[yearStatus]}
				title={title[yearStatus]}
				description={description[yearStatus]}
			/>
		);
	};

	const handleCloseRegimeDetail = () => {
		dispatch(
			setSelectedPeriod({
				year: {
					...selectedPeriod.year,
					selectedDeclaration: null,
				},
				month: {
					...selectedPeriod.month,
					detailByRegime: null,
				},
			}),
		);
	};

	const onViewDetail = (type: 'period' | 'regime') => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_VIEW_PERIOD_FISCAL_DOCUMENTS,
			source: 'declaraciones_mensuales',
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});

		dispatch(
			setSelectedPeriod({
				...selectedPeriod,
				month: {
					...selectedPeriod.month,
					detailByRegimeComplete: type,
				},
			}),
		);
	};

	const handleDownloadReceipt = () => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_TAX_RECEIPT_DOWNLOADED,
			tax_detail: `${selectedPeriod?.month?.detailByRegime?.month || ''}/${selectedPeriod?.month?.detailByRegime?.year || ''}`,
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});

		window.open(selectedPeriod?.month?.detailByRegime?.pdfUrl, '_blank');
	};

	if (period === TPeriodicity.YEARLY && selectedPeriod.year.selectedYear) {
		if (selectedPeriod.year.selectedDeclaration) {
			return (
				<FiscalResume
					title={`Resumen`}
					year={year}
					tagText='Detalle'
					onTagClick={handleCloseRegimeDetail}
					amountToPay={0}
					expenses={1000}
					deductibleExpenses={100}
					declarationType='Complementaria'
					paymentType='Provisional'
					presentedBy='Heru'
					showRefresh
					banner={statusBannerYearly()}
				/>
			);
		}
		return (
			<>
				<ModalAnnualPendingMonths />
				<FiscalResume
					title={`Resumen fiscal del año`}
					year={year}
					expenses={1000}
					deductibleExpenses={100}
					showRefresh
					cta={
						activeAnnualSubscription && (
							<Button
								label='Presentar declaraciones mensuales pendientes'
								sx='!leading-4'
								onClick={() =>
									dispatch(
										setShowModal({
											modalName: 'annualPendingMonths',
											show: !showModal.annualPendingMonths,
										}),
									)
								}
							/>
						)
					}
					banner={statusBannerYearly()}
				/>
			</>
		);
	}

	if (period === TPeriodicity.MONTHLY && selectedPeriod.month.detail) {
		const { detailByRegime } = selectedPeriod.month;
		if (detailByRegime) {
			logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
				event_type: EVENT_TYPES.TAXBOARD_DETAIL_SELECTED,
				tax_detail: `${detailByRegime?.year}/${detailByRegime?.regime}`,
			});

			return (
				<FiscalResume
					title={`Resumen de la declaración`}
					year={year}
					month={month}
					description={
						<>
							Esta es la información de tu declaración presentada de{' '}
							{
								<span className='font-bold'>
									Plataformas tecnológicas de {getMonthName(month)} de {year}.
								</span>
							}
						</>
					}
					tagText='Plataformas Tecnológicas'
					onTagClick={handleCloseRegimeDetail}
					amountToPay={detailByRegime?.amountToPay}
					declarationType={detailByRegime?.declarationType}
					paymentType={detailByRegime?.paymentType}
					presentedBy={detailByRegime?.presentedBy}
					presentedDate={detailByRegime?.declarationPresentedDate}
					showRefresh
					banner={statusBannerMonthly()}
					cta={
						detailByRegime?.pdfUrl && (
							<Button
								label='Descargar acuse'
								onClick={handleDownloadReceipt}
							/>
						)
					}
				/>
			);
		}
		return (
			<FiscalResume
				title={`Resumen fiscal del periodo`}
				year={year}
				month={month}
				description={
					<>
						Está es la información disponible de{' '}
						{<span className='font-bold'>{getMonthName(month)}</span>} de{' '}
						{<span className='font-bold'>{year}</span>}, tu contador tomará la
						información relevante para tu declaración.
					</>
				}
				incomes={fiscalResume.month?.incomes}
				expenses={fiscalResume.month?.expenses}
				retentions={fiscalResume.month?.retentions}
				deductibleExpenses={fiscalResume.month?.deductibles}
				showRefresh={
					statusByPriotityMonth !== TaxboardDeclarationStatus.IN_PROCESS
				}
				onViewDetail={
					showInvoices &&
					statusByPriotityMonth !== TaxboardDeclarationStatus.IN_PROCESS
						? () => onViewDetail('period')
						: undefined
				}
				viewDetailCopy='Ver información del periodo'
				banner={statusBannerMonthly()}
				emptyState={
					statusByPriotityMonth === TaxboardDeclarationStatus.IN_PROCESS
				}
			/>
		);
	}

	return (
		<FiscalResume
			title={`Resumen fiscal del año`}
			year={year}
			incomes={fiscalResume?.year?.incomes}
			expenses={undefined}
			retentions={fiscalResume?.year?.retentions}
			deductibleExpenses={fiscalResume?.year?.deductibles}
			banner={
				isRecentlyLinked ? (
					<AlertBanner
						variant='downloading'
						title='Estamos cargando tu información'
						description='Con las credenciales que nos has dado estamos descargando tu información. Estamos haciéndolo lo más rápido para ti.'
					/>
				) : (
					<AlertBanner
						variant='processing'
						title='Selecciona un período'
						description='Selecciona un período y mira todos los detalles de tu declaración, ingresos, gastos, tipo de declaración y ¡Mucho más!'
					/>
				)
			}
		/>
	);
};

export default FiscalResumeLogic;
