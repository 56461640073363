import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { sendOTP } from '../../services/auth.service';
import Verify from './Verify';
import { LockSimpleOpen } from '@phosphor-icons/react';
import Register from './Register';
import Text from '../../components/atoms/Text';
import { REGEX } from '../../shared/constants/regex';
import { useSearchParams } from 'react-router-dom';
import Input from '../../components/atoms/Input';
import Button from '../../components/atoms/Button';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import ROUTES from '../../shared/constants/routes';
import Heading from '../../components/atoms/Heading';
import { hideAlert, showAlert } from '../../redux/slices/alert.slice';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import Alert, { ALERT_TYPE } from '../../components/molecules/Alert';
import COUNTRY_CODES from '../../shared/constants/countryCodes';
import Selector from '../../components/atoms/Selector';
import { isLocalStorageEnabled } from '../../shared/utils/general.util';
import { removeToken } from '../../redux/slices/auth.slice';
import img from '../../assets/img/png/textura_fondo.png';
import zubaleBg from '../../assets/img/png/zubalero.png';
import heruLogo from '../../assets/img/svg/heru-logo-v3.svg'

type FormBody = {
	countryCode: string;
	phone: string;
};

const zubaleTextList = [
	{
		id: 1,
		info: (
			<div className='flex flex-col'>
				<Heading
					color='white'
					size='m'
				>
					Actualiza tu información fiscal
				</Heading>
				<Text
					size='body-1'
					color='white'
					sx='mt-2'
				>
					Nos aliamos con Heru para ayudarte a cumplir con tus obligaciones y
					actualizar tu información fiscal.
				</Text>
			</div>
		),
	},
	{
		id: 2,
		info: (
			<div className='flex flex-col'>
				<Heading
					color='ultraBlueLight'
					size='m'
				>
					Estamos probando con Anita
				</Heading>
				<Text
					size='body-1'
					color='ultraBlueLight'
					sx='mt-2'
				>
					funciona esto?.
				</Text>
			</div>
		),
	},
];

const textList = [
	{
		id: 1,
		info: (
			<div className='flex flex-col'>
				<Heading
					color='darkBlue'
					size='m'
				>
					Ahorra tiempo, dinero y preocupaciones
				</Heading>
				<Text
					size='body-1'
					sx='mt-2'
				>
					Nos encargamos de tu impuestos mientras tú te enfocas en lo que
					realmente importa: tus ingresos.
				</Text>
			</div>
		),
	},
	{
		id: 2,
		info: (
			<div className='flex flex-col max-w-[80%]'>
				<Heading
					color='darkBlue'
					size='m'
					sx=''
				>
					Tecnología fiscal que habla tu idioma
				</Heading>
				<Text
					size='body-1'
					sx='mt-10'
				>
					Transformamos términos complejos en soluciones claras y accesibles.
				</Text>
			</div>
		),
	},
	{
		id: 3,
		info: (
			<div className='flex flex-col gap-y-10 max-w-[80%]'>
				<Heading
					color='darkBlue'
					size='m'
					sx=''
				>
					Declarar no tiene que ser un dolor de cabeza
				</Heading>
				<Text size='body-1'>
					Con Heru, conviertes una tarea tediosa en un proceso rápido y
					sencillo.
				</Text>
			</div>
		),
	},
	{
		id: 4,
		info: (
			<div className='flex flex-col max-w-[80%] gap-y-10'>
				<Heading
					color='darkBlue'
					size='m'
					sx=''
				>
					Tu contador personal, disponible 24/7
				</Heading>
				<Text size='body-1'>
					Con Heru, tienes un experto fiscal siempre a tu alcance.
				</Text>
			</div>
		),
	},
	{
		id: 5,
		info: (
			<div className='flex flex-col max-w-[80%] gap-y-10'>
				<Heading
					color='darkBlue'
					size='m'
					sx=''
				>
					Tu aliado fiscal en la era digital
				</Heading>
				<Text size='body-1'>
					Combinamos nuestra experiencia tributaria con innovación tecnológica
					para servirte mejor.
				</Text>
			</div>
		),
	},
];

const Login: React.FC = () => {
	const [step, setStep] = useState(1);
	const [inputErrors, setInputErrors] = useState({
		phone: '',
	});
	const [isLoading, setIsLoading] = useState({
		sendOTP: false,
		verifyOTP: false,
	});
	const [animation, setAnimation] = useState('fade-in-left');
	const [textIndex, setTextIndex] = useState(0);

	const { control, handleSubmit, getValues } = useForm<FormBody>({
		defaultValues: { countryCode: COUNTRY_CODES[0].value, phone: '' },
	});

	const dispatch = useDispatch();
	const alert = useSelector((state: RootState) => state.alert);
	const { errors } = useSelector((state: RootState) => state.profile);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	let [searchParams] = useSearchParams();
	const isSignup = searchParams.get('flow') === 'signup';
	const partner = searchParams.get('utm_source'); //?acá estoy definiedo la constante para poder identificar si es partner o no y de acuerdo a eso cambiar imágenes y copies

	const input = useWatch({ control: control, name: 'phone' });
	const [currentText, setCurrentText] = useState(
		partner === 'zubale' ? zubaleTextList[0] : textList[0],
	);
	const onSubmit = async (data: FormBody) => {
		if (inputErrors.phone.length > 0) return;
		const internalCode = 66;
		try {
			setIsLoading({ ...isLoading, sendOTP: true });
			const response = await sendOTP(data.phone, data.countryCode);
			if (response.internal_code === internalCode) {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Error al enviar',
					description:
						errors[internalCode] ||
						'Hay un problema con tu cuenta, comúnicate al número de whatsapp +525513751952',
				};
				dispatch(showAlert(alert));
				return;
			}
			setStep(2);
		} catch (error: any | Error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Error al enviar',
				description:
					'Hay un problema con tu cuenta, comúnicate al número de whatsapp +525513751952',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ ...isLoading, sendOTP: false });
		}
	};

	useEffect(() => {
		if (!input.match(REGEX.onlyNumberRegex) && input.length > 0) {
			setInputErrors({ ...inputErrors, phone: '*Solo se permiten números' });
			return;
		}
		setInputErrors({ ...inputErrors, phone: '' });
	}, [input]);

	useEffect(() => {
		const sessionExpired = isLocalStorageEnabled()
			? localStorage.getItem(LOCAL_STORAGE_KEY.SESSION_EXPIRED)
			: false;

		const token = isLocalStorageEnabled()
			? localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN)
			: tokenSession;

		if (token) {
			window.location.href = ROUTES.ROOT;
		}

		setTimeout(() => {
			if (sessionExpired) {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Tu sesión expiro',
					description: 'Vuelve a ingresar tu número para poder continuar.',
				};
				dispatch(showAlert(alert));
				if (isLocalStorageEnabled()) {
					localStorage.removeItem('session_expired');
				} else {
					dispatch(removeToken());
				}
			}
		}, 1000);
	}, []);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	useEffect(() => {
		const interval = setInterval(() => {
			setAnimation('fade-out-left');

			setTimeout(() => {
				if (partner === 'zubale') {
					setTextIndex((prevIndex) => (prevIndex + 1) % zubaleTextList.length);
				} else {
					setTextIndex((prevIndex) => (prevIndex + 1) % textList.length);
				}
				setAnimation('fade-in-left'); // Apply fade-in for the new text
			}, 1000);
		}, 10000);

		return () => clearInterval(interval); // Cleanup on unmount
	}, []);

	useEffect(() => {
		if (partner === 'zubale') {
			setCurrentText(zubaleTextList[textIndex]);
		} else {
			setCurrentText(textList[textIndex]);
		}
	}, [textIndex]);

	const getUrlPartner = () => {
		//? acá defino las imágenes del sign-up/sign-in de acuerdo al partner
		switch (partner) {
			case 'zubale':
				return zubaleBg;
			// case 'uber':
			// 	return uberBg;
			// case 'rappi':
			// 	return rappiBg;
			// case 'didi':
			// 	return didiBg;
			default:
				return img;
		}
	};
	const getCopyPartner = () => {
		//? acá defino las copias del sign-up/sign-in de acuerdo al partner
		switch (partner) {
			case 'zubale':
				return '¡Únete a Zubale y disfruta de un 10% de descuento en tu primer mes!';
			default:
				return (
					<>
						Haz las paces con <br />
						<b className='text-h-primary'>tus impuestos.</b>
						<br />
						¡Comienza ahora!
					</>
				);
		}
	};
	return (
		<section
			style={{
				backgroundImage: `url(${getUrlPartner()})`,
				backgroundPosition: '-250px center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
			className='flex flex-row w-full h-screen bg-contain bg-h-primary-95'
		>
			<div className='hidden sm:flex flex-col justify-center sm:w-1/2 px-[5%]'>
				<div className={`flex flex-col gap-2 ${animation}`}>
					{currentText.info}
				</div>
			</div>
			{alert.showAlert && (
				<Alert
					type={alert.type}
					description={alert.description}
					title={alert.title}
				/>
			)}
			<div className='px-6 md:px-4 bg-white backdrop-blur-lg min-h-screen w-full sm:w-1/2'>
				<div className='md:max-w-[70%] mx-auto pt-12 lg:mt-12 2xl:mt-16 flex flex-col gap-8 xl:gap-6 2xl:gap-12'>
					<img
						src={heruLogo}
						alt='heru_logo'
						className='w-14'
					/>
					{isSignup ? (
						<Heading
							color='darkMenu'
							size='m'
							sx=''
						>
							Completa tu registro <br /> y disfruta de <br />
							<b className='text-h-primary'>tu contabilidad</b>
							<br />
							fácil y rápido
						</Heading>
					) : (
						<Heading
							color='darkMenu'
							size='m'
							sx=''
							weight='bold'
						>
							{getCopyPartner()}
						</Heading>
					)}

					{step === 1 && (
						<div>
							<Text
								size='l'
								sx='hidden'
							>
								{isSignup
									? 'Tu cuenta solo con tu número de celular'
									: 'Ingresa tu número de celular para comenzar'}
							</Text>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className='flex mb-9 gap-0 md:gap-2 mt-3 items-center'>
									<Selector
										hasFlag
										control={control}
										name='countryCode'
										options={COUNTRY_CODES}
										sx='py-2'
									/>
									<Input
										control={control}
										name='phone'
										type='tel'
										label='Número de celular'
										errorMessage={inputErrors.phone}
									/>
								</div>
								<Button
									variant='primary'
									loading={isLoading.sendOTP}
									disabled={input.length < 10 || inputErrors.phone.length > 0}
									label='Continuar'
									type='submit'
								/>
							</form>
						</div>
					)}
					{step === 2 && (
						<Verify
							setStep={setStep}
							countryCode={getValues('countryCode')}
							phone={getValues('phone')}
						/>
					)}
					{[1, 2].includes(step) && (
						<div className='pb-8 mt-4 2xl:mt-0 bg-h-neutral-94 rounded-lg p-6 md:mt-[30%]'>
							<span className='flex gap-2 items-center'>
								<LockSimpleOpen
									size={18}
									color={'var(--h-medium)'}
									weight='bold'
								/>
								<Text
									color='medium'
									weight='medium'
								>
									Tu seguridad es nuestra máxima prioridad
								</Text>
							</span>
							<Text
								color='medium'
								size='s'
								sx='opacity-80 mt-2'
							>
								En Heru, la seguridad de tu cuenta es nuestra máxima prioridad.
								Solicitamos números de teléfono móvil para el registro y el
								inicio de sesión porque nos permite enviar un código de un solo
								uso (OTP, por sus siglas en inglés) para agregar una capa
								adicional de protección. Este método de autenticación de dos
								factores es la forma más segura de resguardar tu cuenta e
								información sensible.
							</Text>
						</div>
					)}
					{step === 3 && <Register />}
				</div>
			</div>
		</section>
	);
};

export default React.memo(Login);
