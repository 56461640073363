import { useState, useCallback } from 'react';
import {
	getOrders,
	postAddProductsBulk,
	postOrders,
} from '../services/order.service';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../shared/constants/events';
import { Item } from '../@types/Ecommerce';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from '../shared/constants/routes';
import { ALERT_TYPE } from '../components/molecules/Alert';
import { showAlert } from '../redux/slices/alert.slice';
import {
	setOrders,
	setUserOrder,
	updateAmountOfItems,
} from '../redux/slices/cart.slice';
import ERROR from '../shared/constants/errors';
import { APP_CONFIGURATIONS } from '../shared/constants/configurations';
import {
	getElasticyPrice,
	getPriceFromProductVersionId,
} from '../shared/utils/products.utils';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../shared/utils/growthbook.util';
import { PlatformOS } from '../redux/slices/profile.slice';
import {
	Order,
	OrderRefreshMapper,
	ProductVersionItem,
	TUserOrder,
} from '../@types/Order.type';
import { logClevertapEvent } from '../shared/analytics/analytics';
import { deleteAllItemCart } from '../services/order.service';
import {
	addItemBulkShoppingCart,
	getOrderRefresh,
} from '../services/orders.service';
import { getProductProdByProductVersion } from '../shared/utils/ecommerce.util';
import { setShowModalCancelPendingOrder } from '../redux/slices/plans.slice';
import { useStorage } from '../shared/utils/general.util';
import STORAGE_KEYS from '../shared/constants/storage';

const useAddToCart = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState({ products: false });

	const [searchParams] = useSearchParams();
	const { dataLayer } = window as any;

	const { products } = useSelector((state: RootState) => state.productList);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const getProductBySlug = (slug: string) => {
		const allProducts = products?.flatMap((item) => item?.products);
		return allProducts?.find((item) => item?.slug === slug);
	};

	// @ts-ignore
	const getOrder = useCallback(async (): Promise<Order | undefined> => {
		try {
			const response = await getOrders('false', undefined, tokenSession);

			if (response.length === 0) {
				const postNewOrder = await postOrder();
				dispatch(updateAmountOfItems(1));
				dispatch(setOrders([postNewOrder]));

				return postNewOrder;
			}

			dispatch(updateAmountOfItems(response?.[0]?.items?.length || 0));
			dispatch(setOrders(response));
			return response[0];
		} catch (error) {}
	}, []);

	const getUserRefreshOrder = async () => {
		try {
			const params = '?status=open&status=failed&is_renewal=false';
			const orders = await getOrderRefresh(params, tokenSession);

			if (orders.length === 0) {
				const postNewOrder = await postOrder();
				dispatch(updateAmountOfItems(1));
				dispatch(setOrders([postNewOrder]));

				return postNewOrder;
			}
			if (products.length === 0) {
				return;
			}

			const lastOrder = orders.sort((a, b) => b.id - a.id)?.[0];

			const items: ProductVersionItem[] = lastOrder?.shoppingItem.map(
				(item) => {
					const infoProduct = getProductProdByProductVersion(
						products,
						item.productVersionId,
					)!;
					return {
						...item,
						productVersion: {
							product: {
								id: infoProduct.id,
								name: infoProduct.name,
								defaultProductVersionId: infoProduct.default_product_version_id,
								description: infoProduct.description,
								isPurchasable: infoProduct.is_purchasable,
								benefits: infoProduct.benefits,
								slug: infoProduct.slug,
								category: infoProduct.category,
								regime: infoProduct.regime,
							},
							price:
								infoProduct.product_versions.find(
									(version) => version.id === item.productVersionId,
								)?.price ?? 0,
							periodicity:
								infoProduct.product_versions.find(
									(version) => version.id === item.productVersionId,
								)?.periodicity ?? infoProduct.periodicity,
						},
					};
				},
			);

			const newOrder: TUserOrder = {
				...lastOrder,
				shoppingItem: items,
			};

			dispatch(updateAmountOfItems(newOrder?.shoppingItem?.length || 0));
			dispatch(setUserOrder([newOrder]));
			return newOrder;
		} catch (error: any) {}
	};

	const postOrder = async () => {
		try {
			const channelSource = searchParams.get('channel_source');
			let sourceChannel = 'web';
			if (
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
			) {
				sourceChannel = platformOS ?? 'app';
			}
			const body = {
				status: 'open',
				source_channel: sourceChannel,
			};
			const response = await postOrders(body, tokenSession);

			if (response) {
				return response;
			}
		} catch (error) {}
	};

	const postAddProduct = async (body: object, orderId: number) => {
		try {
			const response = await postAddProductsBulk(orderId, body, tokenSession);

			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_TO_CART,
				rfc: '',
				ecommerce: {
					items: response?.map((item: Item) => ({
						price: item?.base_price / 100,
						item_id: item?.product_version?.product_id,
						item_name: item?.product_version?.product?.name,
						category: item?.product_version?.product?.regime,
						item_category1: item?.product_version?.product?.category,
						item_category2: item?.purchase_type,
					})),
				},
			});
		} catch (error: any | Error) {
			throw error;
		}
	};

	const onErrorAddProducts = (error: Error | any, errorMessage?: string) => {
		if (errorMessage) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.error_to_add_product,
				description: errorMessage,
			};
			dispatch(showAlert(alert));
			return;
		}

		if (error?.response?.data?.message?.includes(ERROR.already_on_cart)) {
			navigate(ROUTES.CHECKOUT);
			return;
		}

		if (error?.response?.data?.message?.includes('pendiente de pago')) {
			dispatch(setShowModalCancelPendingOrder(true));
			return;
		}

		if (error?.response?.data?.message?.includes('Ya cuentas')) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.already_on_cart,
				description: ERROR.error_to_add_product,
			};
			dispatch(showAlert(alert));
			return;
		}

		if (error?.response?.data?.message) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.error_to_add_product,
				description: error?.response?.data?.message,
			};
			dispatch(showAlert(alert));
			return;
		}

		const alert = {
			type: ALERT_TYPE.ERROR,
			title: ERROR.error_to_add_product,
			description: ERROR.failed_to_fetch,
		};
		dispatch(showAlert(alert));
	};

	const parseUserOrderHandler = (orders: OrderRefreshMapper[]) => {
		const lastOrder = orders.sort((a, b) => b.id - a.id)?.[0];

		const items: ProductVersionItem[] = lastOrder?.shoppingItem.map((item) => {
			const infoProduct = getProductProdByProductVersion(
				products,
				item.productVersionId,
			)!;
			return {
				...item,
				productVersion: {
					product: {
						id: infoProduct.id,
						name: infoProduct.name,
						defaultProductVersionId: infoProduct.default_product_version_id,
						description: infoProduct.description,
						isPurchasable: infoProduct.is_purchasable,
						benefits: infoProduct.benefits,
						slug: infoProduct.slug,
						category: infoProduct.category,
						regime: infoProduct.regime,
					},
					price:
						infoProduct.product_versions.find(
							(version) => version.id === item.productVersionId,
						)?.price ?? 0,
					periodicity:
						infoProduct.product_versions.find(
							(version) => version.id === item.productVersionId,
						)?.periodicity ?? infoProduct.periodicity,
				},
			};
		});

		const newOrder: TUserOrder = {
			...lastOrder,
			shoppingItem: items,
		};

		dispatch(setUserOrder([newOrder]));
		return newOrder;
	};

	const handleAddProductBySlugBulk = async (
		payload: {
			slug: string;
			additional_information: {
				year: number;
				month?: number;
				period?: string;
				type?: string;
			};
		}[],
		navigateToCart: boolean = true,
	) => {
		if (isLoading.products) return;
		setIsLoading({ ...isLoading, products: true });

		const productsToAdd = payload.map((item) => {
			const productId = getProductBySlug(item?.slug)
				?.default_product_version_id as number;

			const _price =
				getElasticyPrice(
					getPriceFromProductVersionId(products, productId),
					elasticityPrice.group_experiment,
				) * 100;
			return {
				product_version_id: productId,
				additional_information: item?.additional_information,
				custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
					? {
							group_experiment: elasticityPrice.group_experiment,
							total_paid: _price.toString(),
						}
					: undefined,
			};
		});

		try {
			// const order = await getOrder();
			const order = await getUserRefreshOrder();
			const response = await postAddProduct(productsToAdd, order?.id!);

			if (navigateToCart) {
				navigate(ROUTES.CHECKOUT);
			}

			return response;
		} catch (error: any | Error) {
			onErrorAddProducts(error);
		} finally {
			setIsLoading({ ...isLoading, products: false });
		}
	};

	const handleAddProductBySlug = async (
		product_slug: string,
		additional_information: {
			year: number;
			month?: number;
			period?: string;
			type?: string;
			regimes?: string[];
		},
		navigateToCart: boolean = true,
	) => {
		if (isLoading.products) return;
		setIsLoading({ ...isLoading, products: true });

		const productId = getProductBySlug(product_slug)
			?.default_product_version_id as number;

		try {
			// const order = await getOrder();
			let order = await getUserRefreshOrder();

			const _price =
				getElasticyPrice(
					getPriceFromProductVersionId(products, productId),
					elasticityPrice.group_experiment,
				) * 100;

			const response = await addItemBulkShoppingCart(
				order?.id!,
				[
					{
						product_version_id: productId,
						additional_information,
						custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
							? {
									group_experiment: elasticityPrice.group_experiment,
									total_paid: _price.toString(),
								}
							: undefined,
					},
				],
				tokenSession,
			);

			if (navigateToCart) {
				navigate(ROUTES.CHECKOUT);
			}

			const orderAdded = parseUserOrderHandler(response);
			const productName =
				orderAdded?.shoppingItem?.[0]?.productVersion?.product?.name;

			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: productName || '¡Listo!',
				description: 'Producto agregado al carrito exitosamente',
			};
			dispatch(showAlert(alert));

			const eventProperties = {
				event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
				product_name: productName,
			};

			logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_TO_CART,
				ecommerce: {
					items: orderAdded?.shoppingItem?.map((item: ProductVersionItem) => ({
						price: item?.productVersion?.price / 100,
						item_id: item?.id,
						item_name: item?.productVersion?.product?.name,
						category: item?.productVersion?.product?.regime?.toString(),
						item_category1: item?.productVersion?.product?.category,
						item_category2: item?.purchaseType,
					})),
				},
			});

			return response;
		} catch (error: any | Error) {
			onErrorAddProducts(error);
		} finally {
			setIsLoading({ ...isLoading, products: false });
		}
	};

	const handleAddCustomProduct = async ({
		payload,
		navigateToCart = true,
		resetOrders = false,
	}: {
		payload: object[];
		navigateToCart?: boolean;
		resetOrders?: boolean;
	}) => {
		const { dataLayer } = window as any;

		if (isLoading.products) return;
		setIsLoading({ ...isLoading, products: true });

		try {
			let order = await getUserRefreshOrder();

			const itemsIds = order?.shoppingItem?.map((item: ProductVersionItem) => ({
				shopping_item_id: item.id,
			}));

			if (resetOrders && order?.shoppingItem?.length && itemsIds?.length) {
				try {
					await deleteAllItemCart(itemsIds, order?.id, tokenSession);
				} catch (error) {}
			}

			const response = await addItemBulkShoppingCart(
				order?.id!,
				payload,
				tokenSession,
			);

			//@ts-ignore
			if (response?.message) {
				//@ts-ignore

				onErrorAddProducts(null, response?.message);
				return;
			}

			if (navigateToCart) {
				navigate(ROUTES.CHECKOUT);
			}

			const orderAdded = parseUserOrderHandler(response);
			const productName =
				orderAdded?.shoppingItem?.[0]?.productVersion?.product?.name;

			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: productName || '¡Listo!',
				description: 'Producto agregado al carrito exitosamente',
			};
			dispatch(showAlert(alert));

			const eventProperties = {
				event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
				product_name: productName,
			};

			logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_TO_CART,
				ecommerce: {
					items: orderAdded?.shoppingItem?.map((item: ProductVersionItem) => ({
						price: item?.productVersion?.price / 100,
						item_id: item?.id,
						item_name: item?.productVersion?.product?.name,
						category: item?.productVersion?.product?.regime?.toString(),
						item_category1: item?.productVersion?.product?.category,
						item_category2: item?.purchaseType,
					})),
				},
			});

			return response;
		} catch (error: any | Error) {
			onErrorAddProducts(error);
		} finally {
			setIsLoading({ ...isLoading, products: false });
		}
	};

	return {
		handleAddProductBySlugBulk,
		handleAddProductBySlug,
		handleAddCustomProduct,
		isLoading,
	};
};

export default useAddToCart;
