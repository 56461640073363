import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	CalendarCheck,
	CaretCircleLeft,
	CheckFat,
	ClockCountdown,
	Lock,
	SignOut,
} from '@phosphor-icons/react';

//? Types
import {
	Accountants,
	ServiceStatusElement,
} from '../../@types/ServiceStatus.type';
import { SummaryUnblock } from '../../@types/BlockedSubs';

//? Components
import Text from '../../components/atoms/Text';
import Heading from '../../components/atoms/Heading';
import Divider from '../../components/atoms/Divider';
import Button from '../../components/atoms/Button';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import ModalLinkRFC from './components/organisms/ModalLinkRFC';
import AlertBanner from '../../components/molecules/AlertBanner';
import ModalESignature from './components/organisms/ModalESignature';
import ModalDigitalSeals from './components/organisms/ModalDigitalSeals';
import { BlockedBulkList } from './components/organisms/ActiveServices';
import ModalUnlockStatements from './components/organisms/ModalUnlockStatements';

//? Utils
import { getFormatDate } from '../../shared/utils/dates.util';
import UserFillIcon from '../../assets/img/svg/user_fill.svg';
import { HUBSPOT_LINKS } from '../../shared/constants/hubspot';
import {
	ACTION_CURRENT_SLUG,
	PURCHASED_DATE,
	VARIANT_STATUS,
	renderAnnualValidateStatus,
} from '../../shared/constants/serviceStatus';
import ROUTES from '../../shared/constants/routes';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import LITERALS from '../../shared/constants/literals';
import { CONFIG_WHATSAPP_SUPPORT } from '../../shared/constants/whatsappNumbers';

//? Services
import {
	getAccountants,
	getAllServiceStatus,
	getAnnualDeclarationDocument,
	getDeclarationDocument,
	getListBlockedCart,
	getPriceBlockedCart,
	postAddItemToBlockedCart,
	postCreateBlockedCart,
} from '../../services/serviceStatus.service';

//? Redux
import { RootState } from '../../redux/store';
import { showAlert } from '../../redux/slices/alert.slice';
import { PRODUCTS_SLUG } from '../../shared/constants/SlugsProducts';

//? Hooks
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { PlatformOS } from '../../redux/slices/profile.slice';
import STORAGE_KEYS from '../../shared/constants/storage';
import { useStorage } from '../../shared/utils/general.util';

const ServiceStatusDetail: React.FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const isMobile = useMediaQuery('(max-width: 768px)');

	const [origin, setOrigin] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showRFCLink, setShowRFCLink] = useState(false);
	const [showESignature, setShowESignature] = useState(false);
	const [showDigitalSeals, setShowDigitalSeals] = useState(false);
	const [showUnlockModal, setShowUnlockModal] = useState(false);
	const [activeUnlockModal, setActiveUnlockModal] = useState(false);
	const [isLoadingAddBlocked, setIsLoadingAddBlocked] = useState(false);
	const [accountant, setAccountant] = useState<Accountants[]>([]);
	const [serviceStatus, setServiceStatus] = useState<ServiceStatusElement[]>(
		[],
	);

	const [isLoadingDownloadDocument, setIsLoadingDownloadDocument] =
		useState(false);
	const [hasDoc, setHasDoc] = useState(false);
	const [isFetchingDocument, setIsFetchingDocument] = useState(false);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) ?? PlatformOS.WEB;

	const serviceSelected = serviceStatus?.find(
		(service) => service.id === Number(id),
	);

	const getCurrentSlug =
		serviceSelected?.service_has_step.find(
			(step: any) => step?.id === serviceSelected?.current_service_has_step_id,
		)?.stage?.slug ?? '';

	const hasBookCallPending =
		serviceSelected?.current_service_has_alternate_step_id;

	const backToServiceStatus = () => {
		if (origin === 'home') {
			navigate(`/`);
		} else {
			navigate(ROUTES.SERVICE_STATUS);
		}
	};

	const getBgColorByStatus = (status: string) => {
		if (status === VARIANT_STATUS.COMPLETED) {
			return 'bg-h-success-50';
		} else if (status === VARIANT_STATUS.IN_PROGRESS) {
			return 'bg-h-information-50';
		} else if (status === VARIANT_STATUS.PENDING) {
			return 'bg-h-gray-20';
		} else if (status === VARIANT_STATUS.BLOCKED) {
			return 'bg-h-red-50';
		} else if (status === VARIANT_STATUS.ABANDONED) {
			return 'bg-h-neutral-50';
		}
	};

	const getIconByStatus = (status: string) => {
		if (status === VARIANT_STATUS.IN_PROGRESS) {
			return (
				<ClockCountdown
					size={14}
					weight='fill'
					color='var(--h-text-white)'
				/>
			);
		} else if (status === VARIANT_STATUS.BLOCKED) {
			return (
				<Lock
					size={12}
					weight='fill'
					color='var(--h-text-white)'
				/>
			);
		} else if (status === VARIANT_STATUS.ABANDONED) {
			return (
				<SignOut
					size={12}
					weight='fill'
					color='var(--h-text-white)'
				/>
			);
		}
	};

	const fetchServiceStatus = async () => {
		try {
			setIsLoading(true);
			const resource = await getAllServiceStatus(tokenSession);
			setServiceStatus(resource);
		} catch (error: any) {
		} finally {
			setIsLoading(false);
		}
	};

	const fetchAccountants = useCallback(async () => {
		try {
			const res: Accountants[] = await getAccountants(tokenSession);
			setAccountant(res);
		} catch (error) {}
	}, []);

	const getAccountantAssignedName = () => {
		const acc = accountant?.find(
			(acc) => acc?.id === serviceSelected?.accountant_id,
		);
		return `${acc?.first_name ?? ''}`;
	};

	const getAccountantAssignedProfilePic = () => {
		const acc = accountant?.find(
			(acc) => acc?.id === serviceSelected?.accountant_id,
		);
		return `${acc?.profile_picture ?? ''}`;
	};

	const addSingleBlockedPeriod = async (subId: number) => {
		try {
			setIsLoadingAddBlocked(true);
			let order = await getListBlockedCart(tokenSession);

			if (order.length === 0) {
				await postCreateBlockedCart(tokenSession);
				order = await getListBlockedCart(tokenSession);
			}
			const data: SummaryUnblock = await getPriceBlockedCart(
				subId,
				tokenSession,
			);

			let blockedProductsList: BlockedBulkList[] = [];

			serviceStatus
				.filter((item) => item?.id === serviceSelected?.id)
				.forEach((p) => {
					blockedProductsList.push({
						product_version_id: data?.product_version_id_for_unlock_declaration,
						custom_product: { total_paid: data?.final_price },
						additional_information: {
							month: p?.product?.additional_information?.month,
							year: p?.product?.additional_information?.year,
							service_id: p?.id,
							subscription_id: p?.product?.subscription_id,
						},
					});
				});

			if (order[0]?.items?.length === 0) {
				await postAddItemToBlockedCart(
					order[0]?.id,
					blockedProductsList,
					tokenSession,
				);
			}
			setActiveUnlockModal(true);
			setShowUnlockModal(true);
			setIsLoadingAddBlocked(false);
		} catch (error) {
			setIsLoadingAddBlocked(false);
		}
	};

	const alertSuccess = {
		type: ALERT_TYPE.SUCCESS,
		title: 'Aviso',
		description: `Documento descargado correctamente.`,
	};

	const alertError = {
		type: ALERT_TYPE.ERROR,
		title: 'Aviso',
		description: `Error al descargar el documento, intenta de nuevo.`,
	};

	const hasDocument = async () => {
		if (!serviceSelected?.product?.additional_information) return;

		try {
			setIsFetchingDocument(true);
			if (serviceSelected?.product?.additional_information) {
				const { year, month } =
					serviceSelected?.product?.additional_information;
				if (year && month) {
					const data = await getDeclarationDocument(year, month, tokenSession);
					if (data.length > 0) {
						setHasDoc(true);
					} else {
						setHasDoc(false);
					}
				} else if (year && !month) {
					const resp = await getAnnualDeclarationDocument(year, tokenSession);
					if (resp.length > 0) {
						setHasDoc(true);
					} else {
						setHasDoc(false);
					}
				}
				setIsFetchingDocument(false);
			}
		} catch (error) {
		} finally {
			setIsFetchingDocument(false);
		}
	};

	const handleDocument = useCallback(async () => {
		try {
			setIsLoadingDownloadDocument(true);
			if (
				serviceSelected &&
				serviceSelected?.product &&
				serviceSelected?.product?.additional_information
			) {
				const { year, month } =
					serviceSelected?.product?.additional_information;

				if (year && month) {
					const data = await getDeclarationDocument(year, month, tokenSession);
					if (data.length > 0) {
						const monthlyReceiptUrl = data[0]?.url;
						platformOS === PlatformOS.IOS
							? (window.location.href = data[0]?.url)
							: window.open(monthlyReceiptUrl, '_blank', 'noopener,noreferrer');
						dispatch(showAlert(alertSuccess));
					}
				} else if (year && !month) {
					const resp = await getAnnualDeclarationDocument(year, tokenSession);
					if (resp.length > 0) {
						const annualReceiptUrl = resp[0]?.url;
						platformOS === PlatformOS.IOS
							? (window.location.href = resp[0]?.url)
							: window.open(annualReceiptUrl, '_blank', 'noopener,noreferrer');
						dispatch(showAlert(alertSuccess));
					}
				}
			}
		} catch (error) {
			dispatch(showAlert(alertError));
		} finally {
			setIsLoadingDownloadDocument(false);
		}
	}, [serviceSelected]);

	const renderAnnualInfo = () => {
		if (
			serviceSelected?.current_service_has_step.stage?.icon_type !==
			VARIANT_STATUS.COMPLETED
		) {
			if (
				serviceSelected?.service_type_has_product?.product_slug ===
					PRODUCTS_SLUG.ANNUAL_DECLARATION ||
				serviceSelected?.service_type_has_product?.product_slug ===
					PRODUCTS_SLUG.ANNUAL_DECLARATION_BASIC_WITH_REGULARIZATION
			) {
				return (
					<div className='mt-2 md:mt-0'>
						<AlertBanner
							icon={
								<CalendarCheck
									size={24}
									weight='fill'
									color='var(--h-warning-30)'
								/>
							}
							variant='warning'
							title={
								dayjs(serviceSelected?.created_at).isAfter(PURCHASED_DATE)
									? 'Tu declaración será entregada en Mayo'
									: 'Tu declaración será presentada en un plazo de 15 días'
							}
							description={
								dayjs(serviceSelected?.created_at).isAfter(PURCHASED_DATE)
									? '¡No te preocupes! si tienes saldo a favor, no se verá afectado.'
									: 'Recuerda que es una declaración extemporánea'
							}
							sx='mt-4 !border-none items-center shadow-md'
						/>
					</div>
				);
			}
		}
	};

	useEffect(() => {
		fetchServiceStatus();
	}, [id]);

	useEffect(() => {
		if (serviceSelected?.accountant_id !== null) {
			fetchAccountants();
		}
	}, []);

	useEffect(() => {
		if (location.state && location.state.origin) {
			setOrigin(location.state.origin);
		}
	}, []);

	useEffect(() => {
		if (
			getCurrentSlug === ACTION_CURRENT_SLUG.STAGE_COMPLETED_DECLARATION ||
			ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION
		) {
			hasDocument();
		}
	}, [serviceSelected]);

	const renderActionBySlug = (slug: string) => {
		let content = null;

		switch (slug) {
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_TAX_VERIFICATION_DECLARATION:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_TAX_VERIFICATION_ANNUAL_DECLARATION:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_RFC_PROCESSING_SERVICE_ASAT:
				content = (
					<div className='w-full flex justify-end mt-4'>
						<div className='w-full lg:w-44'>
							<Button
								onClick={() => setShowRFCLink(true)}
								label='Vincular mi RFC'
								variant={hasBookCallPending ? 'secondary' : 'primary'}
							/>
						</div>
					</div>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_SUBSCRIPTION_BLOCKED_FOR_PAYMENT_DECLARATION:
				content = (
					<div className='w-full flex justify-end mt-4'>
						<div className='w-full lg:w-52'>
							<Button
								loading={isLoadingAddBlocked}
								onClick={() =>
									addSingleBlockedPeriod(
										serviceSelected?.product?.subscription_id!,
									)
								}
								label='Desbloquear periodo'
								variant={hasBookCallPending ? 'secondary' : 'primary'}
							/>
						</div>
					</div>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_IDENTIFICATION_FORM:
				content = (
					<div className='w-full flex justify-end mt-4'>
						<div className='w full lg:w-56'>
							<Button
								onClick={() => (window.location.href = HUBSPOT_LINKS.ASAT_FORM)}
								label='Completar formulario'
								variant={hasBookCallPending ? 'secondary' : 'primary'}
							/>
						</div>
					</div>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_COMPLETED_DECLARATION:
			case ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION:
				content = (
					<>
						{hasDoc && (
							<>
								{isFetchingDocument ? (
									<div className='flex items-center justify-center mt-5'>
										<CircularProgress size={25} />
									</div>
								) : (
									<div className='w-full flex justify-end mt-5'>
										<div className='w-full lg:w-56'>
											<Button
												loading={isLoadingDownloadDocument}
												onClick={() => handleDocument()}
												label='Descargar acuse'
												variant={'primary'}
											/>
										</div>
									</div>
								)}
							</>
						)}
					</>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_E_SIGNATURE_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_E_SIGNATURE_PROCESSING_SERVICE_ASAT:
				content = (
					<div className='w-full flex justify-end mt-4'>
						<div className='w-full lg:w-44'>
							<Button
								onClick={() => setShowESignature(true)}
								label='Subir mi e.Firma'
								variant={hasBookCallPending ? 'secondary' : 'primary'}
							/>
						</div>
					</div>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_STAMPS_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_STAMPS_PROCESSING_SERVICE_ASAT:
				content = (
					<div className='w-full flex justify-end mt-4'>
						<div className='w-full lg:w-60'>
							<Button
								onClick={() => setShowDigitalSeals(true)}
								label='Subir mis sellos digitales'
								variant={hasBookCallPending ? 'secondary' : 'primary'}
							/>
						</div>
					</div>
				);
				break;

			case ACTION_CURRENT_SLUG.STAGE_ABANDONED_STEP_IDENTIFICATION_FORM:
			case ACTION_CURRENT_SLUG.STAGE_ABANDONED_TAX_VERIFICATION_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_ABANDONED_PROCESSING_SERVICE_ASAT:
			case ACTION_CURRENT_SLUG.STAGE_REQUESTED_CALL_WITH_USER:
				content = (
					<div className='w-full flex justify-end mt-4'>
						<div className='w-full lg:w-48'>
							<Button
								onClick={() => window.open(HUBSPOT_LINKS.BOOK_CALL, '_blank')}
								label='Agendar llamada'
								variant={hasBookCallPending ? 'secondary' : 'primary'}
							/>
						</div>
					</div>
				);
				break;

			default:
				break;
		}
		return content;
	};

	const renderOnHoldText = () => {
		if (
			serviceSelected?.product?.regime?.includes('Plataformas Tecnológicas')
		) {
			return 'Estamos a la espera de tus certificados de ingresos para proceder con tu declaración.';
		} else {
			return 'Estamos recopilando tus datos y pronto procesaremos tu declaración.';
		}
	};

	const whatsappLink = useMemo(
		() =>
			`${CONFIG_WHATSAPP_SUPPORT}?text=${LITERALS.WHATSSAP_REGULARIZATIONS}`,
		[],
	);

	const renderDescription = (item: any) => {
		if (item?.stage?.icon_type === VARIANT_STATUS.IN_PROGRESS) {
			if (
				getCurrentSlug ===
				ACTION_CURRENT_SLUG.STAGE_IN_PROGRESS_ON_HOLD_DECLARATION
			) {
				return (
					<Text
						size='mini-1'
						color='blueInformation'
						weight='regular'
					>
						{renderOnHoldText()}
					</Text>
				);
			} else {
				return (
					<Text
						size='mini-1'
						color='blueInformation'
						weight='regular'
					>
						{item?.stage === null
							? item?.step?.description
							: item?.stage?.description}
					</Text>
				);
			}
		} else if (item?.stage?.icon_type === VARIANT_STATUS.BLOCKED) {
			if (
				getCurrentSlug ===
				ACTION_CURRENT_SLUG.STAGE_IN_PROGRESS_VALIDATE_STATUS_MONTHLY_DECLARATION_IN_ANNUAL_DECLARATION
			) {
				return (
					<>
						{item?.fields_status?.regulations_pending_for_present ===
							VARIANT_STATUS.COMPLETED &&
						item?.fields_status?.declarations_pending_for_pay_in_sat ===
							VARIANT_STATUS.PENDING ? (
							<a
								className='cursor-pointer'
								href={whatsappLink}
								target='_blank'
								rel='noreferrer'
							>
								<Text
									size='mini-1'
									color='red'
									weight='regular'
									sx='!block'
								>
									{renderAnnualValidateStatus(item?.fields_status)}
								</Text>
							</a>
						) : (
							<Text
								size='mini-1'
								color='red'
								weight='regular'
							>
								{renderAnnualValidateStatus(item?.fields_status)}
							</Text>
						)}
					</>
				);
			} else {
				return (
					<Text
						size='mini-1'
						color='red'
						weight='regular'
					>
						{item?.stage === null
							? item?.step?.description
							: item?.stage?.description}
					</Text>
				);
			}
		} else if (item?.stage?.icon_type === VARIANT_STATUS.COMPLETED) {
			const description =
				item?.stage === null
					? item?.step?.description
					: item?.stage?.description;
			const slug = item.stage.slug;

			if (
				slug ===
					ACTION_CURRENT_SLUG.STAGE_COMPLETED_CONFIRM_APPOINTMENT_ASAT_TAX_VERIFICATION_ASAT ||
				slug === ACTION_CURRENT_SLUG.STAGE_COMPLETED_ASAT ||
				slug === ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION ||
				slug === ACTION_CURRENT_SLUG.STAGE_COMPLETED_DECLARATION
			) {
				return (
					<Text
						size='mini-1'
						color='greenSuccess'
						weight='regular'
					>
						{hasDoc
							? description
							: 'Tu declaración ya está presentada. Ahora extraeremos la información del SAT para obtener tu acuse. Cuando esté listo podrás descargarlo.'}
					</Text>
				);
			}
		} else if (item?.stage?.icon_type === VARIANT_STATUS.ABANDONED) {
			return (
				<Text
					size='mini-1'
					color='gray'
					weight='regular'
				>
					{item?.stage === null
						? item?.step?.description
						: item?.stage?.description}
				</Text>
			);
		}
	};

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: '/service-status-detail',
		});
	}, []);

	return (
		<>
			{activeUnlockModal && (
				<ModalUnlockStatements
					isSingle
					showModal={showUnlockModal}
					setShowModal={setShowUnlockModal}
					activeUnlockModal={activeUnlockModal}
					fetchServiceStatus={fetchServiceStatus}
				/>
			)}
			<ModalLinkRFC
				showModal={showRFCLink}
				setShowModal={() => setShowRFCLink(false)}
				onCallbackSuccess={() => {
					fetchServiceStatus();
					setShowRFCLink(false);
				}}
			/>
			<ModalESignature
				showModal={showESignature}
				setShowModal={() => setShowESignature(false)}
				onCallbackSuccess={() => {
					fetchServiceStatus();
					setShowESignature(false);
				}}
			/>
			<ModalDigitalSeals
				showModal={showDigitalSeals}
				setShowModal={() => setShowDigitalSeals(false)}
				onCallbackSuccess={() => {
					fetchServiceStatus();
					setShowDigitalSeals(false);
				}}
			/>

			{isLoading ? (
				<div className='flex items-center justify-center mt-5'>
					<CircularProgress size={30} />
				</div>
			) : (
				<div className='px-5 py-6 md:px-10 md:py-6'>
					<div className='flex flex-col'>
						<div className='flex items-center'>
							<div>
								<button onClick={backToServiceStatus}>
									<CaretCircleLeft
										size={isMobile ? 24 : 32}
										weight='fill'
										color='var(--h-primary)'
									/>
								</button>
							</div>

							<Heading
								size='s'
								weight='bold'
								color='medium'
								sx='ml-2.5 hidden lg:flex'
							>
								{serviceSelected?.product?.name}
							</Heading>
							<Text
								size='body-2'
								weight='bold'
								color='medium'
								sx='ml-2.5 flex lg:hidden'
							>
								{serviceSelected?.product?.name}
							</Text>
						</div>

						<div className='flex items-center ml-10'>
							{serviceSelected?.product?.regime && (
								<>
									<Heading
										size='xs'
										weight='medium'
										color='medium'
										sx='mt-1 hidden lg:flex'
									>
										{serviceSelected?.product?.regime?.join(', ')}
									</Heading>
									<Text
										size='caption'
										weight='regular'
										color='medium'
										sx='mt-1 flex lg:hidden'
									>
										{serviceSelected?.product?.regime?.join(', ')}
									</Text>
								</>
							)}
						</div>

						<div className='flex flex-col bg-white p-5 w-full rounded-lg mt-6 shadow-md'>
							<div className='flex mb-4 md:mb-6'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
								>
									Estado de tu declaración
								</Text>
							</div>
							<Divider />

							<div>
								{serviceSelected?.service_has_step?.map((item, index) => (
									<div
										key={`step-${item.id}`}
										className='flex items-start justify-between'
									>
										<div className='flex gap-3 w-[70%]'>
											<div className='flex items-center flex-col'>
												<div
													className={`${getBgColorByStatus(
														item?.stage?.icon_type ?? item?.status,
													)} min-h-[24px] min-w-[24px] rounded-full flex items-center justify-center`}
												>
													{item?.status === VARIANT_STATUS.COMPLETED ? (
														<>
															{index ===
															serviceSelected?.service_has_step?.length - 1 ? (
																<CheckFat
																	size={14}
																	weight='fill'
																	color='var(--h-text-white)'
																/>
															) : (
																getIconByStatus(
																	item?.stage?.icon_type ?? item?.status,
																)
															)}
														</>
													) : (
														<div className='bg-white min-w-[20px] min-h-[20px] rounded-full flex items-center justify-center'>
															<div
																className={`${getBgColorByStatus(
																	item?.stage?.icon_type ?? item?.status,
																)} rounded-full w-4 h-4 flex items-center justify-center`}
															>
																{getIconByStatus(
																	item?.stage?.icon_type ?? item?.status,
																)}
															</div>
														</div>
													)}
												</div>

												{index !==
													serviceSelected?.service_has_step?.length - 1 && (
													<div
														className={`h-full min-h-[22px] w-1 ${getBgColorByStatus(
															item?.stage?.icon_type ?? item?.status,
														)}`}
													/>
												)}
											</div>
											<div className='mt-1 pb-2 flex flex-col flex-wrap items-start'>
												<Text
													size='caption'
													color={
														item?.stage?.icon_type ===
														VARIANT_STATUS.IN_PROGRESS
															? 'blueInformation'
															: item?.stage?.icon_type ===
																  VARIANT_STATUS.BLOCKED
																? 'red'
																: 'medium'
													}
													weight={
														item?.stage?.icon_type ===
															VARIANT_STATUS.IN_PROGRESS ||
														item?.stage?.icon_type === VARIANT_STATUS.BLOCKED
															? 'medium'
															: 'regular'
													}
												>
													{item?.stage === null
														? item?.step?.name
														: item?.stage?.name}
												</Text>

												{renderDescription(item)}
											</div>
										</div>

										{item.status === VARIANT_STATUS.COMPLETED && (
											<div
												className={`flex items-end justify-end w-[30%] mt-1`}
											>
												<Text
													size='caption'
													color='medium'
													weight='regular'
													sx='whitespace-nowrap'
												>
													{getFormatDate(item?.completed_at!)}
												</Text>
											</div>
										)}
									</div>
								))}
							</div>
							{renderActionBySlug(getCurrentSlug)}
							{renderAnnualInfo()}
						</div>

						{serviceSelected?.accountant_id &&
							serviceSelected?.service_type_has_product.category.includes(
								'asat',
							) && (
								<div className='flex items-center justify-between mt-6 bg-white shadow-md rounded-lg px-6 py-4'>
									<div className='flex items-center'>
										<div className='w-14 h-14 rounded-full flex items-center justify-center'>
											{!getAccountantAssignedProfilePic() ? (
												<img
													src={UserFillIcon}
													alt='no-avatar'
												/>
											) : (
												<img
													src={getAccountantAssignedProfilePic()}
													alt='accountant-avatar'
													className='w-12 h-12 object-cover rounded-full'
												/>
											)}
										</div>
										<div className='flex flex-col items-start lg:flex-row lg:items-center ml-4'>
											<Text
												size='caption'
												color='medium'
												weight='regular'
												sx='mr-1'
											>
												Tu contador asignado para este servicio es:
											</Text>
											<Text
												size='caption'
												color='medium'
												weight='bold'
												sx='mt-1 lg:mt-0'
											>
												{getAccountantAssignedName()}
											</Text>
										</div>
									</div>

									{hasBookCallPending && (
										<div className='flex flex-col items-end w-64'>
											<Button
												onClick={() =>
													window.open(HUBSPOT_LINKS.BOOK_CALL, '_blank')
												}
												label='Agendar llamada'
												variant={'primary'}
											/>
											<Text
												size='mini-1'
												weight='light'
												color='medium'
												sx='text-center mt-2'
											>
												Para continuar con tu servicio necesitamos ponernos en
												contacto contigo
											</Text>
										</div>
									)}
								</div>
							)}
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(ServiceStatusDetail);
