import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import { Checkbox } from '@mui/material';
import { ArrowRight, CircleNotch } from '@phosphor-icons/react';
import { useState } from 'react';
import Modal from '../../molecules/Modal/Modal';
import Button from '../../atoms/Button';
import LinkButton from '../../atoms/LinkButton';
import { FieldValues, UseFormHandleSubmit, useForm } from 'react-hook-form';
import LITERALS from '../../../shared/constants/literals';
import { REGEX } from '../../../shared/constants/regex';
import { TermsAndConditions } from '../../../@types/Profile.type';
import { HERU_TERMS_AND_CONDITIONS } from '../../../shared/constants/general';

type FormBody = {
	name: string;
	surname: string;
	email: string;
};

interface Props {
	control: any;
	handleSubmit?: UseFormHandleSubmit<FormBody, FieldValues>;
	onSubmit: () => void;
	errors: {
		name: string;
		surname: string;
		email: string;
	};
	isLoading: boolean;
	isFormValid: boolean;
	termsAndConditions: TermsAndConditions | null;
}

const RegisterForm = ({
	handleSubmit,
	onSubmit,
	control,
	errors,
	isLoading,
	isFormValid,
	termsAndConditions,
}: Props) => {
	const { control: defaultControl } = useForm();
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [showModal, setShowModal] = useState({
		terms: false,
	});
	const [isLoadingTerms, setIsLoadingTerms] = useState(true);

	const handleLoad = () => setIsLoadingTerms(false);

	const handleClose = () => {
		setShowModal((prev) => ({ ...prev, terms: false }));
		setIsLoadingTerms(true);
	};

	return (
		<>
			<Modal
				open={showModal.terms}
				onClose={handleClose}
			>
				<div className='!w-[calc(100vw-60px)] max-w-[800px] h-[600px] pt-6 relative'>
					{isLoadingTerms && (
						<Text
							sx='absolute top-2 left-2 bg-white border border-h-gray-30 w-fit px-2 py-1 rounded-xl'
							weight='medium'
						>
							<CircleNotch
								size={22}
								color='var(--h-primary)'
								weight='bold'
								className='animate-spin '
							/>
							Cargando Términos
						</Text>
					)}
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src={termsAndConditions?.url || HERU_TERMS_AND_CONDITIONS}
						allowFullScreen
						onLoad={handleLoad}
					></iframe>
				</div>
			</Modal>
			<form onSubmit={handleSubmit && handleSubmit(onSubmit)}>
				<div className='flex flex-col gap-4 mb-9 mt-1'>
					<Input
						rules={{ required: LITERALS.FORM_REQUIRED_ERROR }}
						control={control || defaultControl}
						name='name'
						type='text'
						label='Nombre'
						errorMessage={errors.name}
						autofocus
					/>
					<Input
						rules={{ required: LITERALS.FORM_REQUIRED_ERROR }}
						control={control || defaultControl}
						name='surname'
						type='text'
						label='Apellido'
						errorMessage={errors.surname}
					/>
					<Input
						rules={{
							required: LITERALS.FORM_REQUIRED_ERROR,
							pattern: {
								value: REGEX.emailRegex,
								message: 'Correo inválido',
							},
						}}
						control={control || defaultControl}
						name='email'
						type='email'
						label='Correo electrónico'
						errorMessage={errors.email}
					/>
				</div>
			</form>
			<div className='flex items-center ml-[-12px] mt-[-20px] mb-4'>
				<Checkbox
					checked={acceptedTerms}
					onClick={() => setAcceptedTerms(!acceptedTerms)}
					sx={{
						'&.Mui-checked': {
							color: 'var(--h-primary)',
						},
					}}
				/>
				<Text weight='light'>
					Acepto los{' '}
					<LinkButton
						label='términos y condiciones'
						onClick={() => setShowModal((prev) => ({ ...prev, terms: true }))}
						sx='!font-light'
					/>
				</Text>
			</div>
			<Button
				loading={isLoading}
				disabled={!isFormValid || !acceptedTerms}
				label='Crear cuenta'
				type='submit'
				onClick={onSubmit}
				icon={<ArrowRight size={18} />}
			/>
		</>
	);
};

export default RegisterForm;
