import PrimaryButton from '../../../components/atoms/Button';
import Text from '../../../components/atoms/Text';
import Modal from '../../../components/molecules/Modal/Modal';

interface IModalAIFailedProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	description: string;
}

const ModalAIFailed = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	description,
}: IModalAIFailedProps) => {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
		>
			<div className='!w-[300px] lg:!w-[calc(100vw-60px)] max-w-[500px] relative'>
				<Text
					size='subtitle'
					sx='mb-1'
					color='medium'
					weight='bold'
				>
					{title}
				</Text>
				<Text
					size='body-3'
					sx=''
					color='medium'
					weight='regular'
				>
					{description}
				</Text>

				<div className='flex justify-end items-center mt-4 gap-2'>
					<PrimaryButton
						label='Confirmar'
						sx='!w-full'
						variant='primary'
						onClick={onConfirm}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalAIFailed;
