import { setUserOrder } from '../redux/slices/cart.slice';
import { RootState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderRefresh } from '../services/orders.service';
import { setIsLoadingOrder } from '../redux/slices/cart.slice';
import { ProductVersionItem, TUserOrder } from '../@types/Order.type';
import { getProductProdByProductVersion } from '../shared/utils/ecommerce.util';
import { useSearchParams } from 'react-router-dom';
import { PlatformOS } from '../redux/slices/profile.slice';
import { postOrders } from '../services/order.service';
import STORAGE_KEYS from '../shared/constants/storage';
import { useStorage } from '../shared/utils/general.util';

const useUserShoppingCarts = () => {
	const dispatch = useDispatch();
	const { products } = useSelector((state: RootState) => state.productList);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS);

	const [searchParams] = useSearchParams();
	const channelSource = searchParams.get('channel_source');

	const getUserRefreshOrder = async () => {
		try {
			dispatch(setIsLoadingOrder(true));
			const params = '?status=open&status=failed&is_renewal=false';
			const orders = await getOrderRefresh(params, tokenSession);

			if (orders.length === 0) {
				createOrder();
				return;
			}
			if (products.length === 0) {
				return;
			}

			const lastOrder = orders.sort((a, b) => b.id - a.id)?.[0];

			const items: ProductVersionItem[] = lastOrder?.shoppingItem.map(
				(item) => {
					const infoProduct = getProductProdByProductVersion(
						products,
						item.productVersionId,
					)!;
					return {
						...item,
						productVersion: {
							product: {
								id: infoProduct.id,
								name: infoProduct.name,
								defaultProductVersionId: infoProduct.default_product_version_id,
								description: infoProduct.description,
								isPurchasable: infoProduct.is_purchasable,
								benefits: infoProduct.benefits,
								slug: infoProduct.slug,
								category: infoProduct.category,
								regime: infoProduct.regime,
							},
							price:
								infoProduct.product_versions.find(
									(version) => version.id === item.productVersionId,
								)?.price ?? 0,
							periodicity:
								infoProduct.product_versions.find(
									(version) => version.id === item.productVersionId,
								)?.periodicity ?? infoProduct.periodicity,
						},
					};
				},
			);
			const newOrder: TUserOrder = {
				...lastOrder,
				shoppingItem: items,
			};

			dispatch(setUserOrder([newOrder]));
		} catch (error: any) {
		} finally {
			dispatch(setIsLoadingOrder(false));
		}
	};

	const createOrder = async () => {
		try {
			let sourceChannel = 'web';
			if (
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
			) {
				sourceChannel = platformOS ?? 'app';
			}
			const body = {
				status: 'open',
				source_channel: sourceChannel,
			};
			const response = await postOrders(body, tokenSession);
			if (response) {
				dispatch(setUserOrder([response]));
			}
		} catch (error) {
		} finally {
			dispatch(setIsLoadingOrder(false));
		}
	};

	return { getUserRefreshOrder, createOrder };
};

export default useUserShoppingCarts;
